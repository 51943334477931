<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Catalogue Panel</h3>

                    <router-link to="/pdf-download" class="btn btn-primary">Create Catalogue</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Catalogue Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="catalogue in catalogues" :key="catalogue.id">
                                <th scope="row">{{ catalogue.id }}</th>
                                <td>{{ catalogue.catalogue_name }}</td>
                                <td>
                                    <button @click="downloadCatalogue(catalogue.id)" class="btn btn-primary"><i class="fa fa-download" aria-hidden="true"></i>
Download</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {

    data() {
        return {
            catalogues: [],
        };
    },

    created() {
        this.loadCatalogues();
    },

    methods: {
        loadCatalogues() {
            var page = `api/aff-index-pdf`;
            axiosInstance.get(page).then(({ data }) => {
                this.catalogues = data;
            });
        },

        downloadCatalogue(catalogueId) {
            // Use the catalogueId to fetch the specific catalogue PDF from the server
            var downloadPage = `api/aff-download-pdf/${catalogueId}`;
            axiosInstance.get(downloadPage, { responseType: 'blob' })
                .then(response => {
                    console.log(response); // Log the response for debugging

                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `catalogue_${catalogueId}.pdf`;
                    link.click();
                })
                .catch(error => {
                    console.error('Error downloading catalogue:', error);
                });
        },
    },
};
</script>
  
<style scoped></style>
  