<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Quantity</th>
                                <th>Commission</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="viewOrders in vieworders" :key="viewOrders.id">
                                <th scope="row">{{ viewOrders.id }}</th>
                                <th scope="row"><img
                                        :src="'https://api.gocami.com/product_detail/' + viewOrders.productdetail.image"
                                        height="50" width="50" />
                                </th>
                                <th scope="row">{{ viewOrders.productdetail.sku }}</th>
                                <th scope="row">{{ viewOrders.productdetail.title }}</th>
                                <th scope="row">{{ viewOrders.quantity }}</th>
                                <th scope="row">{{ viewOrders.percentage }}</th>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            vieworders: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `api/affiliate-view-order/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.vieworders = data.vieworder;
            });
        },
    },
};
</script>
<style scoped></style>