<template>
    <table width="100%" cellpadding="0" cellspacing="0" style="page-break-inside: avoid !important;">

        <tr>
            <td style="background-color:#dddddd">

                <!-- ========= Table content ========= -->


                <table cellpadding="0" cellspacing="0" width="600" class="table-mobile" align="center">
                    <tr>
                        <td height="25"></td>
                    </tr>

                    <!-- ========= Header ========= -->

                    <tr>
                        <td>

                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center">
                                <tr>
                                    <td class="header-item">
                                        <img :src="'https://api.gocami.com/logo_image/' + logos.logo" alt="" />
                                    </td>
                                    <td class="header-item">
                                        <p
                                            style="font-family:sans-serif;font-size:20px;font-weight:bold;text-transform:uppercase;margin-top:0;margin-bottom:0;color:#484848;text-align:right;">
                                            Invoice
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Divider ========= -->

                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #f8f8f8;" height="1"></td>
                                </tr>
                            </table>

                        </td>
                    </tr>

                    <tr>
                        <td
                            style="background:#ffffff;padding:35px 0;border-top:1px solid #eeeeee;border-bottom:1px solid #eeeeee; page-break-inside: avoid !important;">
                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center"
                                style="page-break-inside: avoid !important;">
                                <tr>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Shipping info</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Name:</strong> {{ invoices.first_name }} {{
                                                            invoices.middle_name }} {{ invoices.last_name }}
                                                    </p>

                                                    <p v-if="invoices.affiliate_first_name && invoices.affiliate_last_name"
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Affiliate Name:</strong> {{
                                                            invoices.affiliate_first_name
                                                        }} {{ invoices.affiliate_last_name }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Phone:</strong> (+961) {{ invoices.phone }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                        <td
                                            style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                <strong>Email:</strong> johndoe@company.com
                                            </p>
                                        </td>
                                    </tr> -->
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Location:</strong> {{ invoices.governorates_name }} {{
                                                            invoices.city_name }} {{ invoices.area_name }}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr class="break-before">
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Address:</strong> {{ invoices.address }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order details</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order no: INV00</strong>{{ invoices.id }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order Date :</strong> {{ invoices.pending_date }}
                                                    </p>
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Printing date :</strong> {{ currentDate }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Booking details ========= -->

                    <tr>
                        <td style="background-color:#ffffff;">

                            <table cellpadding="0" cellspacing="0" width="100%" align="center">

                                <tbody>

                                    <!----------- product table header ----------->

                                    <tr class="product-header">
                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Title
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Sku
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Configuration
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Quantity
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Discount
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Unite Price
                                            </p>
                                        </td>

                                        <td valign="middle" align="right" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:right;">
                                                Total
                                            </p>
                                        </td>

                                        <td valign="middle" align="right" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:right;">
                                                Disc
                                            </p>
                                        </td>
                                    </tr>

                                    <!--product-->

                                    <tr v-for="carts in cart" :key="carts.id">
                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            {{ carts.title }}
                                        </td>

                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            {{ carts.sku }}
                                        </td>

                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            <span
                                                v-if="carts.attribute_type_id !== null || carts.color_id !== null || carts.size_id !== null">
                                                {{ carts.attribute_type_name }} | {{ carts.color_name }} | {{
                                                    carts.size_name }}
                                            </span>
                                            <span v-else>&nbsp;</span>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                {{ carts.quantity }}
                                            </p>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                %{{ carts.discount }}
                                            </p>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                $ {{ carts.sale_price.toFixed(2) }}
                                            </p>
                                        </td>

                                        <td width="100" valign="middle" class="product-price">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:right;">
                                                $ {{ (carts.sale_price * carts.quantity - (carts.sale_price *
                                                    carts.quantity * carts.discount / 100)).toFixed(2) }}
                                            </p>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p v-if="carts.percentage"
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                %{{ carts.percentage }}
                                            </p>
                                            <p v-else>0</p>
                                        </td>

                                    </tr>


                                </tbody>


                            </table>
                        </td>
                    </tr>

                    <!-- ========= Booking price ========= -->

                    <tr class="break-before">
                        <td style="background-color:#f7f7f7;color:#3a3d45;padding:25px 0;" class="footer-content">

                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center">

                                <tr>
                                    <td style="padding-bottom:20px;">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td width="50%" valign="top">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:left;">
                                                        <strong>Total Before Discount</strong>
                                                    </p>
                                                </td>
                                                <td width="50%" valign="top">

                                                    <p v-if="invoices.price_after_discount !== null"
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:right;">
                                                        $ {{ beforeDisc.toFixed(2) }}
                                                    </p>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:left;">
                                                        <strong>Sub Total</strong>
                                                    </p>
                                                </td>
                                                <td width="50%" valign="top">

                                                    <p v-if="invoices.price_after_discount !== null"
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:right;">
                                                        $ {{ totalPrice.toFixed(2) }}
                                                    </p>

                                                </td>

                                            </tr>
                                            <tr>
                                                <td width="50%" valign="top">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:left;">
                                                        <strong>Shipping</strong>
                                                    </p>
                                                </td>
                                                <td width="50%" valign="top">
                                                    <p v-if="invoices && invoices.price !== null"
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:right;">
                                                        $ {{ invoices.price ? invoices.price.toFixed(2) : '0.00' }}
                                                    </p>
                                                    <p v-else
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin-top:0;margin-bottom:0;padding:3px 0;color:#3a3d45;text-align:right;">
                                                        $ 0.00
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td style="padding-top:20px; border-top:1px solid #dddddd">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td width="50%" valign="top">
                                                    <p
                                                        style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:left;">
                                                        <strong>Total price</strong>
                                                    </p>
                                                </td>
                                                <td width="50%" valign="top">
                                                    <p
                                                        style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:right;">
                                                        $ {{ (totalPrice + invoices.price).toFixed(2) }}
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">

                                            <tr>
                                                <td width="50%" valign="top">
                                                    <div
                                                        style="margin-top:25px; font-weight: bold; font-weight: 700;text-decoration: underline;">
                                                        Billing department:</div>
                                                </td>

                                                <td width="50%" valign="top">
                                                    <div
                                                        style="margin-top:25px; font-weight: bold; font-weight: 700;text-decoration: underline;">
                                                        Auditing:</div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td width="50%" valign="top">
                                                    <div
                                                        style="margin-top:50px; font-weight: bold; font-weight: 700;text-decoration: underline;">
                                                        Administration:</div>
                                                </td>

                                                <td width="50%" valign="top">
                                                    <div
                                                        style="margin-top:50px; font-weight: bold; font-weight: 700;text-decoration: underline;">
                                                        Customer:</div>
                                                </td>

                                            </tr>
                                        </table>


                                    </td>
                                </tr>


                            </table>
                        </td>
                    </tr>
                </table>

            </td>
        </tr>
    </table>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';


export default {
    name: 'ExportInvoice',

    data() {
        return {
            cart: [],
            logos: [],
            invoices: [],
            invo: []
        };
    },

    computed: {
        totalPrice() {
            let total = 0;

            this.invo.forEach((inv) => {
                if (inv.price_after_discount !== null) {
                    total += inv.price_after_discount;
                } else {
                    total += inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.discount / 100);
                }
            });

            return total;
        },

        beforeDisc() {
            let beforeDiscount = 0;

            this.invo.forEach((inv) => {
                beforeDiscount += inv.sale_price * inv.quantity
            });

            return beforeDiscount;
        }
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `api/user-affiliate-order/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.cart = data.cart;
                this.logos = data.logo;
                this.invoices = data.invoice;
                this.invo = data.inv;
            });
        },
    },
};
</script>

<style scoped>
@media print {
    .wtsp {
        display: none !important;
    }

    .page {
        page-break-after: avoid;
    }
}

.wtsp {
    display: none !important;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/****** EMAIL CLIENT BUG FIXES - BEST NOT TO CHANGE THESE ********/

.ExternalClass {
    width: 100%;
}

/* Forces Outlook.com to display emails at full width */
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

/* Forces Outlook.com to display normal line spacing, here is more on that*/

body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevents Webkit and Windows Mobile platforms from changing default font sizes. */

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

/* Resets all body margins and padding to 0 for good measure */

table td {
    border-collapse: collapse;
    border-spacing: 0px;
    border: 0px none;
    vertical-align: middle;
    width: auto;
}

/*This resolves the Outlook 07, 10, and Gmail td padding issue. Heres more info */
/****** END BUG FIXES ********/
/****** RESETTING DEFAULTS, IT IS BEST TO OVERWRITE THESE STYLES INLINE ********/

.table-mobile {
    width: 600px;
    margin: 0 auto;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.table-mobile-small {
    width: 560px !important;
}

body,
p {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: #222222;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

a {
    color: #2e73bb;
    text-decoration: none;
    display: inline-block;
}

img {
    border: 0 none;
    display: block;
}

@media(min-width:480px) {
    .product-header td {
        padding: 25px;
    }

    .product-image,
    .product-title,
    .product-price {
        border-bottom: 1px solid #eeeeee;
    }

    .product-title {
        padding-left: 15px;
    }

    .product-price {
        padding-right: 25px;
    }
}

@media (max-width:479px) {
    body {
        padding-left: 10px;
        padding-right: 10px;
    }

    table,
    table>tbody,
    table>tr,
    table>tbody>tr,
    table>tr>td,
    table>tbody>tr>td {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        /*height: auto !important;*/
    }

    .table-mobile-small {
        width: 95% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        height: auto !important;
    }

    .full-image {
        width: 100% !important;
    }

    .footer-content p {
        text-align: left !important;
    }

    .product-title,
    .product-price {
        width: 50% !important;
        float: left;
        border-bottom: 1px solid #eeeeee;
    }

    .product-image,
    .product-title,
    .product-price {
        padding: 10px;
    }

    .product-image img {
        width: 100% !important;
    }

    .product-price p {
        text-align: right !important;
    }

    .product-header {
        display: none !important;
    }

    .header-item {
        display: table-cell !important;
        float: none !important;
        width: 50% !important;
    }

    .table-mobile {
        box-shadow: none !important;
        margin: 0;
        border-radius: 0;
    }
}
</style>