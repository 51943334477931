<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Product Detail Images</h3>

                    <input type="file" class="form-control" multiple @change="onFileChange">

                    <div v-if="multi.multi_image && multi.multi_image.length > 0">
                        <h4>Selected Images:</h4>
                        <div v-for="(file, index) in multi.multi_image" :key="index">
                            <img :src="getImageUrl(file)" alt="Selected Image" class="selected-image" />
                            <button @click="removeImage(index)">Remove</button>
                        </div>
                    </div>

                    <button @click="save">Upload Images</button>

                    <div class="row mt-5">
                        <div class="col-4" v-for="product in product" :key="product.id">
                            <img :src="'https://api.gocami.com/product_multi_image/' + product.multi_image" alt=""
                                width="250">
                            <button @click="removeMultiImage(product.product_detail_id, product.id)" class="btn btn-danger"><i
                                    class="fa fa-trash"></i></button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../axiosInstance';
import router from "@/router";

export default {
    data() {
        return {
            multi: {
                product_detail_id: '', // Will be set dynamically
                multi_image: [],        // Initialize as an empty array
            },
            product: []
        };
    },

    created() {
        this.fetchImage();
    },

    methods: {
        onFileChange(e) {
            // Convert FileList to array
            this.multi.multi_image = Array.from(e.target.files);
            // Alternatively: this.multi.multi_image = [...e.target.files];
        },

        removeImage(index) {
            console.log('Type of multi_image before splice:', typeof this.multi.multi_image);
            console.log('Value of multi_image before splice:', this.multi.multi_image);

            if (Array.isArray(this.multi.multi_image)) {
                this.multi.multi_image.splice(index, 1);
            } else {
                console.error('multi_image is not an array');
            }

            console.log('Type of multi_image after splice:', typeof this.multi.multi_image);
            console.log('Value of multi_image after splice:', this.multi.multi_image);
        },

        save() {
            const url = window.location.href;
            const urlParts = url.split('/');
            const productId = parseInt(urlParts[urlParts.length - 1]);

            this.multi.product_detail_id = productId;

            const formData = new FormData();
            for (let i = 0; i < this.multi.multi_image.length; i++) {
                formData.append('multi_image[]', this.multi.multi_image[i]);
            }

            // Include the product_detail_id in the URL
            this.saveData(formData, this.multi.product_detail_id);
        },

        saveData(formData, productDetailId) {
            axiosInstance
                .post(`api/multi-image/${productDetailId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    alert('Images uploaded successfully.');
                    router.push("/product-description");
                })
                .catch(error => {
                    console.error('Error uploading images:', error);
                });
        },

        getImageUrl(file) {
            // Check if createObjectURL is available
            if (window.URL && window.URL.createObjectURL) {
                return URL.createObjectURL(file);
            } else {
                // Handle the case where createObjectURL is not available (e.g., use a placeholder)
                console.error('createObjectURL is not supported in this environment.');
                return ''; // Provide a default or placeholder URL
            }
        },

        fetchImage() {
            const page = `api/get-product-multi-image/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.product = data;
            }).catch(error => {
                console.error('Error fetching category:', error);
            });
        },

        removeMultiImage(productDetailId, imageId) {
            const url = `api/destroy-product-image/${productDetailId}/${imageId}`;
            axiosInstance.delete(url).then(() => {
                const index = this.product.findIndex(p => p.product_detail_id === productDetailId);
                if (index !== -1) {
                    this.product.splice(index, 1);
                }
            }).catch(error => {
                console.error("Error deleting image:", error);
            });
        }

    }
}
</script>

<style>
.selected-image {
    max-width: 100px;
    /* Set your desired maximum width */
    max-height: 100px;
    /* Set your desired maximum height */
    margin-right: 10px;
    /* Adjust margin as needed */
}
</style>
