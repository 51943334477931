<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Location</th>
                <th>Note</th>
                <th>Date</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="allOrders in allOrder" :key="allOrders.id">
                <th scope="row">{{ allOrders.id }}</th>
                <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                <td>{{ allOrders.area.area_name }}</td>
                <td>{{ allOrders.note }}</td>
                <td>{{ new Date(allOrders.created_at).toLocaleString() }}</td>
                <td v-if="allOrders.is_printed === 1" style="display:flex">
                  <span class="badge bg-success">Printed</span>
                  <router-link :to="{ name: 'OrderManagementNote', params: { id: allOrders.id } }"
                    class="btn btn-warning me-2">
                    Note
                  </router-link>
                </td>
                <td style="display:flex" v-else>
                  <router-link :to="{ name: 'OrderManagementPrint', params: { id: allOrders.id } }"
                    class="btn btn-primary mr-2">invoice</router-link>
                  <!-- <button @click="remove(allOrders)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->
                  <router-link :to="{ name: 'OrderManagementNote', params: { id: allOrders.id } }"
                    class="btn btn-warning mr-2">
                    Note
                  </router-link>
                  <span class="btn btn-danger mr-2">Print!!</span>
                </td>
                <td>
                  <span v-if="allOrders.is_exported === 0" class="badge badge-danger">Not Exported</span>
                  <span v-else class="badge badge-success">Exported</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
        
<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: "AllOrder",

  data() {
    return {
      allOrder: [],
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {

    orderLoad() {
      var page = `api/order-management-orders`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.allOrder = data;
      });
    },

    //   remove(colors) {
    //     var url = `${BASE_URL}color-delete/${colors.id}`;
    //     axios.delete(url).then(() => {
    //       const index = this.color.indexOf(colors);
    //       this.color.splice(index, 1);
    //     });
    //   },
  },
};

</script>
        
<style scoped></style>