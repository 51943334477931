<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="simplediscount.id" />
                        <input type="text" class="form-control" v-model="simplediscount.user_discount_name" /><br />
                        <input type="text" class="form-control" v-model="simplediscount.user_commission" /><br />
                        <input type="text" class="form-control" v-model="simplediscount.user_coupon_code" /><br />

                        <select v-model="simplediscount.is_delivery" class="form-control">
                            <option value="0">Charge Delivery</option>
                            <option value="1">Free Delivery</option>
                        </select>
                        <br />
                        <select v-model="simplediscount.status" class="form-control">
                            <option value="0">Available</option>
                            <option value="1">Not Available</option>
                        </select>
                        <br />
                        
                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import router from "@/router";
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "EditSimpleDiscount",

    data() {
        return {
            simplediscount: {
                user_discount_name: "",
                user_commission: "",
                user_coupon_code: "",
                is_delivery: "",
                status: "",
                id: this.$route.params.id,
            },
            oldDiscount: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axiosInstance
                .post(
                    `api/simple-discount-update/${this.simplediscount.id}`, // Use the BASE_URL constant for the base URL
                    this.simplediscount
                )
                .then(() => {
                    this.simplediscount = {
                        user_discount_name: "",
                        user_commission: "",
                        user_coupon_code: "",
                        is_delivery: "",
                        status: "",
                        id: "",
                    };
                    router.push("/simple-discount");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axiosInstance
                .get(`api/simple-discount-edit/` + this.simplediscount.id)
                .then((response) => {
                    this.oldDiscount = response.data; // store the old employee data in oldEmployee property
                    this.simplediscount.user_discount_name = this.oldDiscount.user_discount_name; // set the old employee_name value to the input
                    this.simplediscount.user_commission = this.oldDiscount.user_commission; // set the old employee_name value to the input
                    this.simplediscount.user_coupon_code = this.oldDiscount.user_coupon_code; // set the old employee_name value to the input
                    this.simplediscount.is_delivery = this.oldDiscount.is_delivery; // set the old employee_name value to the input
                    this.simplediscount.status = this.oldDiscount.status; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>