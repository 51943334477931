<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Category Panel</h3>

                    <form>
                        <button @click="submitForm" class="btn btn-success">Submit</button>

                        <select class="form-control mt-2 w-25 fs-5 mx-auto text-center mb-5 border-dark"
                            v-model="selectedChildcategory">
                            <option value="0" selected disabled hidden>-- Choose Child Category --</option>
                            <option v-for="childcategory in childcategories" :key="childcategory.id"
                                :value="childcategory.id">
                                {{ childcategory.child_category_name }}
                            </option>
                        </select>

                        <table class="table w-100">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Product Title</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="productdetail in productdetails" v-bind:key="productdetail.id">
                                    <td><input type="checkbox" :value="productdetail.id" v-model="selectedProducts"
                                            class="custom-checkbox"></td>
                                    <td>{{ productdetail.title }}</td>
                                </tr>

                            </tbody>
                        </table>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
      
<script>

import axiosInstance from '../../../../../axiosInstance';

import router from "@/router";

export default {
    name: "CreateProdCat",

    data() {
        return {
            productcat: {
                product_detail_id: 0,
                child_category_id: 0,
            },
            productdetails: [], // Update variable name to employees
            childcategories: [],
            selectedChildcategory: 0,
            selectedProducts: [],
        };
    },

    methods: {
        submitForm() {
            const formData = {
                child_category_id: this.selectedChildcategory,
                product_ids: this.selectedProducts,
            };

            axiosInstance.post(`api/prodcat-store`, formData)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/prodcat");
                })
                .catch(error => {
                    console.error(error);
                });
        },

        fetchAttributes() {
            axiosInstance
                .get(`api/prodcat-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.productdetails = response.data.productdetail;
                    this.childcategories = response.data.childcategory;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};

</script>
      
<style scoped>
.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}
</style>