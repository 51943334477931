<template>
    <div class="photo-app">
        <div class="photo-container">
            <div class="user-photo" @drop="dropSunglass($event)" @dragover.prevent>
                <div class="photo-overlay" @click="openCamera">
                    <span>Take a Photo</span>
                </div>
                <div class="image-controls mt-5">
                    <div class="row d-flex">
                        <div class="col-12 d-flex">
                            <input type="number" class="form-control mr-2" v-model="imageWidth" inputmode="numeric" />
                            <input type="number" class="form-control" v-model="imageHeight" inputmode="numeric" />
                        </div>

                    </div>
                </div>
                <div class="user-photo-image-container">
                    <img v-if="capturedPhoto" :src="capturedPhoto" alt="User Photo" class="user-photo-image" style="max-width: 450px; max-height: 300;"/>
                    <div class="placed-sunglasses">
                        <div v-for="(sunglass, index) in  placedSunglasses " :key="index" class="sunglass-item"
                            :style="{ left: sunglass.x + 'px', top: sunglass.y + 'px' }">
                            <img :src="'https://api.gocami.com/product_detail/' + sunglass.image" alt="Placed Sunglass"
                                class="sunglass-image" ref="resizableImage"
                                :style="{ height: `${imageHeight}px`, width: `${imageWidth}px` }" />
                            <button @click="removeSunglass(index)" class="remove-button">Remove</button>
                            <div class="resize-handle top-left" @mousedown="startResize(index, 'top-left', $event)"></div>
                        </div>
                    </div>
                </div>
                <div v-if="draggedSunglass" class="dragged-sunglass"
                    :style="{ backgroundImage: `url(${draggedSunglass})`, left: dragX + 'px', top: dragY + 'px', zIndex: 999 }">
                </div>
            </div>
            <div class="user-photo-drop-area" @drop="dropPhoto($event)" @dragover.prevent>
                <span>Drop Photo Here</span>
            </div>
        </div>
        <div class="sunglass-list">
            <div class="sunglass-list">
                <div v-for="sunglass in sungs" :key="sunglass.id" class="sunglass-thumbnail">
                    <img :src="'https://api.gocami.com/product_detail/' + sunglass.image" alt="Sunglass"
                        @click="displaySunglass(sunglass.image, sunglass.width, sunglass.height, $event)" />
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            sungs: [],
            capturedPhoto: null,
            placedSunglasses: [],
            selectedSunglass: null,
            selectedSunglassWidth: 0,
            selectedSunglassHeight: 0,
            draggedSunglass: null,
            dragX: 0,
            dragY: 0,
            showFaceGuideOverlay: false,
            resizingIndex: null,
            resizing: false,
            initialResizeMouseX: 0,
            initialResizeMouseY: 0,
            imageWidth: 75,
            imageHeight: 75,
        };
    },

    created() {
        this.fetchUserDetails();
    },

    methods: {
        displaySunglass(sunglassImage, width, height, event) {
            if (event) {
                const containerRect = event.currentTarget.getBoundingClientRect();
                const clickX = event.clientX - containerRect.left;
                const clickY = event.clientY - containerRect.top;

                this.placedSunglasses.push({
                    image: sunglassImage,
                    x: clickX - this.selectedSunglassWidth / 2, // Adjust for the sunglass width
                    y: clickY - this.selectedSunglassHeight / 2, // Adjust for the sunglass height
                    width,
                    height
                });
            }
        },

        startResize(index, handle, event) {
            this.resizingIndex = index;
            this.resizingHandle = handle;
            this.initialResizeMouseX = event.clientX;
            this.initialResizeMouseY = event.clientY;
            this.selectedSunglassWidth = this.placedSunglasses[index].width;
            this.selectedSunglassHeight = this.placedSunglasses[index].height;
            this.resizing = true;
            document.addEventListener('mousemove', this.performResize);
            document.addEventListener('mouseup', this.stopResize);
            document.addEventListener('touchmove', this.performResize); // Add touch event listener
            document.addEventListener('touchend', this.stopResize); // Add touch event listener
            event.preventDefault(); // Prevent any default behavior
        },

        handleDrag(event) {
            if (event.type === 'mousemove' || event.type === 'touchmove') {
                this.dragX = event.clientX || event.touches[0].clientX;
                this.dragY = event.clientY || event.touches[0].clientY;
            }
        },

        startDrag(sunglassImage, width, height, event) {
            this.selectSunglass(sunglassImage, width, height);

            if (event) {
                const clientX = event.clientX || (event.touches && event.touches[0].clientX);
                const clientY = event.clientY || (event.touches && event.touches[0].clientY);

                if (clientX !== undefined && clientY !== undefined) {
                    this.dragX = clientX;
                    this.dragY = clientY;
                }
            }
        },


        performResize(event) {
            if (this.resizingIndex !== null && this.resizing) {
                const sunglass = this.placedSunglasses[this.resizingIndex];
                const offsetX = event.clientX - this.initialResizeMouseX;
                const offsetY = event.clientY - this.initialResizeMouseY;

                let newWidth = this.selectedSunglassWidth;
                let newHeight = this.selectedSunglassHeight;

                if (this.resizingHandle.includes('left')) {
                    newWidth -= offsetX;
                    sunglass.x += offsetX;
                } else if (this.resizingHandle.includes('right')) {
                    newWidth += offsetX;
                }

                if (this.resizingHandle.includes('top')) {
                    newHeight -= offsetY;
                    sunglass.y += offsetY;
                } else if (this.resizingHandle.includes('bottom')) {
                    newHeight += offsetY;
                }

                sunglass.width = newWidth;
                sunglass.height = newHeight;

                this.initialResizeMouseX = event.clientX;
                this.initialResizeMouseY = event.clientY;
            }
        },

        stopResize() {
            document.removeEventListener('mousemove', this.performResize);
            document.removeEventListener('mouseup', this.stopResize);
            this.resizingIndex = null;
            this.resizingHandle = null;
            this.resizing = false;
        },

        fetchUserDetails() {
            axiosInstance
                .get(`api/customised`)
                .then((response) => {
                    this.sungs = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        openCamera() {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*';
            input.capture = 'camera';
            input.addEventListener('change', this.handlePhotoCapture);
            input.click();
            this.showFaceGuideOverlay = true;

        },
        // handleDrag(event) {
        //     this.dragX = event.clientX;
        //     this.dragY = event.clientY;
        // },

        handlePhotoCapture(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.capturedPhoto = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },

        selectSunglass(sunglassImage, width, height) {
            this.selectedSunglass = sunglassImage;
            this.selectedSunglassWidth = width;
            this.selectedSunglassHeight = height;
            this.draggedSunglass = sunglassImage;
        },

        removeSunglass(index) {
            this.placedSunglasses.splice(index, 1);
        },

        dropSunglass(event) {
            event.preventDefault();

            const dropX = event.clientX - event.currentTarget.getBoundingClientRect().left;
            const dropY = event.clientY - event.currentTarget.getBoundingClientRect().top;

            if (this.draggedSunglass) {
                this.placedSunglasses.push({
                    image: this.draggedSunglass,
                    x: dropX,
                    y: dropY,
                    width: this.selectedSunglassWidth,
                    height: this.selectedSunglassHeight
                });

                this.draggedSunglass = null;
                this.selectedSunglass = null;
                this.selectedSunglassWidth = 0;
                this.selectedSunglassHeight = 0;
            }
        },
        dropPhoto(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.capturedPhoto = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
    }
};
</script>

<style scoped>
.photo-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f2f2f2;
    padding: 20px;
}

.photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 20px;
}

.user-photo {
    position: relative;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
    /* Center the content horizontally */
    text-align: center;
    padding: 20px;
}


.photo-overlay {
    background-color: #3498db;
    /* Change the background color */
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
}

.user-photo-image-container {
    position: relative;
    width: 100%;
    height: auto;
}

.user-photo-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.placed-sunglasses {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.sunglass-item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sunglass-image {
    max-width: 100%;
    height: auto;
}

.dragged-sunglass {
    position: absolute;
    width: 80px;
    height: auto;
    pointer-events: none;
}

.user-photo-drop-area {
    margin: 20px;
    padding: 20px;
    border: 2px dashed #3498db;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

.user-photo-drop-area span {
    display: block;
    color: #3498db;
    font-weight: bold;
}

.sunglass-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.sunglass-thumbnail {
    cursor: pointer;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
}

.sunglass-thumbnail img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.remove-button {
    margin-top: 5px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 12px;
}

.remove-button:hover {
    background-color: #d93636;
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #000;
    cursor: pointer;
    z-index: 1000;
}

.top-left {
    top: -5px;
    left: -5px;
}

@media (max-width: 768px) {
    .photo-container {
        margin: 10px;
    }

    .sunglass-thumbnail {
        margin: 5px;
    }
}
</style>