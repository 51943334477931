<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create pdf name"
                            v-model="catalogue.catalogue_name" /><br /><br />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="catalogue.progress" :value="catalogue.progress.percentage" max="100">
                            {{ catalogue.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Category</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateCategory",

    data() {
        return {
            catalogue: {
                catalogue_name: "",
                catalogue_pdf: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.catalogue.catalogue_pdf = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('catalogue_name', this.catalogue.catalogue_name);
            formData.append('catalogue_pdf', this.catalogue.catalogue_pdf);
            axiosInstance.post(`api/store-pdf`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/index-download");
            });
        },
    },
};
</script>