<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <table class="responsive table-responsive table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <!-- <th>Location</th> -->
                <th>Note</th>
                <th>Date</th>
                <th>Action</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in allOrder.data" :key="order.id">
                <th scope="row">{{ order.id }}</th>
                <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                <!-- <td>{{ allOrders.area.area_name }}</td> -->
                <td>{{ order.note }}</td>
                <td>{{ new Date(order.created_at).toLocaleString() }}</td>
                <td style="display: flex;">
                  <router-link :to="{ name: 'ViewOrder', params: { id: order.id } }" class="btn btn-success me-2"
                    style="margin-right:10px"><i class="fa fa-eye"></i></router-link>
                  <router-link :to="{ name: 'ExportInvoice', params: { id: order.id } }" class="btn btn-primary"
                    style="margin-right:10px">invoice</router-link>
                  <!-- <button @click="remove(order)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->
                  <router-link :to="{ name: 'ExportInv', params: { id: order.id } }" class="btn btn-warning me-2"
                    style="margin-right: 10px">
                    Note
                  </router-link>
                  <span v-if="order.is_printed === 1" class="badge bg-success">~Printed!~</span>
                  <span v-else class="badge bg-danger">~Print It!~</span>
                  <!-- <router-link :to="{ name: 'AffiliateResult', params: { id: allOrders.id } }"
      class="btn btn-primary">Affilaite</router-link> -->
                </td>
              </tr>

            </tbody>
          </table>

          <div class="pagination">
            <button @click="orderLoad(allOrder.current_page - 1)"
              :disabled="allOrder.current_page === 1" class="btn btn-primary">Previous</button>
            <button @click="orderLoad(allOrder.current_page + 1)"
              :disabled="allOrder.current_page === allOrder.last_page" class="btn btn-primary">Next</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "AllOrder",

  data() {
    return {
      allOrder: {
        data: [],  // Array to store the orders
        current_page: 1,  // Current page number
        last_page: 1,  // Total number of pages
      },
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad(page = 1) {
      var endpoint = `api/all-orders?page=${page}`;
      axiosInstance.get(endpoint).then(({ data }) => {
        console.log(data);
        this.allOrder.data = data.data;  // Update the orders array
        this.allOrder.current_page = data.current_page;  // Update the current page
        this.allOrder.last_page = data.last_page;  // Update the last page
      });
    },
  },
};

</script>
      
<style scoped></style>