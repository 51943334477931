<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Orders Panel</h3>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="allOrders in allOrder" :key="allOrders.id">
                  <th scope="row">{{ allOrders.id }}</th>
                  <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                  <td>
                    <router-link :to="{ name: 'AffiliateViewOrder', params: { id: allOrders.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-eye"></i></router-link>
  
                    <button type="button" class="btn btn-primary" @click="updateInvoiceAction(allOrders.id)">Update</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
          
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  export default {
    name: "AllOrder",
  
    data() {
      return {
        allOrder: [],
      };
    },
  
    created() {
      this.orderLoad();
    },
  
    methods: {
      orderLoad() {
        var page = `api/admin-affiliate-all-orders`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.allOrder = data;
        });
      },
  
      updateInvoiceAction(id) {
        const endpoint = `api/admin-update-action/${id}`;
        axiosInstance.put(endpoint)
          .then(response => {
            // Handle the response if needed
            console.log(response.data);
            // Optionally, you can refresh the data after the update
            this.orderLoad();
          })
          .catch(error => {
            // Handle the error if needed
            console.error(error);
          });
      },
  
    },
  };
  
  </script>
          
  <style scoped></style>