<template>
  <div v-if="showToast" class="toast" :class="{ 'show': showToast, 'error': toastType === 'error' }">
    {{ toastMessage }}
  </div>

  <div class="wrapper" style="background-color: transparent;">
    <section class="login-wrapper login-wrapper-page" style="background-color: transparent;">
      <div class="container">
        <div class="flex-center">
          <div class="login-block login-block-signup">
            <div class="h4">
              Register now
              <router-link to="/login" class="btn btn-main btn-xs btn-login pull-right">Log in</router-link>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" v-model="form.first_name" class="form-control custom-input"
                    placeholder="First Name" />
                  <span class="error-message" v-if="errors && errors.first_name">{{ errors.first_name[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" v-model="form.last_name" class="form-control custom-input"
                    placeholder="Last Name" />
                  <span class="error-message" v-if="errors && errors.last_name">{{ errors.last_name[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <input type="email" v-model="form.email" class="form-control custom-input" placeholder="Your Email" />
                  <span class="error-message" v-if="errors && errors.email">{{ errors.email[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" v-model="form.phone_first" class="form-control custom-input"
                    placeholder="Your Number" />
                  <span class="error-message" v-if="errors && errors.phone_first">{{ errors.phone_first[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <input type="date" v-model="form.date_of_birth" class="form-control custom-input" />
                  <span class="error-message" v-if="errors && errors.date_of_birth">{{ errors.date_of_birth[0] }}</span>
                </div>
              </div>

              <div class="col-md-12" style="display: flex !important;">
                <div class="radio-container" v-for="gender in genders" :key="gender.id">
                  <input type="radio" :id="'radio-' + gender.id" class="form-check-input" v-model="form.gender_id"
                    :value="gender.id" required>
                  <label class="form-check-label" :for="'radio-' + gender.id">
                    <div class="custom-radio"></div>
                    {{ gender.gender_name }}
                  </label>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <input type="password" v-model="form.password" class="form-control custom-input"
                    placeholder="Your Password" />
                  <span class="error-message" v-if="errors && errors.password">{{ errors.password[0] }}</span>
                </div>
              </div>

              <div class="col-md-12">
                <re-captcha @recaptcha-verified="onRecaptchaVerified"></re-captcha>
              </div>

              <div class="col-md-12">
                <button @click.prevent="saveForm" type="submit" class="btn btn-main btn-block"
                  :disabled="isRegisterSuccessful">
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axiosInstance from '../../../../axiosInstance';
import router from "@/router";
import { useUserStore } from "../../../../store";
import Cookies from "js-cookie";
import ReCaptcha from './reCaptcha.vue';

export default {
  data() {
    return {
      isRegisterSuccessful: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        roles: "0",
        password: "",
        date_of_birth: "",
        gender_id: "",
        phone_first: ""
      },
      errors: null,
      genders: [],
      recaptchaResponse: null,
      showToast: false,
      toastMessage: "",
      toastType: "", // Ensure this property is declared
    };
  },

  components: {
    ReCaptcha,
  },

  created() {
    this.fetchGender();
  },
  methods: {

    insertGuestCart(userId, cartItems) {
      console.log("Inserting guest cart. User ID:", userId, "Cart items:", cartItems); // Log the user ID and cart items

      axiosInstance
        .post(`api/insert-guest-cart`, { user_id: userId, cart_items: cartItems })
        .then((response) => {
          // Handle successful insertion of guest cart data
          console.log("Guest cart data inserted successfully", response.data);
        })
        .catch((error) => {
          // Handle error during insertion
          console.error("Failed to insert guest cart data", error);
        });
    },


    fetchGender() {
      axiosInstance.get(`api/get-gender`).then(({ data }) => {
        this.genders = data; // Assuming your API returns an array of rates
      });
    },

    onRecaptchaVerified(response) {
      // This method is called when reCAPTCHA is successfully verified
      // You can store the reCAPTCHA response for later use
      this.recaptchaResponse = response;

      // Now, you can proceed with the form submission or any other logic
    },

    saveForm() {

      const requestData = {
        ...this.form,
        'g-recaptcha-response': this.recaptchaResponse,
      };

      axiosInstance
        .post(`api/register`, requestData)
        .then((response) => {
          this.isRegisterSuccessful = true;
          // Set the token to cookies
          const token = response.data.authorisation.token;
          Cookies.set("token", token);

          // Set the user in the store
          const userStore = useUserStore();
          userStore.setUser(response.data.user);

          // Extract the 'from' query parameter
          const from = this.$route.query.from;
          this.showToastMessage("Account created successfully!");

          // Redirect back to the originating page or default to home
          if (from) {
            router.push(from);
          } else {
            router.push({ path: "/" });
          }

          const userId = response.data.user.id; // Obtain the user ID from the response

          // Check if user is guest (no id)
          if (userId) {
            console.log("User is a guest"); // Log that user is a guest

            // Retrieve guest cart data from local storage
            const guestCart = JSON.parse(localStorage.getItem('guestCart'));
            console.log("Guest cart:", guestCart); // Log the guest cart data

            // If guest cart data exists, proceed to insert into database
            if (guestCart && guestCart.length > 0) {
              this.insertGuestCart(userId, guestCart)
                .then(() => {
                  // Clear guest cart data from local storage after successful insertion
                  localStorage.removeItem('guestCart');
                  console.log("Guest cart data cleared from local storage"); // Log that guest cart data is cleared
                })
                .catch(error => {
                  console.error("Error inserting guest cart data:", error); // Log error if insertion fails
                });
            } else {
              console.log("Guest cart is empty or not available"); // Log if guest cart is empty or not available
            }
          }

        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.errors = error.response.data.errors;
          } else {
            this.errors = {
              general: "An error occurred while processing your request.",
            };
          }
          this.showToastMessage("An error occurred while processing your request.", 'error');

        });
    },

    showToastMessage(message, type = 'success') {
      this.toastMessage = message;
      this.toastType = type;
      this.showToast = true;

      // Hide the toast after a certain duration (e.g., 3000 milliseconds)
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },

    hideToast() {
      this.showToast = false;
      this.toastMessage = "";
      this.toastType = "";
    },

  },
};
</script>

<style scoped>
.custom-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #3498db;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

.error-message {
  color: #e74c3c;
  margin-top: -10px;
  margin-bottom: 10px;
}

.custom-radio {
  width: 16px;
  height: 16px;
  border: 2px solid #3498db;
  border-radius: 50%;
  margin-right: 5px;
}

.toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #28a745 !important;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99999 !important;
}

.toast.error {
  background-color: #dc3545 !important;
  /* Red color for error */
}

.toast.show {
  opacity: 1;
}
</style>