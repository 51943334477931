<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Bundle Panel</h3>

                    <router-link to="/create-bundle" class="btn btn-primary">Create Bundle</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Bundle Image</th>
                                <th>Bundle Name</th>
                                <th>Bundle Sku</th>
                                <th>Bundle Price</th>
                                <th>Bundle Discount</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="bundle in bundles" v-bind:key="bundle.id">
                                <th scope="row">{{ bundle.id }}</th>
                                <td><img :src="'http://localhost:8000/bundle_image/' + bundle.bundle_image" style="width:50px; height:50px"></td>
                                <td>{{ bundle.bundle_name }}</td>
                                <td>{{ bundle.bundle_sku }}</td>
                                <td>{{ bundle.bundle_price }}</td>
                                <td>{{ bundle.bundle_discount }}</td>
                                <td>
                                    <router-link :to="{ name: 'EditBundle', params: { id: bundle.id } }"
                                        class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                                    <button @click="remove(bundle)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
    name: "BundleView",

    data() {
        return {
            bundles: [],
        };
    },

    created() {
        this.bundleLoad();
    },

    methods: {
        bundleLoad() {
            var page = `api/bundle`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.bundles = data.bundle;
            });
        },

          remove(bundle) {
            var url = `api/bundle-delete/${bundle.id}`;
            axiosInstance.delete(url).then(() => {
              const index = this.bundles.indexOf(bundle);
              this.bundles.splice(index, 1);
            });
          },

    },
};

</script>
      
<style scoped></style>