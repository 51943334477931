<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Refund Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>City</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoices in invoice" v-bind:key="invoices.id">
                                <th scope="row">{{ invoices.id }}</th>
                                <td>{{ invoices.first_name }} {{ invoices.middle_name }} {{ invoices.last_name }}</td>
                                <td>{{ invoices.area_id }}</td>
                                <td>{{ invoices.address }}</td>
                                <td>{{ invoices.phone }}</td>
                                <td>
                                    <router-link :to="{ name: 'RefundProductView', params: { id: invoices.id } }"
                                        class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "RefundView",

    data() {
        return {
            invoice: [],
        };
    },

    created() {
        this.productsaleLoad();
    },

    methods: {
        productsaleLoad() {
            var page = `api/invoice-refund`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.invoice = data;
            });
        },

    },
};

</script>
      
<style scoped></style> 
