<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>

                    <button @click="updateCheckedQuantities" class="btn btn-success">Update Checked Quantities</button>


                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Sku</th>
                                <th>title</th>
                                <th>image</th>
                                <th>Sale Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100">
                                </td>
                                <td>{{ product.sale_price }}</td>
                                <td><input type="text" v-model="product.newPrice" placeholder="New Price"></td>
                                <td><input type="checkbox" class="form-check-input" v-model="product.checked" /></td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
            product: {
                sale_price: ''
            },
            selectedBrand: null,
            brands: [],
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.fetchAllData(this.selectedBrand);
            }
        },


        fetchAllData(brandId) {
            return axiosInstance
                .get(`api/get-product-prices/${brandId}`)
                .then(response => {
                    this.allProductDetails = response.data.productDet.map(product => ({ ...product, checked: false }));
                    this.brands = response.data.brands;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateCheckedQuantities() {
            // Filter only the checked products
            const checkedProducts = this.allProductDetails.filter(product => product.checked);

            // Iterate over checked products and update their quantities
            checkedProducts.forEach(product => {
                const oldPrice = product.sale_price;
                const newPrice = parseFloat(product.newPrice); // Parse as float
                const productDetailId = product.id;

                // Update product quantity and insert into product_quantity_checks
                axiosInstance.put('api/update-product-prices', {
                    productDetailId,
                    oldPrice,
                    newPrice
                }).then(response => {
                    console.log(response);
                    product.sale_price = newPrice;
                    product.newPrice = ''; // Clear input field after update
                }).catch(error => {
                    console.log(error);
                });
            });
        }

    },
}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
