<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Brand Discount</h3>
  
            <router-link to="/create-brand-discount" class="btn btn-primary">Create Brand Discount</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Discount Name</th>
                  <th>Commission</th>
                  <th>Brand Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="branddiscounts in branddiscount" v-bind:key="branddiscounts.id">
                  <th scope="row">{{ branddiscounts.id }}</th>
                  <td>{{ branddiscounts.discount.discount_name }}</td>
                  <td>{{ branddiscounts.discount.commission }}</td>
                  <td>{{ branddiscounts.brand.brand_name }}</td>
                  <td>
                    <router-link :to="{ name: 'EditBrandDiscount', params: { id: branddiscounts.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(branddiscounts)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
import axiosInstance from '../../../../../axiosInstance';

  
  export default {
    name: "BrandDiscountView",
  
    data() {
      return {
        branddiscount: [],
      };
    },
  
    created() {
      this.branddiscountLoad();
    },
  
    methods: {
        branddiscountLoad() {
        var page = `api/brand-discount`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.branddiscount = data;
        });
      },
  
      remove(branddiscounts) {
        var url = `api/brand-discount-delete/${branddiscounts.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.branddiscount.indexOf(branddiscounts);
          this.branddiscount.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>