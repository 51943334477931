<template>
    <div class="main-panel ms-5">
        <div class="content-wrapper container">
            <!-- Add brand selection dropdown -->

            <div class="mt-5">
                <label for="brand">Select Brand:</label>
                <select v-model="selectedBrand" @change="orderLoad" class="form-select mb-4">
                    <option value="">All Brands</option>
                    <option v-for="brand in brands" :key="brand.id" :value="brand.id">{{ brand.brand_name }}</option>
                </select>
            </div>

            <div class="mt-5">
                <label for="brand">Select Supplier</label>
                <select v-model="selectedSupplier" class="form-select mb-4">
                    <option selected hidden>Select Supplier</option>
                    <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{ supplier.supplier_name
                    }}</option>
                </select>
            </div>

            <button class="mb-5" @click="submitProducts">Submit</button> <!-- Add submit button -->


            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="responsive table-responsive table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Image</th>
                                <th>Product Name</th>
                                <th>Supplier Name</th>
                                <th>Select</th> <!-- Add this column for checkboxes -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in allOrder" :key="index">
                                <th scope="row">{{ index + 1 }}</th>
                                <th><img :src="'https://api.gocami.com/product_main/' + order.product_image" width="50"
                                        height="50" /></th>
                                <td>{{ order.product_name }}</td>
                                <td><input type="checkbox" class="form-check-input" v-model="selectedProducts"
                                        :value="order.product_id"></td>
                                <td v-if="order.supplier_name" class="bg-success">{{ order.supplier_name }}</td>
                                <td v-else class="bg-danger">No Supplier</td>
                                <!-- Modify this line -->
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "CartInvoice",

    data() {
        return {
            allOrder: [],
            brands: [],
            selectedBrand: '',
            selectedSupplier: '',
            sortColumn: null,
            sortDirection: 'asc', // default sorting direction
            availableSortColumns: ['current_quantity', 'ordered_times'],
            selectedSortColumn: 'current_quantity', // default column to sort by
            selectedSortOrder: 'asc', // default sorting order
            suppliers: [],
            selectedProducts: [] // Array to hold selected product ids
        };
    },

    created() {
        this.orderLoad();
        this.loadSuppliers();
    },

    methods: {
        sortTable() {
            // Sort the products array based on the selected column and direction
            this.allOrder.sort((a, b) => {
                const modifier = this.selectedSortOrder === 'desc' ? -1 : 1;

                if (a[this.selectedSortColumn] < b[this.selectedSortColumn]) return -1 * modifier;
                if (a[this.selectedSortColumn] > b[this.selectedSortColumn]) return 1 * modifier;
                return 0;
            });
        },

        loadSuppliers() {
            axiosInstance.get('api/supplier').then(({ data }) => {
                this.suppliers = data;
            });
        },

        orderLoad() {
            var endpoint = `api/super-main-product-supplier`;
            var params = {
                brand_id: this.selectedBrand,
            };

            axiosInstance.get(endpoint, { params }).then(({ data }) => {
                console.log(data);
                this.brands = data.brands;
                this.allOrder = data.products;
            });
        },

        submitProducts() {
            if (this.selectedSupplier && this.selectedProducts.length > 0) {
                // Make API call to insert selected products for the selected supplier
                axiosInstance.post('api/main-product-suppliers', {
                    supplier_id: this.selectedSupplier,
                    product_ids: this.selectedProducts
                }).then(response => {
                    console.log(response.data.message);
                    this.orderLoad();
                    // Optionally, you can reset selectedProducts array after successful submission
                    this.selectedProducts = [];
                }).catch(error => {
                    console.error(error);
                });
            } else {
                // Handle case when no supplier is selected or no products are selected
                console.error('Please select a supplier and at least one product.');
            }
        }
    },
};
</script>
