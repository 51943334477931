<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Affiliate Panel</h3>
  
            <router-link to="/create-user-gift" class="btn btn-primary">Create User</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Affiliate Name</th>
                  <th>User Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
  
                <tr v-for="subaffiliate in subaffiliates" v-bind:key="subaffiliate.id">
                  <th scope="row">{{ subaffiliate.id }}</th>
                  <td>{{ subaffiliate.user.first_name }} {{ subaffiliate.user.last_name }}</td>
                  <td>{{ subaffiliate.user_first_name }} {{ subaffiliate.user_last_name }} {{ subaffiliate.user_last_name }}</td>
                  <td>
                    <button @click="remove(subaffiliate)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  import { useUserStore } from "../../../../../store";
  
  export default {
    name: "AffiliateUser",
    setup() {
      const store = useUserStore();
      const user = store.user;
      return {
        user,
      };
    },
    data() {
      return {
        subaffiliates: [],
      };
    },
  
    created() {
      this.userLoad();
    },
  
    methods: {
      userLoad() {
        var page = `api/affiliate-user-gift?user_id=${this.user?.id}`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.subaffiliates = data.userGift || [];
        });
      },
  
      remove(subaffiliate) {
        var url = `api/affiliate-user-gift-delete/${subaffiliate.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.subaffiliates.indexOf(subaffiliate);
          if (index !== -1) {
            this.subaffiliates.splice(index, 1);
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    font-size: 12pt !important;
  }
  </style>
  