<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Brand Images Panel</h3>
  
            <router-link to="/create-brand-image" class="btn btn-primary">Create Brand</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Brand Name</th>
                  <th>Brand Type</th>
                  <th>Brand Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="brands in brandimages" v-bind:key="brands.id">
                  <th scope="row">{{ brands.id }}</th>
                  <td>{{ brands.brand.brand_name }}</td>
                  <td>{{ brands.type }}</td>
                  <td><img :src="'https://api.gocami.com/multi_image/' + brands.multi_image"></td>
                  <td><button @click="remove(brands)" class="btn btn-danger"><i class="fa fa-trash"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  
  export default {
    name: "BrandImagesView",
  
    data() {
      return {
        brandimages: [],
      };
    },
  
    created() {
      this.brandLoad();
    },
  
    methods: {
      brandLoad() {
        var page = `api/brand-images`;
        axiosInstance.get(page).then(({ data }) => {
          this.brandimages = data.brandimage;
        });
      },
  
      remove(brands) {
        var url = `api/brand-images-destroy/${brands.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.brand.indexOf(brands);
          this.brand.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>