<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Brand Discount</h3>
  
            <router-link to="/create-user-brand-discount" class="btn btn-primary">Create Brand Discount</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Discount Name</th>
                  <th>Commission</th>
                  <th>Brand Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="userbranddiscounts in userbranddiscount" v-bind:key="userbranddiscounts.id">
                  <th scope="row">{{ userbranddiscounts.id }}</th>
                  <td>{{ userbranddiscounts.simplediscount.user_discount_name }}</td>
                  <td>{{ userbranddiscounts.simplediscount.user_commission	 }}</td>
                  <td>{{ userbranddiscounts.brand.brand_name }}</td>
                  <td>
                    <router-link :to="{ name: 'EditUserBrandDiscount', params: { id: userbranddiscounts.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(userbranddiscounts)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
import axiosInstance from '../../../../../axiosInstance';

  
  export default {
    name: "UserBrandDiscount",
  
    data() {
      return {
        userbranddiscount: [],
      };
    },
  
    created() {
      this.branddiscountLoad();
    },
  
    methods: {
        branddiscountLoad() {
        var page = `api/user-brand-discount`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.userbranddiscount = data;
        });
      },
  
      remove(userbranddiscounts) {
        var url = `api/user-brand-discount-delete/${userbranddiscounts.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.userbranddiscount.indexOf(userbranddiscounts);
          this.userbranddiscount.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>