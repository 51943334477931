<template>
    <div class="reset-password-container">
        <!-- Toast message for success/error -->
        <div v-if="showToast" class="toast" :class="{ 'show': showToast, 'error': toastType === 'error' }">
            {{ toastMessage }}
        </div>

        <form @submit.prevent="resetPassword" class="reset-password-form">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" required>

            <label for="password">New Password:</label>
            <input type="password" id="password" v-model="password" required>

            <label for="passwordConfirmation">Confirm Password:</label>
            <input type="password" id="passwordConfirmation" v-model="passwordConfirmation" required>

            <span class="password-mismatch" v-if="password !== passwordConfirmation">Passwords do not match</span>

            <button class="mt-3 text-white" type="submit" :disabled="password !== passwordConfirmation">Reset Password</button>
        </form>
    </div>
</template>
  
<script>
import axiosInstance from "../../../../axiosInstance";

export default {
    data() {
        return {
            email: '',
            password: '',
            passwordConfirmation: '',
            showToast: false,
            toastMessage: "",
            toastType: "success", // Default to success
        };
    },
    methods: {
        resetPassword() {
            // Check if passwords match
            if (this.password !== this.passwordConfirmation) {
                this.showToastMessage('Passwords do not match', 'error');
                return;
            }

            // Extract the token from the route parameters or another source
            const token = this.$route.params.token;

            axiosInstance.post('api/password/reset', {
                email: this.email,
                password: this.password,
                token: token, // Use the token extracted from the route
            }).then(() => {
                // Password reset successful
                this.showToastMessage('Password reset successfully');
            }).catch(error => {
                // Password reset failed
                this.showToastMessage('Password reset failed. Please try again.', 'error');
                console.error('Error resetting password:', error);
            });
        },

        showToastMessage(message, type = 'success') {
            this.toastMessage = message;
            this.toastType = type;
            this.showToast = true;

            // Hide the toast after a certain duration (e.g., 3000 milliseconds)
            setTimeout(() => {
                this.hideToast();
            }, 3000);
        },

        hideToast() {
            this.showToast = false;
            this.toastMessage = "";
            this.toastType = "";
        },
    },
};
</script>

<style scoped>
.toast {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #28a745;
    /* Default success color */
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 99999 !important;
}

.toast.error {
    background-color: #dc3545;
    /* Red color for error */
}

.toast.show {
    opacity: 1;
}

.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

.reset-password-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #3498db;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.password-mismatch {
    color: #e74c3c;
    margin-top: -10px;
    margin-bottom: 10px;
}

button {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
</style>
