<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Affiliate Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Affiliate Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in users" :key="user.id">
                                <th scope="row">{{ user.user.id }}</th>
                                <th scope="row">{{ user.user.first_name }} {{ user.user.last_name }}</th>
                                <td v-if="user.status === 0" style="color:red">Not Accepted</td>
                                <td v-else style="color:green">Accepted</td>
                                <td>
                                    <button class="btn btn-primary" @click="updateAffiliate(user.id)">Update</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
          
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "AllAffiliate",

    data() {
        return {
            users: [],
            counts: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `api/request-affiliate`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.users = data.getAffiliate;
            });
        },

        updateAffiliate(id) {
            var page = `api/update-affiliate/${id}`;
            axiosInstance.put(page).then(({ data }) => {
                this.orderLoad();
                console.log(data);
                // Update the status in the users array
                const updatedUser = this.users.find(user => user.id === id);
                if (updatedUser) {
                    updatedUser.status = 1; // Assuming 1 represents the accepted status
                }
            });
        }
    },
};

</script>
          
