<template>
    <div>
        <section class="products">
            <header>
                <div class="row">
                    <div class="col-md-offset-2 col-md-8 text-center">
                        <h2 class="title">Categories</h2>
                        <div class="text">
                            <p>Select category</p>
                        </div>
                    </div>
                </div>
            </header>

            <div class="row row-clean">
                <div class="col-xs-6 col-sm-4 col-md-3" style="padding: 0 !important; margin:0 !important"
                    v-for="category in categories" :key="category.id">
                    <article>
                        <div class="card">
                            <router-link :to="{ name: 'Categories', params: { id: category.category_name } }">
                                <img :src="'https://api.gocami.com/category_image/' + category.category_image" alt="" />
                            </router-link>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    </div>
    <FooterLayout />
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';


export default {
    components: { FooterLayout },
    name: "SubCategory",

    data() {
        return {
            categories: [],
        };
    },

    created() {
        this.dataLoad();
    },

    methods: {
        dataLoad() {
            var page = `api/user-view`;
            axiosInstance.get(page).then(({ data }) => {
                this.categories = data.category;
            });
        },
    },
};
</script>

<style scoped>
.card img {
    width: 42vh;
    height: 50vh;
}


.card {
    position: relative;
}

.card-body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white !important
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: #ccc;
}

.card-text {
    margin: 0;
    color: white !important;
}

@media (max-width: 767px) {

    .col-2,
    .col-9 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .subs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: auto;
    }

    .subcat {
        flex-basis: calc(50% - 10px);
        /* Adjust the percentage and spacing as needed */
        max-width: calc(50% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin: 5px;
        /* Adjust the margin as needed for spacing */
        box-sizing: border-box;
        /* Ensure long words break onto the next line */
        white-space: normal;
        /* Allow wrapping of long text */
        min-width: 150px;

    }

    .row-clean {
        margin: 0 -5px;
    }

    .product-item,
    .product-item-list {
        flex-basis: calc(30% - 10px);
        /* Display three products per row, adjust as needed */
        max-width: calc(30% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin-left: 20px;
        /* margin-right: 20px; */
    }

    .figure-grid,
    .figure-list {
        box-sizing: border-box;
        margin-right: 10px;
        /* Adjust the margin as needed for spacing between items */
        margin-bottom: 15px;
        /* Adjust the margin as needed for spacing between rows */
    }

    .figure-list {
        border-bottom: none;
        /* Remove border bottom for list view */
    }

    .product-image {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .text {
        padding: 10px;
        /* Adjust padding as needed */
    }

    /* Clear the margin-right for the last item in each row */
    .product-item:nth-child(3n) {
        margin-right: 0;
    }
}
</style>