<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <a class="nav-link" data-widget="pushmenu" role="button" @click="toggleSidebar"
        :class="{ 'mobile-sidebar-open': isSidebarOpen && isMobileScreen }">
        <i class="fas fa-bars"></i>
      </a>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->
      <li class="nav-item">
        <a class="nav-link" data-widget="navbar-search" href="#" role="button">
          <i class="fas fa-search"></i>
        </a>
        <div class="navbar-search-block">
          <form class="form-inline">
            <div class="input-group input-group-sm">
              <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
              <div class="input-group-append">
                <button class="btn btn-navbar" type="submit">
                  <i class="fas fa-search"></i>
                </button>
                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>

      <!-- Message Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge">3</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <a href="#" class="dropdown-item">
            <!-- Message Start -->
            <div class="media">
              <img src="admin_assets/dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Brad Diesel
                  <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">Call me whenever you can...</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            <!-- Message End -->
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <!-- Message Start -->
            <div class="media">
              <img src="admin_assets/dist/img/user8-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  John Pierce
                  <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">I got your message bro</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            <!-- Message End -->
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <!-- Message Start -->
            <div class="media">
              <img src="admin_assets/dist/img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  Nora Silvester
                  <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                </h3>
                <p class="text-sm">The subject goes here</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
              </div>
            </div>
            <!-- Message End -->
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
        </div>
      </li>
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- Main Sidebar Container -->
  <aside style="margin-top:150px !important" class="main-sidebar"
    :class="{ 'sidebar-hidden': !isSidebarOpen, 'sidebar-mobile': !isSidebarOpen && isMobileScreen }">

    <!-- Brand Logo -->
    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="background-color: black; font-size: 12pt;">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->

          <li class="nav-item" @click="toggleDropdown" :class="{ active: showDropdown }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Product
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/product" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productdetail" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Details</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/product-conf" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Configuration</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productbundle" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Bundle</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productdetailbundle" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Detail Bundle</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/prodcat" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Category</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productdetailsku" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Detail Quantity</p>
                </router-link>
              </li>

            </ul>
          </li>

          <li class="nav-item">
            <router-link to="/pending-products" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Pending Product
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item" @click="toggleDropdown2" :class="{ active: showDropdown2 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Categories
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown2" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/category" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Category</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/subcategory" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Sub Category</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/childcategory" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Child Category</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/prodcat" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Category</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown11" :class="{ active: showDropdown11 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Bundle
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown11" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/bundle" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Bundle</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productbundle" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Bundle</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/productdetailbundle" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Detail Bundle</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/prodcat" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Category</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown3" :class="{ active: showDropdown3 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Attributes
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown3" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/attribute" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Attribute</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/attributetype" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Attribute Type</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown4" :class="{ active: showDropdown4 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Filters
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown4" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/filter" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Filters</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/filtertype" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Filters Type</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown5" :class="{ active: showDropdown5 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Discounts
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown5" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Discount</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/brand-discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Brand Discount</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/affiliate-discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Affiliate Discount</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/affiliate-user-discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Affiliate User Discount</p>
                </router-link>
              </li>

            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown10" :class="{ active: showDropdown10 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Bag
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown10" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/bag-view" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Bag</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown6" :class="{ active: showDropdown6 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Brands
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown6" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/brand" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Brand</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/brand-images" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Brand Images</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown7" :class="{ active: showDropdown7 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Orders
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown7" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/all-orders" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>All Orders</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/admin-affiliate-order" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Affiliate Pending Order</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/pending-orders" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Pending Orders</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/closed-orders" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Closed Orders</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/refund-orders" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Refund Orders</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item" @click="toggleDropdown8" :class="{ active: showDropdown8 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Sales
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown8" class="nav nav-treeview">

              <li class="nav-item">
                <router-link to="/sale" class="nav-link">
                  <i class="nav-icon fas fa-th"></i>
                  <p>
                    Sale
                  </p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/product-sale" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Sale</p>
                </router-link>
              </li>

            </ul>
          </li>


          <li class="nav-item" @click="toggleDropdown9" :class="{ active: showDropdown9 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Simple Discount
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown9" class="nav nav-treeview">

              <li class="nav-item">
                <router-link to="/simple-discount" class="nav-link">
                  <i class="nav-icon fas fa-th"></i>
                  <p>
                    Simple Discount
                  </p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/user-brand-discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Brand Discount</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/user-product-discount" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Product Discount</p>
                </router-link>
              </li>

            </ul>
          </li>

          <li class="nav-item">
            <router-link to="/gift-card" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Card Gift
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>


          <li class="nav-item" @click="toggleDropdown13" :class="{ active: showDropdown13 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Supplier
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown13" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/supplier" class="nav-link">
                  <i class="nav-icon fas fa-th"></i>
                  <p>
                    Supplier
                    <!-- <span class="right badge badge-danger">New</span> -->
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/supplier-product" class="nav-link">
                  <i class="nav-icon fas fa-th"></i>
                  <p>
                    Supplier Products
                    <!-- <span class="right badge badge-danger">New</span> -->
                  </p>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="nav-item">
            <router-link to="/logo-view" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Logo
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/slider" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Slider
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/admin-user" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                User
                <!-- <span class="right badge badge-danger">New</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/color" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Color
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/size" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Size
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/bundle" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Bundle
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/refund" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Refund Invoice
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/aff-names" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Affiliate Results
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/tips-view" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Tips
              </p>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/affiliate-points" class="nav-link">
              <i class="nav-icon fas fa-th"></i>
              <p>
                Affiliate
              </p>
            </router-link>
          </li>



          <li class="nav-item" @click="toggleDropdown12" :class="{ active: showDropdown12 }">
            <div class="nav-link">
              <i class="nav-icon fas fa-copy"></i>
              <p>
                Return
                <i class="fas fa-angle-left right"></i>
                <span class="badge badge-info right">6</span>
              </p>
            </div>
            <ul v-if="showDropdown12" class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/affiliate-return-cart" class="nav-link">
                  <i class="nav-icon fas fa-th"></i>
                  <p>
                    Affiliate Return Cart
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/affiliate-return-invoice" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Affiliate Return Invoice</p>
                </router-link>
              </li>
            </ul>
          </li>


        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Dashboard</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Dashboard v1</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-info">
              <div class="inner">
                <h3>150</h3>

                <p>New Orders</p>
              </div>
              <div class="icon">
                <i class="ion ion-bag"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-success">
              <div class="inner">
                <h3>53<sup style="font-size: 20px">%</sup></h3>

                <p>Bounce Rate</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-warning">
              <div class="inner">
                <h3>44</h3>

                <p>User Registrations</p>
              </div>
              <div class="icon">
                <i class="ion ion-person-add"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-danger">
              <div class="inner">
                <h3>65</h3>

                <p>Unique Visitors</p>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
              <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
            </div>
          </div>
          <!-- ./col -->
        </div>
        <!-- /.row -->
        <!-- Main row -->

      </div>

    </section>

  </div>
</template>
  
<script>
export default {
  data() {
    return {
      isSidebarOpen: true,
      isMobileScreen: false,
      showDropdown: false,
      showDropdown2: false,
      showDropdown3: false,
      showDropdown4: false,
      showDropdown5: false,
      showDropdown6: false,
      showDropdown7: false,
      showDropdown8: false,
      showDropdown9: false,
      showDropdown10: false,
      showDropdown11: false,
      showDropdown12: false,
      showDropdown13: false,
    };
  },
  mounted() {
    this.checkMobileScreen(); // Check the screen size on component mount
    window.addEventListener('resize', this.checkMobileScreen); // Add a listener to update on window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkMobileScreen); // Remove the listener on component unmount
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleDropdown2() {
      this.showDropdown2 = !this.showDropdown2;
    },
    toggleDropdown3() {
      this.showDropdown3 = !this.showDropdown3;
    },
    toggleDropdown4() {
      this.showDropdown4 = !this.showDropdown4;
    },
    toggleDropdown5() {
      this.showDropdown5 = !this.showDropdown5;
    },
    toggleDropdown6() {
      this.showDropdown6 = !this.showDropdown6;
    },
    toggleDropdown7() {
      this.showDropdown7 = !this.showDropdown7;
    },
    toggleDropdown8() {
      this.showDropdown8 = !this.showDropdown8;
    },
    toggleDropdown9() {
      this.showDropdown9 = !this.showDropdown9;
    },
    toggleDropdown10() {
      this.showDropdown10 = !this.showDropdown10;
    },
    toggleDropdown11() {
      this.showDropdown11 = !this.showDropdown11;
    },
    toggleDropdown12() {
      this.showDropdown12 = !this.showDropdown12;
    },
    toggleDropdown13() {
      this.showDropdown13 = !this.showDropdown13;
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    checkMobileScreen() {
      this.isMobileScreen = window.innerWidth < 768; // Set the isMobileScreen flag based on the window width
    },
  },
};
</script>
  
  
<style scoped>
.nav-item {
  position: relative;
}

.nav-item .nav-link {
  cursor: pointer;
}

.nav-item ul {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  min-width: 200px;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 0;
  list-style: none;
}

.nav-item ul li {
  padding: 5px 0;
}

.nav-item ul li a {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
}

.nav-item.active ul {
  display: block;
}

.sidebar-hidden {
  display: none;
}

.sidebar-mobile {
  display: block !important;
  position: absolute;
  left: 50%;
  top: 50%;
}

.fa-bars {
  @media (max-width: 767px) {
    padding-left: 150px;
    position: fixed !important;
    /* Adjust the padding value as per your requirement */
    /* or use margin instead */
    /* margin-right: 10px; */
  }
}
</style>