<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <div class="row mb-3">
            <div class="col-3">
              <label for="fromDate">From Date:</label>
              <input type="date" v-model="fromDate" class="form-control">
            </div>
            <div class="col-3">
              <label for="toDate">To Date:</label>
              <input type="date" v-model="toDate" class="form-control">
            </div>
            <div class="col-3 mt-4">
              <button class="btn btn-primary" @click="applyDateFilter">Apply Filter</button>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <table class="table w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Address</th>
                    <th>Note</th>
                    <th>Date</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="allOrders in allOrder" :key="allOrders.id">

                    <th scope="row">{{ allOrders.id }}</th>
                    <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                    <td>{{ allOrders.address }}</td>
                    <td>{{ allOrders.note }}</td>
                    <td>{{ new Date(allOrders.created_at).toLocaleString() }}</td>

                    <td style="display: flex;">
                      <router-link :to="{ name: 'SuperAdminExport', params: { id: allOrders.id } }"
                        class="btn btn-warning me-2" style="margin-right:10px"><i class="fa fa-eye"></i></router-link>


                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-6">
              <table class="table w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Address</th>
                    <!-- <th>Location</th> -->
                    <th>Note</th>
                    <th>Date</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="exception in exceptions" :key="exception.id">
                    <th scope="row">{{ exception.invoice_id }}</th>
                    <td>{{ exception.first_name }} {{ exception.middle_name }} {{ exception.last_name }}</td>
                    <td>{{ exception.address }}</td>
                    <!-- <td>{{ exception.area.area_name }}</td> -->
                    <td>{{ exception.note }}</td>
                    <td>{{ new Date(exception.created_at).toLocaleString() }}</td>

                    <td style="display: flex; border: none;">
                      <router-link :to="{ name: 'SuperAdminExport', params: { id: exception.invoice_id } }"
                        class="btn btn-warning me-2" style="margin-right:10px"><i class="fa fa-eye"></i></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
          
<script>
import axiosInstance from '../../../axiosInstance';

export default {
  name: "SuperAdminOrder",

  data() {
    return {
      allOrder: [],
      exceptions: [],
      fromDate: null,
      toDate: null,
    };
  },

  created() {
    this.orderLoad();
    this.exceptionData();
  },

  methods: {

    orderLoad() {
      var page = `api/super-admin-orders-invoice?page=${this.currentPage}`;

      // Add from_date and to_date to the request if they are provided
      if (this.fromDate) {
        page += `&from_date=${this.fromDate}`;
      }

      if (this.toDate) {
        page += `&to_date=${this.toDate}`;
      }

      axiosInstance.get(page).then(({ data }) => {
        console.log('Fetched orders:', data);
        this.allOrder = data;
      });
    },


    exceptionData() {
      var page = `api/super-admin-orders-exception?page=${this.currentPage}`;

      // Add from_date and to_date to the request if they are provided
      if (this.fromDate) {
        page += `&from_date=${this.fromDate}`;
      }

      if (this.toDate) {
        page += `&to_date=${this.toDate}`;
      }

      axiosInstance.get(page).then(({ data }) => {
        console.log('Fetched exceptions:', data);

        // Remove duplicates based on the 'invoice_id' key
        const uniqueExceptions = this.removeDuplicates(data, 'invoice_id');

        this.exceptions = uniqueExceptions;
      });
    },

    applyDateFilter() {
      // Reset current page to 1 and reload data with the new date filter
      this.currentPage = 1;
      this.orderLoad();
      this.exceptionData();
    },

    removeDuplicates(array, key) {
      return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t[key] === item[key]
        ))
      );
    },


  },
};

</script>
          
<style scoped></style>