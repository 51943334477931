<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail Panel</h3>

          <router-link to="/create-productdetail" class="btn btn-primary">Create Product Detail</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Product Name</th>
                <th>SKU</th>
                <th>Price</th>
                <th>Discount</th>
                <th>New Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="productdetails in productdetail" v-bind:key="productdetails.id">
                <th scope="row">{{ productdetails.id }}</th>
                <td>{{ productdetails.products.product_name }}</td>
                <td>{{ productdetails.sku }}</td>
                <td>{{ productdetails.price }}</td>
                <td>{{ productdetails.discount }}</td>
                <td>{{ productdetails.new_price }}</td>
                <td>
                  <router-link :to="{ name: 'EditProductDetail', params: { id: productdetails.id } }"
                    class="btn btn-warning me-2">Edit</router-link>

                    <router-link :to="{ name: 'ProductConfigurationCreate', params: { id: productdetails.id } }"
                    class="btn btn-primary ml-2">Configuration</router-link>

                    <router-link :to="{ name: 'ProductFiltration', params: { id: productdetails.id } }"
                    class="btn btn-success ml-2">Filtration</router-link>


                  <button @click="remove(productdetails)" class="btn btn-danger ml-2"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "ProductDetailView",

  data() {
    return {
      productdetail: [],
    };
  },

  created() {
    this.productdetailLoad();
  },

  methods: {
    productdetailLoad() {
      var page = `api/productdetail`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.productdetail = data;
      });
    },

    remove(productdetails) {
      var url = `api/productdetail-delete/${productdetails.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.productdetail.indexOf(productdetails);
        this.productdetail.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>