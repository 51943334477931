<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Attribute Type Name"
                            v-model="attributetype.attribute_type_name" /><br /><br />

                        <select v-model="attributetype.attribute_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Attribute --</option>
                            <option v-for="attributes in attribute" :key="attributes.id" :value="attributes.id">
                                {{ attributes.attribute }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateAttributeType",

    data() {
        return {
            attributetype: {
                attribute_id: 0,
                attribute_type_name: "",
            },
            attribute: [], // Update variable name to employees
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('attribute_id', this.attributetype.attribute_id);
            formData.append('attribute_type_name', this.attributetype.attribute_type_name);
            axiosInstance.post(`api/attributetype-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/attributetype");
            });
        },
        fetchAttributes() {
            axiosInstance
                .get(`api/attributetype-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.attribute = response.data;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};
</script>
