<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Product Bundle Panel</h3>
  
            <router-link to="/create-productbundle" class="btn btn-primary">Create Product Bundle</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Bundle Name</th>
                  <th>Bundle Sku</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="productbundle in productbundles" v-bind:key="productbundle.id">
                  <th scope="row">{{ productbundle.id }}</th>
                  <td>{{ productbundle.product.product_name }}</td>
                  <td>{{ productbundle.bundle.bundle_name }}</td>
                  <td>{{ productbundle.bundle.bundle_sku }}</td>
                  <td>
                    <router-link :to="{ name: 'EditProductBundle', params: { id: productbundle.id } }" class="btn btn-warning me-2"><i
                        class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(productbundle)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  export default {
    name: "ProductBundlleView",
  
    data() {
      return {
        productbundles: [],
      };
    },
  
    created() {
      this.productbundleLoad();
    },
  
    methods: {
      productbundleLoad() {
        var page = `api/productbundle`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.productbundles = data.productbundle;
        });
      },
  
      remove(productbundle) {
        var url = `api/productbundle-delete/${productbundle.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.productbundles.indexOf(productbundle);
          this.productbundles.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>