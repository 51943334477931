import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyload from "vue-lazyload";
import VueSplide from "@splidejs/vue-splide";
import { createPinia } from "pinia";
import VueCookies from "vue-cookies";
import FacebookConfig from "../facebook-config"; // Import the configuration file

(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s);
  js.id = id;
  js.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=${FacebookConfig.version}`;
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

// Wait for the Facebook SDK to be ready before initializing the Vue app
window.fbAsyncInit = function () {
  window.FB.init(FacebookConfig);
};

const app = createApp(App);
app.use(VueCookies);
app.use(VueLazyload);
app.use(VueSplide);
app.use(createPinia());
app.use(router);
app.mount("#app");

window.addEventListener("popstate", (event) => {
  if (event.state && event.state.scrollPosition) {
    window.scrollTo(0, event.state.scrollPosition);
  }
});
