<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="productbundle.id" />

                        <select class="form-control mb-3" v-model="productbundle.product_id">
                            <option v-for="product in products" :key="product.id" :value="product.id">
                                {{ product.product_name }}
                            </option>
                        </select>

                        <select class="form-control mb-3" v-model="productbundle.bundle_id">
                            <option v-for="bundle in bundles" :key="bundle.id" :value="bundle.id">
                                {{ bundle.bundle_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>
                    
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import router from "@/router";
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "EditPoductBundle",

    data() {
        return {
            productbundle: {
                bundle_id: "",
                product_id: "",
                id: this.$route.params.id,
            },
            products: {}, // new data property to store the old employee data
            bundles: {}, // new data property to store the old employee data
            oldproductbundle: {},
        };
    },
    methods: {

        editData() {
            let formData = new FormData();
            if (this.productbundle.product_id) {
                formData.append('product_id', this.productbundle.product_id);
            }
            if (this.productbundle.bundle_id) {
                formData.append('bundle_id', this.productbundle.bundle_id);
            }
            axiosInstance.put(`api/productbundle-update/${this.productbundle.id}`, formData, {
            }).then(() => {
                this.productbundle = {
                    product_id: "",
                    bundle_id: "",
                    id: "",
                };
                router.push("/productbundle");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/productbundle-edit/` + this.productbundle.id)
                .then((response) => {
                    this.productbundle = response.data.productbundle;
                    this.products = response.data.product;
                    this.bundles = response.data.bundle;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>