<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body p-5">


          <div class="mb-5 p-4">
            <form @submit.prevent="saveSupplier" enctype="multipart/form-data">
              <div class="row">
                <div class="col-6">
                  <input type="text" class="form-control" v-model="productSupp.supplier_name"
                         placeholder="Enter The Supplier Name" required/>
                </div>

                <div class="col-6">
                  <input type="text" class="form-control" v-model="productSupp.phone_number"
                         placeholder="Enter The Supplier Phone" required/>
                </div>

                <div class="col">
                  <button class="btn btn-primary mt-3" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <hr/>
          <hr/>

          <div class="mb-5 p-4">
            <form @submit.prevent="mainProductData"> <!-- Corrected function call -->
              <div class="row">
                <div class="col-12">
                  <input type="text" class="form-control" v-model="mainProduct.product_name"
                  placeholder="Enter The Product Name" required/>
                </div>

                <div class="col">
                  <button class="btn btn-primary mt-3" type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>

          <hr/>
          <hr/>

          <!--                  <div v-if="selectedOption === '2'" class="mb-5 p-4">-->
          <!--                    <form @submit.prevent="supplierProduct" enctype="multipart/form-data">-->
          <!--                      <div class="row">-->
          <!--                        <div class="col-6">-->
          <!--                          <input class="form-control" v-model="supplier.supplier_name" placeholder="Supplier Name" />-->
          <!--                        </div>-->
          <!--                        <div class="col-6">-->
          <!--                          <select class="form-select" v-model="selectedProduct">-->
          <!--                            <option value="" hidden selected disabled>&#45;&#45; Select Product &#45;&#45;</option>-->
          <!--                            <option v-for="product in products" :key="product.id" :value="product.id">-->
          <!--                              {{ product.product_name }}-->
          <!--                            </option>-->
          <!--                          </select>-->
          <!--                        </div>-->
          <!--                      </div>-->
          <!--                      <button class="btn btn-primary mt-3" type="submit">Submit</button>-->
          <!--                    </form>-->
          <!--                  </div>-->


          <!--                    <div v-if="selectedOption === '3'">-->
          <!--                      <form @submit.prevent="ProductSupplier" enctype="multipart/form-data">-->
          <!--                        <div class="row">-->
          <!--                          <div class="col-6">-->
          <!--                            <input class="form-control" v-model="newProd.product_name" placeholder="Product Name" />-->
          <!--                          </div>-->
          <!--                          <div class="col-6">-->
          <!--                            <select class="form-select" v-model="newProd.supplier_id">-->
          <!--                              <option value="" hidden selected disabled>&#45;&#45; Select Supplier &#45;&#45;</option>-->
          <!--                              <option v-for="supplierproduct in supplierproduct" :key="supplierproduct.id" :value="supplierproduct.id">-->
          <!--                                {{ supplierproduct.supplier_name }}-->
          <!--                              </option>-->
          <!--                            </select>-->
          <!--                          </div>-->
          <!--                        </div>-->
          <!--                        <button class="btn btn-primary mt-3" type="submit">Submit</button>-->
          <!--                      </form>-->
          <!--                    </div>-->


          <form @submit.prevent="save" enctype="multipart/form-data">
            <div class="row mb-5" v-for="(detail, index) in product.details" :key="index">

              <div class="row mb-4">
                <div class="col-6">
                  <select class="form-control" v-model="detail.supplier_id">
                    <option value="" hidden selected disabled>-- Select Supplier --</option>
                    <option v-for="supplierproduct in supplierproduct" :key="supplierproduct.id"
                            :value="supplierproduct.id">
                      {{ supplierproduct.supplier_name }}
                    </option>
                  </select>
                </div>
                <div class="col-6">
                  <input type="number" class="form-control" placeholder="Invoice Number"
                         v-model="detail.invoice_number"/>
                </div>
              </div>

              <div class="col-12">
                <label for="">Select Product</label>

                <select v-model="detail.product_id" class="form-select mb-3">
                  <option value="0" selected disabled hidden>-- Choose product --</option>
                  <option v-for="productMains in productMain" :key="productMains.id" :value="productMains.id">
                    {{ productMains.product_name }}
                  </option>
                </select>
              </div>

              <div class="row">

                <div class="col-3 mb-3">
                  <input type="text" class="form-control" placeholder="Create Product Name" v-model="detail.name"/>
                </div>

                <div class="col-3 mb-3">
                  <input type="text" class="form-control" placeholder="Create sku" v-model="detail.sku"/>
                </div>

                <div class="col-3"><input type="number" class="form-control" placeholder="Create weight"
                                          v-model="detail.weight"/></div>
                <div class="col-3"><input type="date" class="form-control" v-model="detail.expiry_date"/>
                </div>
                <div class="col-3"><input type="text" class="form-control" placeholder="Number of product"
                                          v-model="detail.number_of_product"/></div>

                <div class="col-3">
                  <input type="text" class="form-control mb-3" placeholder="Create barcode" v-model="detail.barcode"/>
                </div>

                <div class="col-3">
                  <input type="text" class="form-control mb-3" placeholder="Create Main Quantity"
                         v-model="detail.main_quantity"/>
                </div>


              </div>

              <div class="col-2">
                <button type="button" class="btn btn-danger mb-4" @click="removeDetail(index)">Remove Product</button>
              </div>

              <div class="row mb-5" v-for="(item, itemIndex) in detail.items" :key="itemIndex">


                <div class="col-3">
                  <label for="color-search">Search Color</label>
                  <input
                      type="text"
                      v-model="detail.colorSearch"
                      class="form-control mb-2"
                      placeholder="Type to search color..."
                  />

                  <label for="color-select">Select Color</label>
                  <select
                      v-model="item.color_id"
                      class="form-control mb-3"
                  >
                    <option value="" hidden disabled>-- Choose color --</option>
                    <option
                        v-for="color in getFilteredColors(detail.colorSearch)"
                        :key="color.id"
                        :value="color.id"
                    >
                      {{ color.color_name }}
                    </option>
                  </select>
                </div>

                <!-- Search and Select for Sizes -->
                <div class="col-3">
                  <label for="size-search">Search Size</label>
                  <input
                      type="text"
                      v-model="detail.sizeSearch"
                      class="form-control mb-2"
                      placeholder="Type to search size..."
                  />

                  <label for="size-select">Select Size</label>
                  <select
                      v-model="item.size_id"
                      class="form-control mb-3"
                  >
                    <option value="" hidden disabled>-- Choose size --</option>
                    <option
                        v-for="size in getFilteredSizes(detail.sizeSearch)"
                        :key="size.id"
                        :value="size.id"
                    >
                      {{ size.size_name }}
                    </option>
                  </select>
                </div>

                <div class="col-3">
                  <label for="">Select Attribute</label>

                  <select v-model="item.attribute_type_id" class="form-control mb-3">
                    <option value="0" selected disabled hidden>-- Choose attribute --</option>
                    <option v-for="attribute in attributeOptions" :key="attribute.id" :value="attribute.id">
                      {{ attribute.attribute_type_name }}
                    </option>
                  </select>
                </div>
                <div class="col-3">
                  <label for="">Quantity</label>

                  <input type="number" class="form-control" placeholder="Create quantity" v-model="item.quantity"
                         required/>
                </div>
                <!-- <div class="col-2">
                  <button type="button" class="btn btn-danger" @click="removeItem(index, itemIndex)">Remove</button>
                </div> -->
              </div>

              <div class="row mt-4">
                <div class="col-3">
                  <label for="">Select Zone</label>
                  <select v-model="detail.selectedZone" class="form-select" @change="loadFloors(index)">
                    <option v-for="zone in zones" :key="zone.id" :value="zone.id">
                      {{ zone.zone_name }}
                    </option>
                  </select>
                </div>

                <div class="col-3">
                  <label for="">Select Floor</label>
                  <select v-model="detail.selectedFloor" class="form-select" @change="loadStands(index)">
                    <option v-for="floor in detail.floors" :key="floor.id" :value="floor.id">
                      {{ floor.floor_name }}
                    </option>
                  </select>
                </div>

                <div class="col-3">
                  <label for="">Select Stand</label>
                  <select v-model="detail.selectedStand" class="form-select" @change="loadShelves(index)">
                    <option v-for="stand in detail.standes" :key="stand.id" :value="stand.id">
                      {{ stand.stand_name }}
                    </option>
                  </select>
                </div>

                <div class="col-3">
                  <label for="">Select Shelve</label>
                  <select v-model="detail.selectedShelve" class="form-select">
                    <option v-for="shelve in detail.shelves" :key="shelve.id" :value="shelve.id">
                      {{ shelve.shelve_name }}
                    </option>
                  </select>
                </div>

              </div>
              <!-- <button type="button" class="btn btn-success" @click="addItem(index)">Add More</button> -->

              <hr/>
              <hr/>
              <hr/>
              ....................
            </div>

            <button type="button" class="btn btn-success mr-5" @click="addDetail">Add More Product</button>
            <button type="submit" class="btn btn-primary">Create Product</button>
          </form>
          <!-- Error messages -->
          <div v-if="errors.length" class="mt-3 text-danger">
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {

  data() {
    return {
      mainProduct: {
        product_name:''
      },
      productMain: '',
      category: '',
      brand: '',
      color: '',
      size: '',
      colorOptions: [],
      sizeOptions: [],
      attributeOptions: [],
      zone: '', // Corrected variable name
      selectedZone: '', // corrected property name
      selectedFloor: '',
      selectedStand: '',
      selectedShelve: '', // corrected property name
      floors: [], // corrected property name
      standes: [], // corrected property name
      shelves: [], // corrected property name
      productSupp: {
        supplier_name: '',
        phone_number: ''
      },
      supplier: {
        supplier_id: '',
        supplier_name: ''
      },
      newProd: {
        product_name: '',
        supplier_id: ''
      },
      selectedProduct: '',
      selectedSupplier: '',
      product: {
        details: [],
        items: [],
      },
      products: [],
      filteredFloors: [],
      filteredShelves: [],
      errors: [],
      productsupplier: '',
      supplierproduct: '',
      quantitySum: 0,
      zones: [],
    };
  },


  created() {
    this.fetchAllData();
    this.fetchSupplierProduct();
    this.loadZones();
    this.getMainProduct();
  },

  mounted() {
    // this.onZoneChange();
    // this.onFloorChange();
    this.fetchSupplierProduct();
  },


  watch: {
    // Watch for changes in selectedDetail
    'selectedDetail': {
      handler: 'calculateQuantitySum', // Call the method to recalculate sum when selectedDetail changes
      deep: true,
    },
  },

  methods: {

    getMainProduct() {
      return axiosInstance
          .get(`api/main-product-sub-admin`)
          .then(response => {
            this.productMain = response.data.product;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    getFilteredColors(searchTerm) {
      if (!searchTerm) {
        return this.colorOptions;
      }
      return this.colorOptions.filter(
          (color) => color?.color_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },

    getFilteredSizes(searchTerm) {
      if (!searchTerm) {
        return this.sizeOptions;
      }
      return this.sizeOptions.filter(
          (size) => size?.size_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },

    onFileChange(event) {
      this.product.image = event.target.files[0];
    },

    addDetail() {
      if (this.product.details.length > 0) {
        const lastDetail = this.product.details[this.product.details.length - 1];
        const newDetail = {
          product_id: lastDetail.product_id,
          sku: lastDetail.sku,
          weight: lastDetail.weight,
          expiry_date: lastDetail.expiry_date,
          barcode: lastDetail.barcode,
          supplier_id: lastDetail.supplier_id,
          invoice_number: lastDetail.invoice_number,
          name: lastDetail.name,
          shelve_id: "",
          main_quantity: lastDetail.main_quantity,
          number_of_product: lastDetail.number_of_product,
          selectedZone: "", // Reset zone
          selectedFloor: "", // Reset floor
          selectedStand: "", // Reset stand
          selectedShelve: "", // Reset shelve
          floors: [], // Reset floors array
          standes: [], // Reset standes array
          shelves: [], // Reset shelves array
          colorSearch: '',
          sizeSearch: '',
          items: [{color_id: "", size_id: "", attribute_type_id: "", quantity: 1}]
        };
        this.product.details.push(newDetail);
      } else {
        this.product.details.push({
          product_id: "",
          sku: "",
          weight: "",
          expiry_date: "",
          barcode: "",
          supplier_id: "",
          invoice_number: "",
          shelve_id: "",
          main_quantity: "",
          number_of_product: "",
          selectedZone: "", // Reset zone
          selectedFloor: "", // Reset floor
          selectedStand: "", // Reset stand
          selectedShelve: "", // Reset shelve
          floors: [], // Reset floors array
          standes: [], // Reset standes array
          shelves: [], // Reset shelves array
          items: [{color_id: "", size_id: "", attribute_type_id: "", quantity: 1}]
        });
      }
    },

    addItem(detailIndex) {
      this.product.details[detailIndex].items.push({color_id: "", size_id: "", attribute_type_id: "", quantity: 1});
    },

    removeItem(detailIndex, itemIndex) {
      this.product.details[detailIndex].items.splice(itemIndex, 1);
    },

    removeDetail(index) {
      this.product.details.splice(index, 1);
    },

    save() {
      // this.errors = [];
      // if (!this.validateForm()) {
      //   return;
      // }
      this.saveData();
    },

    // validateForm() {
    //   this.errors = []; // Clear previous errors

    //   let isValid = true;

    //   // Validate each detail
    //   this.product.details.forEach(detail => {
    //     // Check if each configuration quantity is not bigger than main_quantity
    //     detail.items.forEach(item => {
    //       if (item.quantity !== detail.main_quantity) {
    //         this.errors.push("Quantity cannot be greater than Main Quantity for any configuration");
    //         isValid = false;
    //       }
    //     });
    //   });

    //   return isValid;
    // },

    saveData() {
      const formData = new FormData();

      this.product.details.forEach((detail, index) => {
        formData.append(`productDetails[${index}][sku]`, detail.sku);
        formData.append(`productDetails[${index}][weight]`, detail.weight);
        formData.append(`productDetails[${index}][expiry_date]`, detail.expiry_date);
        formData.append(`productDetails[${index}][barcode]`, detail.barcode);
        formData.append(`productDetails[${index}][shelve_id]`, detail.selectedShelve);
        formData.append(`productDetails[${index}][invoice_number]`, detail.invoice_number);
        formData.append(`productDetails[${index}][supplier_id]`, detail.supplier_id);
        formData.append(`productDetails[${index}][main_quantity]`, detail.main_quantity);
        formData.append(`productDetails[${index}][number_of_product]`, detail.number_of_product);
        formData.append(`productDetails[${index}][name]`, detail.name);
        formData.append(`productDetails[${index}][product_id]`, detail.product_id);

        // Check if 'details' field exists in the 'detail' object
        if (!Object.prototype.hasOwnProperty.call(detail, 'details')) {
          detail.details = [];
        }

        detail.items.forEach((item, itemIndex) => {
          formData.append(`configurations[${index}][${itemIndex}][color_id]`, item.color_id);
          formData.append(`configurations[${index}][${itemIndex}][size_id]`, item.size_id);
          formData.append(`configurations[${index}][${itemIndex}][attribute_type_id]`, item.attribute_type_id);
          formData.append(`configurations[${index}][${itemIndex}][quantity]`, item.quantity);
        });
      });

      axiosInstance.post('api/insert-product-detail', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(() => {
            router.push("/prod-admin");
          })
          .catch(error => {
            console.error("Error:", error);
          });
    },


    fetchAllData() {
      return axiosInstance
          .get(`api/get-main-data`)
          .then(response => {
            this.colorOptions = response.data.color;
            this.sizeOptions = response.data.size;
            this.attributeOptions = response.data.attribute;
            this.brand = response.data.brand;
            this.category = response.data.category;
            this.products = response.data.product;
            this.color = response.data.color;
            this.size = response.data.size;
            this.attribute = response.data.attribute;
            this.zone = response.data.zone; // Corrected here
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    loadZones() {
      axiosInstance.get('api/zones').then(({data}) => {
        this.zones = data;
      });
    },

    loadFloors(index) {
      const detail = this.product.details[index];
      if (detail.selectedZone) {
        axiosInstance.get(`api/zones/${detail.selectedZone}/floors`).then(({data}) => {
          detail.floors = data;
        });
      }
    },

    loadStands(index) {
      const detail = this.product.details[index];
      if (detail.selectedFloor) {
        axiosInstance.get(`api/floors/${detail.selectedFloor}/stands`).then(({data}) => {
          detail.standes = data;
        });
      }
    },

    loadShelves(index) {
      const detail = this.product.details[index];
      if (detail.selectedStand) {
        axiosInstance.get(`api/stands/${detail.selectedStand}/shelves`).then(({data}) => {
          detail.shelves = data;
        });
      }
    },


    saveSupplier() {
      this.SupplierData();
    },

    saveMainProduct(){
      this.mainProductData();
    },

    mainProductData() { // Corrected function name
      let formData = new FormData();
      formData.append('product_name', this.mainProduct.product_name); // Corrected data assignment

      axiosInstance.post(`api/main-product-create-sub-admin`, formData, {})
          .then(() => {
            this.mainProduct.product_name = ''; // Clear input
            this.successMessage = "Product saved successfully.";
            this.getMainProduct();
            // Additional actions on success
          })
          .catch(error => {
            console.error('Error:', error);
            this.errorMessage = "Failed to save product.";
          });
    },

    SupplierData() {
      let formData = new FormData();
      formData.append('supplier_name', this.productSupp.supplier_name);
      formData.append('phone_number', this.productSupp.phone_number);
      axiosInstance.post(`api/insert-supplier-and-product`, formData, {})
          .then(() => {
            // Clear form content
            this.productSupp.supplier_name = '';
            this.productSupp.phone_number = '';
            // Display success message
            this.successMessage = "Supplier and product saved successfully.";
            this.fetchAllData();
            this.fetchSupplierProduct();
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
            // Display error message if needed
            this.errorMessage = "Failed to save supplier and product.";
          });
    },

    supplierProduct() {
      this.SupplierProductData();
    },

    SupplierProductData() {
      let formData = new FormData();
      formData.append('supplier_name', this.supplier.supplier_name);
      formData.append('product_id', this.selectedProduct);

      axiosInstance.post('api/insert-supplier-for-product', formData)
          .then(() => {
            // Clear form content
            this.supplier.supplier_name = '';
            this.selectedProduct = '';
            // Display success message if needed
            console.log('Supplier and product saved successfully.');
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
            // Display error message if needed
            console.error('Failed to save supplier and product.');
          });
    },

    ProductSupplier() {
      this.productSupplierData();
    },
    productSupplierData() {
      let formData = new FormData();
      formData.append('supplier_id', this.newProd.supplier_id);
      formData.append('product_name', this.newProd.product_name);

      axiosInstance.post('api/insert-product-for-supplier', formData)
          .then(() => {
            // Clear form content
            this.newProd.supplier_id = '';
            this.newProd.product_name = '';
            // Display success message if needed
            console.log('Supplier and product saved successfully.');
          })
          .catch(error => {
            // Handle error
            console.error('Error:', error);
            // Display error message if needed
            console.error('Failed to save supplier and product.');
          });
    },

    fetchSupplierProduct() {
      return axiosInstance
          .get(`api/get-suppliers-data`)
          .then(response => {
            this.supplierproduct = response.data.supplier;
            this.productsupplier = response.data.product;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

  },
};
</script>
