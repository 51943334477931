<template>
    <div>
      <LayoutHeader />
      <LayoutSidebar />
      <router-view></router-view>
    </div>
  </template>
  
  <script>
  import LayoutHeader from "../newProducts/shared/AffiliateHeader.vue";
  import LayoutSidebar from "../newProducts/shared/AffiliateSidebar.vue";
  
  
  export default {
    name: "SuperAdminPage",
    components: {
      LayoutHeader,
      LayoutSidebar,
    },
    created() {
  
    },
    
  };
  </script>
  
  <style scoped>
      @import url("../../../public/admin_assets/plugins/fontawesome-free/css/all.min.css");
      @import url("../../../public/admin_assets/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css");
      @import url("../../../public/admin_assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css");
      @import url("../../../public/admin_assets/plugins/jqvmap/jqvmap.min.css");
      @import url("../../../public/admin_assets/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");
  
      @import url("../../../public/admin_assets/plugins/daterangepicker/daterangepicker.css");
      @import url("../../../public/admin_assets/plugins/summernote/summernote-bs4.min.css");
      @import url("../../../public/admin_assets/dist/css/adminlte.min.css");
  </style>