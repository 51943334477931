<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Bundle Name"
                            v-model="bundle.bundle_name" /><br />
                        <input type="text" class="form-control" placeholder="Create Bundle Sku"
                            v-model="bundle.bundle_sku" /><br />
                        <input type="text" class="form-control" placeholder="Create Bundle Price"
                            v-model="bundle.bundle_price" /><br />
                        <input type="text" class="form-control" placeholder="Create Bundle Discount"
                            v-model="bundle.bundle_discount" /><br />
                        
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="bundle.progress" :value="bundle.progress.percentage" max="100">
                            {{ bundle.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Bundle</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateBundle",

    data() {
        return {
            bundle: {
                bundle_name: "",
                bundle_sku: "",
                bundle_image: "",
                bundle_price: "",
                bundle_discount: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.bundle.bundle_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('bundle_name', this.bundle.bundle_name);
            formData.append('bundle_image', this.bundle.bundle_image);
            formData.append('bundle_sku', this.bundle.bundle_sku);
            formData.append('bundle_price', this.bundle.bundle_price);
            formData.append('bundle_discount', this.bundle.bundle_discount);
            axiosInstance.post(`api/bundle-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/bundle");
            });
        },
    },
};
</script>