<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <div class="form-group">
                          <label for=""></label>
                          <input type="text" class="form-control" v-model="tip.brand_name">
                        </div>

                        <div class="form-group">
                          <label for=""></label>
                          <textarea class="form-control" v-model="tip.tips_text" rows="3"></textarea>
                        </div>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "DataEntryCreateTips",

    data() {
        return {
            tip: {
                tips_text: "",
                brand_name: "",
            },
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            axiosInstance
                .post(`api/data-entry-store-tips`, this.tip)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/data-entry-tips-view");
                });
        },
    },
};
</script>