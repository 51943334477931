<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Brand</th>
                                <th>Product</th>
                                <th>Child Category</th>
                                <th>Sale Price</th>
                                <th>Description</th>
                                <th>Detail</th>
                                <th>Image</th>
                                <th>Position</th>
                                <th>Color</th>
                                <th>Size</th>
                                <th>Attribute</th>
                                <th>Supplier</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.sku }}</td>
                                <td><input type="text" class="form-control" v-model="product.title" /></td>
                                <td>
                                    <select class="form-select" v-model="product.brand_id">
                                        <option value='' selected hidden disabled>-- Choose Brand --</option>
                                        <option v-for="brand in brand" :key="brand.id" :value="brand.id">{{
                                brand.brand_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="product.product_id">
                                        <option value='' selected hidden disabled>-- Choose Product --</option>
                                        <option v-for="products in products" :key="products.id" :value="products.id">{{
                                products.product_name }}</option>
                                    </select>
                                </td>
                                <td>
                                    <select class="form-select" v-model="product.child_category_id">
                                        <option value='' selected hidden disabled>-- Choose Child Category --</option>
                                        <option v-for="childcategory in childcategory" :key="childcategory.id"
                                            :value="childcategory.id">{{
                                childcategory.child_category_name }}</option>
                                    </select>
                                </td>
                                <td>${{ product.sale_price }}</td>
                                <td>
                                    <p v-html="product.description"></p>
                                </td>
                                <td>
                                    <p v-html="product.details"></p>
                                </td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75" />
                                </td>
                                <td>{{ product.user_position }}</td>
                                <td>{{ product.color_name }}</td>
                                <td>{{ product.size_name }}</td>
                                <td>{{ product.attribute_type_name }}</td>
                                <td>{{ product.supplier_name }}</td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productDetail: [],
            product: {
                brand_id: '',
                title: '',
                product_id: '',
                child_category_id: '',
            },
            brand: [],
            products: [],
            childcategory: [],
        };
    },

    created() {
        this.fetchAllData();
        this.fetchCredential();
    },

    methods: {
        fetchAllData() {
            return axiosInstance
                .get(`api/get-disabled-product`)
                .then(response => {
                    this.productDetail = response.data.productDetail;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-final-data/${product.id}`, {
                    brand_id: product.brand_id, 
                    title: product.title, 
                    product_id: product.product_id, 
                    child_category_id: product.child_category_id
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },


        fetchCredential() {
            return axiosInstance
                .get(`api/get-credential-data`)
                .then(response => {
                    this.brand = response.data.brand;
                    this.products = response.data.product;
                    this.childcategory = response.data.childcategory;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },
    },

}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
