<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productmain" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td><input type="text" class="form-control" v-model="product.price" /></td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productmain: [],
            product: {
                price: '',
            }
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        fetchAllData() {
            return axiosInstance
                .get(`api/get-disabled-product-main`)
                .then(response => {
                    this.productmain = response.data.productmain;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-disabled-product-main/${product.id}`, {
                    price: product.price
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
