<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>
                    <button @click="updateDescriptions" class="btn btn-primary ms-5 w-25">
                        Update
                    </button>
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Barcode Number</th>
                                <th>Weight</th>
                                <th>Expiry Date</th>
                                <td>Zone</td>
                                <td>Floor</td>
                                <td>Stand</td>
                                <td>Shelve</td>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"/></td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.barcode">
                                    </div>
                                </td>
                                <td><input type="number" v-model="product.weight"></td>
                                <td><input type="date" v-model="product.expiry_date"></td>
                                <td>
                                    <select v-model="product.selectedZone" @change="loadFloors(product)">
                                        <option v-for="zone in zones" :key="zone.id" :value="zone.id">
                                            {{ zone.zone_name }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="product.selectedFloor" @change="loadStands(product)">
                                        <option v-for="floor in product.floors" :key="floor.id" :value="floor.id">
                                            {{ floor.floor_name }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="product.selectedStand" @change="loadShelves(product)">
                                        <option v-for="stand in product.standes" :key="stand.id" :value="stand.id">
                                            {{ stand.stand_name }}
                                        </option>
                                    </select>
                                </td>
                                <td>
                                    <select v-model="product.selectedShelve">
                                        <option v-for="shelve in product.shelves" :key="shelve.id" :value="shelve.id">
                                            {{ shelve.shelve_name }}
                                        </option>
                                    </select>
                                </td>

                              <td v-if="product.shelve_id">{{ product.shelve.shelve_name }}</td>
                                <td><input type="checkbox" class="form-check-input" v-model="product.checked"></td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
            currentPage: 1,
            selectedBrand: null,
            brands: [],
            zones: [],
            floors: [],
            shelves: [],
            standes: []
        };
    },

    created() {
        this.loadZones();
        this.userLoad();
    },

    methods: {

        loadZones() {
            axiosInstance.get('api/zones').then(({ data }) => {
                this.zones = data;
            });
        },

        loadFloors(product) {
            if (product.selectedZone) {
                axiosInstance.get(`api/zones/${product.selectedZone}/floors`).then(({ data }) => {
                    product.floors = data;
                });
            }
        },

        loadStands(product) {
            if (product.selectedFloor) {
                axiosInstance.get(`api/floors/${product.selectedFloor}/stands`).then(({ data }) => {
                    product.standes = data;
                });
            }
        },

        loadShelves(product) {
            if (product.selectedStand) {
                axiosInstance.get(`api/stands/${product.selectedStand}/shelves`).then(({ data }) => {
                    product.shelves = data;
                });
            }
        },

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            }
        },

        userLoad(brandId) {
            var nextPage = `api/get-old-data/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.products.map(product => ({
                    ...product,
                    checked: false // Adding a checked property to each product
                }));
                this.brands = data.brands;
            });
        },

        updateDescriptions() {
            const checkedProducts = this.allProductDetails.filter(product => product.checked);
            Promise.all(checkedProducts.map(product => {
                return axiosInstance.post(`api/update-old-data/${product.id}`, {
                    weight: product.weight,
                    barcode: product.barcode,
                    expiry_date: product.expiry_date,
                    shelve_id: product.selectedShelve
                });
            })).then(() => {
                this.userLoad(this.selectedBrand);
            }).catch(error => {
                console.error('Error updating details:', error);
            });
        },

    },
}
</script>
