<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Category Panel</h3>

          <router-link to="/create-childcategory" class="btn btn-primary">Create Child Category</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Child Category Name</th>
                <th>Sub Category Name</th>
                <th>Child Category Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="childcategories in childcategory" v-bind:key="childcategories.id">
                <th scope="row">{{ childcategories.id }}</th>
                <td>{{ childcategories.child_category_name }}</td>
                <td>{{ childcategories.subcategory.sub_category_name }}</td>
                <td><img :src="'https://api.gocami.com/child_category_image/' + childcategories.child_category_image"></td>
                <td>
                  <router-link :to="{ name: 'EditChildCategory', params: { id: childcategories.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(childcategories)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
  name: "ChildCategoryView",

  data() {
    return {
      childcategory: [],
    };
  },

  created() {
    this.childcategoryLoad();
  },

  methods: {
    childcategoryLoad() {
      var page = `api/childcategory`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.childcategory = data;
      });
    },

    remove(childcategories) {
      var url = `api/childcategory-delete/${childcategories.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.childcategory.indexOf(childcategories);
        this.childcategory.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>