<template>
    <div class="row row-clean">
        <div class="row row-clean">
            <div class="col-xs-6 col-sm-4 col-lg-2" v-for="sale in sales" :key="sale.id">
                <div class="product-item">
                    <article>
                        <div class="info">
                            <span class="add-favorite">
                                <a href="javascript:void(0);" data-title="Add to favorites"
                                    data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                            </span>
                        </div>
                        <div class="figure-grid">
                            <div class="image">
                                <router-link :to="{ name: 'ProductDetail', params: { id: sale.id } }" class="mfp-open">
                                    <img v-lazy="'https://api.gocami.com/product_detail/' + sale.image" alt="" width="360"
                                        class="product-image" />
                                </router-link>
                            </div>
                            <div class="text">
                                <h2 class="title h5">
                                    <router-link :to="{ name: 'ProductDetail', params: { id: sale.id } }">{{
                                        sale.title }}</router-link>
                                </h2>
                                <sup>$ {{ sale.sale_price.toFixed(2) }},-</sup>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>

    </div>
    <FooterLayout />
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';

export default {
    components: { FooterLayout },
    data() {
        return {
            sales: [],
        };
    },

    created() {
        this.fetchSalesData();
    },
    methods: {
        fetchSalesData() {
            var page = `api/saleproduct/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.sales = data.sale;
            });
        },
    }
}
</script>