<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      brands: [],
      products: [],
      childcategory: [],
      file: null
    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get(`api/product-without-desc`)
          .then(response => {
            this.productDetail = response.data.data;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

  },
}
</script>


<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <table class="table w-100">
            <thead class="fixed-header border-bottom">
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Details</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75"/></td>
              <td>{{ product.sku }}</td>
              <td>{{ product.title }}</td>
              <td><p v-html="product.details"></p></td>
              <td><p v-html="product.description"></p></td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>