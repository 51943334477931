<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="responsive table-responsive table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>Product Name</th>
                                <th>Product Sku</th>
                                <th>Ordered Times</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in allOrder" :key="order.id" class="flex">
                                <th scope="row">{{ order.id }}</th>
                                <td class="text-danger fw-bold">{{ order.first_name }} {{ order.last_name }}</td>
                                <td>{{ order.product_name }}</td>
                                <td>{{ order.product_sku }}</td>
                                <td>{{ order.ordered_times }}</td>
                                <td>{{ order.sold_quantity }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "CartInvoice",

    data() {
        return {
            allOrder: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var endpoint = `api/super-admin-product-content/${this.$route.params.id}`;
            axiosInstance.get(endpoint).then(({ data }) => {
                console.log(data);
                this.allOrder = data;
            });
        },

    },
};
</script>
  