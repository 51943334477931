<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <router-link to="/create-productdetailbundle" class="btn btn-primary">Create Product Detail</router-link>

                </div>
            </div>
        </div>
    </div>
</template>