<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h4 class="mt-3">Filtrations</h4>

                    <form @submit.prevent="save" enctype="multipart/form-data">

                        <div class="form-group" v-for="(productdetail, index) in productdetail.products" :key="index">

                            <div class="row mb-5">
                                <div class="col-4">
                                    <select class="form-control mb-3" v-model="productdetail.selectedChildcategory">
                                        <option value="0" selected disabled hidden>-- Choose Child Category Name --</option>
                                        <option v-for="childcategori in childcategory" :key="childcategori.id"
                                            :value="childcategori.id">
                                            {{ childcategori.child_category_name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-4">
                                    <select class="form-control" v-model="productdetail.selectedFilter">
                                        <option value="0" hidden selected>Select Filter</option>
                                        <option
                                            v-for="filter in filteredFilterchildcategory(productdetail.selectedChildcategory)"
                                            :key="filter.id" :value="filter.id">
                                            {{ filter.filter_name }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-4">
                                    <select class="form-control" v-model="productdetail.product_filter_id">
                                        <option value="0" hidden selected>Select Product Filter</option>
                                        <option
                                            v-for="filterbyproduct in filteredProductbyfilter(productdetail.selectedFilter)"
                                            :key="filterbyproduct.id" :value="filterbyproduct.id">{{
                                                filterbyproduct.product_filter }}</option>
                                    </select>
                                </div>
                                <div class="col-md-1 d-flex">
                                    <button type="button" class="btn btn-danger me-3"
                                        @click="removeProduct(index)">-</button>
                                    <button type="button" class="btn btn-success" @click="addFilter">+</button>
                                </div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Create</button>

                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    data() {
        return {
            productdetail: {
                selectedChildcategory: [],
                selectedFilter: [],
                product_filter_id: [],
                products: [{ product_filter_id: '' }],
            },
            productdetails: { id: this.$route.params.id, },
            childcategory: [],
            filter: [],
            productbyfilter: [],
        }
    },

    methods: {
        addFilter() {
            this.productdetail.products.push({ selectedChildcategory: '', selectedFilter: '', product_filter_id: '' });
            this.productdetail.selectedChildcategory.push('');
            this.productdetail.selectedFilter.push('');
            this.productdetail.product_filter_id.push('');
        },

        removeProduct(index) {
            this.productdetail.products.splice(index, 1);
            this.productdetail.selectedChildcategory.splice(index, 1);
            this.productdetail.selectedFilter.splice(index, 1);
            this.productdetail.product_filter_id.splice(index, 1);
        },

        filteredFilterchildcategory(childcategoryId) {
            return this.filter.filter(filter => filter.child_category_id === childcategoryId)
        },

        filteredProductbyfilter(filterId) {
            return this.productbyfilter.filter(productbyfilter => productbyfilter.filter_id === filterId);
        },

        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            for (let i = 0; i < this.productdetail.products.length; i++) {
                formData.append(`products[${i}][product_filter_id]`, this.productdetail.products[i].product_filter_id);
            }

            axiosInstance.post(`api/productfiltration-store/${this.productdetails.id}`, formData, {
            }).then(() => {
                // Redirect to the desired page
                router.push("/productdetail");
            });
        },

        fetchData() {
            axiosInstance
                .get(`api/productdetail-create`)
                .then((response) => {
                    this.product = response.data.product;
                    this.brand = response.data.brand;
                    this.childcategory = response.data.childcategory;
                    this.filter = response.data.filter;
                    this.productbyfilter = response.data.productbyfilter;
                    this.color = response.data.color;
                    this.size = response.data.size;
                    this.attributetype = response.data.attributetype;
                }).catch((error) => {
                    console.log(error);
                });
        },

        fetchProduct() {
            axiosInstance
                .get(`api/productdetail-edit/` + this.productdetails.id)
                .then((response) => {
                    this.productdetails = response.data.productdetail;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

    },

    mounted() {
        this.fetchData();
        this.fetchProduct();

    }
}
</script>