<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="giftcard.id" />

                        <input type="text" class="form-control mb-3" v-model="giftcard.card_name" />
                        <input type="text" class="form-control mb-3" v-model="giftcard.card_quantity" />
                        <input type="text" class="form-control mb-3" v-model="giftcard.card_price" />
                        <input type="text" class="form-control mb-3" v-model="giftcard.card_description" />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditCardGift",

    data() {
        return {
            giftcard: {
                card_name: "",
                card_quantity: "",
                card_price: "",
                card_description: "",
                card_photo: "",
                id: this.$route.params.id,
            },
            oldGiftcard: {}, // new data property to store the old employee data
        };
    },
    methods: {
        onFileChange(event) {
            this.giftcard.card_photo = event.target.files[0];
        },
        editData() {
            let formData = new FormData();
            if (this.giftcard.card_name) {
                formData.append('card_name', this.giftcard.card_name);
            }
            if (this.giftcard.card_quantity) {
                formData.append('card_quantity', this.giftcard.card_quantity);
            }
            if (this.giftcard.card_price) {
                formData.append('card_price', this.giftcard.card_price);
            }
            if (this.giftcard.card_description) {
                formData.append('card_description', this.giftcard.card_description);
            }
            if (this.giftcard.card_photo) {
                formData.append('card_photo', this.giftcard.card_photo);
            }
            axiosInstance.post(`api/gift-card-update/${this.giftcard.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.giftcard = {
                    card_name: "",
                    card_quantity: "",
                    card_price: "",
                    card_description: "",
                    card_photo: "",
                    id: "",
                };
                router.push("/gift-card");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/gift-card-edit/` + this.giftcard.id)
                .then((response) => {
                    this.oldGiftcard = response.data;
                    this.giftcard.card_name = this.oldGiftcard.card_name;
                    this.giftcard.card_quantity = this.oldGiftcard.card_quantity;
                    this.giftcard.card_price = this.oldGiftcard.card_price;
                    this.giftcard.card_description = this.oldGiftcard.card_description;
                    this.giftcard.card_photo = this.oldGiftcard.card_photo;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>