<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Brand"
                            v-model="brand.brand_name" /><br /><br />
                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="brand.progress" :value="brand.progress.percentage" max="100">
                            {{ brand.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-primary">Create Brand</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateBrand",

    data() {
        return {
            brand: {
                brand_name: "",
                brand_image: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.brand.brand_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('brand_name', this.brand.brand_name);
            formData.append('brand_image', this.brand.brand_image);
            axiosInstance.post(`api/brand-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/brand");
            });
        },
    },
};
</script>