<script>
import axiosInstance from "../../../axiosInstance";
// import router from "@/router";

export default {
  data() {
    return {
      brands: [],
      minStartDate: new Date().toISOString().split("T")[0], // Today's date in YYYY-MM-DD format
      minEndDate: "", // Minimum date for "End Date"
      alertMessage: "", // Stores the alert message
      validationErrors: [],
      diffusers: [],
      selectedBrand: null,
      productDetail: [],
      agent: {
        name: '',
        phone: '',
        area: ''
      },
      agents: [],
      diffuser: {
        store_name: '',
        user_name: '',
        area: '',
        street: '',
        phone: '',
        start_date: '',
        end_date: '',
        agent_id: '',
        quantity: '',
        entries: [
          {
            quantity: '',
            product_detail_id: '', // Default initialization
            breaks: [
              {
                break_start: '',
                break_end: '',
              },
            ],
          },
        ],
      },
    };
  },

  created() {
    this.agentLoad();
    this.diffuserLoad();
    this.fetchProductDetails();
    this.fetchBrands(); // Fetch brands when the component is created
  },

  computed: {

// Computed property should return a function to check contain_time
    shouldDisplayTimes() {
      // Check if brand_id is valid and brands array exists before accessing 'find'
      return (brandId) => {
        if (Array.isArray(this.brands)) { // Check if 'brands' is defined and is an array
          const brand = this.brands.find((b) => b.id === brandId);
          return brand ? brand.contain_time === 1 : false;
        }
        return false; // Default condition to avoid errors
      };
    },

    isFormInvalid() {
      return !this.diffuser.start_date || !this.diffuser.end_date || this.diffuser.end_date <= this.diffuser.start_date;
    },
  },

  watch: {
    'diffuser.entries': {
      handler(newVal, oldVal) {
        newVal.forEach((entry, index) => {
          if (entry.brand_id !== oldVal[index]?.brand_id) {
            this.fetchProductDetails(entry.brand_id);
          }
        });
      },
      deep: true,
    },
  },

  methods: {

    validatePhoneNumber(event) {
      // Keep only digits and limit to 8 characters
      const sanitized = event.target.value.replace(/[^0-9]/g, ''); // Remove non-digit characters
      this.diffuser.phone = sanitized.slice(0, 8); // Ensure no more than 8 digits

      if (sanitized.length !== 8) {
        this.validationErrors = ["Phone number must be exactly 8 digits."];
      } else {
        this.validationErrors = [];
      }
    },

    fetchBrands(brandId) {
      axiosInstance.get(`api/diffuser-change/${brandId}`).then((response) => {
        this.brands = response.data.brands; // Assign fetched brand data
      }).catch((error) => {
        console.error("Error fetching brands:", error);
      });
    },

    addProduct() {
      const newEntry = {
        brand_id: null,
        product_detail_id: null,
        quantity: "",
        breaks: [
          {
            break_start: '',
            break_end: '',
          },
        ],
      };

      // Use spread operator or deep cloning to create unique objects
      this.diffuser.entries.push({...newEntry}); // This creates a unique object
    },

    onBrandChange(entryIndex) {
      const brandId = this.diffuser.entries[entryIndex].brand_id;
      if (brandId) {
        this.fetchProductDetails(brandId, entryIndex); // Pass the entry index to fetch product details for this specific entry
      }
    },

    updateEndDateMin() {
      // Update the minimum allowed "End Date" to be at least one day after "Start Date"
      if (this.diffuser.start_date) {
        const startDate = new Date(this.diffuser.start_date);
        startDate.setDate(startDate.getDate() + 1);
        this.minEndDate = startDate.toISOString().split("T")[0];
      }
    },
    validateEndDate() {
      // If "End Date" is before "Start Date", reset it and show an alert
      if (this.diffuser.end_date <= this.diffuser.start_date) {
        this.alertMessage = "End Date must be after Start Date.";
        this.diffuser.end_date = ""; // Reset the invalid "End Date"
      } else {
        this.alertMessage = ""; // Clear the alert message if valid
      }
    },

    addEntry() {
      // Ensure new entry has initialized breaks
      this.diffuser.entries.push({
        product_detail_id: '',
        breaks: [
          {
            break_start: '',
            break_end: '',
          },
        ],
      });
    },

    removeEntry(index) {
      this.diffuser.entries.splice(index, 1);
    },

    addBreak(entryIndex) {
      // Adds a break to the specified product entry
      this.diffuser.entries[entryIndex].breaks.push({
        break_start: "",
        break_end: "",
      });
    },

    removeBreak(entryIndex, breakIndex) {
      // Removes a break from the specified product entry
      const breaks = this.diffuser.entries[entryIndex].breaks;
      if (breakIndex >= 0 && breakIndex < breaks.length) {
        breaks.splice(breakIndex, 1);
      }
    },


    save() {
      this.saveData();
    },

    diff() {
      console.log("Entries before submission:", JSON.stringify(this.diffuser.entries)); // Check the data structure
      for (const entry of this.diffuser.entries) {
        if (!entry.product_detail_id || !entry.quantity) {
          console.error("Product detail ID and quantity must be set for each entry");
          return; // Prevent submission if validation fails
        }
      }
      this.saveDataDiffuser(); // Submit if validation is successful
    },

    resetDiffuserData() {
      // Reset all fields after successful data submission
      this.diffuser = {
        store_name: '',
        user_name: '',
        area: '',
        street: '',
        phone: '',
        start_date: '',
        end_date: '',
        agent_id: '',
        quantity: '',
        entries: [
          {
            quantity: '',
            brand_id: '',
            product_detail_id: '',
            productDetails: [], // New array to store specific product details
            breaks: [
              {
                break_start: '',
                break_end: '',
              },
            ],
          },
        ],
      };
    },

    saveData() {
      axiosInstance
          .post(`api/agents`, this.agent)
          .then(() => {
            this.agent.name = "";
            this.agent.phone = "";
            this.agent.area = "";
            this.agentLoad();
          });
    },

    saveDataDiffuser() {
      axiosInstance
          .post(`api/diffusers`, this.diffuser) // Post the current diffuser object
          .then(() => {
            this.successMessage = 'Diffuser Created successfully'; // Set success message
            setTimeout(() => {
              this.successMessage = '';
            }, 5000);
            this.resetDiffuserData(); // Reset the diffuser data after successful POST
            this.agentLoad(); // Reload agents or any other necessary data
          })
          .catch((error) => {
            console.error('Error saving diffuser data:', error); // Handle errors
          });
    },

    agentLoad() {
      var page = `api/agents`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.agents = data.data;
      });
    },

    diffuserLoad() {
      var page = `api/diffusers`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.diffusers = data.diffuser;
      });
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchBrands(this.selectedBrand);
      }
    },


    fetchProductDetails(brandId, entryIndex) {
      axiosInstance.get(`api/diffuser-change/${brandId}`).then((response) => {
        this.diffuser.entries[entryIndex].productDetails = response.data.productDetail;
      }).catch((error) => {
        console.error("Error fetching product details:", error);
      });
    },

    remove(agents) {
      var url = `api/color-delete/${agents.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.agent.indexOf(agents);
        this.agent.splice(index, 1);
      });
    }
    ,

  },
};
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">

        <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>

        <div class="card-body">

          <table class="table w-100 mt-5">
            <thead>
            <tr>
              <th>#</th>
              <th>Store Name</th>
              <th>User Name</th>
              <th>Area / Street</th>
              <th>Phone</th>
              <th>Agent Name</th>
              <th>From / To</th>
              <!--              <th>Action</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="diffus in diffusers" v-bind:key="diffus.id">
              <th scope="row">{{ diffus.id }}</th>
              <td>{{ diffus.store_name }}</td>
              <td>{{ diffus.user_name }}</td>
              <td>{{ diffus.area }}</td>
              <td>{{ diffus.phone }}</td>
              <td>{{ diffus.name }}</td>
              <td>{{ diffus.start_date }} - {{ diffus.end_date }}</td>
            </tr>
            </tbody>
          </table>

          <form @submit.prevent="diff" class="mt-5">
            <div class="row">
              <div class="col-4">
                <label for="name"></label>
                <input type="text" class="form-control" placeholder="Store Name" v-model="diffuser.store_name"
                       id="name" required/>
              </div>

              <div class="col-4">
                <label for="phone"></label>
                <input type="text" class="form-control" placeholder="User Name" v-model="diffuser.user_name"
                       id="name" required/>
              </div>

              <div class="col-4">
                <label for="area"></label>
                <input type="text" class="form-control" placeholder="Area" v-model="diffuser.area"
                       id="name" required/>
              </div>

              <div class="col-4">
                <label for="name"></label>
                <input type="text" class="form-control" placeholder="Street" v-model="diffuser.street"
                       id="name" required/>
              </div>

              <div class="col-4">
                <label for="name"></label>

                <input
                    type="text"
                    class="form-control"
                    placeholder="Phone"
                    v-model="diffuser.phone"
                    id="phone"
                    @input="validatePhoneNumber"
                    maxlength="8"
                    required
                />
                <div v-if="validationErrors.length">
                  <ul>
                    <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
                  </ul>
                </div>
              </div>

              <div class="col-4">
                <label for="name"></label>

                <select v-model="diffuser.agent_id" class="form-select">
                  <option value="" disabled selected>-- Select Agent --</option>
                  <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.name }}</option>
                </select>
              </div>

              <div class="col-6 mt-3">
                <label for="start-date">Start Date</label>
                <input
                    type="date"
                    class="form-control"
                    v-model="diffuser.start_date"
                    :min="minStartDate"
                    @change="updateEndDateMin"
                    required
                />
              </div>

              <div class="col-6 mt-3">
                <label for="end-date">End Date</label>
                <input
                    type="date"
                    class="form-control"
                    v-model="diffuser.end_date"
                    :min="minEndDate"
                    :disabled="!diffuser.start_date"
                    @change="validateEndDate"
                    required
                />
              </div>

              <div v-if="validationErrors.length">
                <!-- Display validation error messages -->
                <ul>
                  <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
                </ul>
              </div>

              <div class="row mt-3 mx-2">
                <!-- Button to add a new product -->
                <div class="col-12 mt-2 text-end">
                  <button @click.prevent="addProduct" class="btn btn-primary">Add More Product</button>
                </div>

                <!-- Loop through the product entries -->
                <div v-for="(entry, entryIndex) in diffuser.entries" :key="entryIndex" class="col-12 mt-3">
                  <div class="row">
                    <div class="col-4">
                      <!-- Ensure the correct property is referenced here -->
                      <select v-model="entry.brand_id" @change="onBrandChange(entryIndex)" class="form-select">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                          {{ brand.product_name }} <!-- Check this property is correct -->
                        </option>
                      </select>
                    </div>

                    <div class="col-4">
                      <select v-model="entry.product_detail_id" class="form-select">
                        <option v-for="product in entry.productDetails" :key="product.id" :value="product.id">
                          {{ product.sku }} - {{ product.title }}
                        </option>
                      </select>
                    </div>

                    <div class="col-4">
                      <input type="text" class="form-control" placeholder="Quantity" v-model="entry.quantity" required/>
                    </div>

                    <!-- Conditionally display time inputs based on computed property -->
                    <div v-if="shouldDisplayTimes(entry.brand_id)">
                      <div v-for="(breakEntry, breakIndex) in entry.breaks" :key="breakIndex" class="col-12">
                        <div class="row">
                          <div class="col-3 mt-3">
                            <input type="time" v-model="breakEntry.break_start" class="form-control"
                                   placeholder="Break Start"/>
                          </div>
                          <div class="col-3 mt-3">
                            <input type="time" v-model="breakEntry.break_end" class="form-control"
                                   placeholder="Break End"/>
                          </div>
                          <div class="col-12 mt-2 text-end">
                            <button @click.prevent="removeBreak(entryIndex, breakIndex)" class="btn btn-danger">Remove
                              Break
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mt-2 text-end">
                        <button @click.prevent="addBreak(entryIndex)" class="btn btn-secondary">Add Break</button>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <button type="submit" class="btn btn-success my-4">Create</button>
          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.success-message {
  position: fixed;
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>