<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>


                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Old Image</th>
                                <th>new Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"
                                        height="100"></td>
                                <td> <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />
                                </td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            currentPage: 1,
            selectedBrand: null,
            brands: []
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        userLoad(brandId) {
            var nextPage = `api/data-entry/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.products;
                this.brands = data.brands;
            });
        },

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            } else {
                // Handle case where no brand is selected (show all products)
                this.userLoad();
            }
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            // Create a FormData object to send the image file
            const formData = new FormData();
            formData.append('image', this.selectedImage); // You need to capture the selected image in data

            axiosInstance
                .post(`api/single-image/${product.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },

        onFileChange(event) {
            this.selectedImage = event.target.files[0]; // Capture the selected image
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}
</style>
  