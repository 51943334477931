<template>
    <div v-if="showToast" class="toast" :class="{ 'show': showToast }">
        {{ toastMessage }}
    </div>

    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">



                    <h3 class="mb-5">Brand Panel</h3>



                    <div class="form-group">
                        <label for="selectedUser">Select User:</label>
                        <select class="form-control" v-model="selectedUser" id="selectedUser">
                            <option v-for="productconfs in productconfs" :key="productconfs.id"
                                :value="productconfs.user.id">
                                {{ productconfs.user.first_name }} {{ productconfs.user.last_name }}
                            </option>
                        </select>
                    </div>

                    <form @submit.prevent="storeAddress">
                        <div class="form-group">
                            <label for="address">Enter the user address:</label>
                            <input type="text" class="form-control" v-model="address" id="address"
                                placeholder="Enter the user address" />
                        </div>
                        <button type="submit" class="btn btn-success mt-3">Submit</button>
                    </form>

                    <!-- Display addresses section -->
                    <div v-if="addresses.length > 0">
                        <h4 class="mt-4">Addresses:</h4>
                        <ul class="flex">
                            <li v-for="address in addresses" :key="address.id" style="display: flex; align-items: center;">
                                <span>{{ address.user.first_name }} {{ address.user.last_name }}</span>
                                <input v-model="address.address_name" class="form-control ms-4 mb-3" style="width: 50%;" />
                                <button @click="updateAddress(address.id)" class="btn btn-warning ms-4"><i
                                        class="fa fa-check"></i></button>
                            </li>

                        </ul>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
            selectedUser: null,
            address: "",
        };
    },
    data() {
        return {
            productconfs: [],
            errorMessage: null,
            addresses: [],
            addressName: '',
            showToast: false,
            toastMessage: "",
        };
    },
    created() {
        this.loadProductConfigs();
        console.log("User ID:", this.user?.id);

        this.loadAddresses();
    },
    methods: {

        async updateAddress(id) {
            try {
                const response = await axiosInstance.put(`api/update-address/${id}`, {
                    address_name: this.addresses.find(a => a.id === id).address_name,
                });
                console.log(response.data.message);

                // Show toast message on successful update
                this.showToastMessage("Address updated successfully!");
            } catch (error) {
                this.showToastMessage("Error updating address!");

                console.error('Error updating address:', error);
            }
        },

        loadProductConfigs() {
            var page = `api/aff-address?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.productconfs = data;
            });
        },

        storeAddress() {
            if (!this.selectedUser || !this.address) {
                this.errorMessage = "Please select a user and enter an address.";
                return;
            }

            const payload = {
                user_id: this.selectedUser,
                address_name: this.address,
            };

            axiosInstance.post('api/store-address', payload)
                .then(() => {
                    console.log('Address stored successfully!');
                    this.showToastMessage("Address Added successfully!");
                    // After storing the address, reload the addresses for the selected user
                    this.loadAddresses();
                })
                .catch(error => {
                    console.error('Error storing address:', error);
                    this.showToastMessage("Error storing address!");

                });
        },

        loadAddresses() {
            if (!this.user) {
                console.error("User not available.");
                return;
            }

            var page = `api/get-address?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.addresses = data;
            });
        },

        showToastMessage(message) {
            this.toastMessage = message;
            this.showToast = true;

            // Hide the toast after a certain duration (e.g., 3000 milliseconds)
            setTimeout(() => {
                this.hideToast();
            }, 3000);
        },

        hideToast() {
            this.showToast = false;
            this.toastMessage = "";
        },

    },
};
</script>


<style scoped>
.toast {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 99999 !important;
}

.toast.show {
    opacity: 1;
}
</style>