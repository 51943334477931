<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="branddiscount.id" />

                        <select class="form-control mb-3" v-model="branddiscount.brand_id">
                            <option v-for="brand in brand" :key="brand.id" :value="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <select class="form-control mb-3" v-model="branddiscount.discount_id">
                            <option v-for="discount in discount" :key="discount.id" :value="discount.id">
                                {{ discount.discount_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditBrandDiscount",

    data() {
        return {
            branddiscount: {
                discount_id: "",
                brand_id: "",
                id: this.$route.params.id,
            },
            brand: [],
            discount: [],
            oldBrandDiscount: {},
        };
    },

    methods: {
        editData() {
            let formData = new FormData();
            if (this.branddiscount.brand_id) {
                formData.append("brand_id", this.branddiscount.brand_id);
            }
            if (this.branddiscount.discount_id) {
                formData.append("discount_id", this.branddiscount.discount_id);
            }
            axiosInstance
                .post(`api/brand-discount-update/${this.branddiscount.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.branddiscount = {
                        discount_id: "",
                        brand_id: "",
                        id: "",
                    };
                    router.push("/brand-discount");
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fetchData() {
            axiosInstance
                .get(`api/brand-discount-edit/` + this.branddiscount.id)
                .then((response) => {
                    this.branddiscount = response.data.branddiscount;
                    this.brand = response.data.brand;
                    this.discount = response.data.discount;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        this.fetchData();
    },
};
</script>