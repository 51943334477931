<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">  
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>Product title</th>
                  <th>SKU</th>
                  <th>Quantity</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="productdetails in productdetail" v-bind:key="productdetails.id">
                  <td>{{ productdetails.title }}</td>
                  <td>{{ productdetails.sku }}</td>
                  <td>{{ productdetails.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  export default {
  
    data() {
      return {
        productdetail: [],
      };
    },
  
    created() {
      this.productdetailLoad();
    },
  
    methods: {
      productdetailLoad() {
        var page = `api/productdetail-sku`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.productdetail = data;
        });
      },

    },
  };
  
  </script>
      
  <style scoped></style>