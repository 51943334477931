<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>


                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Main Price</th>
                                <th>Old Price</th>
                                <th>New Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>

                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.sale_price">
                                    </div>
                                </td>
                                <td><input type="number" v-model="product.old_price"></td>
                                <td><input type="text" v-model="product.new_price"></td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            currentPage: 1,
            selectedBrand: null,
            brands: []
        };
    },

    created() {
        this.userLoad();
    },

    methods: {

        userLoad(brandId) {
            var nextPage = `api/get-product-detail-price-change/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.productDetail;
                this.brands = data.brands;
            });
        },

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            } else {
                // Handle case where no brand is selected (show all products)
                this.userLoad();
            }
        },

        changePage(page) {
            this.currentPage = page;
            this.userLoad();
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-product-detail-price-change/${product.id}`, {
                    sale_price: product.sale_price, old_price: product.old_price, new_price: product.new_price,})
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                    this.userLoad(this.selectedBrand);

                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>
  