<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Sku</th>
              <td>Color</td>
              <td>Size</td>
              <td>Attribute</td>
              <th>Description</th>
              <th>Details</th>
              <th>Position</th>
              <th>Video URL</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.sku }}</td>
              <td>{{ product.color_name }}</td>
              <td>{{ product.size_name }}</td>
              <td>{{ product.attribute_type_name }}</td>
              <td><textarea v-model="product.description" class="form-control"></textarea></td>

              <td><textarea v-model="product.details" class="form-control"></textarea></td>
              <td><input type="text" v-model="product.user_position" placeholder="USER POSITION"></td>
              <td><input type="text" v-model="product.video_url" placeholder="Video Url"></td>
              <td>
                <button @click="updateDescription(product)" class="btn btn-primary">
                  Update
                </button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      productDetail: [],
      product: {
        sku: '',
        sale_price: ''
      }
    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get(`api/get-disabled-product`)
          .then(response => {
            this.productDetail = response.data.productDetail;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/disabled-data-description/${product.id}`, {
            description: product.description,
            details: product.details,
            user_position: product.user_position,
            video_url: product.video_url
          })
          .then(response => {
            console.log('Details updated:', response.data);
            this.fetchAllData();
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },
  },
}
</script>

<style scoped>
* {
  font-size: 12pt !important;
}
</style>
