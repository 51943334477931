<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Old Image</th>
                                <th>new Image</th>
                                <th>Action</th>
                                <th>Multi Image</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"
                                        height="100"></td>
                                <td> <input type="file" accept="image/*" @change="onFileChange"
                                        class="form-control mb-3" />
                                </td>

                                <td v-if="product.photo_needed == 0">
                                    <button @click="setPhotoNeeded(product)" class="btn btn-success me-4">
                                        Get Photo
                                    </button>
                                </td>

                                <td v-if="product.photo_needed == 1">
                                    Photo under prepare!
                                </td>

                                <td v-else>

                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                                <td>
                                    <router-link :to="{ name: 'DisabledMultiImageProduct', params: { id: product.id } }"
                                        class="btn btn-warning me-2">Multi Image</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productDetail: {},
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        userLoad() {
            return axiosInstance
                .get(`api/get-disabled-product`)
                .then(response => {
                    this.productDetail = response.data.productDetail;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        setPhotoNeeded(product) {
            axiosInstance
                .post(`api/update-needed-photo/${product.id}`)
                .then(response => {
                    console.log('Photo needed flag set:', response.data.message);
                    this.userLoad(); // Fetch updated data after setting the flag
                })
                .catch(error => {
                    console.error('Error setting photo needed flag:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            // Create a FormData object to send the image file
            const formData = new FormData();
            formData.append('image', this.selectedImage); // You need to capture the selected image in data

            axiosInstance
                .post(`api/single-image/${product.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })

                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },

        onFileChange(event) {
            this.selectedImage = event.target.files[0]; // Capture the selected image
        },
    },
}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>