<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Address</th>
                <th>Location</th>
                <th>Note</th>
                <th>Date</th>
                <!-- <th>Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="allOrders in allOrder" :key="allOrders.id">
                <th scope="row">{{ allOrders.id }}</th>
                <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                <td>{{ allOrders.address }}</td>
                <td>{{ allOrders.area.area_name }}</td>
                <td>{{ allOrders.note }}</td>
                <td>{{ new Date(allOrders.created_at).toLocaleString() }}</td>
                <td style="display: flex;">
                  <router-link :to="{ name: 'SuperAdminExport', params: { id: allOrders.id } }"
                    class="btn btn-warning me-2" style="margin-right:10px"><i class="fa fa-eye"></i></router-link>

                  <form @submit.prevent="editData(allOrders)">
                    <input type="hidden" v-model="allOrders.id" />
                    <button type="submit" class="btn btn-success">Update</button>
                  </form>


                  <div v-if="allOrders.is_linked == 0">                  <form @submit.prevent="editLinked(allOrders)">
                    <input type="hidden" v-model="allOrders.id" />
                    <button type="submit" class="btn btn-success">Update Link</button>
                  </form>
                </div>

                <div v-else class="m-2 bg-danger text-white p-2 rounded">Closed!</div>




                  <!-- <router-link :to="{ name: 'ViewOrder', params: { id: allOrders.id } }" class="btn btn-warning me-2"><i
                          class="fa fa-eye"></i></router-link>
                      <router-link :to="{ name: 'ExportInvoice', params: { id: allOrders.id } }" class="btn btn-primary">invoice</router-link> -->
                  <!-- <button @click="remove(allOrders)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
          
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "SuperAdminOrder",

  data() {
    return {
      allOrder: [],
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad() {
      var page = `api/sub-admin-pending-orders`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.allOrder = data;
      });
    },

    editData(allOrders) {
      axiosInstance
        .post(
          `api/sub-admin-update-closed-orders/${allOrders.id}`,
          allOrders
        )
        .then(() => {
          this.orderLoad();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editLinked(allOrders) {
      axiosInstance
        .post(
          `api/sub-admin-update-is-linked-order/${allOrders.id}`,
          allOrders
        )
        .then(() => {
          this.orderLoad();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //   remove(colors) {
    //     var url = `${BASE_URL}color-delete/${colors.id}`;
    //     axios.delete(url).then(() => {
    //       const index = this.color.indexOf(colors);
    //       this.color.splice(index, 1);
    //     });
    //   },
  },
};

</script>
          
<style scoped></style>