<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Affiliate Panel</h3>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Affiliate Name</th>
                  <th>Unique Code</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="user in users" :key="user.id">
                  <th scope="row">{{ user.user.id }}</th>
                  <td><router-link :to="{name: 'AllUserAffiliate' , params: {id: user.user.id}}">{{ user.user.first_name }} {{ user.user.last_name }}</router-link></td>
                  <td>{{ user.unique_code }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
          
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  export default {
    name: "AllAffiliate",
  
    data() {
      return {
        users: [],
      };
    },
  
    created() {
      this.orderLoad();
    },
  
    methods: {
      orderLoad() {
        var page = `api/all-affiliate`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.users = data.user;
        });
      },
  
    },
  };
  
  </script>
          
