<script>
import axiosInstance from '../../../axiosInstance';

export default {

  data() {
    return {
      brands: [],
    };
  },

  created() {
    this.brandLoad();
  },

  methods: {
    updateStatus(brand) {
      const newStatus = brand.status === 0 ? 1 : 0;
      axiosInstance.put(`api/update-brand-status/${brand.id}`, {
        status: newStatus,
      })
          .then(response => {
            console.log(response);

            brand.status = newStatus;
            this.brandLoad();
          })
          .catch(error => {
            console.error(error); // Handle error
          });
    },

    updateTarget(brand) {
      const newTarget = brand.is_target === 0 ? 1 : 0;
      axiosInstance.put(`api/update-brand-target/${brand.id}`, {
        is_target: newTarget,
      })
          .then(response => {
            console.log(response);
            brand.is_target = newTarget;
            this.brandLoad();
          })
          .catch(error => {
            console.error(error); // Handle error
          });
    },

    updateMain(brand) {
      const newMain = brand.main_brand === 0 ? 1 : 0;
      axiosInstance.put(`api/update-brand-main/${brand.id}`, {
        main_brand: newMain,
      })
          .then(response => {
            console.log(response);

            brand.main_brand = newMain;
            this.brandLoad();
          })
          .catch(error => {
            console.error(error); // Handle error
          });
    },

    updatePercent(brand) {
      axiosInstance.put(`api/update-brand-percent/${brand.id}`, {percent: brand.percent})
          .then(response => {
            console.log(response);
            this.brandLoad();
          })
          .catch(error => {
            console.error(error);
          });
    },

    brandLoad() {
      var endpoint = `api/get-brand-percent`;
      axiosInstance.get(endpoint).then(({data}) => {
        this.brands = data.brand;
      });
    },
  },

};
</script>

<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Brands</h3>

          <div class="card">
            <div class="card-body">
              <h3 class="mb-5">Orders Panel</h3>

              <table class="responsive table-responsive table w-100">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Brand Name</th>
                  <th>Status</th>
                  <th>Target</th>
                  <th>Brand</th>
                  <th>Percent</th>
                  <th>Action</th> <!-- Add Action header -->
                </tr>
                </thead>
                <tbody>
                <tr v-for="(brand, index) in brands" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ brand.brand_name }}</td>

                  <td>
                    <label class="switch">
                      <input type="checkbox" :checked="brand.status === 0" @change="updateStatus(brand)">
                      <span
                          :class="{'slider': true, 'round': true, 'blue': brand.status === 0, 'default': brand.status !== 0}">
    </span>
                    </label>
                  </td>

                  <td>
                    <label class="switch">
                      <input type="checkbox" :checked="brand.is_target === 0" @change="updateTarget(brand)">
                      <span
                          :class="{'slider': true, 'round': true, 'blue': brand.is_target === 0, 'default': brand.is_target !== 0}">
    </span>
                    </label>
                  </td>

                  <td>
                    <label class="switch">
                      <input type="checkbox" :checked="brand.main_brand === 1" @change="updateMain(brand)">
                      <span
                          :class="{'slider': true, 'round': true, 'blue': brand.main_brand === 1, 'default': brand.main_brand !== 1}">
    </span>
                    </label>
                  </td>
                  <td><input type="number" v-model="brand.percent" @change="updatePercent(brand)"></td>

                </tr>

                </tbody>
              </table>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.blue {
  background-color: #2196F3;
}

.default {
  background-color: #ccc;
}

</style>