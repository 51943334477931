<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">User Discount</h3>

                    <router-link to="/create-user-discount" class="btn btn-primary">Create Affiliate
                        Discount</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Affilaite/User Name</th>
                                <th>Commission</th>
                                <th>First Commission</th>
                                <th>Brand Name</th>
                                <th>Coupon Code</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="affiliateuserdiscounts in affiliateuserdiscount"
                                v-bind:key="affiliateuserdiscounts.id">
                                <th scope="row">{{ affiliateuserdiscounts.id }}</th>
                                <td>{{ affiliateuserdiscounts.first_name }} {{ affiliateuserdiscounts.last_name }}</td>
                                <td>{{ affiliateuserdiscounts.commission }}%</td>
                                <td>{{ affiliateuserdiscounts.aff_com }}%</td>
                                <td>{{ affiliateuserdiscounts.brand_name }}</td>
                                <td>{{ affiliateuserdiscounts.coupon_code }}</td>
                                <td v-if="affiliateuserdiscounts.status == 1">Fixed Coupon</td>
                                <td v-else>Simple Coupon</td>
                                <td><button @click="remove(affiliateuserdiscounts)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
    name: "UserDiscount",
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },
    data() {
        return {
            affiliateuserdiscount: [],
        };
    },

    created() {
        this.affiliateuserdiscountLoad();
    },

    methods: {
        affiliateuserdiscountLoad() {
            var page = `api/user-discount?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.affiliateuserdiscount = data;
            });
        },

        remove(affiliateuserdiscounts) {
            var url = `api/user-discount-delete/${affiliateuserdiscounts.id}`;
            axiosInstance.delete(url).then(() => {
                const index = this.affiliateuserdiscount.indexOf(affiliateuserdiscounts);
                this.affiliateuserdiscount.splice(index, 1);
            });
        },


    },
};

</script>
      
<style scoped>
* {
    text-decoration: none;
    font-size: 12pt !important;
}
</style>
