<template>
  <section class="products">
    <header class="hidden">
      <h3 class="h3 title">Product category grid</h3>
    </header>

    <div class="row row-clean">

      <!-- === product-filters === -->

      <div class="col-md-3 col-xs-12">
        <div class="filters">
          <div v-for="filter in filters" :key="filter.id" class="filter-section">
            <!-- Price Start -->
            <h5 class="section-title text-uppercase mb-3" style="margin-left:2%">
              {{ filter.filter_name.split("-")[1] }}
            </h5>

            <div class="filter-options">
              <div class="custom-checkbox" v-for="productfilters in filteredProductfilter(filter.id)"
                :key="productfilters.id" style="margin-left:2%">
                <input type="checkbox" class="custom-control-input" :id="'product_filter_' + productfilters.id"
                  :value="productfilters.id" v-model="selectedProductFilters" />
                <label class="custom-control-label" :for="'product_filter_' + productfilters.id">
                  {{ productfilters.product_filter }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9 col-xs-12">

        <div class="sort-bar clearfix">
          <div class="sort-results pull-left">
            <!--Showing result per page-->

            <select v-model="selectedProductCount" @change="updateDisplayedProducts">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="All">All</option>
            </select>

            <!-- Items counter -->
            <span v-if="displayedProducts.length === 0">
              Showing <strong>10</strong> of <strong>{{ products.length }}</strong> items
            </span>

            <span v-else>
              Showing <strong>{{ getShowingCount }}</strong> of <strong>{{ products.length }}</strong> items
            </span>


          </div>
          <!--Sort options-->
          <div class="sort-options pull-right">
            <span class="hidden-xs">Sort by</span>
            <select v-model="sortOrder">
              <option value="asc">Price: lowest</option>
              <option value="desc">Price: highest</option>
            </select>
            <!--Grid-list view-->
            <span class="grid-list">
              <button @click="setDisplayStyle('list')"
                style="border: none !important; background-color: white; border-radius: 50%; padding: 5px;">
                <i class="fa fa-th-large"></i>
              </button>
              <button @click="setDisplayStyle('grid')"
                style="border: none !important; background-color: white; border-radius: 50%; padding: 5px;">
                <i class="fa fa-align-justify"></i>
              </button>
            </span>
          </div>
        </div>

        <div class="row row-clean">

          <div v-if="displayStyle === 'list'" class="info">

            <div v-for="product in filteredProducts" :key="product.productdetail.id"
              :class="{ 'col-xs-6 col-sm-4 col-md-3 col-lg-3': displayStyle === 'list', 'col-lg-12': displayStyle === 'grid' }">
              <div class="product-item">
                <article>
                  <div class="info">
                    <span>
                      <a href="#productid1" class="mfp-open" data-title="Quick view"><i class="icon icon-eye"></i></a>
                    </span>
                  </div>
                  <div class="btn btn-add">
                    <i class="icon icon-cart"></i>
                  </div>
                  <div class="figure-grid">
                    <span class="label label-info" v-if="product.productdetail.discount > 0">-{{
                      parseFloat(product.productdetail.discount) }}%</span>
                    <div class="image">
                      <router-link :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }"
                        class="mfp-open">
                        <img :src="'https://api.gocami.com/product_detail/' + product.productdetail.image" alt=""
                          width="360" class="product-image" />
                      </router-link>
                    </div>
                    <div class="text">
                      <h2 class="title h4">
                        <router-link :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }">{{
                          product.productdetail.title }}</router-link>
                      </h2>
                      <div v-if="product.productdetail.discount > 0">
                        <sub>$ {{ product.productdetail.sale_price.toFixed(2) }}</sub>
                        <sup>$ {{ (product.productdetail.sale_price - (product.productdetail.sale_price *
                          product.productdetail.discount / 100)).toFixed(2) }}</sup>
                      </div>
                      <div v-else>
                        <sup>$ {{ product.productdetail.sale_price.toFixed(2) }}</sup>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="col-md-12" v-for="product in filteredProducts" :key="product.productdetail.id">
              <article>
                <div class="info">
                  <span>
                    <a href="#productid1" class="mfp-open" data-title="Quick wiew"><i class="icon icon-eye"></i></a>
                  </span>
                </div>
                <div class="btn btn-add">
                  <i class="icon icon-cart"></i>
                </div>
                <div class="figure-list">
                  <div class="image">
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.productdetail.product_id } }"
                      class="mfp-open">
                      <img :src="'https://api.gocami.com/product_detail/' + product.productdetail.image
                        " alt="" width="360" class="product-image" style="height: 20vh; width: 20vh;" />
                    </router-link>
                  </div>
                  <div class="text">
                    <h2 class="title h4"><a href="product.html">{{ product.productdetail.title }}</a></h2>
                    <div v-if="product.productdetail.discount > 0">
                      <sub>$ {{ product.productdetail.sale_price.toFixed(2) }}</sub>
                      <sup>$ {{ (product.productdetail.sale_price - (product.productdetail.sale_price *
                        product.productdetail.discount / 100)).toFixed(2) }}</sup>
                    </div>
                    <div v-else>
                      <sup>$ {{ product.productdetail.sale_price.toFixed(2) }}</sup>
                    </div>
                    <span class="description clearfix">{{ product.details }}</span>
                  </div>
                </div>
              </article>
            </div>
          </div>

        </div><!--/row-->
        <!--Pagination-->
        <!-- <div class="pagination-wrapper">
          <ul class="pagination">
            <li>
              <a href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="active"><a href="#">1</a></li>
            <li><a href="#">2</a></li>
            <li><a href="#">3</a></li>
            <li><a href="#">4</a></li>
            <li><a href="#">5</a></li>
            <li>
              <a href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </div> -->

      </div> <!--/product items-->

    </div><!--/row-->

    <!-- ========================  Product info popup - quick view ======================== -->


  </section>
  <FooterLayout />
</template>
<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';

export default {
  components: { FooterLayout },
  methods: {

    updateDisplayedProducts() {
      if (this.selectedProductCount === 'All') {
        this.displayedProducts = this.filteredProducts;
      } else {
        this.displayedProducts = this.filteredProducts.slice(0, parseInt(this.selectedProductCount, 10));
      }
    },

    setDisplayStyle(style) {
      this.displayStyle = style;
    },

    dataLoad() {
      var page = `api/productlist-view/${this.$route.params.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.childcategories = data.childcategory;
        this.products = data.product;
        this.filters = data.filter;
        this.productfilters = data.productfilter;
        this.filterbyproducts = data.filterbyproduct;
        this.sales = data.sale;
      });
    },

    filteredProductfilter(filterId) {
      return this.productfilters.filter(productfilters => productfilters.filter_id === filterId);
    },
  },
  data() {
    return {
      displayStyle: 'list',  // Default display style
      selectedProductFilters: [],
      childcategories: [],
      products: [],
      filters: [],
      productfilters: [],
      filterbyproducts: [],
      sales: [],
      timerSettings: [],
      timerIntervals: {},
      sortOrder: 'asc',  // Default sort order
      selectedProductCount: 10,  // Default selected number of products
      displayedProducts: [],  // Initialize with an empty array
    };
  },

  created() {
    this.dataLoad();
    this.updateDisplayedProducts();  // Set the initial displayed products

  },

  computed: {
    getShowingCount() {
      if (this.selectedProductCount === 'All') {
        return `all`;
      } else {
        return `${this.displayedProducts.length}`;
      }
    },

    filteredProducts() {
      let filteredProducts = [];

      // Filter products based on selected filters
      if (this.selectedProductFilters.length === 0) {
        filteredProducts = this.products;
      } else {
        const filteredProductIds = this.filterbyproducts
          .filter(filterbyproduct => this.selectedProductFilters.includes(filterbyproduct.product_filter_id))
          .map(filterbyproduct => filterbyproduct.product_detail_id);
        filteredProducts = this.products.filter(product => {
          const productDetail = product.productdetail;
          return productDetail && filteredProductIds.includes(productDetail.id);
        });
      }

      // Sort products based on the selected sort order
      let sortedProducts = [...filteredProducts];  // Create a new array to avoid mutating the original data
      if (this.sortOrder === 'asc') {
        sortedProducts = sortedProducts.sort((a, b) => a.productdetail.sale_price - b.productdetail.sale_price);
      } else if (this.sortOrder === 'desc') {
        sortedProducts = sortedProducts.sort((a, b) => b.productdetail.sale_price - a.productdetail.sale_price);
      }

      // Slice the sorted products based on the selected product count
      if (this.selectedProductCount === 'All') {
        return sortedProducts;
      } else {
        return sortedProducts.slice(0, parseInt(this.selectedProductCount, 10));
      }
    }

  },


};
</script>
  
<style scoped>
/* Custom checkbox styles */
.filter-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.filter-options {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-control-input {
  margin-right: 10px;
}

.custom-control-label {
  font-size: 0.9em;
  color: #555;
}

.custom-control-label:hover {
  color: #333;
}

.custom-checkbox input[type="checkbox"] {
  /* Hide the default checkbox */
  position: absolute;
  opacity: 0;
}

.custom-checkbox label {
  /* Style the custom checkbox */
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

.custom-checkbox label:before {
  /* Create the checkbox indicator */
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
}

.custom-checkbox input[type="checkbox"]:checked+label:before {
  /* Style the checked checkbox */
  background-color: #2196F3;
  /* Change to your desired color */
  border-color: #2196F3;
  /* Change to your desired color */
}

.custom-checkbox label:after {
  /* Create the checkbox inner indicator */
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked+label:after {
  /* Show the inner indicator when checkbox is checked */
  display: block;
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  background-color: white;
}
</style>