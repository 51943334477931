<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
            {{ message }}
          </div>

          <h3 class="mb-5">Cart Panel</h3>


          <div>

            <table class="table table-responsive" style="min-width:100%">

              <div v-for="(bdl, index) in bdlArray" :key="index" style="display:flex">
                <div class="cart-block cart-block-item"
                     style="margin-top: 1%; display: flex; align-items: start;">
                  <div
                      style="display: flex; flex-direction: column; align-items: center; justify-items: center;">
                    <div class="image">
                      {{ bdl.bundle.bundle_sku }} - $ {{ bdl.bundle.bundle_price.toFixed(2) }}
                      <img :src="'https://api.gocami.com/bundle_image/' + bdl.bundle.bundle_image"
                           alt="" style="min-width: 75px; height: 75px;"/>
                    </div>
                    <div>Quantity {{ bdl.quantity }}</div>
                  </div>
                </div>
                <button class="btn fs-4" @click="removeBdl(index)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>


              <div class="bg-primary" v-for="cartBdl in cartBdl" :key="cartBdl.id"
                   style="display: block !important;">
                <p>{{ cartBdl.productdetail.title }} -- QTY: {{ cartBdl.quantity }} -- ${{
                    cartBdl.sale_price
                  }} - %{{ cartBdl.percentage }} --- {{ cartBdl.bundle.bundle_name }}</p>
              </div>


              <div class="cart-block cart-block-footer clearfix my-5 d-flex">
                <div>
                  <strong><u>Bundles Total:</u></strong>
                </div>
                <div>
                  Before Discount: <span>$ {{ bdlTotal.toFixed(2) }}</span>
                  After Discount: <span>$ {{ bdlTotalDiscount.toFixed(2) }}</span>
                </div>
              </div>


              <thead>
              <tr>
                <th>#</th>
                <th>Product Image</th>
                <th>Product Sku</th>
                <th>Product Title</th>
                <th>Product Price</th>
                <th>Percentage</th>
                <th>Discount</th>
                <th>Quantity</th>
                <th>Price After Discount</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="invoice in invoices" v-bind:key="invoice.id">
                <th scope="row">{{ invoice.id }}</th>
                <td><img :src="'https://api.gocami.com/product_detail/' + invoice.image"
                         class="img-fluid"></td>
                <td>{{ invoice.sku }}</td>
                <td>{{ invoice.title }}</td>
                <td>$ {{ invoice.sale_price.toFixed(2) }}</td>
                <td>{{ invoice.percentage }}</td>
                <td>%{{ invoice.cart_discount }}</td>
                <td>
                  <div class="d-flex align-items-center">

                    <!-- <button class="btn bg-primary rounded text-white me-3"
                        @click="incrementQuantity(invoice)">+</button> -->

                    <div>
                                                <span v-if="invoice.quantity > invoice.config_quantity"
                                                      style="color:red">Out of Stock</span>
                      <span v-else>{{ invoice.quantity }}</span>
                    </div>
                    <!-- <button class="btn bg-primary rounded text-white ms-3"
                        @click="decrementQuantity(invoice)">-</button> -->

                  </div>
                </td>

                <td v-if="invoice.cart_discount === 0">
                                        <span class="final h3" style="margin-right: 10px;">
                                            ${{
                                            invoice.price_after_discount ? (invoice.price_after_discount).toFixed(2) :
                                                (invoice.sale_price * invoice.quantity).toFixed(2)
                                          }}
                                        </span>

                  <span class="discount" v-if="invoice.price_after_discount != null"
                        style="margin-right: 10px;">
                                            $<s>{{ (invoice.sale_price * invoice.quantity).toFixed(2) }}</s>
                                        </span>
                </td>

                <td v-else>
                                        <span v-if="invoice.price_after_discount != null">
                                            <span class="final h3" style="margin-right: 10px;">
                                                ${{ invoice.price_after_discount.toFixed(2) }}
                                            </span>
                                            <span class="discount" style="margin-right: 10px;">
                                                <s>${{
                                                    (invoice.sale_price * invoice.quantity - (invoice.sale_price *
                                                        invoice.quantity * invoice.cart_discount / 100)).toFixed(2)
                                                  }}</s>
                                            </span>
                                        </span>

                  <span v-else>
                                            <span class="final h3" style="margin-right: 10px;">
                                                ${{
                                                (invoice.sale_price * invoice.quantity - (invoice.sale_price *
                                                    invoice.quantity * invoice.cart_discount / 100)).toFixed(2)
                                              }}
                                            </span>

                                            <span class="discount" style="margin-right: 10px;">
                                                <s>${{ (invoice.sale_price * invoice.quantity).toFixed(2) }}</s>
                                            </span>
                                        </span>

                </td>

                <td v-if="invoice.cart_discount === 0">
                  ${{
                    invoice.price_after_discount ? (invoice.price_after_discount).toFixed(2) :
                        (invoice.sale_price * invoice.quantity).toFixed(2)
                  }}
                </td>

                <td v-else>
                  ${{
                    invoice.price_after_discount ? (invoice.price_after_discount).toFixed(2) :
                        (invoice.sale_price * invoice.quantity - (invoice.sale_price * invoice.quantity
                            * invoice.cart_discount / 100)).toFixed(2)
                  }}
                </td>

                <td>
                  <button @click="remove(invoice)" class="btn btn-danger">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="clearfix" style="margin-top: 2%">


              <div class="cart-block cart-block-footer clearfix">
                <div class="mt-3">
                  <strong>Total Before Discount</strong>
                </div>
                <div class="mt-1">
                  <span>$ {{ cartTotalBeforeDiscount.toFixed(2) }}</span>
                </div>
              </div>

              <div class="cart-block cart-block-footer clearfix">
                <div class="mt-3">
                  <strong>sub Total</strong>
                </div>
                <div class="mt-1">
                  <span>$ {{ cartTotal.toFixed(2) }}</span>
                </div>
              </div>

              <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                <div class="mt-3">
                  <strong>Delivery</strong>
                </div>
                <div class="mt-1">
                  <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
                </div>
              </div>

              <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                <div class="mt-3">
                  <strong>Total</strong>
                </div>
                <div class="mt-1">
                  <!-- <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span> -->
                  <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }} </span>
                </div>
              </div>
            </div>

            <!--cart final price -->

            <div class="clearfix mt-5">

              <div class="cart-block cart-block-footer cart-block-footer-price clearfix">
                <div class="d-flex">
                  <!-- <form @submit.prevent="save">
                      <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                          role="alert" v-if="couponMessage">
                          <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                          <span v-else>&#10004;</span>
                          {{ couponMessage }}
                      </div>
                      <span class="checkbox">
                          <input type="checkbox" id="couponCodeID" />
                          <label for="couponCodeID">Promo code</label>
                          <input type="text" v-model="cart.coupon_code" class="form-control form-coupon"
                              placeholder="Enter your coupon code" />
                      </span>

                      <button type="submit" class="btn btn-main">Apply</button>
                  </form> -->

                  <form @submit.prevent="subPercent" class="me-5">
                    <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                         role="alert" v-if="couponMessage">
                      <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                      <span v-else>&#10004;</span>
                      {{ couponMessage }}
                    </div>
                    <!-- <span class="checkbox">
                        <input type="checkbox" id="couponCodeID" />
                        <label for="couponCodeID">Promo code</label>
                        <input type="text" v-model="cart.coupon_code" class="form-control form-coupon"
                            placeholder="Enter your coupon code" />
                    </span> -->

                    <button type="submit" class="btn btn-main">Apply</button>
                  </form>

                  <!-- <form @submit.prevent="monthlyPercent">
                      <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                          role="alert" v-if="couponMessage">
                          <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                          <span v-else>&#10004;</span>
                          {{ couponMessage }}
                      </div>

                      <button type="submit" class="btn btn-outline-dark">Monthly Percent</button>
                  </form> -->

                  <form @submit.prevent="percentLinked" class="ms-5">
                    <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                         role="alert" v-if="couponMessage">
                      <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                      <span v-else>&#10004;</span>
                      {{ couponMessage }}
                    </div>

                    <button type="submit" class="btn btn-success">Linked Percent</button>
                  </form>

                  <!-- <form @submit.prevent="totalPercentage">
                      <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']"
                          role="alert" v-if="couponMessage">
                          <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                          <span v-else>&#10004;</span>
                          {{ couponMessage }}
                      </div>

                      <button type="submit" class="btn btn-outline-dark">Percentage</button>
                  </form> -->

                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>

            <div v-if="claimedCoupon">
              <input type="text" v-model="cart.coupon_code" class="form-control w-50 my-3" placeholder="Coupon Code"/>
              <button @click="saveCouponCodeSub" class="btn btn-dark">Claim Coupon</button>

            </div>

            <div v-else>
            </div>

            <div id="messageSection" class="mt-5">
              <div v-if="successMessage" class="alert alert-success">
                {{ successMessage }}
              </div>
              <div v-if="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
              </div>
            </div>

            <form @submit="confirmCheckout">
              <div class="login-block login-block-signup">
                <hr/>

                <h3>Your Information</h3>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.first_name" class="form-control"
                             placeholder="First name: *"/>
                      <span class="error-message">{{ validationMessages.first_name }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.middle_name" class="form-control"
                             placeholder="Middle name: *"/>
                      <span class="error-message">{{ validationMessages.middle_name }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.last_name" class="form-control"
                             placeholder="Last name: *"/>
                      <span class="error-message">{{ validationMessages.last_name }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <select class="form-control" v-model="checkout.selectedGovernorate">
                        <option value="0" selected hidden>-- Choose your governorates --</option>
                        <option v-for="governorate in governorates" :key="governorate.id"
                                :value="governorate.id">
                          {{ governorate.governorates_name }}
                        </option>
                      </select>
                      <span class="error-message">{{ validationMessages.selectedGovernorate }}</span>

                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <select class="form-control" v-model="checkout.selectedCity">
                        <option value="0" selected hidden>-- Choose your city --</option>
                        <option
                            v-for="city in filteredFilterGovernorate(checkout.selectedGovernorate)"
                            :key="city.id" :value="city.id">
                          {{ city.city_name }}
                        </option>
                      </select>
                      <span class="error-message">{{ validationMessages.selectedCity }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="areaSearch"
                             @input="filterAreas" placeholder="Search for an area"/>
                      <select class="form-control" v-model="checkout.area_id">
                        <option value="0" selected hidden>-- Choose your area --</option>
                        <option v-for="area in filteredAreas" :key="area.id" :value="area.id">
                          {{ area.area_name }}
                        </option>
                      </select>
                      <span class="error-message">{{ validationMessages.area_id }}</span>
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.address" class="form-control"
                             placeholder="Address: *"/>
                      <span class="error-message">{{ validationMessages.address }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.phone" class="form-control"
                             placeholder="Phone: *"/>
                      <span class="error-message">{{ validationMessages.phone }}</span>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <input type="text" v-model="checkout.note" class="form-control"
                             placeholder="Note: *"/>

                    </div>
                  </div>
                  <!--
                                                      <div class="col-12">
                                                          <div class="form-group">
                                                              <select class="form-select" v-model="checkout.user_for_affiliate_id">
                                                                  <option>--Select User--</option>
                                                                  <option v-for="winner in winner" :key="winner.key" :value="winner.id">
                                                                      {{ winner.user_first_name }} {{ winner.user_middle_name }} {{
                                                                          winner.user_last_name }}
                                                                  </option>
                                                              </select>
                                                          </div>
                                                      </div> -->

                </div>
              </div>

              <div class="col-md-12">
                <div class="white-block">
                  <div class="h4">Choose delivery</div>

                  <hr/>

                  <span class="checkbox">
                                        <input type="radio" id="deliveryId2" name="deliveryOption"
                                               v-model="checkout.deliveryOption" value="delivery"/>
                                        <label for="deliveryId2">Delivery - <strong>{{
                                            selectedGovernorateDetails ? '$ ' +
                                                selectedGovernorateDetails.price.toFixed(2) : ''
                                          }}</strong></label>
                                    </span>

                  <span class="checkbox">
                                        <input type="radio" id="deliveryId3" name="deliveryOption"
                                               v-model="checkout.deliveryOption" value="pickup"/>
                                        <label for="deliveryId3">Pick up in the store - <strong>0</strong></label>
                                    </span>

                  <span class="checkbox">
                                        <input type="radio" id="deliveryId4" name="deliveryOption"
                                               v-model="checkout.deliveryOption" value="link"/>
                                        <label for="deliveryId4">Link to another order - <strong>0</strong></label>
                                    </span>

                  <div class="col-xs-6 text-center">
                    <button type="submit" class="btn btn-main">
                      <span class="icon icon-cart"></span> Checkout
                    </button>
                  </div>
                  <div v-if="showOutOfStockError" class="error-message">
                    One or more products are out of stock.
                  </div>

                  <div v-if="showMaxQuantityError" class="error-message">
                    Max Quantity exceed
                  </div>

                  <hr/>
                </div>
              </div>
            </form>
            <!--/signup-->
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from "../../../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },
  data() {
    return {
      bdlArray: [],
      cartBdl: [],
      errorMessage: null,
      successMessage: null,
      areaSearch: '',
      affiliateuserdiscounts: [],
      validationMessages: {
        first_name: '',
        middle_name: '',
        last_name: '',
        selectedGovernorate: '',
        selectedCity: '',
        area_id: '',
        address: '',
        phone: '',
      },
      cart: {
        coupon_code: "",
      },
      selectedOption: '',
      invoices: [],
      invoice: [],
      users: [],
      productSales: [],
      selectedUserId: 0,
      checkout: {
        area_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        note: "",
        phone: "",
        deliveryOption: 'delivery',
        pick_up: 1,
        selectedGovernorate: [],
        selectedCity: [],
        user_id: this.user?.id,
      },
      productConfigurations: [],
      priceAfterDiscount: null,
      carts: [],
      governorates: [],
      areas: [],
      cities: [],
      isLoading: false, // Add this line
      message: "", // Define the "message" property with an initial value
      isError: false,
      isCouponError: false,
      couponMessage: "",
      showOutOfStockError: false, // Add this line to define the property
      showMaxQuantityError: false,
      showSuccessMessage: false,
      total: 0,
      results: [],
      winner: [],
      claimedCoupon: null
    }
  },

  watch: {
    carts: {
      handler() {
        this.$nextTick(() => {
          this.updateCartTotal();
        });
      },
      deep: true,
    },
  },

  computed: {

    areasForSelectedCity() {
      const selectedCityId = this.checkout.selectedCity;
      return this.areas.filter((area) => area.city_id === selectedCityId);
    },
    filteredAreas() {
      const searchQuery = this.areaSearch.toLowerCase().trim();

      // If there is a search query, filter areas based on it; otherwise, show all areas for the selected city
      return searchQuery
          ? this.areasForSelectedCity.filter((area) =>
              area.area_name.toLowerCase().includes(searchQuery)
          )
          : this.areasForSelectedCity;
    },


    filteredUsers() {
      return this.users.filter(user => user.user_id === this.user?.id);
    },


    selectedGovernorateDetails() {
      if (this.checkout.area_id === 0) {
        return null; // No governorate selected
      }

      if (this.checkout.deliveryOption === 'pickup') {
        return {price: 0}; // Set price to 0 for pick up in store
      }

      if (this.checkout.deliveryOption === 'link') {
        return {price: 0}; // Set price to 0 for pick up in store
      }

      return this.areas.find((area) => area.id === this.checkout.area_id);
    },

    cartTotal() {
      return this.invoices.reduce((total, invoice) => {
        let price;
        if (invoice.price_after_discount !== null) {
          price = invoice.price_after_discount;
        } else if (invoice.cart_discount > 0) {
          price = invoice.sale_price * invoice.quantity - (invoice.sale_price * invoice.quantity * invoice.cart_discount / 100)
        } else {
          price = invoice.sale_price * invoice.quantity;
        }
        return total + parseFloat(price);
      }, 0);
    },

    cartTotalBeforeDiscount() {
      return this.invoices.reduce((total, invoice) => {
        let price;

        price = invoice.sale_price * invoice.quantity;

        return total + parseFloat(price);
      }, 0);
    },

    bdlTotal() {
      return this.cartBdl.reduce((total, item) => {
        let price = 0; // Initialize the price with a default value
        if (item.bundle_id !== null && item.sale_price) { // Check if sale_price is truthy
          price = item.sale_price * item.quantity;
        }
        return total + price;
      }, 0);
    },

    bdlTotalDiscount() {
      return this.cartBdl.reduce((total, item) => {
        let price = 0; // Initialize the price with a default value
        if (item.bundle_id !== null && item.percentage !== null) {
          // Use the same condition without checking for sale_price again
          price = item.sale_price * item.quantity - (item.sale_price * item.quantity * item.percentage / 100);
        }

        return total + price;
      }, 0);
    },


  },


  created() {
    this.cartLoad();
    this.userLoad();
    this.checkBdl();
    this.userWinner();
    this.getClaimedCouponCode();
  },

  methods: {

    saveCouponCodeSub() {
      this.saveCoupon();
    },

    saveCoupon() {
      let formData = new FormData();
      formData.append("coupon_code", this.cart.coupon_code);
      axiosInstance
          .post(
              `api/apply-coupon-code?user_id=${this.user?.id}`,
              formData
          )
          .then((response) => {
            if (response.status === 200) {
              this.showSuccessMessage = true;
              this.message = "Coupon has been added successfully";
              this.isError = false;
              this.hideMessageAfterDelay(5000);
              this.cartLoad();
              this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
              this.updateCartTotal(); // Update the cart total
            } else {
              this.showSuccessMessage = true;
              this.message = "Failed to add coupon";
              this.isError = true;
              this.hideMessageAfterDelay(5000);
            }
            this.hideCouponMessageAfterDelay(5000);
          })
          .catch((error) => {
            this.showSuccessMessage = true;
            this.message = "Failed to add coupon";
            this.isError = true;
            console.error("Failed to add coupon", error);
            this.hideMessageAfterDelay(5000);
            console.error("Failed to add to cart", error);
          });
    },

    getClaimedCouponCode() {
      const page = `api/get-claimed-coupon?user_id=${this.user?.id}`; // Ensure the correct endpoint
      axiosInstance.get(page).then(({data}) => {
        console.log("Claimed coupon data:", data); // Log the response to check
        this.claimedCoupon = data.claimedCoupon; // Access correctly
      }).catch((error) => {
        console.error("Error fetching claimed coupon:", error); // Log errors
      });
    },

    percentLinked() {
      this.updateLinkedPercent();
    },

    couponCodeSub() {
      this.updateSubCouponCode();
    },

    updateLinkedPercent() {
      axiosInstance
          .post(
              `api/linked-percent?user_id=${this.user?.id}`)
          .then(response => {
            this.cartLoad();
            this.checkBdl();
            console.log(response);
          })
          .catch(error => {
            console.error('Error updating affiliate percentages:', error);
          });
    },

    updateSubCouponCode() {
      axiosInstance
          .post(
              `api/apply-coupon-code?user_id=${this.user?.id}`)
          .then(response => {
            this.cartLoad();
            this.checkBdl();
            console.log(response);
          })
          .catch(error => {
            console.error('Error updating affiliate percentages:', error);
          });
    },

    checkBdl() {
      // Check if selectedUserId is defined and not null
      var page = `api/affiliate-cart-bundle?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.cartBdl = data.cart;
        this.bdlArray = data.bdl;
      });


    },

    totalPercentage() {
      this.updateTotalPercentage();
    },

    updateTotalPercentage() {
      axiosInstance
          .post(
              `api/sub-aff-invoice?user_id=${this.user?.id}`)
          .then(response => {
            this.cartLoad();
            console.log(response);
          })
          .catch(error => {
            console.error('Error updating affiliate percentages:', error);
          });
    },

    copyCouponCode(couponCode) {
      const tempInput = document.createElement('input');
      tempInput.value = couponCode;
      document.body.appendChild(tempInput);

      tempInput.select();
      document.execCommand('copy');

      document.body.removeChild(tempInput);

      // Update the copyMessage property of the current item
      this.affiliateuserdiscounts.forEach(item => {
        if (item.coupon_code === couponCode) {
          item.copyMessage = 'Coupon code copied to clipboard';
          setTimeout(() => {
            item.copyMessage = ''; // Clear the message after a few seconds
          }, 3000); // Hide the message after 3 seconds (adjust as needed)
        }
      });
    },

    save() {
      this.saveData();
    },

    affiliate() {
      this.affiliateDiscount();
    },

    affiliateDiscount() {
      let formData = new FormData();
      formData.append("user_id", this.selectedUserId);
      axiosInstance
          .post(
              `api/affiliate-sale`,
              formData
          )
          .then((response) => {
            if (response.status === 200) {
              this.couponMessage = "Coupon has been added successfully";
              this.isCouponError = false;
              this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
              this.updateCartTotal(); // Update the cart total
              this.cartLoad();
            }
            this.hideCouponMessageAfterDelay(5000);
          })
          .catch((error) => {
            this.couponMessage = "Failed to add coupon or Invalid coupon code";
            this.isCouponError = true;
            console.error("Failed to add coupon", error);
            this.hideCouponMessageAfterDelay(5000);
          });
    },

    userWinner() {
      var page = `api/sub-affiliate-user-gift?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        console.log(data);
        this.winner = data.userGift || [];
      });
    },

    saveData() {
      let formData = new FormData();
      formData.append("coupon_code", this.cart.coupon_code);
      formData.append("user_id", this.selectedUserId);
      axiosInstance
          .post(
              `api/affiliate-coupon`,
              formData
          )
          .then((response) => {
            if (response.status === 200) {
              // Coupon is valid, update the cart and display success message
              this.couponMessage = "Coupon has been added successfully";
              this.isCouponError = false;
              this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
              this.updateCartTotal(); // Update the cart total
              this.cartLoad();
            } else {
              // Server responded with a non-200 status, handle the error
              this.couponMessage = "Failed to add coupon";
              this.isCouponError = true;
            }
            this.hideCouponMessageAfterDelay(5000);
          })
          .catch((error) => {
            // Server responded with an error, handle the error
            if (error.response && error.response.status === 404) {
              // Coupon not found (product does not belong to any coupon)
              this.couponMessage = "Product does not belong to any coupon";
              this.isCouponError = true;
            } else {
              // Other errors
              this.couponMessage = "Failed to add coupon";
              this.isCouponError = true;
              console.error("Failed to add coupon", error);
            }
            this.hideCouponMessageAfterDelay(5000);
          });
    },
    isOutOfStock(invoice) {
      const maxQuantity = this.getMaxQuantity(invoice.product_detail_id, invoice);
      return invoice.quantity >= maxQuantity;
    },

    // async saveData() {
    //     try {
    //         const response = await this.checkCouponValidity();

    //         if (!response.isValid) {
    //             this.couponMessage = "Invalid coupon code for your current cart items.";
    //             this.isCouponError = true;
    //             this.hideCouponMessageAfterDelay(5000);
    //             return;
    //         }

    //         let formData = new FormData();
    //         formData.append("coupon_code", this.cart.coupon_code);
    //         formData.append("user_id", this.selectedUserId);

    //         axiosInstance
    //             .post(`api/affiliate-coupon`, formData)
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     this.couponMessage = "Coupon has been added successfully";
    //                     this.isCouponError = false;
    //                     this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
    //                     this.updateCartTotal(); // Update the cart total
    //                     this.cartLoad();
    //                 } else {

    //                 }
    //                 this.hideCouponMessageAfterDelay(5000);
    //             })
    //             .catch((error) => {
    //                 this.couponMessage = "Failed to add coupon or Invalid coupon code";
    //                 this.isCouponError = true;
    //                 console.error("Failed to add coupon", error);
    //                 this.hideCouponMessageAfterDelay(5000);
    //             });
    //     } catch (error) {
    //         console.error("Failed to check coupon validity", error);
    //     }
    // },

    // async checkCouponValidity() {
    //     try {
    //         const response = await axiosInstance.get(`api/check-coupon-validity`, {
    //             params: {
    //                 coupon_code: this.cart.coupon_code,
    //                 user_id: this.selectedUserId
    //             }
    //         });
    //         return response.data; // Server should return { isValid: true/false }
    //     } catch (error) {
    //         console.error("Failed to check coupon validity", error);
    //         return { isValid: false };
    //     }
    // },


    clearDataAfterSuccess() {
      // Reset the relevant data properties to their initial values
      // For example, assuming you have a data object like this:
      this.someData = null;
      this.anotherData = [];
      // Clear other data properties as needed
    },


    filteredFilterGovernorate(governorateId) {
      return this.cities.filter(city => city.governorates_id === governorateId)
    },

    filteredFilterCity(cityId) {
      return this.areas.filter(areas => areas.city_id === cityId);
    },

    saveOrder() {
      this.saveOrderData();
    },

    percentage() {
      this.makePercent();
    },

    subPercent() {
      this.updateAffiliatePercent();
    },

    confirmCheckout(event) {
      event.preventDefault(); // Prevent the form from submitting

      if (window.confirm("Are you sure you want to proceed with the checkout?")) {
        // User confirmed, proceed with checkout logic
        this.saveOrderData();
      } else {
        // User canceled, do not proceed with checkout
        // You can add a message or perform other actions here
      }
    },

    saveOrderData() {
      if (this.checkout.deliveryOption === 'pickup') {
        this.checkout.pick_up = 0; // Pick up from the store
      } else if (this.checkout.deliveryOption === 'delivery') {
        this.checkout.pick_up = 1; // Not pick up from the store
      } else {
        this.checkout.pick_up = 2;
      }

      Object.keys(this.validationMessages).forEach(field => {
        this.validationMessages[field] = '';
      });

      let isValid = true;

      // Check if each required field is filled, if not, update the corresponding validation message
      if (!this.checkout.first_name) {
        this.validationMessages.first_name = '* First name is required.';
        isValid = false;
      }

      if (!this.checkout.middle_name) {
        this.validationMessages.middle_name = '* Middle name is required.';
        isValid = false;
      }

      if (!this.checkout.last_name) {
        this.validationMessages.last_name = '* Last name is required.';
        isValid = false;
      }

      if (!this.checkout.selectedGovernorate) {
        this.validationMessages.selectedGovernorate = '* Governorate selection is required.';
        isValid = false;
      }

      if (!this.checkout.selectedCity) {
        this.validationMessages.selectedCity = '* City selection is required.';
        isValid = false;
      }

      if (!this.checkout.area_id) {
        this.validationMessages.area_id = '* Area selection is required.';
        isValid = false;
      }

      if (!this.checkout.address) {
        this.validationMessages.address = '* Address is required.';
        isValid = false;
      }

      if (!this.checkout.phone) {
        this.validationMessages.phone = '* Phone is required.';
        isValid = false;
      }

      // If any required field is not filled, stop further processing and show the validation messages
      if (!isValid) {
        return;
      }
      // Prepare the order data to be sent to the server
      const orderData = {
        user_id: this.user?.id,
        area_id: this.checkout.area_id,
        first_name: this.checkout.first_name,
        middle_name: this.checkout.middle_name,
        last_name: this.checkout.last_name,
        address: this.checkout.address,
        note: this.checkout.note,
        phone: this.checkout.phone,
        pick_up: this.checkout.pick_up,
        user_for_affiliate_id: this.checkout.user_for_affiliate_id,
        // other order data properties...
      };

      // Make the API request to save the order data
      axiosInstance.post(`api/sub-affiliate-checkout`, orderData)
          .then((response) => {
            if (response.status === 200) {
              // Successful API call
              this.cartLoad();
              this.showSuccessMessage = true;
              this.message = "Your invoice has been successful"; // Update success message
              this.isError = false; // Reset isError to false (no error)

              // Clear all data after success
              this.clearDataAfterSuccess(); // Create a function to clear data

              // Display the message
              this.displayMessageAndReload();
            } else {
              // API call failed
              this.message = "Failed to send invoice!"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
              this.hideMessageAfterDelay(5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.message = "Failed to checkout: " + error.response.data.error;

                // Check if the error indicates exceeding max quantity
                if (error.response.data.error.includes("Quantity exceeds maximum allowed for product")) {
                  this.showMaxQuantityError = true;
                  this.isError = true;
                }

                // Check if the error indicates insufficient stock
                if (error.response.data.error.includes("Insufficient stock")) {
                  this.showOutOfStockError = true;
                }
              }
            } else if (error.request) {
              // The request was made but no response was received
              this.message = "No response received from the server.";
            } else {
              // Something happened in setting up the request that triggered an Error
              this.message = "An error occurred while making the request.";
            }
            this.isError = true;
            console.error("Failed to checkout", error);
            this.hideMessageAfterDelay(5000);
          });

      // Function to display the message and reload the page

    },

    monthlyPercent() {
      this.updateAffiliatePercentMonthly();
    },

    displayMessageAndReload() {
      // Add the code to display the message on the webpage (e.g., toast message)
      // For example:
      // const toastElement = document.getElementById('toast');
      // toastElement.innerHTML = this.message;
      // toastElement.style.display = 'block';

      // Reload the page after a delay (e.g., 3 seconds)
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Adjust the delay time as needed (here, it's set to 3 seconds)
    },

    userLoad() {
      var page = `api/sub-affiliate-usercart?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.users = data.user;
        this.governorates = data.governorate;
        this.cities = data.city;
        this.areas = data.area;
        this.isLoading = false;
      });
    },

    cartLoad() {
      var page = `api/sub-affiliate-cart?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {

        this.invoices = data.invoice;
        this.governorates = data.governorate;
        this.cities = data.city;
        this.areas = data.area;
        this.productConfigurations = data.productConfiguration;
        this.productSales = data.productSales;
        this.affiliateuserdiscounts = data.affiliateuserdiscount;

        this.isLoading = false;
      });
    },

    getMaxQuantity(productDetailId, invoice) {
      const productConfiguration = invoice.productConfiguration;

      if (productConfiguration && productConfiguration.quantity !== null) {
        return productConfiguration.quantity;
      } else {
        return 0;
      }
    },
    incrementQuantity(invoice) {
      const maxQuantity = this.getMaxQuantity(invoice.product_detail_id, invoice);
      if (invoice.quantity < maxQuantity) {
        invoice.quantity++;
        this.updateCartTotal();
        this.updateQuantityInDatabase(invoice);
      }
    },
    decrementQuantity(invoice) {
      if (invoice.quantity > 1) {
        invoice.quantity--;
        this.updateCartTotal();
        this.updateQuantityInDatabase(invoice);
      }
    },

    updateQuantityInDatabase(invoice) {
      const productDetailId = invoice.product_detail_id || invoice.id; // Use invoice.product_detail_id if available, otherwise fall back to invoice.id

      axiosInstance.post(`api/cart-update/${invoice.id}`, {
        quantity: invoice.quantity,
        user_id: this.user?.id,
        product_detail_id: productDetailId,
      })
    },

    makePercent() {
      axiosInstance.post(`api/make-percent`)
          .then((response) => {
            if (response.status === 200) {
              // Successful API call
              this.cartLoad();
              this.checkBdl();
              this.bdlTotalDiscount();
              this.showSuccessMessage = true;
              this.message = "Percentage applied success"; // Update success message
              this.isError = false; // Reset isError to false (no error)
            } else {
              // API call failed
              this.message = "Failed to apply percentage!"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
              this.hideMessageAfterDelay(5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.message = "Failed to apply percentage: " + error.response.data.error;
              }
            } else if (error.request) {
              // The request was made but no response was received
              this.message = "No response received from the server.";
            } else {
              // Something happened in setting up the request that triggered an Error
              this.message = "An error occurred while making the request.";
            }
            this.isError = true;
            console.error("Failed to apply percentage", error);
            this.hideMessageAfterDelay(5000);
          });
    },

    updateCartTotal() {
      if (
          this.invoice &&
          this.invoice.discount !== undefined &&
          this.invoice.quantity !== undefined
      ) {
        if (this.invoice.discount > 0 && this.priceAfterDiscount !== null) {
          // Calculate total with discount
          this.cartTotal = (this.priceAfterDiscount * this.invoice.quantity).toFixed(2);
        } else {
          // Calculate total without discount
          this.cartTotal = (this.invoice.sale_price * this.cart.quantity).toFixed(2);
        }
      }
    },

    hideCouponMessageAfterDelay(delay) {
      setTimeout(() => {
        this.Couponmessage = ""; // Clear the message
        this.isCouponError = false; // Reset isError to false
      }, delay);
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.message = ""; // Clear the message
        this.isError = false; // Reset isError to false
      }, delay);
    },

    updateAffiliatePercentMonthly() {
      axiosInstance
          .post(`api/sub-aff-monthly-percent?user_id=${this.user?.id}`)
          .then(response => {
            this.cartLoad();
            this.checkBdl();
            this.bdlTotalDiscount();

            // Check for success message
            if (response.data.success) {
              // Update successMessage and clear errorMessage
              this.successMessage = response.data.success;
              this.errorMessage = null;
            } else {
              // Update errorMessage and clear successMessage
              this.errorMessage = response.data.error;
              this.successMessage = null;
            }
          })
          .catch(error => {
            console.error('Error updating affiliate percentages:', error);

            // Update errorMessage and clear successMessage
            this.errorMessage = 'An error occurred while updating affiliate percentages.';
            this.successMessage = null;
          });
    },

    remove(invoices) {
      var url = `api/cart-delete/${invoices.id}`;
      axiosInstance.delete(url)
          .then((response) => {
            if (Array.isArray(this.invoices) && invoices) {
              const index = this.invoices.indexOf(invoices);
              if (index !== -1) {
                this.invoices.splice(index, 1);
              }
            }
            if (response.status === 200) {
              this.message = "Product has been removed from cart successfully"; // Update success message
              this.isError = false; // Reset isError to false
            } else {
              this.message = "Failed to remove product"; // Update error message
              this.isError = true; // Set isError to true
            }
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          })
          .catch((error) => {
            this.message = "Failed to remove product"; // Update error message
            this.isError = true; // Set isError to true
            console.error("Failed to remove product", error);
            this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
          });
    },

    updateAffiliatePercent() {
      axiosInstance
          .post(
              `api/sub-affiliate-percent?user_id=${this.user?.id}`)
          .then(response => {
            this.cartLoad();
            this.checkBdl();
            this.bdlTotalDiscount();
            console.log(response);
          })
          .catch(error => {
            console.error('Error updating affiliate percentages:', error);
          });
    },

    removeBdl(index) {
      const bdl = this.bdlArray[index];

      // Correctly format the URL
      const url = `api/delete-affiliate-bundle/${bdl.bundle.id}?user_id=${this.user?.id}`;

      axiosInstance
          .delete(url)
          .then(async (response) => {
            if (Array.isArray(this.bdlArray) && response.status === 200) {
              // Update the bdlArray after removing the bundle
              await this.checkBdl();

              // Create a copy of the array to trigger reactivity
              const updatedBdlArray = [...this.bdlArray];

              // Use Vue.js $set to ensure proper reactivity
              this.$set(this, 'bdlArray', updatedBdlArray);

              this.message = "Bundle has been removed from cart successfully";
              this.isError = false;
              console.log('Bundle removed successfully');
            } else {
              this.checkBdl();
              console.error('Failed to remove bundle. Response status:', response.status);
              console.error('Response data:', response.data);

              this.message = "Failed to remove bundle";
              this.isError = true;
              console.log('Failed to remove bundle');
            }
            this.hideMessageAfterDelay(5000);
          })
          .catch((error) => {
            this.message = "Failed to remove bundle";
            this.isError = true;
            console.error("Failed to remove bundle", error);
            this.hideMessageAfterDelay(5000);
          });
    },


  },
}
</script>

<style scoped>
img {
  width: 50px;
  height: 50px;
}

.error-message {
  color: red;
  font-size: 12px;
}

* {
  font-size: 12pt !important;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}


.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>