<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form>
                        <select v-model="selectedDiscount">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="discount in discounts" :key="discount.id" :value="discount.id">
                                {{ discount.discount_name }}
                            </option>
                        </select>
                        <button @click="submitForm" class="btn btn-success">Submit</button>

                        <table class="table w-100">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Sku</th>
                                    <th>Title</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="productdetail in productdetails" :key="productdetail.id">
                                    <th scope="row">{{ productdetail.id }}</th>
                                    <td><img :src="'http://localhost:8000/product_detail/' + productdetail.image" /></td>
                                    <td>{{ productdetail.sku }}</td>
                                    <td>{{ productdetail.title }}</td>
                                    <td>
                                        <input type="checkbox" :value="productdetail.id" v-model="selectedProducts"
                                            class="custom-checkbox">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';

import router from "@/router";

export default {
    name: "CreateProductDiscount",

    data() {
        return {
            productdiscount: {
                product_detail_id: 0,
                discount_id: 0,
            },
            productdetails: [], // Update variable name to employees
            discounts: [],
            selectedDiscount: null,
            selectedProducts: [],
        };
    },

    methods: {
        // save() {
        //     this.saveData();
        // },

        submitForm() {
            const formData = {
                discount_id: this.selectedDiscount,
                product_ids: this.selectedProducts,
            };

            axiosInstance.post(`api/product-discount-store`, formData)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/product-discount");
                })
                .catch(error => {
                    console.error(error);
                });
        },
        // saveData() {
        //     let formData = new FormData();
        //     formData.append('product_detail_id', this.productdiscount.product_detail_id);
        //     formData.append('discount_id', this.productdiscount.discount_id);
        //     axios.post(`${BASE_URL}product-discount-store`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(() => {
        //         // Redirect to the desired page
        //         router.push("/product-discount");
        //     });
        // },
        fetchAttributes() {
            axiosInstance
                .get(`api/product-discount-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.productdetails = response.data.productdetail;
                    this.discounts = response.data.discount;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};
</script>

<style scoped>
.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox:checked {
    /* Add your custom styling for checked state */
    /* Example properties */
    background-color: #5cb85c;
    /* Change the background color when checked */
}
</style>