<script>
import axiosInstance from '../../../axiosInstance';
import * as XLSX from 'xlsx'; // Import the xlsx library

export default {
  data() {
    return {
      allProductDetails: [],
      currentPage: 1,
      selectedBrand: null,
      brands: []
    };
  },

  created() {
    this.userLoad();
  },

  methods: {
    userLoad() {
      var nextPage = `api/export-admin-prices`;
      axiosInstance.get(nextPage).then(({ data }) => {
        this.allProductDetails = data.productDetail;
      });
    },

    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      } else {
        this.userLoad();
      }
    },

    changePage(page) {
      this.currentPage = page;
      this.userLoad();
    },

    updateDescription(product) {
      axiosInstance
          .post(`api/update-add-to-excel/${product.id}`, {})
          .then(() => {
            this.userLoad(); // Reload data after update
          })
          .catch((error) => {
            console.error('Error updating details:', error);
          });
    },

    exportToExcel() {
      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Create a worksheet from the data in the `allProductDetails` array
      const ws = XLSX.utils.json_to_sheet(this.allProductDetails);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'Product Details');

      // Set the filename and save the workbook as an Excel file
      const filename = 'Product_Details.xlsx';
      XLSX.writeFile(wb, filename); // Trigger file download
    },
  },
};
</script>


<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <h3 class="mb-5">Product Detail</h3>

          <button @click="exportToExcel" class="btn btn-secondary mb-3">
            Export to Excel
          </button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>Product Title</th>
              <th>Product Sku</th>
              <th>Main Price</th>
              <th>Old Price</th>
              <th>New Price</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in allProductDetails" :key="product.id">
              <td>{{ product.title }}</td>
              <td>{{ product.sku }}</td>
              <td>{{ product.sale_price }}</td>
              <td>{{ product.old_price }}</td>
              <td>{{ product.new_price }}</td>
              <td>
                <button @click="updateDescription(product)" class="btn btn-primary">
                  Update
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>