<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <div class="row">
            <div class="col-6">

              <u>{{ affs.first_name }} {{ affs.last_name }}</u> <br/>

              <div>
                <label for="from">From:</label>
                <input type="date" v-model="filterFrom" @change="applyDateFilter"/>
              </div>
              <div>
                <label for="to">To:</label>
                <input type="date" v-model="filterTo" @change="applyDateFilter"/>
              </div>

              <table class="table table-responsive">
                <tr>
                  <td>Affiliate Name</td>
                  <td>User Name</td>
                  <td>Invoice NB.</td>
                  <td>Item</td>
                  <td>Quantity</td>
                  <td>Item Price</td>
                  <td>Commission</td>
                  <td>Total</td>
                  <td>Created At</td>
                </tr>

                <tr v-for="cart in carts" :key="cart.id">
                  <td>{{ cart.first_name }} {{ cart.last_name }}</td>
                  <td>
                    {{ cart.invoices_first }} {{ cart.invoices_middle }}
                    {{ cart.invoices_last }}
                  </td>
                  <td>INV00{{ cart.invoice_id }}</td>
                  <td>{{ cart.sku }}</td>
                  <td>{{ cart.quantity }}</td>
                  <td>{{ cart.sale_price.toFixed(2) }}</td>

                  <td v-if="cart.cart_discount > 0 && cart.affiliate_percentage === null
                    ">
                    0
                  </td>
                  <td v-else-if="cart.affiliate_percentage === null">%0</td>
                  <td v-else>%{{ cart.percentage }}</td>
                  <td v-if="cart.affiliate_percentage === null">
                    {{ ((cart.sale_price * cart.quantity * 30) / 100).toFixed(2) }}
                  </td>
                  <td v-else>
                    {{
                      (
                          cart.sale_price *
                          cart.quantity *
                          ((cart.affiliate_percentage - cart.percentage) / 100)
                      ).toFixed(2)
                    }}
                  </td>

                  <td>{{ new Date(cart.created_at).toLocaleString() }}</td>
                </tr>
              </table>

              <div>Before Discount: $ {{ totalBeforeDiscount.toFixed(2) }}</div>
              <div>After Discount: $ {{ totalAfterDiscount.toFixed(2) }}</div>
              <div>Total: $ {{ total.toFixed(2) }}</div>

            </div>

            <div class="col-6">
              <div>
                <label for="from">From:</label>
                <input type="date" v-model="returnFilterFrom" @change="applyReturnDateFilter"/>
              </div>
              <div>
                <label for="to">To:</label>
                <input type="date" v-model="returnFilterTo" @change="applyReturnDateFilter"/>
              </div>

              <table class="table table-responsive">
                <tr>
                  <td>Affiliate Name</td>
                  <td>User Name</td>
                  <td>Invoice NB.</td>
                  <td>Item</td>
                  <td>Quantity</td>
                  <td>Item Price</td>
                  <td>Commission</td>
                  <td>Total</td>
                  <td>Created At</td>
                </tr>

                <tr v-for="returnCart in returnCarts" :key="returnCart.id">
                  <td>{{ returnCart.first_name }} {{ returnCart.last_name }}</td>
                  <td>
                    {{ returnCart.invoices_first }} {{ returnCart.invoices_middle }}
                    {{ returnCart.invoices_last }}
                  </td>
                  <td>INV00{{ returnCart.invNb }}</td>
                  <td>{{ returnCart.sku }}</td>
                  <td>{{ returnCart.quantity }}</td>
                  <td>{{ returnCart.sale_price.toFixed(2) }}</td>

                  <td v-if="returnCart.cart_discount > 0 && returnCart.affiliate_percentage === null
                    ">
                    0
                  </td>
                  <td v-else-if="returnCart.affiliate_percentage === null">%0</td>
                  <td v-else>%{{ returnCart.percentage }}</td>
                  <td v-if="returnCart.affiliate_percentage === null">
                    {{ ((returnCart.sale_price * returnCart.quantity * 30) / 100).toFixed(2) }}
                  </td>
                  <td v-else>
                    {{
                      (
                          returnCart.sale_price *
                          returnCart.quantity *
                          ((returnCart.affiliate_percentage - returnCart.percentage) / 100)
                      ).toFixed(2)
                    }}
                  </td>

                  <td>{{ new Date(returnCart.created_at).toLocaleString() }}</td>
                </tr>
              </table>

              <div>Before Discount: $ {{ returnTotalBeforeDiscount.toFixed(2) }}</div>
              <div>After Discount: $ {{ returnTotalAfterDiscount.toFixed(2) }}</div>
              <div>Total: $ {{ returnTotal.toFixed(2) }}</div>

            </div>

          </div>

          <div>Before Discount: $ {{ lastBefore.toFixed(2) }}</div>
          <div>After Discount: $ {{ lastAfter.toFixed(2) }}</div>
          <div>Total: $ {{ lastTotal.toFixed(2) }}</div>


          <button @click="calculateAndDownloadExcel">Download Excel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../../axiosInstance";
import * as XLSX from "xlsx"; // Import the entire XLSX module

export default {
  data() {
    return {
      carts: [],
      returnCarts: [],
      total: 0,
      returnTotal: 0,
      filterFrom: null,
      filterTo: null,
      returnFilterFrom: null,
      returnFilterTo: null,
      totalBeforeDiscount: 0,
      totalAfterDiscount: 0,
      returnTotalBeforeDiscount: 0,
      returnTotalAfterDiscount: 0,
      user: [],
      lastBefore: 0,
      lastAfter: 0,
      lastTotal: 0,
      affs: []
    };
  },

  created() {
    this.orderLoad();
    this.getUser();
    this.userNameLoad();

  },

  mounted() {
    this.$nextTick(() => {
      this.lastBeforeDiscount();
      this.lastAfterDiscount();
      this.lastTotals();
    });
  },

  methods: {
    userNameLoad() {
      var page = `api/user-name-get/${this.$route.params.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.affs = data;
      });
    },

    getUser() {
      const apiEndpoint = `api/export-admin-aff/${this.$route.params.id}`;
      axiosInstance
          .get(apiEndpoint)
          .then(({data}) => {
            // Update your data properties with the fetched data
            this.user = data;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    },

    applyDateFilter() {
      if (this.filterFrom && this.filterTo) {
        const startDate = new Date(this.filterFrom);
        const endDate = new Date(this.filterTo);

        // Format the dates as 'YYYY-MM-DD' before sending
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];

        // Make the API request with formatted dates
        this.orderLoad(formattedStartDate, formattedEndDate);
      }
    },

    orderLoad(startDate, endDate) {
      const apiEndpoint = `api/export-admin-res/${this.$route.params.id}`;
      const params = {
        from: startDate,
        to: endDate,
      };

      axiosInstance
          .get(apiEndpoint, {params})
          .then(({data}) => {
            // Update your data properties with the fetched data
            this.carts = data.cart;
            this.calculateTotal();
            this.beforeDiscount();
            this.afterDiscount();
            this.lastBeforeDiscount();
            this.lastAfterDiscount();
            this.lastTotals();
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    },

    applyReturnDateFilter() {
      if (this.returnFilterFrom && this.returnFilterTo) {
        const returnStartDate = new Date(this.returnFilterFrom);
        const returnEndDate = new Date(this.returnFilterTo);

        // Format the dates as 'YYYY-MM-DD' before sending
        const formattedStartDate = returnStartDate.toISOString().split("T")[0];
        const formattedEndDate = returnEndDate.toISOString().split("T")[0];

        // Make the API request with formatted dates
        this.returnOrderLoad(formattedStartDate, formattedEndDate);
      }
    },

    returnOrderLoad(returnStartDate, returnEndDate) {
      const apiEndpoint = `api/export-admin-return-res/${this.$route.params.id}`;
      const params = {
        from: returnStartDate,
        to: returnEndDate,
      };

      axiosInstance.get(apiEndpoint, {params})
          .then(({data}) => {
            this.returnCarts = data.refund;
            this.returnCalculateTotal();
            this.returnBeforeDiscount();
            this.returnAfterDiscount();
            this.lastBeforeDiscount();
            this.lastAfterDiscount();
            this.lastTotals();
          })
    },

    calculateAndDownloadExcel() {
      const wb = XLSX.utils.book_new();

      // Create a new worksheet for the combined data
      const ws = XLSX.utils.aoa_to_sheet([]);

      // Add Invoice Data
      let invoiceData = [["Affiliate Name", "Invoice NB.", "Item", "Quantity", "Item Price", "Commission", "Total", "Created At"]];
      this.carts.forEach(cart => {
        const rowData = [
          `${cart.first_name} ${cart.last_name}`,
          `INV00${cart.invoice_id}`,
          cart.sku,
          cart.quantity,
          cart.sale_price.toFixed(2),
          cart.affiliate_percentage === null ? '0%' : `%${cart.percentage}`,
          cart.affiliate_percentage === null ? ((cart.sale_price * cart.quantity * 30) / 100).toFixed(2) : ((cart.sale_price * cart.quantity * ((cart.affiliate_percentage - cart.percentage) / 100))).toFixed(2),
          new Date(cart.created_at).toLocaleString()
        ];
        invoiceData.push(rowData);
      });
      invoiceData.push(["Before Discount:", "", "", "", "", "", "", this.totalBeforeDiscount.toFixed(2)]);
      invoiceData.push(["After Discount:", "", "", "", "", "", "", this.totalAfterDiscount.toFixed(2)]);
      invoiceData.push(["Total:", "", "", "", "", "", "", this.total.toFixed(2)]);
      // Add empty rows
      invoiceData.push(["", "", "", "", "", "", "", "", ""]);
      invoiceData.push(["", "", "", "", "", "", "", "", ""]);
      XLSX.utils.sheet_add_aoa(ws, invoiceData, {origin: -1});

      // Add Return Data
      let returnData = [["Affiliate Name", "Invoice NB.", "Item", "Quantity", "Item Price", "Commission", "Total", "Created At"]];
      this.returnCarts.forEach(returnCart => {
        const rowData = [
          `${returnCart.first_name} ${returnCart.last_name}`,
          `INV00${returnCart.invNb}`,
          returnCart.sku,
          returnCart.quantity,
          returnCart.sale_price.toFixed(2),
          returnCart.affiliate_percentage === null ? '0%' : `%${returnCart.percentage}`,
          returnCart.affiliate_percentage === null ? ((returnCart.sale_price * returnCart.quantity * 30) / 100).toFixed(2) : ((returnCart.sale_price * returnCart.quantity * ((returnCart.affiliate_percentage - returnCart.percentage) / 100))).toFixed(2),
          new Date(returnCart.created_at).toLocaleString()
        ];
        returnData.push(rowData);
      });
      returnData.push(["Before Discount:", "", "", "", "", "", "", this.returnTotalBeforeDiscount.toFixed(2)]);
      returnData.push(["After Discount:", "", "", "", "", "", "", this.returnTotalAfterDiscount.toFixed(2)]);
      returnData.push(["Total:", "", "", "", "", "", "", this.returnTotal.toFixed(2)]);
      // Add empty rows
      returnData.push(["", "", "", "", "", "", "", "", ""]);
      returnData.push(["", "", "", "", "", "", "", "", ""]);
      XLSX.utils.sheet_add_aoa(ws, returnData, {origin: -1});

      // Add Overall Totals
      const overallTotals = [
        ["Overall Totals"],
        ["Before Discount:", "", "", "", "", "", "", this.lastBefore.toFixed(2)],
        ["After Discount:", "", "", "", "", "", "", this.lastAfter.toFixed(2)],
        ["Total:", "", "", "", "", "", "", this.lastTotal.toFixed(2)]
      ];
      XLSX.utils.sheet_add_aoa(ws, overallTotals, {origin: -1});

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Combined Data");

      // Save the workbook as a file
      const filename = `${this.user.first_name}_${this.user.last_name}.xlsx`;
      XLSX.writeFile(wb, filename);
    },

    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.table_to_sheet(this.$el.querySelector(".table"));

      // Add rows for Before Discount and After Discount
      const beforeDiscountRow = [
        "Before Discount:",
        "",
        "",
        "",
        "",
        "",
        "",
        this.lastBefore.toFixed(2),
      ];
      const afterDiscountRow = [
        "After Discount:",
        "",
        "",
        "",
        "",
        "",
        "",
        this.lastAfter.toFixed(2),
      ];

      // Append the rows to the sheet
      XLSX.utils.sheet_add_aoa(ws, [beforeDiscountRow], {origin: -1});
      XLSX.utils.sheet_add_aoa(ws, [afterDiscountRow], {origin: -1});

      // Append the total row again (if needed)
      XLSX.utils.sheet_add_aoa(
          ws,
          [["Total:", "", "", "", "", "", "", this.lastTotal.toFixed(2)]],
          {origin: -1}
      );

      XLSX.utils.book_append_sheet(wb, ws, "Cart Data");

      const filename = `${this.user.first_name}_${this.user.last_name}.xlsx`;
      /* generate XLSX file and download */
      XLSX.writeFile(wb, filename);
    },

    calculateTotal() {
      this.total = this.carts.reduce((total, cart) => {
        if (cart.affiliate_percentage === null && cart.cart_discount === 0) {
          total += (cart.sale_price * cart.quantity * 30) / 100;
        } else {
          total +=
              cart.sale_price *
              cart.quantity *
              ((cart.affiliate_percentage - cart.percentage) / 100);
        }
        return total;
      }, 0);
    },

    returnCalculateTotal() {
      this.returnTotal = this.returnCarts.reduce((returnTotal, refund) => {
        if (refund.affiliate_percentage === null && refund.cart_discount === 0) {
          returnTotal += (refund.sale_price * refund.quantity * 30) / 100;
        } else {
          returnTotal +=
              refund.sale_price *
              refund.quantity *
              ((refund.affiliate_percentage - refund.percentage) / 100);
        }
        return returnTotal;
      }, 0);
    },

    beforeDiscount() {
      this.totalBeforeDiscount = this.carts.reduce(
          (totalBeforeDiscount, cart) => {
            if (cart.cart_discount === 0) {
              totalBeforeDiscount += cart.sale_price * cart.quantity;
            } else {
              totalBeforeDiscount += cart.sale_price * cart.quantity;
            }
            return totalBeforeDiscount;
          },
          0
      );
    },

    returnBeforeDiscount() {
      this.returnTotalBeforeDiscount = this.returnCarts.reduce(
          (returnTotalBeforeDiscount, refund) => {
            if (refund.cart_discount === 0) {
              returnTotalBeforeDiscount += refund.sale_price * refund.quantity;
            } else {
              returnTotalBeforeDiscount += refund.sale_price * refund.quantity;
            }
            return returnTotalBeforeDiscount;
          },
          0
      );
    },

    afterDiscount() {
      this.totalAfterDiscount = this.carts.reduce(
          (totalAfterDiscount, cart) => {
            if (cart.percentage > 0) {
              totalAfterDiscount +=
                  cart.sale_price * cart.quantity -
                  (cart.sale_price * cart.quantity * cart.percentage) / 100;
            } else if (cart.cart_discount > 0) {
              totalAfterDiscount +=
                  cart.sale_price * cart.quantity -
                  (cart.sale_price * cart.quantity * cart.cart_discount) / 100;
            } else {
              totalAfterDiscount += cart.sale_price * cart.quantity;
            }
            return totalAfterDiscount;
          },
          0
      );
    },

    returnAfterDiscount() {
      this.returnTotalAfterDiscount = this.returnCarts.reduce(
          (returnTotalAfterDiscount, refund) => {
            if (refund.percentage > 0) {
              returnTotalAfterDiscount +=
                  refund.sale_price * refund.quantity -
                  (refund.sale_price * refund.quantity * refund.percentage) / 100;
            } else if (refund.cart_discount > 0) {
              returnTotalAfterDiscount +=
                  refund.sale_price * refund.quantity -
                  (refund.sale_price * refund.quantity * refund.cart_discount) / 100;
            } else {
              returnTotalAfterDiscount += refund.sale_price * refund.quantity;
            }
            return returnTotalAfterDiscount;
          },
          0
      );
    },

    lastBeforeDiscount() {
      this.lastBefore = this.totalBeforeDiscount - this.returnTotalBeforeDiscount;
    },

    lastAfterDiscount() {
      this.lastAfter = this.totalAfterDiscount - this.returnTotalAfterDiscount;
    },

    lastTotals() {
      this.lastTotal = this.total - this.returnTotal;
    },

  },
};
</script>
