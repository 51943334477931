<template>
    <div>
      <p>Loading...</p>
    </div>
  </template>
  
  <script>
  import axiosInstance from '../../../../axiosInstance';

  export default {
    mounted() {
      // Get the access token or authorization code from the URL query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
  
      console.log(code)
      console.log(state)
      // Make an API call to your Laravel backend to handle the callback and get user data
      axiosInstance
        .get(`api/login/facebook/callback?code=${code}&state=${state}`)
        .then((response) => {
          // Handle the response from the backend (e.g., store user data in Vuex or navigate to another page)
          // For example, if your backend returns an access token, you can store it in Vuex or localStorage for further API requests
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
          // Handle errors if necessary
        });
    },
  };
  </script>
  