<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Child Category Name"
                            v-model="childcategory.child_category_name" /><br /><br />

                        <select v-model="childcategory.sub_category_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Sub Category --</option>
                            <option v-for="subcategory in subcategories" :key="subcategory.id" :value="subcategory.id">
                                {{ subcategory.sub_category_name }} - ( {{ subcategory.category.category_name }} )
                            </option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="childcategory.progress" :value="childcategory.progress.percentage" max="100">
                            {{ childcategory.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateChildCategory",

    data() {
        return {
            childcategory: {
                sub_category_id: 0,
                child_category_name: "",
                child_category_image: "",
            },
            subcategories: [], // Update variable name to employees
        };
    },

    methods: {
        onFileChange(event) {
            this.childcategory.child_category_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('child_category_name', this.childcategory.child_category_name);
            formData.append('sub_category_id', this.childcategory.sub_category_id);
            formData.append('child_category_image', this.childcategory.child_category_image);
            axiosInstance.post(`api/childcategory-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/childcategory");
            });
        },
        fetchSubCategories() {
            axiosInstance
                .get(`api/childcategory-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.subcategories = response.data;
                });
        },
    },
    mounted() {
        this.fetchSubCategories();
    },
};
</script>
