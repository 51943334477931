<template>
  <div class="main-panel">
      <div class="content-wrapper">
          <div class="card">
              <div class="card-body">
                  <p>Current Date and Time: {{ currentDate }}</p>
                  <div v-for="(brandData, brandName) in brandResults" :key="brandName">
                      <h2>Brand: {{ brandName }}</h2>

                      <table class="table table-responsive border">
                          <tr>
                              <th>SKU</th>
                              <th>Image</th>
                              <th>Title</th>
                              <th>Quantity</th>
                              <th>Location</th>
                          </tr>
                          <tr v-for="(cart, index) in brandData" :key="index" class="d-flex">
                              <td>{{ cart.sku }}</td>
                              <td><img :src="'https://api.gocami.com/product_detail/' + cart.image " width="75"/></td>
                              <td>{{ cart.title }}</td>
                              <td>{{ cart.total_quantity }}</td>
                              <td>{{ cart.zone_name }} - {{ cart.floor_name }} - {{ cart.stand_name }} - {{ cart.shelve_name }}</td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
name: "OldSuperAdminOrder",

data() {
  return {
    brandResults: [],
    currentDate: new Date().toLocaleString()
  };
},

created() {
  this.getCarts();
  setInterval(this.updateCurrentDateTime, 1000); // Update every second (1000 milliseconds)
},

methods: {
  getCarts() {
    var page = `api/cart-details`;
    axiosInstance.get(page).then(({ data }) => {
      console.log(data);
      this.brandResults = data;
    });
  },

  updateCurrentDateTime() {
    const now = new Date();
    this.currentDate = now.toLocaleString();
  },
},
};

</script>

<style scoped>

</style>
