<template>
    <section class="products">
        <header class="hidden">
            <h3 class="h3 title">Product category grid</h3>
        </header>

        <div class="row row-clean">

            <!-- === product-filters === -->

            <div class="col-md-3 col-xs-12">
                <div class="filters">
                    <div v-for="filter in filters" :key="filter.id">
                        <!-- Price Start -->
                        <h5 class="section-title position-relative text-uppercase mb-3">
                            <span class="bg-secondary pr-3">
                                {{ filter.filter_name.split("-")[1] }}
                            </span>
                        </h5>

                        <div class="p-4 mb-30">
                            <div class="custom-checkbox mb-2" v-for="productfilters in filteredProductfilter(filter.id)"
                                :key="productfilters.id">
                                <input type="checkbox" class="custom-control-input"
                                    :id="'product_filter_' + productfilters.id" :value="productfilters.id"
                                    v-model="selectedProductFilters" />
                                <label class="custom-control-label" :for="'product_filter_' + productfilters.id">
                                    {{ productfilters.product_filter }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div> <!--/filters-->
            </div>
            <!--product items-->

            <div class="col-md-9 col-xs-12">

                <!-- <div class="sort-bar clearfix">
                    <div class="sort-results pull-left">
                        <select>
                            <option value="1">10</option>
                            <option value="2">50</option>
                            <option value="3">100</option>
                            <option value="4">All</option>
                        </select>
                        <span>Showing all <strong>50</strong> of <strong>3,250</strong> items</span>
                    </div>
                    <div class="sort-options pull-right">
                        <span class="hidden-xs">Sort by</span>
                        <select>
                            <option value="1">Name</option>
                            <option value="2">Popular items</option>
                            <option value="3">Price: lowest</option>
                            <option value="4">Price: highest</option>
                        </select>
                        <span class="grid-list">
                            <a href="products-grid.html"><i class="fa fa-th-large"></i></a>
                            <a href="products-list.html"><i class="fa fa-align-justify"></i></a>
                            <a href="javascript:void(0);" class="toggle-filters-mobile"><i class="fa fa-search"></i></a>
                        </span>
                    </div>
                </div> -->

                <div class="row row-clean" style="margin-top:20px;">
                    <div class="sale-item">
                        <img v-lazy="'https://api.gocami.com/sale/' + saleimgs.sale_image" alt="Sale Image"
                            style="width:100%">
                    </div>


                    <div class="col-xs-6 col-sm-4 col-lg-2" v-for="sale in sales" :key="sale.id" style="margin-top:25px;">
                        <div class="product-item">
                            <article>
                                <!-- <div class="info">
                                    <span class="add-favorite">
                                        <a href="javascript:void(0);" data-title="Add to favorites"
                                            data-title-added="Added to favorites list"><i class="icon icon-heart"></i></a>
                                    </span>

                                </div> -->
                                <div class="figure-grid">
                                    <div class="image">
                                        <router-link :to="{ name: 'ProductDetail', params: { id: sale.product_id } }"
                                            class="mfp-open">
                                            <img v-lazy="'https://api.gocami.com/product_detail/' + sale.image" alt=""
                                                width="360" class="product-image" />
                                        </router-link>
                                    </div>
                                    <div class="text">
                                        <h2 class="title h5">
                                            <router-link :to="{ name: 'ProductDetail', params: { id: sale.product_id } }">{{
                                                sale.title }}</router-link>
                                        </h2>
                                        <sup>$ {{ sale.sale_price.toFixed(2) }}</sup>
                                        <p style="color:red">Quantites left: {{ sale.quantity }}</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                <!--/row-->
                <!--Pagination-->
                <!-- <div class="pagination-wrapper">
                    <ul class="pagination">
                        <li>
                            <a href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        <li class="active"><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li>
                            <a href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </div> -->

            </div> <!--/product items-->

        </div><!--/row-->

        <!-- ========================  Product info popup - quick view ======================== -->

    </section>
    <FooterLayout />
</template>
<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';


export default {
    components: { FooterLayout },
    data() {
        return {
            sales: [],
            saleimgs: [],
            selectedProductFilters: [],
            products: [],
            filters: [],
            productfilters: [],
            filterbyproducts: [],
            countdownIntervals: {},
        };
    },
    created() {
        this.fetchSalesData();
    },

    mounted() {
        this.fetchSalesData();
    },
    methods: {
        fetchSalesData() {
            var page = `api/saleproduct/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.saleimgs = data.saleimg;
                this.sales = data.sale;
                this.startCountdownTimers();
            });
        },

        filteredProductfilter(filterId) {
            return this.productfilters.filter(
                (productfilters) => productfilters.filter_id === filterId
            );
        },

        startCountdownTimers() {
            this.sales.forEach(sale => {
                const countdownTime = new Date(sale.countdown_time).getTime();
                const now = new Date().getTime();
                const timeRemaining = countdownTime - now;

                if (timeRemaining > 0) {
                    const countdownInterval = setInterval(() => {
                        const currentTime = new Date().getTime();
                        const timeRemaining = countdownTime - currentTime;

                        if (timeRemaining <= 0) {
                            clearInterval(countdownInterval);
                        }
                        sale.countdown = this.getCountdownTime(timeRemaining);
                    }, 1000);

                    this.countdownIntervals[sale.id] = countdownInterval;
                }
            });
        },

        getCountdownTime(timeRemaining) {
            const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

            return `${days} Days, ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },

    },

    computed: {
        filteredProducts() {
            if (this.selectedProductFilters.length === 0) {
                return this.products;
            } else {
                const filteredProductIds = this.filterbyproducts
                    .filter((filterbyproducts) =>
                        this.selectedProductFilters.includes(
                            filterbyproducts.product_filter_id
                        )
                    )
                    .map((filterbyproducts) => filterbyproducts.product_detail_id);
                return this.products.filter((products) =>
                    filteredProductIds.includes(products.id)
                );
            }
        },
    },


};
</script>
  
<style scoped>
.image-container {
    position: relative;
    display: flex;
    align-items: flex-start;
}

.sale-image {
    height: 30vh;
    margin-right: 10px;
}

.time-container {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
}

.product-item {
    height: 400px;
    /* Adjust the height as per your requirement */
    overflow: hidden;
}

.product-image {
    height: 200px;
    /* Adjust the height as per your requirement */
    object-fit: cover;
    /* or object-fit: contain; */
}

.product-image-category {
    height: 350px;
    /* Adjust the height as per your requirement */
    object-fit: cover;
    /* or object-fit: contain; */
}

.sale-item {
    position: relative;
    display: flex;
    align-items: center;
}

.countdown-container {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    text-align: center;
}

.countdown {
    color: #fff;
    font-size: 16px;
}

.countdown-label {
    font-weight: bold;
    margin-bottom: 5px;
}

.countdown-timer {
    font-size: 24px;
}
</style>