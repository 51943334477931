<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="responsive table-responsive table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>Status</th>
                                <th>Action</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in allOrder.data" :key="order.id" class="flex">
                                <th scope="row">{{ order.id }}</th>
                                <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                                <td v-if="order.is_complete == 0" class="text-danger">Not Complete</td>
                                <td v-if="order.is_complete == 1" class="text-success">Complete</td>

                                <td v-if="order.is_complete == 0">
                                    <router-link :to="{name: 'CartInvoice', params: {id: order.id}}" class="btn btn-primary">Show</router-link>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <div class="pagination">
                        <button @click="orderLoad(allOrder.current_page - 1)" :disabled="allOrder.current_page === 1"
                            class="btn btn-primary">Previous</button>
                        <button @click="orderLoad(allOrder.current_page + 1)"
                            :disabled="allOrder.current_page === allOrder.last_page" class="btn btn-primary">Next</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
        
<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "CartInvoice",

    data() {
        return {
            allOrder: {
                data: [],  // Array to store the orders
                current_page: 1,  // Current page number
                last_page: 1,  // Total number of pages
            },
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad(page = 1) {
            var endpoint = `api/stock-invoices?page=${page}`;
            axiosInstance.get(endpoint).then(({ data }) => {
                console.log(data);
                this.allOrder.data = data.data;  // Update the orders array
                this.allOrder.current_page = data.current_page;  // Update the current page
                this.allOrder.last_page = data.last_page;  // Update the last page
            });
        },
    },
};

</script>
        
<style scoped></style>