<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Supplier Panel</h3>

                    <router-link to="/create-supplier" class="btn btn-primary">Create Supplier</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Supplier Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="suppliers in supplier" v-bind:key="suppliers.id">
                                <th scope="row">{{ suppliers.id }}</th>
                                <td>{{ suppliers.supplier_name }}</td>
                                <td>
                                    <router-link :to="{ name: 'EditSupplier', params: { id: suppliers.id } }"
                                        class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                                    <button @click="remove(suppliers)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
    name: "SupplierView",

    data() {
        return {
            supplier: [],
        };
    },

    created() {
        this.supplierLoad();
    },

    methods: {
        supplierLoad() {
            var page = `api/supplier`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.supplier = data;
            });
        },

        remove(suppliers) {
            var url = `api/supplier-delete/${suppliers.id}`;
            axiosInstance.delete(url).then(() => {
                const index = this.supplier.indexOf(suppliers);
                this.supplier.splice(index, 1);
            });
        },
    },
};

</script>
      
<style scoped></style>