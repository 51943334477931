<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="attribute.id" />
                        <input type="text" class="form-control" v-model="attribute.attribute" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditAttribute",

    data() {
        return {
            attribute: {
                attribute: "",
                id: this.$route.params.id,
            },
            oldAttribute: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axiosInstance
                .put(
                    `api/attribute-update/${this.attribute.id}`, // Use the BASE_URL constant for the base URL
                    this.attribute
                )
                .then(() => {
                    this.attribute = {
                        attribute: "",
                        id: "",
                    };
                    router.push("/attribute");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axiosInstance
                .get(`api/attribute-edit/` + this.attribute.id)
                .then((response) => {
                    this.oldAttribute = response.data; // store the old employee data in oldEmployee property
                    this.attribute.attribute = this.oldAttribute.attribute; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>