<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Discount</h3>

          <router-link to="/create-product-discount" class="btn btn-primary">Create Product Discount</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Discount Name</th>
                <th>Commission</th>
                <th>Product SKU</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="productdiscounts in productdiscount" v-bind:key="productdiscounts.id">
                <th scope="row">{{ productdiscounts.id }}</th>
                <td>{{ productdiscounts.discount.discount_name }}</td>
                <td>{{ productdiscounts.discount.commission }}</td>
                <td>{{ productdiscounts.productdetail.sku }}</td>
                <td>

                  <button @click="remove(productdiscounts)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "ProductDiscountView",

  data() {
    return {
      productdiscount: [],
    };
  },

  created() {
    this.productdiscountLoad();
  },

  methods: {
    productdiscountLoad() {
      var page = `api/product-discount`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.productdiscount = data;
      });
    },

    remove(productdiscounts) {
      var url = `api/product-discount-delete/${productdiscounts.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.productdiscount.indexOf(productdiscounts);
        this.productdiscount.splice(index, 1);
      });
    },
  },
};

</script>
      
<style scoped></style>