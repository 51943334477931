<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <input type="text" class="form-control" placeholder="Create Discount Name" v-model="simplediscount.user_discount_name" /><br /><br />
                        <input type="text" class="form-control" placeholder="Create Commission" v-model="simplediscount.user_commission" /><br /><br />
                        <input type="text" class="form-control" placeholder="Create Coupon" v-model="simplediscount.user_coupon_code" /><br /><br />
                        <select class="form-control" v-model="simplediscount.is_delivery">
                            <option value="0">Charge Delivery</option>
                            <option value="1">Free Delivery</option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateSimpleDiscount",

    data() {
        return {
            simplediscount: {
                user_discount_name: "",
                user_commission: "",
                user_coupon_code: "",
                is_delivery: '',
            },
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            axiosInstance
                .post(`api/simple-discount-store`, this.simplediscount)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/simple-discount");
                });
        },
    },
};
</script>