<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="userbranddiscount.id" />

                        <select class="form-control mb-3" v-model="userbranddiscount.brand_id">
                            <option v-for="brand in brand" :key="brand.id" :value="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <select class="form-control mb-3" v-model="userbranddiscount.user_discount_id">
                            <option v-for="simplediscount in simplediscount" :key="simplediscount.id" :value="simplediscount.id">
                                {{ simplediscount.user_discount_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditUserBrandDiscount",

    data() {
        return {
            userbranddiscount: {
                user_discount_id: "",
                brand_id: "",
                id: this.$route.params.id,
            },
            brand: [],
            simplediscount: [],
            oldBrandDiscount: {},
        };
    },

    methods: {
        editData() {
            let formData = new FormData();
            if (this.userbranddiscount.brand_id) {
                formData.append("brand_id", this.userbranddiscount.brand_id);
            }
            if (this.userbranddiscount.user_discount_id) {
                formData.append("user_discount_id", this.userbranddiscount.user_discount_id);
            }
            axiosInstance
                .post(`api/user-brand-discount-update/${this.userbranddiscount.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.userbranddiscount = {
                        discount_id: "",
                        user_brand_id: "",
                        id: "",
                    };
                    router.push("/user-brand-discount");
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        fetchData() {
            axiosInstance
                .get(`api/user-brand-discount-edit/` + this.userbranddiscount.id)
                .then((response) => {
                    this.userbranddiscount = response.data.userbranddiscount;
                    this.brand = response.data.brand;
                    this.simplediscount = response.data.simplediscount;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        this.fetchData();
    },
};
</script>