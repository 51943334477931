<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Product Name</th>
              <th>Sku</th>
              <th>Sale Price</th>
              <th>Main Price</th>
              <th>Quantity</th>
              <th>Color Name</th>
              <th>Size Name</th>
              <th>Attribute Type</th>
              <th>Supplier Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.name }}</td>
              <td><input type="text" v-model="product.sku" placeholder="SKU"></td>

              <td><input type="text" v-model="product.sale_price" placeholder="SALE PRICE"></td>
              <td><input type="text" v-model="product.main_price" placeholder="Main Price"></td>
              <td>{{ product.quantity }}</td>
              <td>{{ product.color_name }}</td>
              <td>{{ product.size_name }}</td>
              <td>{{ product.attribute_type_name }}</td>
              <td>{{ product.supplier_name }}</td>
              <td>
                <button @click="updateDescription(product)" class="btn btn-primary">
                  Update
                </button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  data() {
    return {
      productDetail: [],
      product: {
        sku: '',
        sale_price: ''
      }
    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    fetchAllData() {
      return axiosInstance
          .get(`api/get-disabled-product`)
          .then(response => {
            this.productDetail = response.data.productDetail;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/update-disabled-data/${product.id}`, {
            sku: product.sku, sale_price: product.sale_price, main_price: product.main_price
          })
          .then(response => {
            console.log('Details updated:', response.data);
            this.fetchAllData();
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },
  },
}
</script>

<style scoped>
* {
  font-size: 12pt !important;
}
</style>
