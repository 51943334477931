<template>
    <div v-if="showToast" class="toast" :class="{ 'show': showToast, 'error': toastType === 'error' }">
        {{ toastMessage }}
    </div>

    <div class="forgot">
        <form class="forgot-password-form" @submit.prevent="sendResetLink">
            <label for="email">Email:</label>
            <div class="input-wrapper">
                <input type="email" id="email" v-model="email" required>
                <span class="highlight"></span>
                <span class="bar"></span>
            </div>
            <button type="submit" class="text-white">Send Reset Link</button>
        </form>

    </div>
</template>


<script>
import axiosInstance from "../../../../axiosInstance";

export default {
    data() {
        return {
            email: '',
            showToast: false,
            toastMessage: "",
            toastType: "success", // Default to success
        };
    },
    methods: {
        async sendResetLink() {
            try {
                // Send the reset link
                await axiosInstance.post('api/password/email', { email: this.email });

                // Reset the email input
                this.email = "";

                // Show success toast message
                this.showToastMessage("Reset link sent to your email!");

            } catch (error) {
                // Handle errors
                if (error.response) {
                    // The request was made and the server responded with a status code
                    if (error.response.status === 422) {
                        // Validation error, handle it accordingly
                        this.errorMessage = "Invalid email address.";
                    } else {
                        this.errorMessage = "An error occurred while processing your request. Please try again later.";
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    this.errorMessage = "No response received from the server. Please try again later.";
                } else {
                    // Something happened in setting up the request that triggered an Error
                    this.errorMessage = "An unexpected error occurred. Please try again later.";
                }
                // Show error toast message
                this.showToastMessage(this.errorMessage, 'error');
            }
        },


        showToastMessage(message, type = 'success') {
            this.toastMessage = message;
            this.toastType = type;
            this.showToast = true;

            // Hide the toast after a certain duration (e.g., 3000 milliseconds)
            setTimeout(() => {
                this.hideToast();
            }, 3000);
        },

        hideToast() {
            this.showToast = false;
            this.toastMessage = "";
        },
    },

};
</script>
  
<style scoped>
.error {
    background-color: #dc3545 !important;
    /* Red background color for error */
}

.toast {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #28a745;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 99999 !important;
}

.toast.show {
    opacity: 1;
}

.forgot-password-form {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

label {
    display: block;
    margin-bottom: 5px;
}

.input-wrapper {
    position: relative;
    margin-bottom: 20px;
}

input {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 2px solid #3498db;
    background-color: transparent;
    outline: none;
}

/* Highlight and bar effects */
.input-wrapper .highlight {
    position: absolute;
    height: 60%;
    width: 100%;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

.input-wrapper .bar {
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: #3498db;
    transition: width 0.3s ease;
}

input:focus~.bar {
    width: 100%;
}

button {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.forgot {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}
</style>