<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">

                        <select class="form-control" v-model="brands.brand_id">
                            <option value="0" selected disabled hidden>-- Choose Brand --</option>
                            <option v-for="brandse in brand" :key="brandse.id" :value="brandse.id">
                                {{ brandse.brand_name }}
                            </option>
                        </select>

                        <select class="form-control" v-model="brands.type">
                            <option value="0" selected disabled hidden>-- Choose Type --</option>
                            <option value="image">Image</option>
                            <option value="video">Video</option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-primary">Create Brand</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateBrandImages",

    data() {
        return {
            brands: {
                brand_id: "",
                multi_image: "",
                type: "",
            },
            brand:[]
        };
    },

    methods: {
        onFileChange(event) {
            this.brands.multi_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('multi_image', this.brands.multi_image);
            formData.append('brand_id', this.brands.brand_id);
            formData.append('type', this.brands.type);
            axiosInstance.post(`api/brand-images-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/brand-images");
            });
        },

        fetchBrand() {
            axiosInstance
                .get(`api/brand-images-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.brand = response.data;
                });
        },
        
    },

    mounted() {
        this.fetchBrand();
    },
};
</script>