<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Hidden Products</h3>

                    <div class="row">
                        <div class="col-6">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Title</th>
                                        <th>Product Sku</th>
                                        <th>Main Product</th>
                                        <th>Brand Name</th>
                                        <th>Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in allProductDetails.data" :key="product.id">
                                        <th scope="row">{{ product.id }}</th>
                                        <td>{{ product.title }}</td>
                                        <td>{{ product.sku }}</td>
                                        <td class="text-success">{{ product.product.product_name }}</td>
                                        <td class="text-danger">{{ product.brand.brand_name }}</td>
                                        <td v-if="product.image"><img
                                                :src="'https://api.gocami.com/product_detail/' + product.image"
                                                width="60" /></td>
                                        <td v-else class="text-warning">No Image</td>

                                    </tr>
                                </tbody>
                            </table>

                            <div class="col-12 d-flex">
                                <div class="pagination-container">
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item" :class="{ disabled: !allProductDetails.prev_page_url }">
                                                <button class="page-link btn-custom"
                                                    @click="changePageUser(allProductDetails.current_page - 1)"
                                                    :disabled="!allProductDetails.prev_page_url" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            <li v-for="page in displayedPagesUser" :key="page"
                                                :class="{ active: page === allProductDetails.current_page }">
                                                <button class="page-link btn-custom" @click="changePageUser(page)">{{ page
                                                }}</button>
                                            </li>
                                            <li class="page-item" :class="{ disabled: !allProductDetails.next_page_url }">
                                                <button class="page-link btn-custom"
                                                    @click="changePageUser(allProductDetails.current_page + 1)"
                                                    :disabled="!allProductDetails.next_page_url" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                        </div>

                        <div class="col-6">
                            <table class="table table-responsive">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        <th>Image</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="product in allProductDetail.data" :key="product.id">
                                        <th scope="row">{{ product.id }}</th>
                                        <td>{{ product.product_name }}</td>
                                        <td v-if="product.image"><img
                                                :src="'https://api.gocami.com/product_main/' + product.image" width="60" />
                                        </td>
                                        <td v-else class="text-warning">No Image</td>

                                    </tr>
                                </tbody>
                            </table>

                            <div class="col-12 d-flex">
                                <div class="pagination-container">
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination justify-content-center">
                                            <li class="page-item" :class="{ disabled: !allProductDetail.prev_page_url }">
                                                <button class="page-link btn-custom"
                                                    @click="changePageProduct(allProductDetail.current_page - 1)"
                                                    :disabled="!allProductDetail.prev_page_url" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </button>
                                            </li>
                                            <li v-for="page in displayedPagesProduct" :key="page"
                                                :class="{ active: page === allProductDetail.current_page }">
                                                <button class="page-link btn-custom" @click="changePageProduct(page)">{{
                                                    page }}</button>
                                            </li>
                                            <li class="page-item" :class="{ disabled: !allProductDetail.next_page_url }">
                                                <button class="page-link btn-custom"
                                                    @click="changePageProduct(allProductDetail.current_page + 1)"
                                                    :disabled="!allProductDetail.next_page_url" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            allProductDetail: {},
            currentPageUser: 1,  // Separate currentPage for userLoad
            currentPageProduct: 1,
        };
    },


    created() {
        this.userLoad();
        this.productLoad();

    },

    computed: {
        displayedPagesUser() {
            const total = this.allProductDetails.last_page;
            const current = this.allProductDetails.current_page;
            const pages = [];

            for (let i = Math.max(current - 2, 1); i <= Math.min(current + 2, total); i++) {
                pages.push(i);
            }

            return pages;
        },

        displayedPagesProduct() {
            const total = this.allProductDetail.last_page;
            const current = this.allProductDetail.current_page;
            const pages = [];

            for (let i = Math.max(current - 2, 1); i <= Math.min(current + 2, total); i++) {
                pages.push(i);
            }

            return pages;
        },
    },
    methods: {

        userLoad() {
            var nextPage = `api/product-without-product?page=${this.currentPageUser}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data;
            });
        },

        productLoad() {
            var nextPage = `api/product-main-without-image?page=${this.currentPageProduct}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetail = data;
            });
        },

        changePageUser(page) {
            this.currentPageUser = page;
            this.userLoad();
        },

        changePageProduct(page) {
            this.currentPageProduct = page;
            this.productLoad();
        },

    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}

.pagination-container {
    margin-top: 20px;
}

.pagination {
    margin: 0;
}

.btn-custom {
    cursor: pointer;
    background-color: #4caf50;
    /* Green */
    color: white;
    border: 1px solid #4caf50;
    /* Green */
}

.btn-custom:hover {
    background-color: #45a049;
    /* Darker green */
    border: 1px solid #45a049;
    /* Darker green */
}

.page-item.disabled .page-link {
    cursor: not-allowed;
    background-color: #ddd;
    color: #888;
    border: 1px solid #ddd;
}

.page-item.active .page-link {
    background-color: #2196f3;
    /* Blue */
    border: 1px solid #2196f3;
    /* Blue */
    color: white;
}
</style>
  