<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="attributetype.id" />

                        <input type="text" class="form-control" v-model="attributetype.attribute_type_name" /><br /><br />

                        <select class="form-control mb-3" v-model="attributetype.attribute_id">
                            <option v-for="attributes in attribute" :key="attributes.id" :value="attributes.id">
                                {{ attributes.attribute }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditAttributetype",

    data() {
        return {
            attributetype: {
                attribute_id: "",
                attribute_type_name: "",
                id: this.$route.params.id,
            },
            attribute: {}, // new data property to store the old employee data
            oldAttributeType: {},
        };
    },
    methods: {

        editData() {
            let formData = new FormData();
            if (this.attributetype.attribute_type_name) {
                formData.append('attribute_type_name', this.attributetype.attribute_type_name);
            }
            if (this.attributetype.attribute_id) {
                formData.append('attribute_id', this.attributetype.attribute_id);
            }
            axiosInstance.post(`api/attributetype-update/${this.attributetype.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.attributetype = {
                    attribute_type_name: "",
                    attribute_id: "",
                    id: "",
                };
                router.push("/attributetype");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/attributetype-edit/` + this.attributetype.id)
                .then((response) => {
                    this.attributetype = response.data.attributetype;
                    this.attribute = response.data.attribute;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>