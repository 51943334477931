<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="affiliateuserdiscount.affiliate_discount_id" @change="onChange($event)"
                            class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="affiliatediscount in affiliatediscounts" :key="affiliatediscount.id"
                                :value="affiliatediscount.id">
                                {{ affiliatediscount.discount.discount_name }}
                            </option>
                        </select>

                        <select v-model="affiliateuserdiscount.user_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose User --</option>
                            <option v-for="user in users" :key="user.id" :value="user.user_id">
                                {{ user.user.first_name }} {{ user.user.last_name }}
                            </option>
                        </select>

                        <select v-model="affiliateuserdiscount.status" class="form-control mb-3">
                            <option value="0" selected>Simple Coupon</option>
                            <option value="1">Fixed Coupon</option>
                        </select>

                        <input type="number" :max="maxDiscount" class="form-control mb-2"
                            v-model="affiliateuserdiscount.commission" placeholder="Commision">

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>

import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

import router from "@/router";

export default {
    name: "CreateUserDiscount",
    
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            affiliateuserdiscount: {
                affiliate_discount_id: 0,
                user_id: 0,
                commission: '',
                status: 0,
            },

            onChange(e) {
                this.affiliatediscounts.filter((disc) => {
                    //   maxCommission: discount.commission
                    if (disc.id == e.target.value)
                        this.maxDiscount = disc.discount.commission
                    //  console.log(disc.discount.commission);
                });

            },
            maxDiscount: 0,
            affiliatediscounts: [],
            users: [],
        };
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('user_id', this.affiliateuserdiscount.user_id);
            formData.append('affiliate_discount_id', this.affiliateuserdiscount.affiliate_discount_id);
            formData.append('commission', this.affiliateuserdiscount.commission);
            formData.append('status', this.affiliateuserdiscount.status);
            formData.append('affiliate_id', this.user?.id);
            axiosInstance.post(`api/user-discount-store`, formData)
                .then(() => {
                    router.push("/user-discount");
                });
        },

        fetchAffiliateUserDiscount() {
            axiosInstance
                .get(`api/user-discount-create?user_id=${this.user?.id}`)
                .then(response => {
                    this.users = response.data.user;
                    this.affiliatediscounts = response.data.affiliatediscount;

                });
        },
    },
    mounted() {
        this.fetchAffiliateUserDiscount();
    },
};
</script>
<style scoped>
* {
    font-size: 12pt !important;
}
</style>