<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>

                    <h3 class="mb-5">Product Detail</h3>

                    <button @click="updateCheckedItems" class="btn btn-primary mb-3 me-5">
                        Update Checked Items
                    </button>
<!-- 
                    <button @click="copyNewPriceToSalePrice" class="btn btn-primary mb-3">
                        Copy New Price to Sale Price
                    </button> -->

                    <table class="table w-100">
                        <thead class="fixed-header border-bottom">
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Sale Price</th>
                                <th>Image</th>
                                <th>New Price(NEW)</th>
                                <th>Select</th> <!-- Added -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td v-if="product.price">${{ product.price }}</td>
                                <td><img :src="'https://api.gocami.com/product_main/' + product.image" width="75" />
                                </td>
                                <td><input type="text" class="form-control" v-model="product.new_price" /></td>
                                <td><input type="checkbox" class="form-check-input" v-model="product.checked" /></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            selectedBrand: null,
            productDetail: [],
            product: {
                new_price: '',
                // price: '',
            },
            brands: [],
            products: [],
            childcategory: [],
            file: null
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.fetchAllData(this.selectedBrand);
            }
        },

        fetchAllData(brandId) {
            return axiosInstance
                .get(`api/get-main-prod-price/${brandId}`)
                .then(response => {
                    this.productDetail = response.data.product;
                    this.brands = response.data.brands;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-main-prod-price/${product.id}`, {
                    new_price: product.new_price,
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData(this.selectedBrand);
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },

        updateCheckedItems() {
            const checkedProducts = this.productDetail.filter(product => product.checked);
            if (checkedProducts.length === 0) {
                alert("Please select at least one item to update.");
                return;
            }

            checkedProducts.forEach(product => {
                // Update the checked products
                this.updateDescription(product);
            });
        },
    },
}
</script>


<style scoped>
.fixed-header {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
    background-color: #ddd !important;
    border-bottom: 1px solid black !important;
    /* Adjust as needed */
}
</style>