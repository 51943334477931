<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h4 class="mt-3">Attributes</h4>

                    <form @submit.prevent="save" enctype="multipart/form-data">

                        <div class="row m-3">
                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.color_id">
                                    <option v-for="colors in color" :key="colors.id" :value="colors.id">
                                        {{ colors.color_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.size_id">
                                    <option v-for="sizes in size" :key="sizes.id" :value="sizes.id">
                                        {{ sizes.size_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <select class="form-control" v-model="productdetail.attribute_type_id">
                                    <option v-for="attributetypes in attributetype" :key="attributetypes.id"
                                        :value="attributetypes.id">
                                        {{ attributetypes.attribute_type_name }}
                                    </option>
                                </select>
                            </div>

                            <div class="col-3">
                                <input type="text" class="form-control" v-model="productdetail.quantity"
                                    placeholder="Product Quantity">
                            </div>

                        </div>
                        <button type="submit" class="btn btn-primary">Create</button>

                    </form>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    data() {
        return {
            productdetail: {
                color_id: '',
                size_id: '',
                attribute_type_id: '',
                quantity: "",
            },
            productdetails: { id: this.$route.params.id, },
            color: [],
            size: [],
            attributetype: []
        }
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('product_detail_id', this.productdetails.id); // Change this line
            formData.append('color_id', this.productdetail.color_id);
            formData.append('size_id', this.productdetail.size_id);
            formData.append('attribute_type_id', this.productdetail.attribute_type_id);
            formData.append('quantity', this.productdetail.quantity);
            axiosInstance.post(`api/productconfiguration-store/${this.productdetails.id}`, formData, {
            }).then(() => {
                // Redirect to the desired page
                router.push("/productdetail");
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/productdetail-create`)
                .then((response) => {
                    this.product = response.data.product;
                    this.color = response.data.color;
                    this.size = response.data.size;
                    this.attributetype = response.data.attributetype;
                }).catch((error) => {
                    console.log(error);
                });
        },

        fetchProduct() {
            axiosInstance
                .get(`api/productdetail-edit/` + this.productdetails.id)
                .then((response) => {
                    this.productdetails = response.data.productdetail;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },

    mounted() {
        this.fetchData();
        this.fetchProduct();
    }
}

</script>