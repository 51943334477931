<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Supplier Name</th>
                                <th>Category</th>
                                <th>Brand</th>
                                <th>Image</th>
                                <th>Price</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td>{{ product.supplier_name }}</td>
                                <td>{{ product.category_name }}</td>
                                <td>{{ product.brand_name }}</td>
                                <td><img :src="'https://api.gocami.com/product_main/' + product.image" width="100"/></td>
                                <td>{{ product.price }}</td>
                                <td><p v-html="product.description"></p></td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            productDetail: [],
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        fetchAllData() {
            return axiosInstance
                .get(`api/data-insert-main-get`)
                .then(response => {
                    this.productDetail = response.data.mainProduct;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

    },
}
</script>
