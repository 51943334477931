<template>

    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <div v-if="showZoomedImage" id="zoomedImageContainer" style="width: 100px; height: 100px;">
                        <div class="zoomedImageContent" style="width: 100%; height: 100%;">
                            <span class="close" @click="closeZoomedImage">&times;</span>
                            <img :src="zoomedImageSrc" alt="Zoomed Image" style="max-width: 100%; max-height: 100%;">
                        </div>
                    </div>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productconfs" :key="product.id">
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td><img @click="zoomImage(product.image)"
                                        :src="'https://api.gocami.com/product_detail/' + product.image" width="100" />
                                </td>
                                <td v-if="product.sale_price">${{ product.sale_price.toFixed(2) }}</td>
                                <td>
                                    <p v-html="product.description"></p>
                                </td>
                                <td><button type="submit" class="btn btn-primary"
                                        @click="setProductAction(product)">Update</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
// import { useUserStore } from "../../../../../store";

export default {
    data() {
        return {
            productconfs: [],
            showZoomedImage: false,
            zoomedImageSrc: ''
        };
    },
    created() {
        this.loadProductConfigs();
    },
    methods: {

        zoomImage(imageSrc) {
            this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
            this.showZoomedImage = true;
        },

        closeZoomedImage() {
            this.showZoomedImage = false;
        },

        loadProductConfigs() {
            var page = `api/get-new-product-acc`;
            axiosInstance.get(page).then(({ data }) => {
                // console.log(data);
                this.productconfs = data.newProductDetail;
            });
        },

        setProductAction(product) {
            axiosInstance
                .post(`api/update-new-product-acc/${product.id}`)
                .then(response => {
                    console.log('Photo needed flag set:', response.data.message);
                    this.loadProductConfigs(); // Fetch updated data after setting the flag
                })
                .catch(error => {
                    console.error('Error setting photo needed flag:', error);
                });
        },

    },
};
</script>

<style scoped>
.link-to:hover {
    color: red;
}

.bg-danger {
    background-color: red;
}

.bg-warning {
    background-color: orange;
}

.bg-success {
    background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
    display: block;
    /* Add this line to make the container visible */
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
    overflow: auto;
}

.zoomedImageContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999999 !important;
}

.zoomedImageContent img {
    z-index: 99999 !important;
    max-width: 100%;
    max-height: 100%;
    border: 2px solid #fff;
    /* Optional: Add a border around the image */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* Optional: Add a box shadow */
}

.close {
    color: red !important;
    position: absolute;
    top: 10px;
    right: 110px;
    font-size: 30px !important;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.close:hover {
    transform: scale(1.2);
    /* Optional: Increase the size of the close icon on hover */
}
</style>