<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <div class="flex flex-wrap max-w-xl">
                            <div class="p-2 text-2xl text-gray-800 font-semibold">
                                <h3>Creat User</h3>
                            </div>
                            <div class="p-2">
                                <label class="" for="name">First Name</label>
                                <input class="form-control" placeholder="First Name" type="text" v-model="form.user_first_name"
                                    required>
                            </div>
                            <div class="p-2">
                                <label class="" for="middle_name">Middle Name</label>
                                <input class="form-control" placeholder="Middle Name" type="text" v-model="form.user_middle_name"
                                    required>
                            </div>
                            <div class="p-2">
                                <label class="" for="last_name">Last Name</label>
                                <input class="form-control" placeholder="Last Name" type="text" v-model="form.user_last_name"
                                    required>
                            </div>

                            <div class="p-2 w-full mt-4">
                                <button type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

import router from "@/router";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },
    data() {
        return {
            form: {
                first_name: '',
                middle_name: '',
                last_name: '',
            },
            errors: []
        }
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            if (!this.form.user_first_name || !this.form.user_middle_name || !this.form.user_last_name) {
                alert('Please fill out all required fields.');
                return;
            }

            let formData = new FormData();
            formData.append('user_first_name', this.form.user_first_name);
            formData.append('user_middle_name', this.form.user_middle_name);
            formData.append('user_last_name', this.form.user_last_name);
            formData.append('user_id', this.user?.id);
            axiosInstance.post(`api/create-user-sub-gift`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/sub-user-gift");
            });
        },
        //     saveForm() {
        //         axios.post(`${BASE_URL}affiliate-user-store`, this.form).then(() => {

        //             router.push("/affiliate-user");
        //         }).catch((error) => {
        //             this.errors = error.response.data.errors;
        //         })
        //     }
    }
}
</script>


<style scoped>* {
    font-size: 12pt !important;
}</style>