<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Minimum Quantity</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails.data" :key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>

                                <td>
                                    <div class="form-group">
                                        <input type="integer" v-model="product.min_quantity">
                                    </div>
                                </td>

                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="row d-flex m-auto">
                        <div class="col-12 d-flex">
                            <div class="pagination-container">
                                <nav aria-label="Page navigation">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" :class="{ disabled: !allProductDetails.prev_page_url }">
                                            <button class="page-link btn-custom"
                                                @click="changePage(allProductDetails.current_page - 1)"
                                                :disabled="!allProductDetails.prev_page_url" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </button>
                                        </li>
                                        <li v-for="page in displayedPages" :key="page"
                                            :class="{ active: page === allProductDetails.current_page }">
                                            <button class="page-link btn-custom" @click="changePage(page)">{{ page
                                            }}</button>
                                        </li>
                                        <li class="page-item" :class="{ disabled: !allProductDetails.next_page_url }">
                                            <button class="page-link btn-custom"
                                                @click="changePage(allProductDetails.current_page + 1)"
                                                :disabled="!allProductDetails.next_page_url" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            currentPage: 1,
        };
    },


    created() {
        this.userLoad();
    },

    computed: {
        displayedPages() {
            const total = this.allProductDetails.last_page;
            const current = this.allProductDetails.current_page;
            const pages = [];

            for (let i = Math.max(current - 2, 1); i <= Math.min(current + 2, total); i++) {
                pages.push(i);
            }

            return pages;
        },
    },
    methods: {

        userLoad() {
            var nextPage = `api/super-admin-product-quantity?page=${this.currentPage}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data;
            });
        },

        changePage(page) {
            this.currentPage = page;
            this.userLoad();
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/super-admin-update-min-quantity/${product.id}`, {
                    min_quantity: product.min_quantity
                })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}

.pagination-container {
    margin-top: 20px;
}

.pagination {
    margin: 0;
}

.btn-custom {
    cursor: pointer;
    background-color: #4caf50;
    /* Green */
    color: white;
    border: 1px solid #4caf50;
    /* Green */
}

.btn-custom:hover {
    background-color: #45a049;
    /* Darker green */
    border: 1px solid #45a049;
    /* Darker green */
}

.page-item.disabled .page-link {
    cursor: not-allowed;
    background-color: #ddd;
    color: #888;
    border: 1px solid #ddd;
}

.page-item.active .page-link {
    background-color: #2196f3;
    /* Blue */
    border: 1px solid #2196f3;
    /* Blue */
    color: white;
}
</style>
  