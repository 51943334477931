import axios from "axios";
import Cookies from 'js-cookie';
// Create an instance of Axios with default configurations
const axiosInstance = axios.create({
  // Set your API base URL
  baseURL: "https://api.gocami.com/",

  // Add headers, such as content-type if needed
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the authentication token
axiosInstance.interceptors.request.use((config) => {
  // Retrieve the token from wherever you store it (e.g., Vuex, localStorage)
  const token = Cookies.get("token");

  // Add the token to the request headers
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export default axiosInstance;
