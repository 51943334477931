<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <router-link to="/create-sale" class="btn btn-primary">Create Sale</router-link>

          <div v-for="saleItem in sale" :key="saleItem.id">
            {{ saleItem.child_category_id }}
            <img :src="'https://api.gocami.com/sale/' + saleItem.sale_image" />
            <div>{{ saleItem.countdown }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  data: () => ({
    sale: [],
    countdownIntervals: {}, // Track countdown intervals for each sale item
  }),

  created() {
    this.fetchSale();
  },

  methods: {
    fetchSale() {
      axiosInstance.get(`api/sale-admin`)
        .then(response => {
          this.sale = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },


    getCountdownTime(timeRemaining) {
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },

  },

};
</script>