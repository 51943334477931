<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Configurations Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <div v-if="errorMessage" class="error-message text-danger">
                                {{ errorMessage }}
                            </div>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Product Color</th>
                                <th>Product Size</th>
                                <th>Product Attribute Name</th>
                                <th>Product Quantity</th>
                                <th>Old Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productconf in productconfs" :key="productconf.id">
                                <th scope="row">{{ productconf.id }}</th>
                                <td>{{ productconf.productdetail.title }}</td>
                                <td>{{ productconf.productdetail.sku }}</td>
                                <td>{{ productconf.color ? productconf.color.color_name : '' }}</td>
                                <td>{{ productconf.size ? productconf.size.size_name : '' }}</td>
                                <td>{{ productconf.attributetype ? productconf.attributetype.attribute_type_name : '' }}
                                </td>
                                <td>
                                    <input type="number" v-model="productconf.quantity"
                                        @change="updateQuantity(productconf)">
                                </td>
                                <td>{{ productconf.old_quantity ? productconf.old_quantity : 0 }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            productconfs: [],
            errorMessage: null,

        };
    },
    created() {
        this.loadProductConfigs();
    },
    methods: {
        loadProductConfigs() {
            var page = `api/product-stock`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.productconfs = data.productconf;
            });
        },
        updateQuantity(productconf) {
            axiosInstance.put(`api/update-product-stock/${productconf.id}`, { quantity: productconf.quantity })
                .then(({ data }) => {
                    console.log(data);
                    // Handle success message or other logic
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.error);

                        // Map specific error messages based on the response status or content
                        if (error.response.status === 500) {
                            this.errorMessage = 'Failed to update quantity. Please try again.';
                        } else if (error.response.status === 429) {
                            this.errorMessage = 'Too many requests. Please try again later.';
                        } else {
                            this.errorMessage = 'An unexpected error occurred. Please try again.';
                        }

                        // Display the error message as an alert
                        alert(this.errorMessage);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                    console.log(error.config);
                });
        },
    },
};
</script>
