<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Configurations Panel</h3>

                    <router-link to="/create-color" class="btn btn-primary">Create Product Configuration</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Product Color</th>
                                <th>Product Size</th>
                                <th>Product Attribute Name</th>
                                <th>Product Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productconf in productconfs" :key="productconf.id">
                                <th scope="row">{{ productconf.id }}</th>
                                <td>{{ productconf.productdetail?.title }}</td>
                                <td>{{ productconf.productdetail?.sku }}</td>
                                <td>{{ productconf.color ? productconf.color.color_name : '' }}</td>
                                <td>{{ productconf.size ? productconf.size.size_name : '' }}</td>
                                <td>{{ productconf.attributetype ? productconf.attributetype.attribute_type_name : '' }}
                                </td>
                                <td>
                                    <input type="number" v-model="productconf.quantity"
                                        @change="updateQuantity(productconf)">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            productconfs: [],
        };
    },
    created() {
        this.loadProductConfigs();
    },
    methods: {
        loadProductConfigs() {
            var page = `api/product-conf`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.productconfs = data.productconf;
            });
        },
        updateQuantity(productconf) {
            axiosInstance.put(`api/update-product-conf/${productconf.id}`, { quantity: productconf.quantity })
                .then(({ data }) => {
                    console.log(data);
                    // Handle success message or other logic
                })
                .catch(error => {
                    console.log(error);
                    // Handle error response or other logic
                });
        },
    },
};
</script>
