<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Product Point</th>
                                <th>Product Point Price</th>
                                <th>Position</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" v-bind:key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.product_name }}</td>
                                <td><input type="number" v-model="product.points" class="form-control"></td>
                                <td><input type="number" v-model="product.point_price" class="form-control"></td>
                                <td><input type="number" v-model="product.position" class="form-control"></td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        userLoad() {
            var page = `api/products-point`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.allProductDetails = data;
            });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-product-point/${product.id}`, { points: product.points, point_price: product.point_price, position: product.position })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}
</style>
  