<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Orders Panel</h3>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User Name</th>
                  <th>Address</th>
                  <th>Location</th>
                  <th>Note</th>
                  <th>Date</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="allOrders in allOrder" :key="allOrders.id">
                  <th scope="row">{{ allOrders.id }}</th>
                  <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                  <td>{{ allOrders.address }}</td>
                  <td>{{ allOrders.area.area_name }}</td>
                  <td>{{ allOrders.note }}</td>
                  <td>{{ new Date(allOrders.created_at).toLocaleString() }}</td>

                  <td style="display: flex;">
                  <router-link :to="{ name: 'SuperAdminExport', params: { id: allOrders.id } }" class="btn btn-warning me-2"><i
                      class="fa fa-eye"></i></router-link> 
                    <!-- <router-link :to="{ name: 'ViewOrder', params: { id: allOrders.id } }" class="btn btn-warning me-2"><i
                          class="fa fa-eye"></i></router-link>
                      <router-link :to="{ name: 'ExportInvoice', params: { id: allOrders.id } }" class="btn btn-primary">invoice</router-link> -->
                    <!-- <button @click="remove(allOrders)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
          
  <script>
  import axiosInstance from '../../../../axiosInstance';
  
  export default {
    name: "SuperAdminOrder",
  
    data() {
      return {
        allOrder: [],
      };
    },
  
    created() {
      this.orderLoad();
    },
  
    methods: {
      orderLoad() {
        var page = `api/sub-admin-delivered-order`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.allOrder = data;
        });
      },
  
  
      //   remove(colors) {
      //     var url = `${BASE_URL}color-delete/${colors.id}`;
      //     axios.delete(url).then(() => {
      //       const index = this.color.indexOf(colors);
      //       this.color.splice(index, 1);
      //     });
      //   },
    },
  };
  
  </script>
          
  <style scoped></style>