<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div v-if="successMessage" class="success-message">
          {{ successMessage }}
        </div>

        <div class="card-body">

          <select v-model="selectedBrand" @change="loadProductsByBrand">
            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
              {{ brand.brand_name }}
            </option>
          </select>

          <h3 class="mb-5">Product Detail</h3>
          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Product Title</th>
              <th>Product Sku</th>
              <th>Expiry Dates</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product, productIndex) in allProductDetails" :key="product.id">
              <th scope="row">{{ product.id }}</th>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100"/></td>
              <td>{{ product.title }}</td>
              <td>{{ product.sku }}</td>
              <td class="flex" style="display: flex">
                <div v-for="(expiry, expiryIndex) in product.expiry_dates" :key="expiryIndex">
                  <input type="date" v-model="expiry.date" class="form-control">
                  <button @click="removeExpiryDate(productIndex, expiryIndex)" class="btn bg-danger ml-2"><i
                      class="fa fa-trash"></i></button>
                  <!-- Display the expiry date here -->
                  <div>{{ expiry.date }}</div>
                </div>
                <button @click="addExpiryDate(productIndex)" class="btn bg-success ml-2"><i class="fa fa-plus"></i>
                </button>
              </td>
              <td>
                <button @click="updateDescription(product)" class="btn btn-primary">Update</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../../axiosInstance";

export default {
  data() {
    return {
      allProductDetails: [],
      selectedBrand: null,
      brands: [],
      successMessage: '', // Initialize success message
    };
  },

  created() {
    this.userLoad();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.userLoad(this.selectedBrand);
      }
    },

    userLoad(brandId) {
      var page = `api/get-product-expiry-data/${brandId}`;
      axiosInstance.get(page).then(({data}) => {
        if (data && Array.isArray(data.mainPrice)) {
          this.allProductDetails = data.mainPrice.reduce((acc, product) => {
            const existingProduct = acc.find(item => item.id === product.id);
            if (existingProduct) {
              existingProduct.expiry_dates.push({date: product.expiry_date});
            } else {
              acc.push({
                ...product,
                expiry_dates: [{date: product.expiry_date}],
              });
            }
            return acc;
          }, []);
        } else {
          console.error("mainPrice data is missing or not an array.");
        }
        this.brands = data.brands;
      }).catch(error => {
        console.error(error);
      });
    },

    addExpiryDate(productIndex) {
      this.allProductDetails[productIndex].expiry_dates.push({date: ""}); // Add a new empty expiry date
    },

    removeExpiryDate(productIndex, expiryIndex) {
      this.allProductDetails[productIndex].expiry_dates.splice(expiryIndex, 1); // Remove the expiry date at the specified index
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/expiry-product-date/${product.id}`, {expiry_dates: product.expiry_dates.map(expiry => expiry.date)})
          .then(response => {
            this.successMessage = 'Main price updated successfully';
            setTimeout(() => {
              this.successMessage = '';
            }, 5000);
            this.priceLoad(this.selectedBrand);

            console.log('Details updated:', response.data);
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },
  },
};
</script>

<style scoped>
.success-message {
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 15px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
