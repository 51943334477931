<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <div
                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                        <strong>Order no: INV00</strong>{{ invoices.id }}
                    </div>

                    <div
                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                        <strong>Name:</strong> {{ invoices.first_name }} {{
                            invoices.middle_name }} {{ invoices.last_name }}
                    </div>

                    <div v-if="invoices.affiliate_first_name && invoices.affiliate_last_name"
                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                        <strong>Affiliate Name:</strong> {{
                            invoices.affiliate_first_name
                        }} {{ invoices.affiliate_last_name }}
                    </div>

                    <table>
                        <tr>
                            <td>Sku</td>
                            <td>Sale Price</td>
                            <td>Discount</td>
                        </tr>

                        <tr v-for="carts in cart" :key="carts.id">
                            <td>{{ carts.sku }}</td>
                            <td>{{ (carts.sale_price * carts.quantity *
                                ((carts.affiliate_percentage - carts.percentage) / 100)).toFixed(2) }}</td>
                            <td>% {{ (carts.affiliate_percentage - carts.percentage) }}</td>
                        </tr>

                        <tr>
                            <td colspan="2"><strong>Total Sale Price:</strong></td>
                            <td><strong>{{ calculateTotalSalePrice().toFixed(2) }}</strong></td>
                        </tr>
                    </table>
                </div>
                <button @click="downloadExcel">Download Excel</button>

            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';
import * as XLSX from 'xlsx'; // Import the entire XLSX module

import { saveAs } from 'file-saver';

export default {
    name: 'ExportInvoice',

    data() {
        return {
            cart: [],
            logos: [],
            invoices: [],
            invo: [],
            bundles: [],

        };
    },

    computed: {
        totalPrice() {
            let total = 0;

            this.invo.forEach((inv) => {
                if (inv.price_after_discount !== null) {
                    total += inv.price_after_discount;
                } else {
                    total += inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.discount / 100);
                }
            });

            return total;
        },

        beforeDisc() {
            let beforeDiscount = 0;

            this.invo.forEach((inv) => {
                beforeDiscount += inv.sale_price * inv.quantity
            });

            return beforeDiscount;
        }

    },

    created() {
        this.orderLoad();
    },

    methods: {
        calculateTotalSalePrice() {
            let total = 0;

            this.cart.forEach((cart) => {
                total += (cart.sale_price * cart.quantity *
                    ((cart.affiliate_percentage - cart.percentage) / 100));
            });

            return total;
        },

        downloadExcel() {
            const wb = XLSX.utils.book_new();
            const wsData = [];

            // Add invoice details to the worksheet data
            wsData.push(['Order no:', `INV00${this.invoices.id}`]);
            wsData.push(['Name:', `${this.invoices.first_name} ${this.invoices.middle_name} ${this.invoices.last_name}`]);

            if (this.invoices.affiliate_first_name && this.invoices.affiliate_last_name) {
                wsData.push(['Affiliate Name:', `${this.invoices.affiliate_first_name} ${this.invoices.affiliate_last_name}`]);
            }

            wsData.push([]); // Add an empty row for spacing

            // Prepare header row
            const headers = ['Sku', 'Sale Price', 'Discount'];
            wsData.push(headers);

            // Populate data rows
            this.cart.forEach((cart) => {
                const row = [
                    cart.sku,

                    (cart.sale_price * cart.quantity * ((cart.affiliate_percentage - cart.percentage) / 100))
                        .toFixed(2),
                    `% ${(cart.affiliate_percentage - cart.percentage)}`,
                ];
                wsData.push(row);
            });

            // Add total sale price row
            wsData.push(['', 'Total Sale Price:', this.calculateTotalSalePrice().toFixed(2)]);

            // Create a new worksheet
            const ws = XLSX.utils.aoa_to_sheet(wsData);

            // Add worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'Invoice Data');

            // Generate Excel blob
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

            // Create a Blob object from the workbook data
            const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Use file-saver to initiate the download
            saveAs(blob, 'invoice_data.xlsx');
        },

        orderLoad() {
            var page = `api/invoice/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.cart = data.cart;
                this.logos = data.logo;
                this.invoices = data.invoice;
                this.invo = data.inv;
                this.bundles = data.bundle;
            });
        },
    },

};
</script>