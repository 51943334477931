<template>
    <div>
        <div ref="recaptcha"></div>
        <div v-if="error" style="color: red;">{{ error }}</div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            recaptchaWidgetId: null,
            error: null,
        };
    },
    mounted() {
        this.loadReCaptchaScript();
    },
    methods: {
        loadReCaptchaScript() {
            if (typeof window.grecaptcha === 'undefined') {
                const script = document.createElement('script');
                script.src = 'https://www.google.com/recaptcha/api.js?onload=onReCaptchaScriptLoad&render=explicit';
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
                window.onReCaptchaScriptLoad = this.initializeReCaptcha;
            } else {
                this.initializeReCaptcha();
            }
        },
        initializeReCaptcha() {
            if (typeof window.grecaptcha === 'undefined') {
                this.setError('ReCaptcha script not loaded');
                return;
            }

            this.recaptchaWidgetId = window.grecaptcha.render(this.$refs.recaptcha, {
                sitekey: '6LdVbjopAAAAAIEOB5PCjgP850ncpW4SgRZ77Oph',
                callback: this.onRecaptchaVerified,
                theme: 'light', // or 'dark'
            });
        },
        onRecaptchaVerified(response) {
            this.$emit('recaptcha-verified', response);
        },
        resetReCaptcha() {
            if (this.recaptchaWidgetId) {
                window.grecaptcha.reset(this.recaptchaWidgetId);
            }
        },
        setError(error) {
            this.error = error;
        },
    },
};
</script>