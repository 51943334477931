<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="productbundle.product_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Product --</option>
                            <option v-for="product in products" :key="product.id" :value="product.id">
                                {{ product.product_name }}
                            </option>
                        </select>

                        <select v-model="productbundle.bundle_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Bundle --</option>
                            <option v-for="bundle in bundles" :key="bundle.id" :value="bundle.id">
                                {{ bundle.bundle_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateProductBundle",

    data() {
        return {
            productbundle: {
                product_id: 0,
                bundle_id: 0,
            },
            products: [], // Update variable name to employees
            bundles: [], // Update variable name to employees
        };
    },

    methods: {

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('bundle_id', this.productbundle.bundle_id);
            formData.append('product_id', this.productbundle.product_id);
            axiosInstance.post(`api/productbundle-store`, formData, {

            }).then(() => {
                // Redirect to the desired page
                router.push("/productbundle");
            });
        },
        fetchCategories() {
            axiosInstance
                .get(`api/productbundle-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.products = response.data.product;
                    this.bundles = response.data.bundle;
                });
        },
    },
    mounted() {
        this.fetchCategories();
    },
};
</script>
