<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Sku</th>
                                <th>Title(if exist)</th>
                                <th>Color</th>
                                <th>Size</th>
                                <th>Attribute</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td>{{ product.color_name }}</td>
                                <td>{{ product.size_name }}</td>
                                <td>{{ product.attribute_type_name }}</td>
                                <td v-if="product.photo_needed == 0" class="bg-primary">No need</td>
                                <td v-else-if="product.photo_needed == 1" class="bg-danger">Need Photo</td>
                                <td v-else-if="product.photo_needed == 2" class="bg-success">Done!!</td>
                                <td v-if="product.photo_needed == 0 || product.photo_needed == 2"></td>

                                <td v-else>
                                    <button @click="setPhotoNeeded(product)" class="btn btn-success me-4">
                                        Photo Done
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productDetail: [],
            product: {
                sku: '',
                sale_price: ''
            }
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {

        setPhotoNeeded(product) {
            axiosInstance
                .post(`api/update-needed-photo-done/${product.id}`)
                .then(response => {
                    console.log('Photo needed flag set:', response.data.message);
                    this.fetchAllData(); // Fetch updated data after setting the flag
                })
                .catch(error => {
                    console.error('Error setting photo needed flag:', error);
                });
        },

        fetchAllData() {
            return axiosInstance
                .get(`api/get-disabled-product`)
                .then(response => {
                    this.productDetail = response.data.productDetail;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-disabled-data/${product.id}`, {
                    sku: product.sku, sale_price: product.sale_price
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
