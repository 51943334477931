<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <div class="bundle-container mx-2" ref="bundleContainer">
                        <div class="bundle-items">
                            <!-- Loop through bundles -->
                            <div class="row">

                                <div v-for="bundle in bundle" :key="bundle.id" class="bundle-item col-4">
                                    <router-link :to="{ name: 'AffiliateBundleView', params: { id: bundle.id } }"
                                        class="mfp-open">
                                        <img :src="'https://api.gocami.com/bundle_image/' + bundle.bundle_image" alt=""
                                            class="product-image" style="width: 300px !important;" />
                                        <div class="text">
                                            <h2 class="title h5">{{ bundle.bundle_name }}</h2>
                                            <sup>$ {{ bundle.bundle_price.toFixed(2) }}</sup>
                                        </div>
                                    </router-link>
                                </div>
                                <!-- End loop -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            bundle: []
        }
    },

    created() {
        this.fetchBundle();
    },

    methods: {
        fetchBundle() {
            const page = `api/get-affiliate-bundle`;
            axiosInstance.get(page)
                .then(({ data }) => {
                    this.bundle = data;
                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                });
        },
    }
}
</script>