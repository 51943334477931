<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <div>
                        <label for="from">From:</label>
                        <input type="date" v-model="filterFrom" @change="applyDateFilter">
                    </div>
                    <div>
                        <label for="to">To:</label>
                        <input type="date" v-model="filterTo" @change="applyDateFilter">
                    </div>

                    <table class="table table-responsive">
                        <tr>
                            <td>Affiliate Name</td>
                            <td>Invoice NB.</td>
                            <td>Item</td>
                            <td>Quantity</td>
                            <td>Item Price</td>
                            <td>Commission</td>
                            <td>Total</td>
                            <td>Created At</td>
                        </tr>

                        <tr v-for="cart in carts" :key="cart.id">
                            <td>{{ cart.first_name }} {{ cart.last_name }}</td>
                            <td>INV00{{ cart.invoice_id }}</td>
                            <td>{{ cart.sku }}</td>
                            <td>{{ cart.quantity }}</td>
                            <td>{{ cart.sale_price.toFixed(2) }}</td>
                            <td v-if="cart.affiliate_percentage === null">%30</td>
                            <td v-else>%{{ cart.affiliate_percentage - cart.percentage }}</td>
                            <td v-if="cart.affiliate_percentage === null">{{ (cart.sale_price * cart.quantity * 30 /
                                100).toFixed(2) }}</td>
                            <td v-else>{{ (cart.sale_price * cart.quantity *
                                ((cart.affiliate_percentage - cart.percentage) / 100)).toFixed(2) }}</td>
                            <td>{{ new Date(cart.created_at).toLocaleString() }}</td>

                        </tr>
                    </table>

                    <div>Before Discount: $ {{ totalBeforeDiscount.toFixed(2) }}</div>
                    <div>After Discount: $ {{ totalAfterDiscount.toFixed(2) }}</div>
                    <div>Total: $ {{ total.toFixed(2) }}</div>

                    <button @click="calculateAndDownloadExcel">Download Excel</button>


                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import * as XLSX from 'xlsx'; // Import the entire XLSX module

export default {
    data() {
        return {
            carts: [],
            total: 0,
            filterFrom: null,
            filterTo: null,
            totalBeforeDiscount: 0,
            totalAfterDiscount: 0,
            user: [],
        };
    },

    created() {
        this.orderLoad();
        this.getUser();
    },

    methods: {
        getUser() {
            const apiEndpoint = `api/get-aff/${this.$route.params.id}`;
            axiosInstance.get(apiEndpoint)
                .then(({ data }) => {
                    // Update your data properties with the fetched data
                    this.user = data;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },

        applyDateFilter() {
            if (this.filterFrom && this.filterTo) {
                const startDate = new Date(this.filterFrom);
                const endDate = new Date(this.filterTo);

                // Format the dates as 'YYYY-MM-DD' before sending
                const formattedStartDate = startDate.toISOString().split('T')[0];
                const formattedEndDate = endDate.toISOString().split('T')[0];

                // Make the API request with formatted dates
                this.orderLoad(formattedStartDate, formattedEndDate);
            }
        },
        orderLoad(startDate, endDate) {
            const apiEndpoint = `api/aff-res/${this.$route.params.id}`;
            const params = {
                from: startDate,
                to: endDate
            };

            axiosInstance.get(apiEndpoint, { params })
                .then(({ data }) => {
                    // Update your data properties with the fetched data
                    this.carts = data.cart;
                    this.calculateTotal();
                    this.beforeDiscount();
                    this.afterDiscount();
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },

        calculateAndDownloadExcel() {
            this.calculateTotal(); // Calculate the total
            this.beforeDiscount(); // Calculate before discount total
            this.afterDiscount();  // Calculate after discount total
            this.downloadExcel();  // Trigger the download
        },

        downloadExcel() {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.table_to_sheet(this.$el.querySelector('.table'));

            // Add rows for Before Discount and After Discount
            const beforeDiscountRow = ['Before Discount:', '', '', '', '', '', '', this.totalBeforeDiscount.toFixed(2)];
            const afterDiscountRow = ['After Discount:', '', '', '', '', '', '', this.totalAfterDiscount.toFixed(2)];

            // Append the rows to the sheet
            XLSX.utils.sheet_add_aoa(ws, [beforeDiscountRow], { origin: -1 });
            XLSX.utils.sheet_add_aoa(ws, [afterDiscountRow], { origin: -1 });

            // Append the total row again (if needed)
            XLSX.utils.sheet_add_aoa(ws, [['Total:', '', '', '', '', '', '', this.total.toFixed(2)]], { origin: -1 });

            XLSX.utils.book_append_sheet(wb, ws, 'Cart Data');

            const filename = `${this.user.first_name}_${this.user.last_name}.xlsx`;
            /* generate XLSX file and download */
            XLSX.writeFile(wb, filename);
        },

        calculateTotal() {
            this.total = this.carts.reduce((total, cart) => {
                if (cart.affiliate_percentage === null) {
                    total += cart.sale_price * cart.quantity * 30 / 100;
                } else {
                    total += cart.sale_price * cart.quantity * ((cart.affiliate_percentage - cart.percentage) / 100);
                }
                return total;
            }, 0);
        },

        beforeDiscount() {
            this.totalBeforeDiscount = this.carts.reduce((totalBeforeDiscount, cart) => {
                if (cart.discount === 0) {
                    totalBeforeDiscount += cart.sale_price * cart.quantity;
                } else {
                    totalBeforeDiscount += cart.sale_price * cart.quantity - ((cart.sale_price * cart.quantity * cart.discount) / 100);
                }
                return totalBeforeDiscount;
            }, 0);
        },

        afterDiscount() {
            this.totalAfterDiscount = this.carts.reduce((totalAfterDiscount, cart) => {
                if (cart.discount === 0) {
                    totalAfterDiscount += cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.percentage / 100);
                } else {
                    totalAfterDiscount += (cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity * cart.discount / 100)) - ((cart.sale_price * cart.quantity * cart.percentage) / 100);
                }
                return totalAfterDiscount;
            }, 0);
        }

    },
};
</script>
