<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Main Product</h3>

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-4"><input type="text" class="form-control" v-model="product.product_name"
                                    placeholder="Product Name" /></div>

                            <div class="col-4"><input type="text" class="form-control" v-model="product.price"
                                    placeholder="Price" /></div>


                            <div class="col-4">
                                <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />
                            </div>

                            <div class="col-12 my-5"><textarea cols="30" rows="10" class="form-control" v-model="product.description"></textarea>
                            </div>


                            <div class="col-4">
                                <select class="form-select" v-model="product.category_id">
                                    <option value="" selected hidden disabled>-- Choose Category --</option>
                                    <option v-for="category in category" :key="category.id" :value="category.id">{{
                        category.category_name }}</option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-select" v-model="product.brand_id">
                                    <option value="" selected hidden disabled>-- Choose Brand --</option>
                                    <option v-for="brand in brand" :key="brand.id" :value="brand.id">{{ brand.brand_name
                                        }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-4">
                                <select class="form-select" v-model="product.supplier_id">
                                    <option value="" selected hidden disabled>-- Choose supplier --</option>
                                    <option v-for="supplier in supplier" :key="supplier.id" :value="supplier.id">{{
                                        supplier.supplier_name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary mt-4">Create Product</button>


                    </form>


                    <!-- <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Image</th>
                                <th>Position</th>
                                <th>Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td>{{ product.brand_name }}</td>
                                <td>{{ product.category_name }}</td>
                                <td>
                                    <p v-html="product.description"></p>
                                </td>
                                <td>${{ product.price }}</td>

                                <td><img :src="'https://api.gocami.com/product_main/' + product.image" width="75" />
                                </td>
                                <td>{{ product.position }}</td>
                                <td>{{ product.supplier_name }}</td>

                            </tr>
                        </tbody>
                    </table> -->

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
import { useUserStore } from "../../../store";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            productDetail: [],
            product: {
                product_name: '',
                supplier_id: '',
                brand_id: '',
                category_id: '',
                description: '',
                price: '',
            },
            brand: [],
            products: [],
            category: [],
            supplier: []
        };
    },

    created() {
        this.fetchCredential();
    },

    methods: {

        onFileChange(event) {
            this.product.image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('product_name', this.product.product_name);
            formData.append('image', this.product.image);
            formData.append('supplier_id', this.product.supplier_id);
            formData.append('brand_id', this.product.brand_id);
            formData.append('category_id', this.product.category_id);
            formData.append('description', this.product.description);
            formData.append('price', this.product.price);
            formData.append('category_id', this.product.category_id);
            formData.append('user_id', this.user?.id);
            axiosInstance.post(`api/data-insert-data`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                console.log();
            });
        },

        fetchCredential() {
            return axiosInstance
                .get(`api/data-insert-credentials`)
                .then(response => {
                    this.brand = response.data.brand;
                    this.category = response.data.category;
                    this.supplier = response.data.supplier;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },
    },

}
</script>
