<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
            <!-- Use v-if to wait for data before rendering -->
            <div v-if="cartLoaded">
              <div v-for="carts in cart" :key="carts.id">
                <p>{{ carts.sku }}</p>
                <p>{{ carts.quantity }}</p>
                <p>{{ carts.sale_price }}</p>
              </div>
              <button @click="downloadAsTxt">Download as TXT</button> <!-- Add the download button -->
            </div>
            <div v-else>
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  import { downloadAsTxt } from '../../../../../exportUtils'; // Correctly import the downloadAsTxt method from the appropriate file
  
  export default {
    data() {
      return {
        cart: [],
        cartLoaded: false, // Add a flag to track data loading status
      };
    },
  
    methods: {
      orderLoad() {
        var page = `api/export-data/${this.$route.params.id}`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.cart = data.carts;
          this.cartLoaded = true; // Set the flag to true when data is loaded
        }).catch((error) => {
          console.error('Error loading data:', error);
          this.cartLoaded = true; // Set the flag to true even in case of error to avoid being stuck in a loading state
        });
      },
  
      downloadAsTxt() {
        // Use the method directly imported from the exportUtils.js file
        downloadAsTxt(this.cart);
      },
    },
  
    created() {
      this.orderLoad();
    },
  };
  </script>
  