<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Gocami</title>
  </head>

  <body>
    <div class="wrapper">
      <TopbarLayout />

      <router-view />
    </div>
  </body>
</template>
  
<style scoped>
html {
  height: 100%;
}
</style>
  
<script>
import TopbarLayout from "../User/shared/TopbarLayout.vue"

export default {
  components: { TopbarLayout },

}
</script>
  
<style scoped>
  @import url("../../../public/user_assets/css/bootstrap.css");
  @import url("../../../public/user_assets/css/animate.css");
  @import url("../../../public/user_assets/css/font-awesome.css");
  @import url("../../../public/user_assets/css/linear-icons.css");
  @import url("../../../public/user_assets/css/magnific-popup.css");
  @import url("../../../public/user_assets/css/owl.carousel.css");
  @import url("../../../public/user_assets/css/ion-range-slider.css");
  @import url("../../../public/user_assets/css/theme.css");
</style>