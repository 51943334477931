<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Bag Panel</h3>
  
            <router-link to="/create-bag" class="btn btn-primary">Create Bag</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Bag Name</th>
                  <th>Bag Price</th>
                  <th>Bag Image</th>
                  <th>Action</th>
                </tr> 
              </thead>
              <tbody>
                <tr v-for="bag in bag" v-bind:key="bag.id">
                  <th scope="row">{{ bag.id }}</th>
                  <td>{{ bag.bag_name }}</td>
                  <td>{{ bag.bag_price }}</td>
                  <td><img :src="'https://api.gocami.com/' + bag.bag_image" style="height: 50px; width:50px"></td>
                  <td>
                    <router-link :to="{ name: 'EditBag', params: { id: bag.id } }" class="btn btn-warning me-2"><i
                        class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(bag)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  
  export default {
    name: "BrandView",
  
    data() {
      return {
        bag: [],
      };
    },
  
    created() {
      this.brandLoad();
    },
  
    methods: {
      brandLoad() {
        var page = `api/bag`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.bag = data;
        });
      },
  
      remove(bags) {
        var url = `api/bag-delete/${bags.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.bag.indexOf(bags);
          this.bag.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>