<template>
    <div class="container">
        <div class="content">
            <h1>About Us</h1>
            <p>GoCami “your ultimate destination for shopping” Welcome to GoCami, your premium online shop in Lebanon, that provides a wide range of high-quality products from health & beauty, home appliances, electronics, outdoor tools for camping, water activities, sports to studying supplies. Our mission is to provide you with a seamless and delightful shopping experience while delivering all your needs and passions.</p>

            <h2>GoCami Goals & Objectives</h2>
            <ul>
                <li>Commitment to Quality: Our products are carefully selected to enhance your confidence, where they meet high quality, this is a major part of our culture.</li>
                <li>Passion for Adventure in E-commerce: Ensure that the website has a simple, effective design, pages load quickly and it is easy to navigate, along with compelling content.</li>
                <li>A Customer-Centric Approach: Customer satisfaction is at the heart of everything we do. We strive to create an online shopping experience that's not only convenient but also personalized. Our user-friendly interface, payment options, and efficient delivery process are designed to make your journey from selection to checkout smooth and hassle-free. Our dedicated customer support team is always ready to assist you, ensuring that your needs are met with the utmost care.</li>
            </ul>

            <p>Join the GoCami Community When you shop at GoCami, you become a part of our family. Connect with fellow enthusiasts, share your experiences, and discover new tips and tricks to make the most of your purchases. GoCami's community culture is the shared values, attitudes, behaviors, and norms that make the work environment. Our culture is what creates the daily experience in our humble community of participation, transparency, awareness, appreciation, and competence.</p>
        </div>
    </div>
</template>

<style scoped>

template {
    font-family: Arial, sans-serif;
    margin: 20px;
    background-color: #f4f4f4;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
}

.content {
    text-align: left;
    margin-bottom: 25px;
}

h1 {
    color: #333;
}

h2, h3 {
    color: #555;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

p {
    line-height: 1.6;
    margin-bottom: 20px;
}
</style>