<template>
    <!-- ================== Footer  ================== -->

    <footer>
        <!--footer showroom-->
        <!-- <div class="footer-showroom hidden">
            <div class="row">
                <div class="col-sm-8">
                    <h2>Visit our showroom</h2>
                    <p>200 12th Ave, New York, NY 10001, USA</p>
                    <p>Mon - Sat: 10 am - 6 pm &nbsp; &nbsp; | &nbsp; &nbsp; Sun: 12pm - 2 pm</p>
                </div>
                <div class="col-sm-4 text-center">
                    <a href="#" class="btn btn-clean"><span class="icon icon-map-marker"></span> Get directions</a>
                    <div class="call-us h4"><span class="icon icon-phone-handset"></span> 333.278.06622</div>
                </div>
            </div>
        </div> -->

        <!--footer links-->
        <div class="footer-links">
            <div class="row">
                <div class="col-sm-4 col-md-2">
                    <h5>Browse by</h5>
                    <ul>
                        <li><a href="#">Brands</a></li>
                        <li><a href="#">Products</a></li>
                        <li><a href="#">Categories</a></li>
                    </ul>
                </div>
                <div class="col-sm-4 col-md-2">
                    <h5>Quick links</h5>
                    <ul>
                        <li><a href="#">After Sale</a></li>
                        <li><a href="#">FAQ</a></li>
                    </ul>
                </div>
                <div class="col-sm-4 col-md-2">
                    <h5>Information</h5>
                    <ul>
                        <li><router-link to="/privacy-policy">Privacy Policy </router-link></li>
                        <li><a href="#">Terms & Conditions</a></li>
                        <li><a href="#">Shipping</a></li>
                        <li><a href="#">Returns</a></li>
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Affiliate Policy</a></li>
                    </ul>
                </div>
                <div class="col-sm-4 col-md-2">
                    <h5>Customer service</h5>
                    <ul>
                        <li><a href="#">Help Center</a></li>
                        <li><a href="#">Feedback</a></li>
                        <li><a href="#">Contact us</a></li>
                    </ul>
                </div>
                <div class="col-sm-12 col-md-4">
                    <h5>Sign up for our newsletter</h5>
                    <p><i>Add your email address to sign up for our monthly emails and to receive promotional offers.</i>
                    </p>
                    <div class="form-group form-newsletter">
                        <input class="form-control" type="text" name="email" value="" placeholder="Email address" />
                        <input type="submit" class="btn btn-main btn-sm" value="Subscribe" />
                        <!-- Tel:<a href="tel:+96181204084">+961 81 20 40 84</a> -->
                    </div>
                    <span class="pt-4">Tel:<a href="tel:+96181204085">+961 81 20 40 85</a><br/></span>

                </div>
            </div>
        </div>

        <!--footer social-->
    </footer>
</template>


<style scoped>
    h5{
        color: #154068 !important;
    }

    footer{
        background-color: #EDEFF3;
    }
</style>