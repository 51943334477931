<template>
    <div class="container">

        <div class="row">
            <div class="col-12">
                <p class="text-center justify-content-center my-5 attention">.<u>ملاحظة: </u> العميل يدخل اسمه في السحب مرة
                    واحدة فقط</p>
            </div>
        </div>
        <div class="row">
            <div class="col-6 py-3 px-3" v-for="brandDisc in brandDisc" :key="brandDisc.id">
                <router-link :to="{ name: 'HomeBrandView', params: { id: brandDisc.id } }">
                    <img :src="'https://api.gocami.com/brand_discount/' + brandDisc.brand_discount" class="w-100">
                </router-link>
            </div>

        </div>
    </div>
    <FooterLayout />
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';

export default {
    components: { FooterLayout },

    data() {
        return {
            brandDisc: []
        }
    },

    created() {
        this.getBrandDisc();
    },

    methods: {
        getBrandDisc() {
            var page = `api/brand-discounts`;
            axiosInstance.get(page).then(({ data }) => {
                this.brandDisc = data.brands;
                console.log(data);
            }).catch(error => {
                console.error('Error fetching data:', error);
            });
        }
    }
}

</script>

<style scoped>
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.attention {
    animation: pulse 1.5s infinite;
}

.attention,
u {
    font-size: 36pt !important;
}
</style>