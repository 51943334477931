<template>
  <div v-if="showToast" class="toast" :class="{ 'show': showToast, 'error': toastType === 'error' }">
    {{ toastMessage }}
  </div>

  <section class="login-wrapper login-wrapper-page" style="background-color: transparent;">
    <div class="container">
      <div class="login-block login-block-signup">
        <div class="h4">
          Login now
          <router-link to="/register" class="btn btn-main btn-xs btn-login pull-right">Sign Up</router-link>
        </div>
        <div class="toast" :class="{ show: showSuccessMessage }">
          Registration successful! You can now log in.
        </div>
        <hr/>
        <form @submit.prevent="save" class="login-form">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input type="text" v-model="login.identifier" class="form-control custom-input"
                       placeholder="Your Email or Phone"/>
                <span class="error-message" v-if="validationErrors.identifier">{{
                    validationErrors.identifier[0]
                  }}</span>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group">
                <input type="password" v-model="login.password" class="form-control custom-input"
                       placeholder="Your Password"/>
                <span class="error-message" v-if="validationErrors.password">{{ validationErrors.password[0] }}</span>
                <span class="error-message" v-if="errorMessage">{{ errorMessage }}</span>
              </div>
            </div>

            <div class="col-md-12">
              <button type="submit" class="btn btn-main btn-block" :disabled="isLoginSuccessful">
                Sign In
              </button>
            </div>

            <router-link to="/forgot-password" class="forgot-password-link">Forgot your password?</router-link>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";
import axiosInstance from "../../../../axiosInstance";
import {useUserStore} from "../../../../store";
import Cookies from "js-cookie";

export default {
  name: "LoginView",

  data() {
    return {
      isLoginSuccessful: false, // New property to track login status
      login: {
        identifier: "",
        password: "",
      },
      validationErrors: {},
      errorMessage: "", // New property to store the server error message
      showSuccessMessage: false,
      showToast: false,
      toastMessage: "",
      toastType: "", // Ensure this property is declared
    };
  },

  mounted() {
    this.checkLocalStorageToken();
  },

  methods: {
    save() {
      this.saveData();
    },

    // saveData() {
    //   axiosInstance
    //     .post(`api/login`, this.login)
    //     .then((response) => {
    //       Cookies.set("token", response.data.authorisation.token);
    //       const userStore = useUserStore();
    //       userStore.setUser(response.data.user);
    //       this.isLoginSuccessful = true;

    //       // Show success toast message
    //       this.showToastMessage("Login successful!");

    //       const from = this.$route.query.from;

    //       if (from) {
    //         router.push(from);
    //       } else {
    //         router.push({ path: "/" });
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.status === 401) {
    //           this.showToastMessage("Invalid email or password!", 'error');
    //         } else if (error.response.status === 422) {
    //           this.validationErrors = error.response.data.errors;
    //         } else {
    //           this.showToastMessage("An error occurred during login!", 'error');
    //         }
    //       } else {
    //         this.showToastMessage("An error occurred. Please try again later!", 'error');
    //       }
    //     });
    // },

    saveData() {
      axiosInstance
          .post(`api/login`, this.login)
          .then((response) => {
            const expiryDate = new Date();
            expiryDate.setDate(expiryDate.getDate() + 7); // Set expiry to 7 days from now
            Cookies.set("token", response.data.authorisation.token, {expires: expiryDate});
            localStorage.setItem('token', response.data.authorisation.token);

            const userStore = useUserStore();
            userStore.setUser(response.data.user);
            this.isLoginSuccessful = true;
            this.showToastMessage("Login successful!");

            const from = this.$route.query.from;
            if (from) {
              router.push(from);
            } else {
              router.push({path: "/"});
            }
            const userId = response.data.user.id;
            if (userId) {
              const guestCart = JSON.parse(localStorage.getItem('guestCart'));
              if (guestCart && guestCart.length > 0) {
                this.insertGuestCart(userId, guestCart)
                    .then(() => {
                      // console.log("Guest cart removed successfully"); // Add this line for debugging
                      localStorage.removeItem('guestCart');
                    })
                    .catch(error => {
                      console.error("Error inserting guest cart data:", error);
                    });
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.showToastMessage("Invalid email or password!", 'error');
              } else if (error.response.status === 422) {
                this.validationErrors = error.response.data.errors;
              } else {
                this.showToastMessage("An error occurred during login!", 'error');
              }
            } else {
              this.showToastMessage("An error occurred. Please try again later!", 'error');
            }
          });
    },


    autoLogin(token) {
      // Make a request to the server to validate the token and get user data
      axiosInstance.get(`api/user?user_id=${this.user?.id}`, {headers: {Authorization: `Bearer ${token}`}})
          .then((response) => {
            // Set user data in store
            const userStore = useUserStore();
            userStore.setUser(response.data.user);
            // Set login status
            this.isLoginSuccessful = true;
            // Redirect to home or appropriate route
            router.push({path: "/"});
          })
          .catch((error) => {
            // Handle error (e.g., token expired, invalid token)
            console.error("Auto login failed:", error);
            // Clear token from localStorage
            localStorage.removeItem('token');
          });
    },


    checkLocalStorageToken() {
      const token = localStorage.getItem('token');
      if (token) {
        // Token exists in local storage
        // You can send this token with your requests to authenticate the user on the server-side
        // You don't need to decode it in the client-side JavaScript
        // Simply perform any necessary actions with the token, such as sending it with Axios requests
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
    },

    insertGuestCart(userId, cartItems) {
      console.log("Inserting guest cart. User ID:", userId, "Cart items:", cartItems); // Log the user ID and cart items

      axiosInstance
          .post(`api/insert-guest-cart`, {user_id: userId, cart_items: cartItems})
          .then((response) => {
            // Handle successful insertion of guest cart data
            console.log(response);
            localStorage.removeItem('guestCart');
          })
          .catch((error) => {
            // Handle error during insertion
            console.error("Failed to insert guest cart data", error);
          });
    },

    showToastMessage(message, type = 'success') {
      this.toastMessage = message;
      this.toastType = type;
      this.showToast = true;

      // Hide the toast after a certain duration (e.g., 3000 milliseconds)
      setTimeout(() => {
        this.hideToast();
      }, 3000);
    },

    hideToast() {
      this.showToast = false;
      this.toastMessage = "";
      this.toastType = "";
    },

  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #28a745 !important;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 99999 !important;
}

.toast.error {
  background-color: #dc3545 !important;
  /* Red color for error */
}

.toast.show {
  opacity: 1;
}

.custom-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #3498db;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

.error-message {
  color: #e74c3c;
  margin-top: -10px;
  margin-bottom: 10px;
}

.login-form {
  margin-top: 20px;
}

.forgot-password-link {
  margin-top: 15px;
  display: inline-block;
  color: #3498db;
  text-decoration: none;
}
</style>
