<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Supplier Panel</h3>

                    <!-- Supplier Form for Adding and Editing -->
                    <form @submit.prevent="submitForm">
                        <div class="mb-3">
                            <label for="supplierName" class="form-label">Supplier Name</label>
                            <input type="text" class="form-control" id="supplierName" v-model="form.supplier_name" required>
                        </div>
                        <button type="submit" class="btn btn-primary">{{ editMode ? 'Update Supplier' : 'Add Supplier' }}</button>
                        <button type="button" @click="cancelEdit" class="btn btn-secondary" v-if="editMode">Cancel</button>
                    </form>

                    <!-- Supplier Table -->
                    <table class="table w-100 mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Supplier Name</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="supplier in suppliers" :key="supplier.id">
                                <th scope="row">{{ supplier.id }}</th>
                                <td>{{ supplier.supplier_name }}</td>
                                <td>
                                    <button @click="editSupplier(supplier)" class="btn btn-warning me-2"><i class="fa fa-pencil"></i></button>
                                    <button @click="remove(supplier)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "SupplierView",

    data() {
        return {
            suppliers: [],
            editMode: false,
            form: {
                id: null,
                supplier_name: ''
            }
        };
    },

    created() {
        this.loadSuppliers();
    },

    methods: {
        loadSuppliers() {
            axiosInstance.get('api/supplier').then(({ data }) => {
                this.suppliers = data;
            });
        },

        remove(supplier) {
            const url = `api/supplier-delete/${supplier.id}`;
            axiosInstance.delete(url).then(() => {
                const index = this.suppliers.indexOf(supplier);
                this.suppliers.splice(index, 1);
            });
        },

        editSupplier(supplier) {
            this.editMode = true;
            this.form.id = supplier.id;
            this.form.supplier_name = supplier.supplier_name;
        },

        submitForm() {
            if (!this.form.supplier_name) return;

            if (this.editMode) {
                axiosInstance.put(`api/supplier-update-super/${this.form.id}`, this.form)
                    .then(() => {
                        this.loadSuppliers();
                        this.resetForm();
                    })
                    .catch(error => {
                        console.error('Error updating supplier:', error);
                    });
            } else {
                axiosInstance.post('api/super-admin-supplier', this.form)
                    .then(() => {
                        this.loadSuppliers();
                        this.resetForm();
                    })
                    .catch(error => {
                        console.error('Error adding supplier:', error);
                    });
            }
        },

        resetForm() {
            this.editMode = false;
            this.form.id = null;
            this.form.supplier_name = '';
        },
        
        cancelEdit() {
            this.resetForm();
        }
    }
};
</script>

<style scoped></style>
