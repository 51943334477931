<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Filter Panel</h3>

          <router-link to="/create-filtertype" class="btn btn-primary">Create Filter Type</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Filter Name</th>
                <th>Filter Type Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="filtertypes in filtertype" v-bind:key="filtertypes.id">
                <th scope="row">{{ filtertypes.id }}</th>
                <td>{{ filtertypes.filter.filter_name }}</td>
                <td>{{ filtertypes.product_filter }}</td>
                <td>
                  <router-link :to="{ name: 'EditFilterType', params: { id: filtertypes.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(filtertypes)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
  name: "FilterTypeView",

  data() {
    return {
      filtertype: [],
    };
  },

  created() {
    this.filtertypeLoad();
  },

  methods: {
    filtertypeLoad() {
      var page = `api/filtertype`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.filtertype = data;
      });
    },

    remove(filtertypes) {
      var url = `api/filtertype-delete/${filtertypes.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.filtertype.indexOf(filtertypes);
        this.filtertype.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>