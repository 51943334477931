<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body p-5">


                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <div class="row mb-5" v-for="(detail, index) in product.details" :key="index">

                            <div class="row">

                                <div class="col-2 mb-3">
                                    <input type="text" class="form-control" placeholder="Create sku"
                                        v-model="detail.sku" />
                                </div>

                                <div class="col-2 mb-3">
                                    <input type="text" class="form-control" placeholder="Create Title"
                                        v-model="detail.title" />
                                </div>

                                <div class="col-2 mb-3">
                                    <input type="text" class="form-control" placeholder="Create Price"
                                        v-model="detail.sale_price" />
                                </div>

                                <div class="col-2 mb-3">
                                    <select class="form-select" v-model="detail.brand_id">
                                        <option value="" selected hidden disabled>-- Choose Brand --</option>
                                        <option v-for="brand in brand" :key="brand.id" :value="brand.id">{{
                        brand.brand_name
                    }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-2 mb-3">
                                    <select class="form-select" v-model="detail.product_id">
                                        <option value="" selected hidden disabled>-- Choose Product --</option>
                                        <option v-for="products in products" :key="products.id" :value="products.id">{{
                        products.product_name
                    }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-6">
                                    <textarea id="" cols="30" rows="10" class="form-control"
                                        v-model="detail.details"></textarea>
                                </div>

                                <div class="col-6">
                                    <textarea id="" cols="30" rows="10" class="form-control"
                                        v-model="detail.description"></textarea>
                                </div>

                                <div class="col-12 my-4">
                                    <input type="file" accept="image/*" @change="onFileChange"
                                        class="form-control mb-3" />
                                </div>

                            </div>

                            <div class="row mb-5" v-for="(item, itemIndex) in detail.items" :key="itemIndex">
                                <div class="col-3">
                                    <label for="">Select Color</label>

                                    <select v-model="item.color_id" class="form-control mb-3">
                                        <option value="0" selected disabled hidden>-- Choose color --</option>
                                        <option v-for="color in colorOptions" :key="color.id" :value="color.id">{{
                        color.color_name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <label for="">Select Size</label>

                                    <select v-model="item.size_id" class="form-control mb-3">
                                        <option value="0" selected disabled hidden>-- Choose size --</option>
                                        <option v-for="size in sizeOptions" :key="size.id" :value="size.id">{{
                        size.size_name }}</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <label for="">Select Attribute</label>

                                    <select v-model="item.attribute_type_id" class="form-control mb-3">
                                        <option value="0" selected disabled hidden>-- Choose attribute --</option>
                                        <option v-for="attribute in attributeOptions" :key="attribute.id"
                                            :value="attribute.id">
                                            {{ attribute.attribute_type_name }}</option>
                                    </select>
                                </div>
                                <div class="col-3">
                                    <label for="">Quantity</label>

                                    <input type="number" class="form-control" placeholder="Create quantity"
                                        v-model="item.quantity" required />
                                </div>
                                <!-- <div class="col-2">
                    <button type="button" class="btn btn-danger" @click="removeItem(index, itemIndex)">Remove</button>
                  </div> -->
                            </div>
                            <div class="col-2">
                                <button type="button" class="btn btn-danger mb-4" @click="removeDetail(index)">Remove
                                    Product</button>
                            </div>

                            <hr />
                            <hr />
                            <hr />
                            ....................
                        </div>

                        <button type="button" class="btn btn-success mr-5" @click="addDetail">Add More Product</button>
                        <button type="submit" class="btn btn-primary">Create Product</button>
                    </form>
                    <!-- Error messages -->
                    <div v-if="errors.length" class="mt-3 text-danger">
                        <ul>
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';
// import router from "@/router";

import { useUserStore } from "../../../store";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            category: '',
            brand: '',
            color: '',
            size: '',
            colorOptions: [],
            sizeOptions: [],
            attributeOptions: [],
            selectedProduct: '',
            selectedSupplier: '',
            product: {
                details: [],
                items: [],
            },
            products: [],
            errors: [],
            quantitySum: 0,
            zones: [],
        };
    },


    created() {
        this.fetchAllData();
    },

    methods: {

        onFileChange(event) {
            const file = event.target.files[0];
            console.log("Selected File:", file); // Check if the file is correctly retrieved
            this.product.image = file; // Update the product's image
            // Update the image for each detail
            this.product.details.forEach(detail => {
                detail.image = file;
            });
        },

        addDetail() {
            if (this.product.details.length > 0) {
                const lastDetail = this.product.details[this.product.details.length - 1];
                const newDetail = {
                    sku: lastDetail.sku,
                    title: lastDetail.title,
                    description: lastDetail.description,
                    details: lastDetail.details,
                    sale_price: lastDetail.sale_price,
                    brand_id: lastDetail.brand_id,
                    product_id: lastDetail.product_id,
                    image: lastDetail.image,
                    user_id: this.user?.id,
                    items: [{ color_id: "", size_id: "", attribute_type_id: "", quantity: 1 }]
                };
                this.product.details.push(newDetail);
            } else {
                this.product.details.push({
                    sku: "",
                    title: "",
                    description: "",
                    details: "",
                    sale_price: "",
                    brand_id: "",
                    product_id: "",
                    image: "",
                    user_id: this.user?.id,
                    items: [{ color_id: "", size_id: "", attribute_type_id: "", quantity: 1 }]
                });
            }
        },

        addItem(detailIndex) {
            this.product.details[detailIndex].items.push({ color_id: "", size_id: "", attribute_type_id: "", quantity: 1 });
        },

        removeItem(detailIndex, itemIndex) {
            this.product.details[detailIndex].items.splice(itemIndex, 1);
        },

        removeDetail(index) {
            this.product.details.splice(index, 1);
        },

        save() {
            this.saveData();
        },

        saveData() {
            const formData = new FormData();

            this.product.details.forEach((detail, index) => {
                formData.append(`productDetails[${index}][sku]`, detail.sku);
                formData.append(`productDetails[${index}][title]`, detail.title);
                formData.append(`productDetails[${index}][description]`, detail.description);
                formData.append(`productDetails[${index}][details]`, detail.details);
                formData.append(`productDetails[${index}][sale_price]`, detail.sale_price);
                formData.append(`productDetails[${index}][brand_id]`, detail.brand_id);
                formData.append(`productDetails[${index}][product_id]`, detail.product_id);
                formData.append(`productDetails[${index}][image]`, detail.image);
                formData.append(`productDetails[${index}][user_id]`, this.user?.id);

                // Check if 'details' field exists in the 'detail' object
                if (!Object.prototype.hasOwnProperty.call(detail, 'details')) {
                    detail.details = [];
                }

                detail.items.forEach((item, itemIndex) => {
                    formData.append(`configurations[${index}][${itemIndex}][color_id]`, item.color_id);
                    formData.append(`configurations[${index}][${itemIndex}][size_id]`, item.size_id);
                    formData.append(`configurations[${index}][${itemIndex}][attribute_type_id]`, item.attribute_type_id);
                    formData.append(`configurations[${index}][${itemIndex}][quantity]`, item.quantity);
                });
            });

            axiosInstance.post('api/data-insert-data-detail', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(() => {
                    console.log(formData);
                    // router.push("/prod-admin");
                })
                .catch(error => {
                    console.error("Error:", error);
                });
        },


        fetchAllData() {
            return axiosInstance
                .get(`api/get-main-data`)
                .then(response => {
                    this.colorOptions = response.data.color;
                    this.sizeOptions = response.data.size;
                    this.attributeOptions = response.data.attribute;
                    this.brand = response.data.brand;
                    this.products = response.data.product;
                    this.color = response.data.color;
                    this.size = response.data.size;
                    this.attribute = response.data.attribute;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

    },
};
</script>