<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <input v-model="is_scanned" class="my-4 form-control w-50" @input="onBarcodeInput" placeholder="Scan Barcode" />

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Location</th>
                <th>Note</th>
                <th>Date</th>
                <th>Status</th>
                <th>Scanned</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in allOrder" :key="order.id">
                <th scope="row">{{ order.id }}</th>
                <td>{{ order.first_name }} {{ order.middle_name }} {{ order.last_name }}</td>
                <td>{{ order.area.area_name }}</td>
                <td>{{ order.note }}</td>
                <td>{{ new Date(order.created_at).toLocaleString() }}</td>
                <td v-if="order.complete_product === 0" class="text-danger">Not Completed</td>
                <td v-else class="text-success" style="font-weight: bolder !important;">Completed</td>
                <td v-if="order.is_scanned === 0" class="text-danger">Not Scanned</td>
                <td v-else class="text-success" style="font-weight: bolder !important;">Scanned</td>
                <td style="display:flex">
                  <router-link :to="{ name: 'BarcodeExport', params: { id: order.id } }"
                    class="btn btn-primary mr-2">invoice</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "AllOrder",

  data() {
    return {
      allOrder: [],
      is_scanned: '',
      scannedInvoices: [], // Array to store scanned barcodes and corresponding invoice IDs
    };
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad() {
      var page = `api/order-management-orders-barcode`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.allOrder = data;
      });
    },

    onBarcodeInput() {
      clearTimeout(this.barcodeTimer);

      this.barcodeTimer = setTimeout(() => {
        axiosInstance.post('api/scan-invoice-barcode', { barcode: this.is_scanned })
          .then(response => {
            const data = response.data;
            console.log('Data from API:', data);

            if (data.invoice) {
              const invoiceId = data.invoice.id;

              // Check if the invoice is already scanned
              const alreadyScanned = this.scannedInvoices.some(item => item.invoiceId === invoiceId);

              if (!alreadyScanned) {
                // Update is_scan for the current invoice
                this.updateIsCompleteScanPromise(invoiceId)
                  .then(() => {
                    console.log('is_scanned updated successfully');
                  })
                  .catch(error => {
                    console.error(error);
                  });

                // Add the scanned barcode and invoice ID to the array
                this.scannedInvoices.push({ is_scanned: this.is_scanned, invoiceId });

                this.is_scanned = '';
              } else {
                // Invoice already scanned, handle accordingly (e.g., show a message)
                console.log('Invoice already scanned');
              }
            } else {
              // Barcode does not exist, play notification
              this.is_scanned = '';
              this.playNotification();
              console.log('Barcode does not exist');
            }
          })
          .catch(error => {
            console.error(error);
          });
      }, 10);
    },

    updateIsCompleteScanPromise(invoiceId) {
      return new Promise((resolve, reject) => {
        console.log('Updating is_scanned...');
        // Call Laravel API to update is_scanned using POST
        axiosInstance.post(`api/update-is-scan/${invoiceId}`)
          .then(response => {
            console.log('is_scanned updated successfully:', response.data);

            // Clear the input field
            this.is_scanned = '';

            // Refresh the order data (optional)
            this.orderLoad();

            resolve(); // Resolve the promise
          })
          .catch(error => {
            console.error('Error updating is_scanned:', error);
            reject(error); // Reject the promise with the error
          });
      });
    },

  },
};

</script>
          
<style scoped></style>