<template>
    <div class="page">

        <p style="margin-top:0;margin-bottom:0;color:#484848;text-align:right;">
            <svg ref="barcode"></svg>
        </p>


    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

import JsBarcode from 'jsbarcode';

export default {
    name: 'ExportInvoice',

    data() {
        return {
            cart: [],
            logos: [],
            invoices: [],
            invo: [],
            bundles: [],
            currentDate: '', // Initialize with an empty string

        };
    },

    computed: {

        totalPrice() {
            let total = 0;

            this.invo.forEach((inv) => {
                if (inv.is_simple_discount == 1) {
                    total += this.calculateSimpleDiscountTotal(inv);
                } else if (inv.is_sale == 1) {
                    total += this.calculateSaleTotal(inv);
                } else {
                    total += this.calculateSimpleTotal(inv);
                }
            });

            return total;
        },
    },
    watch: {
        'invoices.invoice_barcode': 'generateBarcode',
    },
    methods: {

        generateBarcode() {
            const barcodeData = this.invoices.invoice_barcode;
            const svg = this.$refs.barcode;
            svg.innerHTML = '';

            JsBarcode(svg, barcodeData, {
                format: 'CODE39',
                width: 1,
                height: 50,
            });
        },

        calculateSimpleDiscountTotal(inv) {
            if (inv.price_after_discount !== null) {
                return inv.price_after_discount;
            } else if (inv.user_simple_discount > 0) {
                return inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.user_simple_discount / 100);
            } else {
                return inv.sale_price * inv.quantity;
            }
        },

        calculateSaleTotal(inv) {
            if (inv.price_after_discount !== null) {
                return inv.price_after_discount;
            } else if (inv.cart_discount > 0) {
                return inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.cart_discount / 100);
            } else {
                return inv.sale_price * inv.quantity;
            }
        },

        calculateSimpleTotal(inv) {
            if (inv.price_after_discount !== null) {
                return inv.price_after_discount;
            } else {
                // Define your logic for calculateSimpleTotal here
                return inv.sale_price * inv.quantity;
            }
        },

        beforeDisc() {
            let beforeDiscount = 0;

            this.invo.forEach((inv) => {
                beforeDiscount += inv.sale_price * inv.quantity;
            });

            return beforeDiscount;
        },

        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            this.currentDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
        },
        orderLoad() {
            var page = `api/invoice/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.cart = data.cart;
                this.logos = data.logo;
                this.invoices = data.invoice;
                this.invo = data.inv;
                this.bundles = data.bundle;
            });
        },
    },
    created() {
        this.orderLoad();
    },
    mounted() {
        this.getCurrentDate();
        this.generateBarcode();

        // Use setInterval to update the date and time every second
        setInterval(this.getCurrentDate, 1000);
    },

};
</script>

<style scoped>
.barcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.barcode-text {
    margin: 0;
    /* Remove margin */
    padding: 0;
    /* Remove padding */
}


@media print {
    .wtsp {
        display: none !important;
    }

    .page {
        page-break-after: avoid;
    }
}

.wtsp {
    display: none !important;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/****** EMAIL CLIENT BUG FIXES - BEST NOT TO CHANGE THESE ********/

.ExternalClass {
    width: 100%;
}

/* Forces Outlook.com to display emails at full width */
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

/* Forces Outlook.com to display normal line spacing, here is more on that*/

template {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevents Webkit and Windows Mobile platforms from changing default font sizes. */

template {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

/* Resets all body margins and padding to 0 for good measure */

table td {
    border-collapse: collapse;
    border-spacing: 0px;
    border: 0px none;
    vertical-align: middle;
    width: auto;
}

/*This resolves the Outlook 07, 10, and Gmail td padding issue. Heres more info */
/****** END BUG FIXES ********/
/****** RESETTING DEFAULTS, IT IS BEST TO OVERWRITE THESE STYLES INLINE ********/

.table-mobile {
    width: 600px;
    margin: 0 auto;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.table-mobile-small {
    width: 560px !important;
}

body,
p {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: #222222;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

a {
    color: #2e73bb;
    text-decoration: none;
    display: inline-block;
}

img {
    border: 0 none;
    display: block;
}

@media(min-width:480px) {
    .product-header td {
        padding: 25px;
    }

    .product-image,
    .product-title,
    .product-price {
        border-bottom: 1px solid #eeeeee;
    }

    .product-title {
        padding-left: 15px;
    }

    .product-price {
        padding-right: 25px;
    }
}

@media (max-width:479px) {
    body {
        padding-left: 10px;
        padding-right: 10px;
    }

    table,
    table>tbody,
    table>tr,
    table>tbody>tr,
    table>tr>td,
    table>tbody>tr>td {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        /*height: auto !important;*/
    }

    .table-mobile-small {
        width: 95% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        height: auto !important;
    }

    .full-image {
        width: 100% !important;
    }

    .footer-content p {
        text-align: left !important;
    }

    .product-title,
    .product-price {
        width: 50% !important;
        float: left;
        border-bottom: 1px solid #eeeeee;
    }

    .product-image,
    .product-title,
    .product-price {
        padding: 10px;
    }

    .product-image img {
        width: 100% !important;
    }

    .product-price p {
        text-align: right !important;
    }

    .product-header {
        display: none !important;
    }

    .header-item {
        display: table-cell !important;
        float: none !important;
        width: 50% !important;
    }

    .table-mobile {
        box-shadow: none !important;
        margin: 0;
        border-radius: 0;
    }
}

.bundle-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.bundle-header {
    font-weight: bold;
}

.product-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.product-table th,
.product-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.product-table th {
    background-color: #f2f2f2;
}
</style>