<template>
    <table width="100%" cellpadding="0" cellspacing="0" style="page-break-inside: avoid;">

        <tr>
            <td style="background-color:#dddddd">

                <!-- ========= Table content ========= -->


                <table cellpadding="0" cellspacing="0" width="600" class="table-mobile" align="center">
                    <tr>
                        <td height="25"></td>
                    </tr>

                    <!-- ========= Header ========= -->

                    <tr>
                        <td>

                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center">
                                <tr>
                                    <td class="header-item">
                                        <img :src="'https://api.gocami.com/logo_image/' + logos.logo" alt="" />
                                    </td>
                                    <td class="header-item">
                                        <p
                                            style="font-family:sans-serif;font-size:20px;font-weight:bold;text-transform:uppercase;margin-top:0;margin-bottom:0;color:#484848;text-align:right;">
                                            Invoice
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Divider ========= -->

                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" width="100%" align="center"
                                style="page-break-inside: avoid;">
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #f8f8f8;" height="1"></td>
                                </tr>
                            </table>

                        </td>
                    </tr>

                    <tr>
                        <td
                            style="background:#ffffff;padding:35px 0;border-top:1px solid #eeeeee;border-bottom:1px solid #eeeeee; page-break-inside: avoid !important;">
                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center"
                                style="page-break-inside: avoid !important;">
                                <tr>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Shipping info</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Name:</strong> {{ invoices.first_name }} {{
                                                            invoices.middle_name }} {{ invoices.last_name }}
                                                    </p>

                                                    <p v-if="invoices.affiliate_first_name && invoices.affiliate_last_name"
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Administrator Name:</strong> {{
                                                            invoices.affiliate_first_name
                                                        }} {{ invoices.affiliate_last_name }}
                                                    </p>

                                                    <p v-if="invoices.user_first_name && invoices.user_middle_name && invoices.user_last_name"
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>To:</strong> {{
                                                            invoices.user_first_name
                                                        }} {{ invoices.user_middle_name }} {{ invoices.user_last_name }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Phone:</strong> (+961) {{ invoices.phone }}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Location:</strong> {{ invoices.governorates_name }} {{
                                                            invoices.city_name }} {{ invoices.area_name }}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr class="break-before">
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Address:</strong> {{ invoices.address }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order details</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order no: INV00</strong>{{ invoices.id }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order Date :</strong> {{ invoices.pending_date }}
                                                    </p>
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Printing date :</strong> {{ currentDate }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Booking details ========= -->

                    <div v-for="(carts, bundleId) in bundles" :key="bundleId" class="bundle-container">
                        <div class="bundle-header">
                            Bundle Name: <b>{{ carts[0].bundle.bundle_name }}</b>
                            <br>
                            Bundle Sku: <b>{{ carts[0].bundle.bundle_sku }}</b>
                            <br>
                            Bundle Price: <b>{{ carts[0].bundle.bundle_price.toFixed(2) }}</b>
                        </div>

                        <div>
                            <table class="product-table">
                                <tr>
                                    <th>Product Sku</th>
                                    <th>Product Title</th>
                                    <th>Quantity</th>
                                </tr>
                                <tr v-for="cart in carts" :key="cart.id">
                                    <td>{{ cart.productdetail.sku }}</td>
                                    <td>{{ cart.productdetail.title }}</td>
                                    <td>{{ cart.quantity }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>



                    <tr>
                        <td style="background-color:#ffffff;">

                            <table cellpadding="0" cellspacing="0" width="100%" align="center">

                                <tbody>

                                    <!----------- product table header ----------->

                                    <tr class="product-header">
                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Title
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Sku
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Configuration
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Quantity
                                            </p>
                                        </td>

                                    </tr>

                                    <!--product-->

                                    <tr class="order-mobile" v-for="carts in cart" :key="carts.id">
                                      <td class="product-kleb" style="border-bottom:1px solid black">
                                        <div class="title">{{ carts.title }}</div>
                                        <div class="sku">{{ carts.sku }}</div>
                                        <div class="attributes" v-if="carts.attribute_type_id !== null || carts.color_id !== null || carts.size_id !== null">
                                          <span>{{ carts.attribute_type_name }} | {{ carts.color_name }} | {{ carts.size_name }}</span>
                                        </div>
                                        <div class="quantity">
                                          <p>{{ carts.quantity }}</p>
                                        </div>
                                      </td>

                                      <hr class="custom-hr">
                                    </tr>
                                </tbody>


                                <div style="display:flex; padding-top: 50px; padding-left:25px;min-width:500px">
                                    Note:
                                    <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">{{ invoices.note }}</span>
                                    </p>
                                </div>


                                <div v-if="invoices.pick_up === 1" style="display:flex;padding-left:25px;min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">By Delivery</span>
                                    </p>
                                </div>
                                <div v-else-if="invoices.pick_up === 0"
                                    style="display:flex;padding-left:25px; min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">From Office</span>
                                    </p>

                                </div>

                                <div v-else style="display:flex;padding-left:25px; min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">ملحق</span>
                                    </p>

                                </div>

                                <div style="padding-left: 25px; margin-top: 10px;">Total Quantity: <b>{{
                                    invoices.total_quantity }}</b></div>

                                <tr align="right">
                                    <td align="right" colspan="4">
                                        <p v-if="invoices.pick_up == 1"
                                            style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:right;">
                                            $ {{ (totalPrice + invoices.price).toFixed(3) }}
                                        </p>
                                        <p v-else
                                            style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:right;">
                                            $ {{ (totalPrice).toFixed(3) }}
                                        </p>
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';


export default {
    data() {
        return {
            cart: [],
            logos: [],
            invoices: [],
            invo: [],
            bundles: [],
            currentDate: '', // Initialize with an empty string

        }
    },
    mounted() {
        this.getCurrentDate();

        // Use setInterval to update the date and time every second
        setInterval(this.getCurrentDate, 1000);
    },
    methods: {
        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            this.currentDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
        },

        orderLoad() {
            var page = `api/invoice/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.cart = data.cart;
                this.logos = data.logo;
                this.invoices = data.invoice;
                this.invo = data.inv;
                this.bundles = data.bundle;
            });
        },
    },
    created() {
        this.orderLoad();
    },

    computed: {
        totalPrice() {
            let total = 0;

            this.invo.forEach((inv) => {
                if (inv.price_after_discount !== null) {
                    total += inv.price_after_discount;
                } else {
                    total += inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.cart_discount / 100);
                }
            });

            return total;
        },
    }
}
</script>

<style scoped>

@media print {}

@media print {
    .wtsp {
        display: none !important;
    }

    .page {
        page-break-after: avoid;
    }


}

.wtsp {
    display: none !important;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/****** EMAIL CLIENT BUG FIXES - BEST NOT TO CHANGE THESE ********/

.ExternalClass {
    width: 100%;
}

/* Forces Outlook.com to display emails at full width */
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

/* Forces Outlook.com to display normal line spacing, here is more on that*/

template {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevents Webkit and Windows Mobile platforms from changing default font sizes. */

template {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

/* Resets all body margins and padding to 0 for good measure */

table td {
    border-collapse: collapse;
    border-spacing: 0px;
    border: 0px none;
    vertical-align: middle;
    width: auto;
}

/*This resolves the Outlook 07, 10, and Gmail td padding issue. Heres more info */
/****** END BUG FIXES ********/
/****** RESETTING DEFAULTS, IT IS BEST TO OVERWRITE THESE STYLES INLINE ********/

.table-mobile {
    width: 600px;
    margin: 0 auto;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.table-mobile-small {
    width: 560px !important;
}

body,
p {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: #222222;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

a {
    color: #2e73bb;
    text-decoration: none;
    display: inline-block;
}

img {
    border: 0 none;
    display: block;
}

@media(max-width: 768px){
  .product-kleb {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .product-kleb .title,
  .product-kleb .sku,
  .product-kleb .attributes,
  .product-kleb .quantity {
    flex: 1 !important;
    padding: 5px !important; /* Adjust padding as needed */
  }

  hr.custom-hr {
    border: none !important;
    height: 3px !important;
    background-color: red !important; /* Change the color as needed */
    margin: 10px 0 !important; /* Adjust the margin as needed */
  }

}

@media(min-width:480px) {
    .product-header td {
        padding: 25px;
    }

    .product-image,
    .product-title,
    .product-price {
        border-bottom: 1px solid #eeeeee;
    }

    .product-title {
        padding-left: 15px;
    }

    .product-price {
        padding-right: 25px;
    }
}

@media (max-width:479px) {
    body {
        padding-left: 10px;
        padding-right: 10px;
    }

    table,
    table>tbody,
    table>tr,
    table>tbody>tr,
    table>tr>td,
    table>tbody>tr>td {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        /*height: auto !important;*/
    }

    .table-mobile-small {
        width: 95% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        height: auto !important;
    }

    .full-image {
        width: 100% !important;
    }

    .footer-content p {
        text-align: left !important;
    }

    .product-title,
    .product-price {
        width: 50% !important;
        float: left;
        border-bottom: 1px solid #eeeeee;
    }

    .product-image,
    .product-title,
    .product-price {
        padding: 10px;
    }

    .product-image img {
        width: 100% !important;
    }

    .product-price p {
        text-align: right !important;
    }

    .product-header {
        display: none !important;
    }

    .header-item {
        display: table-cell !important;
        float: none !important;
        width: 50% !important;
    }

    .table-mobile {
        box-shadow: none !important;
        margin: 0;
        border-radius: 0;
    }
}

.bundle-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.bundle-header {
    font-weight: bold;
}

.product-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.product-table th,
.product-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.product-table th {
    background-color: #f2f2f2;
}
</style>