<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Affiliate Panel</h3>

          <router-link to="/create-affiliate-user" class="btn btn-primary">Create User/Affiliate</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Sub Affiliate Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="subaffiliate in subaffiliates" v-bind:key="subaffiliate.id">
                <th scope="row">{{ subaffiliate.id }}</th>
                <td>{{ subaffiliate.affiliate.first_name }}</td>
                <td>
                  <router-link :to="{ name: 'EditAffiliateUser', params: { id: subaffiliate.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(subaffiliate)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
  name: "AffiliateUser",
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },
  data() {
    return {
      subaffiliates: [],
    };
  },

  created() {
    this.userLoad();
  },

  methods: {
    userLoad() {
      var page = `api/affiliate?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.subaffiliates = data.subaffiliate || [];
      });
    },

    remove(subaffiliate) {
      var url = `api/affiliate-user-delete/${subaffiliate.user_affiliate_id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.subaffiliates.indexOf(subaffiliate);
        if (index !== -1) {
          this.subaffiliates.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  font-size: 12pt !important;
}
</style>
