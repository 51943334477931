<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div>
            <label for="from">From:</label>
            <input type="date" v-model="filterFrom" @change="applyDateFilter" />
          </div>
          <div>
            <label for="to">To:</label>
            <input type="date" v-model="filterTo" @change="applyDateFilter" />
          </div>

          <table class="table w-100">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Total Sale Price</th>
                <th>Total After Discount</th> <!-- Display total after discount -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cart, index) in carts" :key="index">
                <td>{{ cart.first_name }} {{ cart.last_name }}</td>
                <td>$ {{ cart.total_sale_price.toFixed(2) }}</td>
                <td>$ {{ cart.total_after_discount.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      carts: [],
    };
  },

  created() {
    this.userLoad();
  },

  methods: {
    userLoad(startDate, endDate) {
      const page = `api/sub-invoices`;
      const params = {
        user_id: this.user?.id,
        from: startDate,
        to: endDate,
      };

      axiosInstance.get(page, { params }).then(({ data }) => {
        console.log(data);

        // Aggregate carts by user
        const userCarts = {};
        data.carts.forEach(cart => {
          const userId = cart.first_name + ' ' + cart.last_name;
          if (!userCarts[userId]) {
            userCarts[userId] = {
              first_name: cart.first_name,
              last_name: cart.last_name,
              total_sale_price: 0,
              total_after_discount: 0,
            };
          }

          userCarts[userId].total_sale_price += cart.total_sale_price;
          userCarts[userId].total_after_discount += cart.total_after_discount;
        });

        // Convert the aggregated data to an array
        this.carts = Object.values(userCarts);
      });
    },
    applyDateFilter() {
      if (this.filterFrom && this.filterTo) {
        const startDate = new Date(this.filterFrom);
        const endDate = new Date(this.filterTo);

        // Format the dates as 'YYYY-MM-DD' before sending
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = endDate.toISOString().split("T")[0];

        // Make the API request with formatted dates
        this.userLoad(formattedStartDate, formattedEndDate);
      }
    },


  },
};
</script>
