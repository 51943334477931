<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="tip.id" />

                        <div class="form-group">
                          <label for=""></label>
                          <input type="text" class="form-control" v-model="tip.brand_name">
                        </div>

                        <div class="form-group">
                          <label for=""></label>
                          <textarea class="form-control" v-model="tip.tips_text" rows="3"></textarea>
                        </div>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditTip",

    data() {
        return {
            tip: {
                tips_text: "",
                brand_name: "",
                id: this.$route.params.id,
            },
            oldTip: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axiosInstance
                .put(
                    `api/update-tips/${this.tip.id}`, // Use the BASE_URL constant for the base URL
                    this.tip
                )
                .then(() => {
                    this.tip = {
                        tips_text: "",
                        brand_name: "",
                        id: "",
                    };
                    router.push("/tips-view");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axiosInstance
                .get(`api/edit-tips/` + this.tip.id)
                .then((response) => {
                    this.oldTip = response.data; // store the old employee data in oldEmployee property
                    this.tip.tips_text = this.oldTip.tips_text; // set the old employee_name value to the input
                    this.tip.brand_name = this.oldTip.brand_name; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>