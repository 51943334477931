<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Brands Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <div v-if="errorMessage" class="error-message text-danger">
                                {{ errorMessage }}
                            </div>
                            <tr>
                                <th>#</th>
                                <th>Brand Name</th>
                                <th>Target</th>
                                <th>Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productconf in productconfs" :key="productconf.id">
                                <th scope="row">{{ productconf.id }}</th>
                                <td>{{ productconf.brand_name }}</td>
                                <td v-if="productconf.is_target === 1" class="text-danger">No Target</td>
                                <td v-else class="text-success">Target</td>
                                <td>% {{ productconf.percent }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            productconfs: [],
            errorMessage: null,

        };
    },
    created() {
        this.loadProductConfigs();
    },
    methods: {
        loadProductConfigs() {
            var page = `api/aff-brand`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.productconfs = data;
            });
        },
    },
};
</script>
