<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Sub Category Panel</h3>

          <router-link to="/create-subcategory" class="btn btn-primary">Create Sub Category</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Sub Category Name</th>
                <th>Category Name</th>
                <th>Sub Category Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subcategories in subcategory" v-bind:key="subcategories.id">
                <th scope="row">{{ subcategories.id }}</th>
                <td>{{ subcategories.sub_category_name }}</td>
                <td>{{ subcategories.category.category_name }}</td>
                <td><img :src="'https://api.gocami.com/sub_category_image/' + subcategories.sub_category_image"></td>
                <td>
                  <router-link :to="{ name: 'EditSubCategory', params: { id: subcategories.id } }" class="btn btn-warning me-2"><i
                      class="fa fa-pencil"></i></router-link>

                  <button @click="remove(subcategories)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "SubCategoryView",

  data() {
    return {
      subcategory: [],
    };
  },

  created() {
    this.subcategoryLoad();
  },

  methods: {
    subcategoryLoad() {
      var page = `api/subcategory`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.subcategory = data;
      });
    },

    remove(subcategories) {
      var url = `api/subcategory-delete/${subcategories.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.subcategory.indexOf(subcategories);
        this.subcategory.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>