<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="affiliateuserdiscount.affiliate_discount_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="affiliatediscount in affiliatediscounts" :key="affiliatediscount.id" :value="affiliatediscount.id">
                                {{ affiliatediscount.discount.discount_name }}
                            </option>
                        </select>

                        <select v-model="affiliateuserdiscount.user_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose User --</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">
                                {{ user.first_name }} {{ user.last_name }}
                            </option>
                        </select>

                        <input type="text" class="form-control mb-2" v-model="affiliateuserdiscount.commission" placeholder="Commision">

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>

import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateAffiliateUserDiscount",

    data() {
        return {
            affiliateuserdiscount: {
                affiliate_discount_id: 0,
                user_id: 0,
                commission: '',
            },
            affiliatediscounts: [],
            users: [],
        };
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('user_id', this.affiliateuserdiscount.user_id);
            formData.append('affiliate_discount_id', this.affiliateuserdiscount.affiliate_discount_id);
            formData.append('commission', this.affiliateuserdiscount.commission);
            axiosInstance.post(`api/affiliate-user-discount-store`, formData, {
            }).then(() => {
                // Redirect to the desired page
                router.push("/affiliate-user-discount");
            });
        },

        fetchAffiliateUserDiscount() {
            axiosInstance
                .get(`api/affiliate-user-discount-create`)
                .then(response => {
                    this.users = response.data.user;
                    this.affiliatediscounts = response.data.affiliatediscount;

                });
        },
    },
    mounted() {
        this.fetchAffiliateUserDiscount();
    },
};
</script>
