<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Last Name</th>
                                <th>Location</th>
                                <th>Address</th>
                                <th>Note</th>
                                <!-- <th>Before Affiliate Discount</th>
                                <th>Total</th>
                                <th>Revenue</th> -->
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in orders" :key="order.id">
                                <th scope="row">{{ order.id }}</th>
                                <td>{{ order.first_name }}</td>
                                <td>{{ order.middle_name }}</td>
                                <td>{{ order.last_name }}</td>
                                <td>{{ order.area_name }} {{ order.city_name }} {{ order.governorates_name }}</td>
                                <td>{{ order.address }}</td>
                                <td>{{ order.note }}</td>
                                <!-- <td class="text-center">$ {{ order.price.toFixed(2) }}</td>
                                <td>$ {{ order.total.toFixed(2) }}</td>
                                <td v-if="order.percentage === order.affiliate_percentage && order.percentage == null && order.affiliate_percentage == null ">$ 0</td>
                                <td v-else>$ {{ order.total.toFixed(2) }}</td> -->
                                <td>
                                    <span v-if="order.action === 1" style="color: green;">Accepted</span>
                                    <span v-else-if="order.action === 0" style="color: red;">Not Accepted</span>
                                </td>
                                <td>
                                    <router-link :to="{ name: 'AffiliateOrderDetails', params: { id: order.id } }"
                                        class="btn btn-warning me-2">
                                        <i class="fa fa-eye"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
        
<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

export default {
    name: "AffiliateRevenue",
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },
    data() {
        return {
            orders: [],
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        userLoad() {
            var page = `api/affiliate-revenue?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.orders = data.order;
            });
        },

    },
};

</script>
        
<style scoped>
* {
    font-size: 12pt !important;
}
</style>