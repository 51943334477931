<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Product name"
                            v-model="product.product_name" required /><br /><br />

                        <!-- <input type="text" class="form-control" placeholder="Create description"
                            v-model="product.description" /><br /><br />

                        <input type="number" class="form-control" placeholder="Create Price"
                            v-model="product.price" required /><br /><br />

                        <input type="number" class="form-control" placeholder="Create discount"
                            v-model="product.discount" required /><br /><br />

                            <label>Select category</label>

                        <select v-model="product.category_id" class="form-select mb-3" required>
                            <option value="0" selected disabled hidden>-- Choose Category --</option>
                            <option v-for="category in category" :key="category.id" :value="category.id">
                                {{ category.category_name }}
                            </option>
                        </select>

                        <label>Select Brand</label>
                        <select v-model="product.brand_id" class="form-select mb-3" required>
                            <option value="0" selected disabled hidden>-- Choose brand --</option>
                            <option v-for="brand in brand" :key="brand.id" :value="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" required />

                        <progress v-if="product.progress" :value="product.progress.percentage" max="100">
                            {{ product.progress.percentage }}%
                        </progress> -->

                        <button type="submit" class="btn btn-primary">Create Product</button>
                    </form>

                    <!-- Error messages -->
                    <div v-if="errors.length" class="mt-3 text-danger">
                        <ul>
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {

    data() {
        return {
            category: '',
            brand: '',
            product: {
                product_name: "",
                // image: "",
                // description: "",
                // price: "",
                // discount:"",
                // category_id:"",
                // brand_id:""
            },
            errors: []
        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        onFileChange(event) {
            this.product.image = event.target.files[0];
        },

        save() {
            // Clear previous errors
            this.errors = [];
            // Validate form
            if (!this.validateForm()) {
                return;
            }
            // If form is valid, save data
            this.saveData();
        },

        validateForm() {
            let isValid = true;
            if (!this.product.product_name) {
                this.errors.push("Product name is required");
                isValid = false;
            }
            // if (!this.product.description) {
            //     this.errors.push("Description is required");
            //     isValid = false;
            // }
            // if (!this.product.price) {
            //     this.errors.push("Price is required");
            //     isValid = false;
            // }
            // if (!this.product.discount) {
            //     this.errors.push("Discount is required");
            //     isValid = false;
            // }
            // if (!this.product.category_id) {
            //     this.errors.push("Category is required");
            //     isValid = false;
            // }
            // if (!this.product.brand_id) {
            //     this.errors.push("Brand is required");
            //     isValid = false;
            // }
            // if (!this.product.image) {
            //     this.errors.push("Image is required");
            //     isValid = false;
            // }
            return isValid;
        },

        saveData() {
            let formData = new FormData();
            formData.append('product_name', this.product.product_name);
            formData.append('image', this.product.image);
            formData.append('description', this.product.description);
            formData.append('price', this.product.price);
            formData.append('discount', this.product.discount);
            formData.append('brand_id', this.product.brand_id);
            formData.append('category_id', this.product.category_id);
            axiosInstance.post(`api/insert-main-product`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/prod-admin");
            });
        },

        fetchAllData() {
            axiosInstance
                .get(`api/get-main-data`) // Update endpoint to fetch employees
                .then(response => {
                    this.brand = response.data.brand;
                    this.category = response.data.category;
                });
        },

    },
};
</script>
