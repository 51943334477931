<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Pending Products</h3>
                    <button @click="toggleStatusForCurrentProduct">Toggle Status for Current Product</button>

                    <!-- <router-link to="/create-brand" class="btn btn-primary">Create Brand</router-link> -->

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product title</th>
                                <th>Product sku</th>
                                <th>Product Price</th>
                                <th>Product Discount</th>
                                <th>Category Weight</th>
                                <th>Product Barcode</th>
                                <th>Brand Name</th>
                                <th>Expiry Date</th>
                                <th>Description</th>
                                <th>Details</th>
                                <th>Color</th>
                                <th>Size</th>
                                <th>Attribute</th>
                                <th>Quantity</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="productDetail in productDetail" :key="productDetail.id">
                                <th scope="row">{{ productDetail.id }}</th>
                                <td>{{ productDetail.title }}</td>
                                <td>{{ productDetail.sku }}</td>
                                <td>{{ productDetail.sale_price }}$</td>
                                <td>{{ productDetail.discount }}%</td>
                                <td>{{ productDetail.weight }}</td>
                                <td>{{ productDetail.barcode }}</td>
                                <td>{{ productDetail.brand_name }}</td>
                                <td>{{ productDetail.expiry_date }}</td>
                                <td>{{ productDetail.description }}</td>
                                <td>{{ productDetail.details }}</td>
                                <td>{{ productDetail.color_name }}</td>
                                <td>{{ productDetail.size_name }}</td>
                                <td>{{ productDetail.attribute_type_name }}</td>
                                <td>{{ productDetail.quantity }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + productDetail.image"
                                        width="100" /></td>
                                <td>
                                    <button @click="toggleStatusForCurrentProductDetail(productDetail.id)">Update
                                        Status</button>


                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
    name: "BrandView",

    data() {
        return {
            productDetail: [],
            currentProductId: null, // Store the ID of the product in the URL

        };
    },

    created() {
        this.brandLoad();
        this.currentProductId = this.$route.params.id;

    },

    methods: {

        toggleStatusForCurrentProductDetail(productId) {
            axiosInstance.put(`api/update-product-status/${productId}`)
                .then(response => {
                    console.log(response.data.message);
                    this.brandLoad(); // Reload the list of products after status update
                })
                .catch(error => {
                    console.error("Error:", error);
                });
        },

        toggleStatusForCurrentProduct() {
            if (this.currentProductId) {
                axiosInstance.put(`api/products/${this.currentProductId}/status`)
                    .then(response => {
                        console.log(response.data.message);
                        // Optionally, update the UI or perform additional actions after successful update
                        this.brandLoad(); // Reload the list of products after status update
                    })
                    .catch(error => {
                        console.error("Error:", error);
                    });
            } else {
                console.error("No product ID found in the URL");
            }
        },

        brandLoad() {
            var page = `api/get-pending-products-details/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.productDetail = data.productDetail;
            });
        },

    },
};

</script>
      
<style scoped></style>