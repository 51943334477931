<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Refunded Products</h3>

                    <div class="card">
                        <div class="card-body">
                            <h3 class="mb-5">Orders Panel</h3>

                            <table class="responsive table-responsive table w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Name</th>
                                        <th>Category Name</th>
                                        <th>Brand Name</th>
                                        <th>Description</th>
                                        <th>Price</th>
                                        <th>Image</th>
                                        <th>Action</th> <!-- Add Action header -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, index) in allOrder" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <td>{{ order.product_name }}</td>
                                        <td>{{ order.category_name }}</td>
                                        <td>{{ order.brand_name }}</td>
                                        <td>
                                            <p v-html="order.description"></p>
                                        </td>
                                        <td v-if="order.price">{{ order.price.toFixed(2) }}</td>
                                        <td>
                                            <img :src="'https://api.gocami.com/product_main/' + order.image"
                                                width="100" />
                                        </td>
                                        <td>
                                            <router-link
                                                :to="{ name: 'SuperAdminDisabledProductDetail', params: { id: order.id } }"
                                                class="btn btn-success">
                                                View
                                            </router-link>
                                        </td>
                                        <td>
                                            <button @click="changeStatus(order.id)" class="btn btn-primary">Change
                                                Status</button>
                                        </td> <!-- Add a button for changing status -->
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../axiosInstance';

export default {

    data() {
        return {
            allOrder: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        changeStatus(productId) {
            // Make an HTTP request to change the status
            // Example using Axios
            axiosInstance.post(`api/products/${productId}/change-status`)
                .then(response => {
                    console.log(response.data); // Handle success response
                    // Optionally, update the product status in the UI if needed
                    this.orderLoad(); // Load updated data after changing status
                })
                .catch(error => {
                    console.error(error); // Handle error
                });
        },

        orderLoad() {
            var endpoint = `api/super-admin-disabled-product`;
            axiosInstance.get(endpoint).then(({ data }) => {
                console.log(data);
                this.allOrder = data.product;
            });
        },
    },

};
</script>