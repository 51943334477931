<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Product Category Panel</h3>
  
            <router-link to="/create-prodcat" class="btn btn-primary">Create Product Category</router-link>
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Title</th>
                  <th>Child Category Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="productcats in productcat" v-bind:key="productcats.id">
                  <th scope="row">{{ productcats.id }}</th>
                  <td>{{ productcats.productdetail.title }}</td>
                  <td>{{ productcats.childcategory.child_category_name }}</td>
                  <td>
                    <router-link :to="{ name: 'EditProdCat', params: { id: productcats.id } }"
                      class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>
  
                    <button @click="remove(productcats)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>

import axiosInstance from '../../../../../axiosInstance';

  
  export default {
    name: "ProdCatView",
  
    data() {
      return {
        productcat: [],
      };
    },
  
    created() {
      this.productLoad();
    },
  
    methods: {
      productLoad() {
        var page = `api/prodcat`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.productcat = data;
        });
      },
  
      remove(productcats) {
        var url = `api/prodcat-delete/${productcats.id}`;
        axiosInstance.delete(url).then(() => {
          const index = this.productcat.indexOf(productcats);
          this.productcat.splice(index, 1);
        });
      },
    },
  };
  
  </script>
      
  <style scoped></style>