<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.product_name }}
                        </option>
                    </select>

                    <h3 class="mb-5">Product Detail</h3>

                    <button @click="updateCheckedItems" class="btn btn-primary mb-3">
                        Update Checked Items
                    </button>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Color Name</th>
                                <th>Size Name</th>
                                <th>Attribute Type Name</th>
                                <th>Position</th>
                                <th>Select</th> <!-- Added -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75" />
                                </td>
                                <td>{{ product.color_name }}</td>
                                <td>{{ product.size_name }}</td>
                                <td>{{ product.attribute_type_name }}</td>
                                <td><input type="text" class="form-control" v-model="product.position" /></td>
                                <td>
                                    <input type="checkbox" class="form-check-input" v-model="product.checked" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            selectedBrand: null,
            productDetail: [],
            product: {
                position: '',
            },
            brands: [],

            products: [],
            childcategory: [],
            file: null

        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.fetchAllData(this.selectedBrand);
            }
        },

        fetchAllData(brandId) {
            return axiosInstance
                .get(`api/get-product-conf/${brandId}`)
                .then(response => {
                    this.productDetail = response.data.productConf;
                    this.brands = response.data.brands;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-product-conf-position/${product.id}`, {
                    position: product.position,
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData(this.selectedBrand);
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },

        updateCheckedItems() {
            const checkedProducts = this.productDetail.filter(product => product.checked);
            if (checkedProducts.length === 0) {
                alert("Please select at least one item to update.");
                return;
            }

            checkedProducts.forEach(product => {
                // Update the checked products
                this.updateDescription(product);
            });
        },

    },

}
</script>
