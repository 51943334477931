<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="responsive table-responsive table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Product Sku</th>
                                <th>Price</th>
                                <th>Sold Quantity</th>
                                <th>Current quantity</th>
                                <th>the rest Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in allOrder" :key="order.id" class="flex">
                                <th scope="row">{{ order.id }}</th>
                                <td>{{ order.title }}</td>
                                <td>{{ order.sku }}</td>
                                <td>$ {{ order.sale_price.toFixed(2) }}</td>
                                <td>{{ order.quantity }}</td>
                                <td>{{ order.final_quantity }}</td>
                                <td><input type="number" v-model="editedFinalQuantity[order.id]"></td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-3">Total: $ {{ calculateTotal }}</div>

                    <button class="btn btn-success mt-2" @click="updateStock">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../axiosInstance';

export default {
    name: "CartInvoice",

    data() {
        return {
            allOrder: [],
            editedFinalQuantity: {}, // Add this property

        };
    },

    created() {
        this.orderLoad();
    },

    computed: {
        calculateTotal() {
            return this.allOrder.reduce((total, order) => {
                const finalQuantity = this.editedFinalQuantity[order.id] || order.final_quantity;
                const percentage = order.percentage || 0; // Assume 0 if percentage is not defined

                const subtotal = order.sale_price * (order.quantity - finalQuantity);
                const discount = (subtotal * percentage) / 100;
                total += subtotal - discount;

                return total;
            }, 0).toFixed(2);
        },
    },

    methods: {
        orderLoad() {
            var endpoint = `api/stock-invoices-products/${this.$route.params.id}`;
            axiosInstance.get(endpoint).then(({ data }) => {
                console.log(data);
                this.allOrder = data;
            });
        },

        updateStock() {
            const stockUpdates = this.allOrder.map(order => {
                return {
                    cart_id: order.id,
                    initial_quantity: order.quantity,
                    final_quantity: this.editedFinalQuantity[order.id] || order.final_quantity, // Use edited value if available
                };
            });

            console.log('Stock Updates:', stockUpdates);

            const endpoint = `api/update-stocks`;
            axiosInstance.post(endpoint, { stockUpdates }).then(() => {
                // console.log('Stocks updated successfully:', response.data);
                this.orderLoad();
                this.editedFinalQuantity = {}; // Reset editedFinalQuantity
            }).catch(error => {
                console.error('Error updating stocks:', error);
            });
        },
    },
};
</script>
  