<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Orders Panel</h3>

          <div class="row">
            <div class="col-6">
              <table class="table w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Address</th>
                    <th>Note</th>
                    <th>Date</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="allOrders in allOrder" :key="allOrders.id" draggable="true"
                    @dragstart="event => dragStart(event, allOrders, 'order')">

                    <th scope="row">{{ allOrders.id }}</th>
                    <td>{{ allOrders.first_name }} {{ allOrders.middle_name }} {{ allOrders.last_name }}</td>
                    <td>{{ allOrders.address }}</td>
                    <td>{{ allOrders.note }}</td>
                    <td>{{ new Date(allOrders.created_at).toLocaleString() }}</td>

                    <td style="display: flex;">
                      <router-link :to="{ name: 'SuperAdminExport', params: { id: allOrders.id } }"
                        class="btn btn-warning me-2" style="margin-right:10px"><i class="fa fa-eye"></i></router-link>

                      <form @submit.prevent="editData(allOrders)">
                        <input type="hidden" v-model="allOrders.id" />
                        <button type="submit" class="btn btn-success ml-4" style="width: 250px !important;">Update</button>
                      </form>
                      <!-- <router-link :to="{ name: 'ViewOrder', params: { id: allOrders.id } }" class="btn btn-warning me-2"><i
                        class="fa fa-eye"></i></router-link>
                    <router-link :to="{ name: 'ExportInvoice', params: { id: allOrders.id } }" class="btn btn-primary">invoice</router-link> -->
                      <!-- <button @click="remove(allOrders)" class="btn btn-danger"><i class="fa fa-trash"></i></button> -->

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-6" @dragover.prevent="allowDrop" @drop="event => dropException(event, yourExceptionId)">
              <table class="table w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>User Name</th>
                    <th>Address</th>
                    <!-- <th>Location</th> -->
                    <th>Note</th>
                    <th>Date</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="exception in exceptions" :key="exception.id" draggable="true"
                    @dragstart="event => dragStart(event, exception, 'exception')">
                    <th scope="row">{{ exception.invoice_id }}</th>
                    <td>{{ exception.first_name }} {{ exception.middle_name }} {{ exception.last_name }}</td>
                    <td>{{ exception.address }}</td>
                    <!-- <td>{{ exception.area.area_name }}</td> -->
                    <td>{{ exception.note }}</td>
                    <td>{{ new Date(exception.created_at).toLocaleString() }}</td>

                    <td style="display: flex; border: none;">
                      <router-link :to="{ name: 'SuperAdminExport', params: { id: exception.invoice_id } }"
                        class="btn btn-warning me-2" style="margin-right:10px"><i class="fa fa-eye"></i></router-link>

                      <form @submit.prevent="editExceptionData(exception)">
                        <input type="hidden" v-model="exception.invoice_id" />
                        <button type="submit" class="btn btn-success">Update</button>
                      </form>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>
        
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
  name: "SuperAdminOrder",

  data() {
    return {
      allOrder: [],
      exceptions: [],
      draggedOrderId: null,
    };
  },

  created() {
    this.orderLoad();
    this.exceptionData();
  },

  methods: {
    editData(allOrders) {
      axiosInstance
        .post(
          `api/sub-admin-update-pending-order/${allOrders.id}`,
          allOrders
        )
        .then(() => {
          this.orderLoad();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editExceptionData(exception) {
      axiosInstance
        .post(
          `api/sub-admin-update-pending-order/${exception.invoice_id}`,
          exception
        )
        .then(() => {
          this.orderLoad();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    orderLoad() {
      var page = `api/sub-admin-orders`;
      axiosInstance.get(page).then(({ data }) => {
        console.log('Fetched orders:', data);
        this.allOrder = data;
      });
    },

    exceptionData() {
      var page = `api/exception-order`;
      axiosInstance.get(page).then(({ data }) => {
        console.log('Fetched exceptions:', data);

        // Remove duplicates based on the 'invoice_id' key
        const uniqueExceptions = this.removeDuplicates(data, 'invoice_id');

        this.exceptions = uniqueExceptions;
      });
    },


    removeDuplicates(array, key) {
      return array.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t[key] === item[key]
        ))
      );
    },

    dragStart(event, item, panel) {
      // Store the dragged order ID and panel in the local state
      this.draggedOrderId = item.id;
      this.draggedPanel = panel;
      // Prevent further propagation of the dragstart event
      event.stopPropagation();
    },
    allowDrop(event) {
      event.preventDefault();
    },

    dropException(event, exceptionId) {
      // Prevent the default drop behavior
      event.preventDefault();

      // Retrieve the dragged order ID and panel from the local state
      const orderId = this.draggedOrderId;
      const draggedPanel = this.draggedPanel;

      // Check if orderId is valid and the draggedPanel is "order"
      if (orderId !== null && draggedPanel === 'order') {
        // Make a POST request to your Laravel backend to handle the drop
        axiosInstance.post('api/drop-exception', { invoice_id: orderId, exceptionId })
          .then(response => {
            // Handle success
            this.orderLoad();
            this.exceptionData();
            console.log(response.data);
          })
          .catch(error => {
            // Handle error
            console.error(error);
          });

        // Reset the dragged order ID and panel in the local state
        this.draggedOrderId = null;
        this.draggedPanel = null;
      }
    },

    //   remove(colors) {
    //     var url = `${BASE_URL}color-delete/${colors.id}`;
    //     axios.delete(url).then(() => {
    //       const index = this.color.indexOf(colors);
    //       this.color.splice(index, 1);
    //     });
    //   },
  },
};

</script>
        
<style scoped></style>