<template>
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="card">
          <div class="card-body">
  
            <h3 class="mb-5">Pending Products</h3>
  
            <!-- <router-link to="/create-brand" class="btn btn-primary">Create Brand</router-link> -->
  
            <table class="table w-100">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                  <th>Product Price</th>
                  <th>Product Discount</th>
                  <th>Product Description</th>
                  <th>Category Name</th>
                  <th>Brand Name</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="products in products" v-bind:key="products.id">
                  <th scope="row">{{ products.id }}</th>
                  <td>{{ products.product_name }}</td>
                  <td>{{ products.price }}$</td>
                  <td>{{ products.discount }}%</td>
                  <td>{{ products.description }}</td>
                  <td>{{ products.category_name }}</td>
                  <td>{{ products.brand_name }}</td>
                  <td><img :src="'https://api.gocami.com/product_main/' + products.image" width="100"/></td>
                  <td>
                    <router-link :to="{ name: 'PendingProductDetail', params: { id: products.id } }" class="btn btn-warning me-2"><i
                        class="fa fa-eye"></i></router-link>
  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    </div>
  </template>
      
  <script>
  import axiosInstance from '../../../../../axiosInstance';
  
  
  export default {
    name: "BrandView",
  
    data() {
      return {
        products: [],
      };
    },
  
    created() {
      this.brandLoad();
    },
  
    methods: {
      brandLoad() {
        var page = `api/get-pending-products`;
        axiosInstance.get(page).then(({ data }) => {
          console.log(data);
          this.products = data.products;
        });
      },

    },
  };
  
  </script>
      
  <style scoped></style>