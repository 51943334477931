<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <form @submit.prevent="save">
            <input type="text" class="form-control" placeholder="Create Size"
                   v-model="size.size_name" /><br /><br />

            <button type="submit" class="btn btn-success">Create</button>
          </form>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
// import router from "@/router";

export default {
  name: "CreateSize",

  data() {
    return {
      size: {
        size_name: "",
      },
    };
  },

  methods: {
    save() {
      this.saveData();
    },
    saveData() {
      axiosInstance
          .post(`api/size-store`, this.size)
          .then(() => {
            // Redirect to the desired page
            this.clearForm();
          });
    },

    clearForm() {
      // Reset the color object to clear the form
      this.size.size_name = ""; // Reset the color name to empty
    },

  },
};
</script>