<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
                        {{ message }}
                    </div>


                    <h3 class="mb-5">Orders Panel</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Image</th>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Quantity</th>
                                <!-- <th>Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="viewOrders in vieworder" :key="viewOrders.id">
                                <th scope="row">{{ viewOrders.id }}</th>
                                <th scope="row"><img
                                        :src="'https://api.gocami.com/product_detail/' + viewOrders.productdetail.image"
                                        width="50" height="50" />
                                </th>
                                <th scope="row">{{ viewOrders.productdetail.sku }}</th>
                                <th scope="row">{{ viewOrders.productdetail.title }}</th>
                                <th scope="row">{{ viewOrders.quantity }}</th>
                                <th scope="row">
                                    <label class="custom-checkbox">
                                        <input type="checkbox" v-model="viewOrders.selected" />
                                        <span class="checkmark"></span>
                                    </label>
                                </th>
                            </tr>
                        </tbody>

                        <button @click="submitRefunds">Submit</button>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>
      
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            vieworder: [],
            message: '',
            isError: false,
            showSuccessMessage: false,
        };
    },

    created() {
        this.orderLoad();
    },

    beforeRouteEnter(to, from, next) {
        if (to.query.addToCart) {
            next(vm => {
                vm.showSuccessMessage = true;
                setTimeout(() => {
                    vm.showSuccessMessage = false;
                }, 5000);
            });
        } else {
            next();
        }
    },


    methods: {
        orderLoad() {
            var page = `api/view-order/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.vieworder = data.vieworder.map(item => ({
                    ...item,
                    selected: false // Add a 'selected' property to each item
                }));
            });
        },
        submitRefunds() {
            const selectedItems = this.vieworder.filter(item => item.selected);

            if (selectedItems.length === 0) {
                // No items selected, handle accordingly (show an error, etc.)
                return;
            }

            const refundData = {
                cart_id: selectedItems.map(item => item.id),
                quantity: selectedItems.map(item => item.quantity),
            };

            refundData.cart_id = Array.isArray(refundData.cart_id) ? refundData.cart_id : [refundData.cart_id];
            refundData.quantity = Array.isArray(refundData.quantity) ? refundData.quantity : [refundData.quantity];

            axiosInstance.post(`api/insert-order`, refundData)
                .then((response) => {
                    if (response.status === 200) {
                        this.showSuccessMessage = true;
                        this.message = "Product has been added to refunds successfully"; // Update success message
                        this.isError = false; // Reset isError to false (no error)
                    } else {
                        this.message = "Failed to add to refunds"; // Update error message
                        this.isError = true; // Set isError to true (error)
                        this.showSuccessMessage = true; // Show the toast message
                    }
                    this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                })
                .catch((error) => {
                    this.message = "Failed to add to refunds"; // Update error message
                    this.isError = true; // Set isError to true (error)
                    this.showSuccessMessage = true; // Show the toast message
                    console.error("Failed to add to refunds", error);
                    this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                });
        },
        hideMessageAfterDelay(delay) {
            setTimeout(() => {
                this.showSuccessMessage = false; // Hide the toast message after the specified delay
            }, delay);
        },
    },


};
</script>
<style scoped>
.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.custom-checkbox input:checked~.checkmark {
    background-color: #2196F3;
    /* Change to your desired color */
    border-color: #2196F3;
    /* Change to your desired color */
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.toast {
    position: fixed !important;
    max-width: 50% !important;
    top: 20px !important;
    right: -100% !important;
    /* Start offscreen on the right */
    background-color: #28a745 !important;
    color: #fff !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    transition: right 0.5s ease-in-out !important;
    z-index: 999999 !important;
}

.toast.error {
    position: fixed !important;
    max-width: 50% !important;
    top: 20px !important;
    right: -100% !important;
    /* Start offscreen on the right */
    background-color: #dc3545 !important;
    /* Background color for error */
    color: #fff !important;
    padding: 12px 16px !important;
    border-radius: 4px !important;
    transition: right 0.5s ease-in-out !important;
    z-index: 999999 !important;
}

.toast.show {
    right: 20px !important;
    /* Slide in to the desired position */
}
</style>