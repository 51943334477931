<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Description</th>
                                <th>Position</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productmain" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td><textarea v-model="product.description" class="form-control"></textarea></td>
                                <td><input type="text" v-model="product.position" placeholder="USER POSITION"></td>
                                <td><input type="file" accept="image/*" @change="onFileChange($event, product.id)"
                                        class="form-control mb-3" /></td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>

                                    <button @click="updateImage(product)" class="btn btn-primary">
                                        Update Image
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productmain: [],
            product: {
                description: '',
                position: ''
            },
            selectedImage: {}, // Use an object to store selected images for each row

        };
    },

    created() {
        this.fetchAllData();
    },

    methods: {
        fetchAllData() {
            return axiosInstance
                .get(`api/get-disabled-product-description`)
                .then(response => {
                    this.productmain = response.data.productmain;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/disabled-data-description-main/${product.id}`, {
                    description: product.description, position: product.position,
                })
                .then(response => {
                    console.log('Details updated:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('Error updating details:', error);
                });
        },

        updateImage(product) {
            console.log('Updating image for product:', product);

            // Create a FormData object to send the image file
            const formData = new FormData();
            formData.append('image', this.selectedImage); // Use 'image' as the key

            axiosInstance
                .post(`api/update-product-image/${product.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Image updated successfully:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating image:', error);
                });
        },

        onFileChange(event) {
            this.selectedImage = event.target.files[0]; // Capture the selected image
        },

    },
}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
