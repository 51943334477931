<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="size.id" />
                        <input type="text" class="form-control" v-model="size.size_name" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditSize",

    data() {
        return {
            size: {
                size_name: "",
                id: this.$route.params.id,
            },
            oldSize: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axiosInstance
                .put(
                    `api/size-update/${this.size.id}`, // Use the BASE_URL constant for the base URL
                    this.size
                )
                .then(() => {
                    this.size = {
                        size_name: "",
                        id: "",
                    };
                    router.push("/size");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axiosInstance
                .get(`api/size-edit/` + this.size.id)
                .then((response) => {
                    this.oldSize = response.data; // store the old employee data in oldEmployee property
                    this.size.size_name = this.oldSize.size_name; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>