<template>
    <!-- Cart Start -->
    <div class="temp mx-auto">

        <div class="container-fluid mb-5">
            <div class="row px-xl-5">
                <div class="table-responsive">
                    <table class="table table-light table-borderless table-hover mb-0">
                        <thead class="thead-dark">
                            <tr>
                                <th width="50%">Products</th>
                                <th>Price</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="favorite in favorites" :key="favorite.id">
                                <td class="product-cell">
                                    <img :src="'https://api.gocami.com/product_detail/' + favorite.productdetail.image"
                                        alt="" class="product-image">
                                    <router-link
                                        :to="{ name: 'ProductDetail', params: { id: favorite.productdetail.product_id } }">{{
                                            favorite.productdetail.title }}</router-link>
                                </td>
                                <td class="price-cell">$ {{ favorite.productdetail.sale_price.toFixed(2) }}</td>
                                <td class="remove-cell">
                                    <button @click="remove(favorite)" class="btn btn-sm btn-danger"><i
                                            class="fa fa-times"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <FooterLayout />

    </div>
    <!-- Cart End -->
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
// import router from "@/router";
import { useUserStore } from "../../../../store";
import FooterLayout from "./FooterLayout.vue";

export default {
    components: { FooterLayout },

    name: "addtofavorite-view",
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },
    data() {
        return {
            favorites: [],
        };
    },

    created() {
        this.favoriteLoad();
    },

    methods: {
        favoriteLoad() {
            var page = `api/product-detail-favorite?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.favorites = data;
            });
        },

        remove(favorite) {
            const url = `api/product-detail-favorite-delete/${favorite.id}`;
            axiosInstance.delete(url)
                .then(() => {
                    const index = this.favorites.indexOf(favorite);
                    if (index !== -1) {
                        this.favorites.splice(index, 1);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    alert('An error occurred while removing the favorite item.');
                });
        }

    },
};

</script>

<style scoped>
@media (min-width: 1366px) {
    .temp {
        width: 96%;
        max-width: 1500px !important;
        overflow-x: hidden !important;
    }
}

@media (max-width: 767px) {
    .temp {
        overflow-x: hidden !important;
    }
}

.product-cell,
.price-cell,
.remove-cell {
    vertical-align: middle;
}

.product-image {
    width: 50px;
    margin-right: 10px;
}
</style>