<template>
    <div class="container">
        <div class="row d-flex m-auto">
            <div class="col-12">
                <router-link to="/">Home </router-link> / Profile
            </div>
        </div>

        <div class="container mb-5 profile">
            <div class="row">
                <div class="col-md-5">
                    <div class="border">
                        <div class="row ps-2 pe-2">
                            <div class="col-12">
                                <div class="border-bottom p-4 fs-5 text-dark bg-secondary">Account Information</div>

                                <div v-for="(content, index) in accountContents" :key="index"
                                    class="border-bottom p-4 fs-5 check" @click="showContent(index)">
                                    <i :class="content.icon"></i> {{ content.title }}
                                </div>
                                <router-link to="/" @click="logout">
                                    <div class="border-bottom p-4 fs-5 check"><i class="pi pi-sign-out pe-2"></i>Logout
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="row px-3 py-3">
                        <div class="col-12">
                            <template v-if="selectedContent !== null">
                                <div v-if="selectedContent === 0">
                                    <div class="col-12">
                                        {{ accountContents[selectedContent].title }}
                                        <hr style="height: 1px; background-color: gray;" />
                                        <form @submit="updateProfile" class="form-container">
                                            <div class="form-group">
                                                <label for="first_name">First Name</label>
                                                <input type="text" id="first_name" v-model="firstName" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="middle_name">Middle Name</label>
                                                <input type="text" id="middle_name" v-model="middleName"
                                                    class="form-control" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="last_name">Last Name</label>
                                                <input type="text" id="last_name" v-model="lastName" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="address">Address</label>
                                                <input type="text" id="address" v-model="address" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" id="email" v-model="email" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="dob">Date of birth</label>
                                                <input type="date" id="dob" v-model="date_of_birth" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="phonefirst">First Phone</label>
                                                <input type="text" id="phonefirst" v-model="phoneFirst" class="form-control"
                                                    required>
                                            </div>
                                            <div class="form-group">
                                                <label for="phonesecond">Second Phone</label>
                                                <input type="text" id="phonesecond" v-model="phoneSecond"
                                                    class="form-control" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="current_password">Current Password</label>
                                                <input type="password" id="current_password" v-model="currentPassword"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label for="new_password">New Password</label>
                                                <input type="password" id="new_password" v-model="newPassword"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group">
                                                <label for="confirm_password">Confirm Password</label>
                                                <input type="password" id="confirm_password" v-model="confirmPassword"
                                                    class="form-control">
                                                <span class="text-danger" v-if="passwordError">{{ passwordError }}</span>
                                            </div>

                                            <div class="form-group">
                                                <button class="btn" type="submit">Update</button>
                                            </div>
                                        </form>

                                        <div class="container" style="margin-top: 20px">
                                            <div v-if="!users">
                                                <form @submit.prevent="save">
                                                    <div class="form-group">
                                                        <label for="Unique">Unique Code</label>
                                                        <input type="text" class="form-control w-50"
                                                            v-model="userAffilaiteUniqueCode.unique_code"
                                                            placeholder="Enter your affiliate unique code" />
                                                    </div>
                                                    <div class="form-group">
                                                        <button type="submit" class="btn btn-block w-50"
                                                            style="margin-top:10px">Apply</button>
                                                    </div>
                                                </form>
                                            </div>

                                            <div class="row" style="margin-bottom:55px" v-else>
                                                <div class="col-12">
                                                    Your Affiliate Coupon is: {{ users.unique_code }}
                                                </div>
                                            </div>

                                        </div>

                                        <div v-if="users"></div>

                                        <div v-else>
                                            <div class="container">
                                                <div class="row">
                                                    <div v-if="affreq && affreq.status == 0" style="display: flex;">Your
                                                        request has been sent! Please wait for
                                                        acceptance.</div>
                                                    <div v-else-if="affreq && affreq.status == 1" style="display: flex;">
                                                    </div>
                                                    <div v-else>
                                                        <form @submit.prevent="affiliate">
                                                            <button type="submit" class="btn"
                                                                style="margin-top: 10px; margin-left:20px">
                                                                Request for affiliate
                                                            </button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="selectedContent === 1">
                                    <div class="col-12">
                                        {{ accountContents[selectedContent].title }}
                                        <hr style="height: 1px; background-color: gray;" />

                                        <table class="table table-responsive w-100">
                                            <tr>
                                                <th>#</th>
                                                <th class="w-50">NAME</th>
                                                <th>DATE</th>
                                                <!-- <th>STATUS</th> -->
                                            </tr>

                                            <tr v-for="invoices in invoice" :key="invoices.id">
                                                <td>{{ invoices.id }}</td>
                                                <td>{{ invoices.first_name }} {{ invoices.middle_name }} {{
                                                    invoices.last_name }}</td>
                                                <td>{{ new Date(invoices.created_at).toLocaleString() }}</td>
                                                <!-- <td>{{ invoices.status }}</td> -->
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
import Cookies from "js-cookie";
import { useUserStore } from "../../../../store";
import router from "@/router";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            accountContents: [
                { title: "Profile Update", icon: "pi pi-user" },
                { title: "Invoices", icon: "pi pi-file" },
            ],
            selectedContent: null,
            users: [],
            userAffilaiteUniqueCode: {
                user_id: "",
                unique_code: "",
            },
            invoice: [],
            affreq: null,
            firstName: '',
            middleName: '',
            lastName: '',
            address: '',
            email: '',
            phoneFirst: '',
            phoneSecond: '',
            date_of_birth: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            passwordError: '',
            profileUpdateSuccess: false
        };
    },
    mounted() {
        const userId = this.user?.id;
        this.fetchData(userId);
    },

    created() {
        this.fetchCoupon();
        this.fetchAff();
        this.fetchInvoices();
    },

    methods: {
        logout() {
            // localStorage.clear();
            this.publicKey = null; // Clear the publicKey property
            Cookies.remove("token", () => {
                console.log("Token removed:", Cookies.get("token"));
                router.replace("/");
            });
        },

        showContent(index) {
            this.selectedContent = index;
        },

        saveAffiliate() {
            let formData = new FormData();
            formData.append("affiliate_id", this.user?.id);
            axiosInstance
                .post(`api/aff-request`, formData, {})
                .then(() => {
                    this.fetchAff();
                });
        },

        affiliate() {
            if (this.profileUpdateSuccess && this.areRequiredFieldsFilled()) {
                this.saveAffiliate();
            } else if (!this.profileUpdateSuccess) {
                alert('Profile update has not succeeded yet. Please wait.');
            } else {
                alert('Please fill all required fields before applying the coupon.');
            }
        },

        save() {
            if (this.profileUpdateSuccess && this.areRequiredFieldsFilled()) {
                this.saveData();
            } else if (!this.profileUpdateSuccess) {
                alert('Profile update has not succeeded yet. Please wait.');
            } else {
                alert('Please fill all required fields before sending the request.');
            }
        },

        areRequiredFieldsFilled() {
            const requiredFields = ['firstName', 'middleName', 'lastName', 'address', 'email', 'date_of_birth', 'phoneFirst', 'phoneSecond'];

            for (const field of requiredFields) {
                if (!this[field]) {
                    return false;
                }
            }

            return true;
        },

        saveData() {
            let formData = new FormData();
            formData.append("user_id", this.user?.id);
            formData.append("unique_code", this.userAffilaiteUniqueCode.unique_code);
            axiosInstance
                .post(`api/user-affiliate-unique-code`, formData, {})
                .then(() => {
                    this.fetchCoupon();
                });
        },

        fetchAff() {
            var page = `api/check-aff?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.affreq = data.affreq;
            });
        },

        fetchCoupon() {
            var page = `api/exist-coupon?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.users = data.user;
                this.isLoading = false; // Set loading state to false after data is received
            });
        },

        fetchInvoices() {
            var page = `api/get-user-invoice?user_id=${this.user?.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.invoice = data;
            });
        },


        fetchData(userId) {
            axiosInstance
                .get(`api/user-get/${userId}`)
                .then(response => {
                    const user = response.data;
                    this.firstName = user.first_name;
                    this.middleName = user.middle_name;
                    this.lastName = user.last_name;
                    this.address = user.address;
                    this.date_of_birth = user.date_of_birth;
                    this.phoneFirst = user.phone_first;
                    this.phoneSecond = user.phone_second;
                    this.email = user.email;
                    this.userFullName = user.first_name + ' ' + user.last_name;
                })
                .catch(() => {
                });
        },

        updateProfile(event) {
            event.preventDefault();
            const userId = this.user?.id;

            if (this.newPassword !== this.confirmPassword) {
                this.passwordError = 'New password and confirmation password do not match';
                return;
            }

            if (this.newPassword && !this.currentPassword) {
                this.passwordError = 'Please enter your current password';
                return;
            }

            this.passwordError = ''; // Reset the password error message

            // Make the API request to update the user profile
            axiosInstance.put(`api/profile/${userId}`, {
                first_name: this.firstName,
                middle_name: this.middleName,
                last_name: this.lastName,
                address: this.address,
                email: this.email,
                phone_first: this.phoneFirst,
                phone_second: this.phoneSecond,
                date_of_birth: this.date_of_birth,
                current_password: this.currentPassword,
                new_password: this.newPassword,
                confirm_password: this.confirmPassword
            })
                .then(() => {
                    console.log('Profile updated successfully!');
                    this.profileUpdateSuccess = true; // Set the flag to true upon success
                    this.triggerAdditionalActions(); // Trigger additional actions
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        this.passwordError = error.response.data.message;
                    } else {
                        console.error('Profile update failed:', error);
                    }
                });
        },

        triggerAdditionalActions() {
            // Additional actions to trigger upon successful profile update
            if (this.areRequiredFieldsFilled() && this.profileUpdateSuccess) {
                this.saveAffiliate();
                this.saveData();
            } else {
                alert('Please fill all required fields before proceeding.');
            }
        },

    }
};
</script>

<style scoped>
.check:hover {
    background-color: #154068;
    color: white;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.btn {
    background-color: #154068;
    color: white;
}

.profile {
    margin-top: 100px;
}
</style>