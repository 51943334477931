<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Card Name"
                            v-model="giftcard.card_name" /><br /><br />

                        <input type="text" class="form-control" placeholder="Create Card Description"
                            v-model="giftcard.card_description" /><br /><br />

                        <input type="text" class="form-control" placeholder="Create Card Price"
                            v-model="giftcard.card_price" /><br /><br />

                        <input type="text" class="form-control" placeholder="Create Card Quantity"
                            v-model="giftcard.card_quantity" /><br /><br />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-primary">Create Gift Card</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateGiftCard",

    data() {
        return {
            giftcard: {
                card_name: "",
                card_description: "",
                card_price: "",
                card_quantity: "",
                card_photo: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.giftcard.card_photo = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('card_name', this.giftcard.card_name);
            formData.append('card_description', this.giftcard.card_description);
            formData.append('card_photo', this.giftcard.card_photo);
            formData.append('card_price', this.giftcard.card_price);
            formData.append('card_quantity', this.giftcard.card_quantity);
            axiosInstance.post(`api/gift-card-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/gift-card");
            });
        },
    },
};
</script>