<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Product Detail</h3>

          <button @click="updateCheckedItems" class="btn btn-primary mb-3">
            Update Checked Items
          </button>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Color</th>
              <th>Size</th>
              <th>Attribute</th>
              <th>Main Price</th>
              <th>Total Commission</th>
              <th>Affiliate Commission</th>
              <th>Sub Affiliate Commission</th>
              <th>Select</th> <!-- Added -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in productDetail" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.name }}</td>
              <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="100" /></td>
              <td>{{product.sku}}</td>
              <td>{{product.color_name}}</td>
              <td>{{product.size_name}}</td>
              <td>{{product.attribute_type_name}}</td>
              <td>{{product.main_price}}</td>
              <td><input type="text" class="form-control" v-model="product.total_commission" /></td>
              <td><input type="text" class="form-control" v-model="product.affiliate_commission" /></td>
              <td><input type="text" class="form-control" v-model="product.sub_affiliate_commission" />
              </td>
              <td>
                <input type="checkbox" class="form-check-input" v-model="product.checked" />
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
  data() {
    return {
      selectedBrand: null,
      productDetail: [],
      product: {
        main_price: '',
        new_price: '',
        total_commission: '',
        affiliate_commission: '',
        sub_affiliate_commission: '',
      },
      brands: [],

      products: [],
      childcategory: [],
      file: null

    };
  },

  created() {
    this.fetchAllData();
  },

  methods: {
    loadProductsByBrand() {
      if (this.selectedBrand) {
        this.fetchAllData(this.selectedBrand);
      }
    },

    fetchAllData() {
      return axiosInstance
          .get(`api/product-without-brand`)
          .then(response => {
            this.productDetail = response.data.productDetail;
          })
          .catch(error => {
            console.error('Error fetching main data:', error);
          });
    },

    updateDescription(product) {
      console.log('Updating description for product:', product);

      axiosInstance
          .post(`api/update-product-without-brand/${product.id}`, {
            total_commission: product.total_commission,
            affiliate_commission: product.affiliate_commission,
            sub_affiliate_commission: product.sub_affiliate_commission
          })
          .then(response => {
            console.log('Details updated:', response.data);
            this.fetchAllData(this.selectedBrand);
          })
          .catch(error => {
            console.error('Error updating details:', error);
          });
    },

    updateCheckedItems() {
      const checkedProducts = this.productDetail.filter(product => product.checked);
      if (checkedProducts.length === 0) {
        alert("Please select at least one item to update.");
        return;
      }

      checkedProducts.forEach(product => {
        // Update the checked products
        this.updateDescription(product);
      });
    },

  },

}
</script>
