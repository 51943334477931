<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <select class="form-control" v-model="selectedBundle">
                        <option value="">Select a bundle</option>
                        <option v-for="(bundle, index) in bundles" :value="index" :key="bundle.bundle.id">
                            {{ bundle.bundle.bundle_name }}
                        </option>
                    </select>
                    

                    <select class="form-control mt-4" v-model="selectedProduct">
                        <option value="">Select a product</option>
                        <option v-for="product in selectedBundleProducts" :value="product.id" :key="product.id">
                            {{ product.product_name }}
                        </option>
                    </select>

                    <h3 class="pt-5 pb-3">Selected Product Details:</h3>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>SKU</th>
                                <th>Quantity</th>
                                <th>Selected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="detail in selectedProductDetails" :key="detail.sku">
                                <td>{{ detail.title }}</td>
                                <td>{{ detail.sku }}</td>
                                <td><input type="integer" v-model="detail.quantity"></td>
                                <td>
                                    <input type="checkbox" class="custom-checkbox" v-model="selectedDetails"
                                        :value="detail.id">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn btn-primary mt-4" @click="submitForm">Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            bundles: [],
            selectedBundle: null,
            selectedProduct: null,
            productDetails: [],
            selectedProductDetails: [],
            selectedProductQuantities: {},
            selectedDetails: [], // Add this line to define the selectedDetails property
        };
    },

    created() {
        this.bundleLoad();
    },

    methods: {
        bundleLoad() {
            var page = `api/productdetail-bundle-create`;
            axiosInstance.get(page).then(({ data }) => {
                this.bundles = data.bundle;
                this.productDetails = data.productDetails;
            });
        },

        submitForm() {
            const requestData = {
                bundle_id: this.selectedBundle,
                product_details: [], // Make sure this array is populated with data
            };

            // Filter out unchecked product details
            const checkedProductDetails = this.selectedProductDetails.filter(detail => this.selectedDetails.includes(detail.id));

            // Populate product_details array with data
            for (const detail of checkedProductDetails) {
                requestData.product_details.push({
                    product_detail_id: detail.id,
                    quantity: detail.quantity,
                });
            }

            // Send the data using Axios or your preferred HTTP library
            axiosInstance.post('api/productdetail-bundle-store', requestData)
                .then((response) => {
                    // Handle the success response
                    console.log(response.data);
                })
                .catch((error) => {
                    // Handle the error response
                    console.error(error.response.data);
                });
        },
    },

    computed: {
        selectedBundleProducts() {
            if (this.selectedBundle !== null) {
                return this.bundles[this.selectedBundle].products;
            } else {
                return [];
            }
        },
    },

    watch: {
        selectedProduct(newProduct) {
            this.selectedProductDetails = this.productDetails.filter(detail => detail.product_id === newProduct);
            this.selectedProductQuantities = {}; // Reset the selectedProductQuantities object
        },
    },
};
</script>

<style scoped>
.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox {
    /* Add your custom styling properties here */
    /* Example properties */
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.custom-checkbox:checked {
    /* Add your custom styling for checked state */
    /* Example properties */
    background-color: #5cb85c;
    /* Change the background color when checked */
}
</style>
