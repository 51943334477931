<template>
    <div class="temp justify-content-center text-center mx-auto">

        <div v-if="loading" class="loading-indicator">
            <img src="https://api.gocami.com/photos/cami.gif" width="200" height="200" alt="Loading..." />
        </div>

        <div v-else>
            <section class="header-content">
                <img v-if="isPC" :src="'https://api.gocami.com/photos/' + category.banner_category"
                    style="width: auto !important; height: auto !important;" alt="">
                <img v-else :src="'https://api.gocami.com/photos/' + category.banner_category"
                    style="width: 100% !important; height: 150px !important;" alt="">

                <div class="row mt-2 mx-2 subs">
                    <div class="d-flex text-center justify-content-center">
                        <router-link v-for="subcategory in subcategories" :key="subcategory.id"
                            :to="{ name: 'SubCategory', params: { id: subcategory.sub_category_name } }">
                            <div class="border px-3 py-3 mx-2 my-5 subcat">
                                {{ subcategory.sub_category_name }}
                            </div>
                        </router-link>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <h1 class="fs-3" style="margin: 0; padding: 0;">{{ category.category_name }}</h1>
                        <h3 class="fs-1 text-black" style="margin: 0; padding: 0; letter-spacing: 2px;">{{
            subcategory.sub_category_name }}</h3>
                    </div>
                </div>

            </section>

            <div class="mb-3 mx-5 text-end d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <label for="sortBy fw-bolder text-gray" class="me-3">Sort By:</label>
                    <select v-model="selectedSortBy" id="sortBy" @change="sortProducts" class="me-3 rounded display"
                        style="width: 120px; padding-left: 15px;">
                        <option v-for="option in sortByOptions" :key="option.value" :value="option.value">
                            {{ option.label }}
                        </option>
                    </select>
                    <span v-if="displayMode === 'grid'">Grid<i class="pi pi-list border border-dark p-2 ms-1 display"
                            @click="toggleDisplayMode"></i></span>
                    <span v-else>List <i class="pi pi-th-large border border-dark p-2 ms-1 display"
                            @click="toggleDisplayMode"></i></span>
                </div>
            </div>

            <div class="row">
                <div class="col-2 mx-5 mt-5">

                    <!-- Brand Filter -->
                    <div class="filter-section">
                        <h6 class="text-black text-start">Brands</h6>
                        <label class="custom-checkbox" v-for="brand in brands" :key="brand.id">
                            <input type="checkbox" v-model="selectedBrands" :value="brand.id">
                            <span class="checkmark"></span> <span class="ms-4">{{ brand.brand_name }}</span>
                        </label>
                    </div>

                    <div class="filter-section">
                        <h6 class="text-black text-start">Prices</h6>
                        <div v-for="priceRange in priceRanges" :key="priceRange.value">
                            <span class="custom-checkbox" @click="togglePriceFilter(priceRange.value)">
                                <input type="checkbox" :checked="selectedPrices.includes(priceRange.value)" />
                                <span class="checkmark"></span>
                                <span class="ms-4">{{ priceRange.label }}</span>
                            </span>
                        </div>
                    </div>

                    <!-- Discount Filter -->
                    <div class="filter-section">
                        <h6 class="text-black text-start">Discounts</h6>
                        <div v-for="discountValue in discountValues" :key="discountValue.value">
                            <span class="custom-checkbox" @click="toggleDiscountFilter(discountValue.value)">
                                <input type="checkbox" :checked="selectedDiscounts.includes(discountValue.value)" />
                                <span class="checkmark"></span>
                                <span class="ms-4">{{ discountValue.label }}</span>
                            </span>
                        </div>
                    </div>

                </div>

                <div class="col-9" style="margin: 0 !important; padding: 0 !important;">
                    <div class="row row-clean mt-3 mb-4">
                        <div v-for="product in filteredProducts" :key="product.id"
                            :class="{ 'product-item': displayMode === 'grid', 'product-item-list': displayMode === 'list' }">
                            <article style="overflow: hidden">
                                <div v-if="displayMode === 'grid'" class="figure-grid">
                                    <div class="image" v-if="product.in_stock == 0" style="position: relative;">
                                        <router-link :to="{ name: 'ProductDetail', params: { id: product.product_id } }"
                                            class="mfp-open">
                                            <img :src="'https://api.gocami.com/product_detail/' + product.image" alt=""
                                                class="product-image" />
                                        </router-link>
                                        <div class="out-of-stock-overlay">Out of Stock</div>
                                    </div>

                                    <div class="image" v-else style="position: relative;">
                                        <router-link :to="{ name: 'ProductDetail', params: { id: product.product_id } }"
                                            class="mfp-open">
                                            <img :src="'https://api.gocami.com/product_detail/' + product.image" alt=""
                                                class="product-image" />
                                        </router-link>
                                    </div>
                                    <div class="text text-start ms-3">
                                        <h2 class="title h5 pb-0 mb-0" style="color: black">{{ product.title }}</h2>
                                        <span v-if="product.discount > 0">
                                            <p class="title pt-2 mt-0 pb-0 mb-0 text-dark fw-bolder"> <del>${{
            product.sale_price.toFixed(2)
        }}</del> ${{ (product.sale_price - (product.sale_price *
            product.discount /
            100)).toFixed(2) }}
                                            </p>
                                        </span>
                                        <span v-else>

                                            <p class="title pt-2 mt-0 pb-0 mb-0"> ${{ product.sale_price.toFixed(2)
                                                }}
                                            </p>

                                            <span v-if="product.old_price > product.sale_price">
                                                <del>
                                                    <p class="title pt-2 mt-0 pb-0 mb-0 text-danger"
                                                        v-if="product.old_price"> ${{ product.old_price.toFixed(2) }}
                                                    </p>
                                                </del>
                                            </span>
                                        </span>
                                        <p class="title pt-2 mt-0 text-danger fw-bolder" v-if="product.discount > 0">%{{
            parseFloat(product.discount)
        }} OFF</p>
                                        <p class="title pt-0 mt-0" v-else></p>
                                    </div>

                                </div>
                                <div v-else class="figure-list border-bottom list text-start">
                                    <div>
                                        <router-link :to="{ name: 'ProductDetail', params: { id: product.product_id } }"
                                            class="mfp-open">
                                            <img :src="'https://api.gocami.com/product_detail/' + product.image" alt=""
                                                class="product-image" style="width:100px" />
                                        </router-link>
                                    </div>
                                    <div class="text text-start ms-3">
                                        <h2 class="title h5 pb-0 mb-0" style="color: black">{{ product.title }}</h2>
                                        <p v-html="product.description"></p> <span v-if="product.discount > 0">
                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"> <del>${{
            product.sale_price.toFixed(2)
        }}</del> ${{ (product.sale_price - (product.sale_price *
            product.discount /
            100)).toFixed(2) }}
                                            </p>
                                        </span>
                                        <span v-else>
                                            <p class="title pt-0 mt-0 pb-0 mb-0"> ${{ product.sale_price.toFixed(2)
                                                }}
                                            </p>
                                        </span>
                                        <p class="title pt-0 mt-0 text-danger fw-bolder" v-if="product.discount > 0">%{{
            parseFloat(product.discount)
                                            }} OFF</p>
                                        <p class="title pt-0 mt-0" v-else></p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <div class="pagination">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                                    <a class="page-link" @click="firstPage" href="#" aria-label="First">
                                        <span aria-hidden="true">&laquo;&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
                                    <a class="page-link" @click="prevPage" href="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" v-for="pageNumber in displayedPages" :key="pageNumber"
                                    :class="{ 'active': pageNumber === currentPage }">
                                    <a class="page-link" @click="setPage(pageNumber)" href="#">{{ pageNumber }}</a>
                                </li>
                                <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                                    <a class="page-link" @click="nextPage" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                                <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
                                    <a class="page-link" @click="lastPage" href="#" aria-label="Last">
                                        <span aria-hidden="true">&raquo;&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <FooterLayout />

</template>
<script>
import axiosInstance from '../../../../axiosInstance';
import FooterLayout from "./FooterLayout.vue";

export default {
    components: { FooterLayout },

    data() {
        return {
            displayedPagesCount: 5, // Adjust this number as needed
            isPC: false,
            subcategory: {},
            category: {},
            subcategories: [],
            brands: [],
            products: [],
            currentPage: 1,
            totalPages: 0,
            loading: true,
            selectedBrands: [],
            filteredProducts: [],
            selectedPrices: [],
            selectedDiscounts: [],
            priceRanges: [
                { label: '$0 - $50', value: '0-50' },
                { label: '$51 - $100', value: '51-100' },
                { label: '$101 - $150', value: '101-150' },
                // Add more price ranges as needed
            ],
            discountValues: [
                { label: '10% Off', value: '0-10' },
                { label: '15% Off', value: '11-15' },
                { label: '20% Off', value: '16-20' },
                // Add more discount values as needed
            ],
            sortByOptions: [
                { label: 'Default', value: 'default' },
                { label: 'High to Low Price', value: 'highToLow' },
                { label: 'Low to High Price', value: 'lowToHigh' },
                { label: 'A to Z', value: 'aToZ' },
                { label: 'Z to A', value: 'zToA' },
                { label: 'Newest First', value: 'newestFirst' },
            ],
            defaultSortBy: 'default',
            selectedSortBy: 'default',
            displayMode: 'grid',
        };
    },

    created() {
        this.fetchSale();
        this.selectedSortBy = this.defaultSortBy;
    },
    beforeUnmount() {

        window.removeEventListener("resize", this.checkIfPC);
    },

    computed: {
        displayedPages() {
            const startPage = Math.max(1, this.currentPage - Math.floor(this.displayedPagesCount / 2));
            const endPage = Math.min(this.totalPages, startPage + this.displayedPagesCount - 1);

            const pages = [];
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }
            return pages;
        }
    },

    methods: {

        scrollToStoredPosition() {
            const scrollPosition = sessionStorage.getItem('scrollPosition');
            if (scrollPosition) {
                window.scrollTo(0, parseInt(scrollPosition));
                sessionStorage.removeItem('scrollPosition'); // Remove stored position after use
            }
        },

        // Add this method to store the scroll position
        storeScrollPosition() {
            sessionStorage.setItem('scrollPosition', window.pageYOffset);
        },
        
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.updateCurrentPage();
                this.fetchProduct();
            }
        },

        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.updateCurrentPage();
                this.fetchProduct();
            }
        },
        firstPage() {
            if (this.currentPage !== 1) {
                this.currentPage = 1;
                this.updateCurrentPage();
                this.fetchProduct();
            }
        },
        lastPage() {
            if (this.currentPage !== this.totalPages) {
                this.currentPage = this.totalPages;
                this.updateCurrentPage();
                this.fetchProduct();
            }
        },
        setPage(pageNumber) {
            if (this.currentPage !== pageNumber) {
                this.currentPage = pageNumber;
                this.updateCurrentPage();
                this.fetchProduct();
            }
        },
        updateCurrentPage() {
            sessionStorage.setItem('currentPage', this.currentPage);
        },

        checkIfPC() {
            this.isPC = window.innerWidth >= 768;
        },
        fetchData() {
            this.fetchSale();
            this.fetchProduct();
        },

        sortProducts() {
            switch (this.selectedSortBy) {
                case 'highToLow':
                    this.filteredProducts.sort((a, b) => b.sale_price - a.sale_price);
                    break;
                case 'lowToHigh':
                    this.filteredProducts.sort((a, b) => a.sale_price - b.sale_price);
                    break;
                case 'aToZ':
                    this.filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
                    break;
                case 'zToA':
                    this.filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
                    break;
                case 'newestFirst':
                    this.filteredProducts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    break;
                default:
                    break;
            }
        },

        toggleDisplayMode() {
            this.displayMode = this.displayMode === 'grid' ? 'list' : 'grid';
        },

        getPriceRange(sale_price) {
            if (sale_price >= 1 && sale_price <= 50) return '0-50';
            else if (sale_price > 50 && sale_price <= 100) return '51-100';
            else if (sale_price > 100 && sale_price <= 150) return '101-150';
            else return '151+';
        },

        getDiscountValue(discount) {
            if (discount >= 11 && discount <= 14) return '0-10';
            else if (discount <= 15) return '11-15';
            else if (discount <= 20) return '16-20';
            // Add more conditions as needed
        },

        fetchSale() {
            const page = `api/get-sub-category/${this.$route.params.id}`;
            this.loading = true;

            axiosInstance.get(page).then(({ data }) => {
                this.subcategory = data.subcategory;
                this.category = data.category;
                this.subcategories = data.subcategories;
                this.brands = data.brands;
                this.loading = false;

            }).catch(error => {
                console.error('Error fetching category:', error);
            });
        },
        fetchProduct() {
            const page = `api/get-sub-category/${this.$route.params.id}?page=${this.currentPage}`;

            axiosInstance.get(page)
                .then(({ data }) => {
                    this.products = data.product.data;
                    this.totalPages = data.product.last_page;
                    // Update filteredProducts when products change
                    this.filterProducts();
                    this.storeScrollPosition();

                })
                .catch(error => {
                    console.error('Error fetching products:', error);
                });
        },
        // setPage(page) {
        //     this.currentPage = page;
        //     this.fetchProduct();
        // },
        // nextPage() {
        //     if (this.currentPage < this.totalPages) {
        //         this.currentPage++;
        //         this.fetchProduct();
        //     }
        // },
        // prevPage() {
        //     if (this.currentPage > 1) {
        //         this.currentPage--;
        //         this.fetchProduct();
        //     }
        // },

        togglePriceFilter(sale_price) {
            if (this.selectedPrices.includes(sale_price)) {
                this.selectedPrices = this.selectedPrices.filter(p => p !== sale_price);
            } else {
                this.selectedPrices.push(sale_price);
            }
            this.filterProducts();
        },

        toggleDiscountFilter(discount) {
            if (this.selectedDiscounts.includes(discount)) {
                this.selectedDiscounts = this.selectedDiscounts.filter(d => d !== discount);
            } else {
                this.selectedDiscounts.push(discount);
            }
            this.filterProducts();
        },
        filterProducts() {

            // Filter products based on selected brands
            let filteredByBrands = this.selectedBrands.length > 0 ?
                this.products.filter(product => this.selectedBrands.includes(product.brand_id)) :
                this.products;

            // Filter products based on selected price ranges
            let filteredByPrices = this.selectedPrices.length > 0 ?
                filteredByBrands.filter(product => this.selectedPrices.includes(this.getPriceRange(product.sale_price))) :
                filteredByBrands;

            // Filter products based on selected discount values
            this.filteredProducts = this.selectedDiscounts.length > 0 ?
                filteredByPrices.filter(product => this.selectedDiscounts.includes(this.getDiscountValue(product.discount))) :
                filteredByPrices;

            // Convert Proxies to regular objects
            this.filteredProducts = JSON.parse(JSON.stringify(this.filteredProducts));

            // Log the final filtered products
        },

    },
    watch: {
        selectedBrands: 'filterProducts', // Watch for changes in selected brands
        $route: {
            handler(newRoute, oldRoute) {
                // Check if the route parameter 'id' changed
                if (newRoute.params.id !== oldRoute.params.id) {
                    // Subcategory changed, reset currentPage to 1
                    this.currentPage = 1;
                    this.fetchProduct();
                }

                // Call 'fetchData' method when the whole route changes
                this.fetchData();
            },
            deep: true,
        },
    },

    mounted() {
        const storedPage = sessionStorage.getItem('currentPage');
        if (storedPage) {
            this.currentPage = parseInt(storedPage);
            this.fetchProduct();
        } else {
            this.currentPage = 1;
            this.fetchProduct();
        }

        this.checkIfPC();
        window.addEventListener("resize", this.checkIfPC);
        this.fetchProduct();
    },
};
</script>

<style scoped>
.out-of-stock-overlay {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: rgba(255, 0, 0, 0.7) !important;
    /* Red color with some transparency */
    color: white !important;
    padding: 5px 10px !important;
    border-radius: 5px !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center !important;
    z-index: 1 !important;
    /* Ensure the overlay is above the image */
}

@media (min-width: 1366px) {
    .temp {
        width: 96%;
        max-width: 1500px !important;
        overflow-x: hidden !important;
    }
}

@media (max-width: 767px) {
    .temp {
        overflow-x: hidden !important;
    }
}

.loading-indicator {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 1000 !important;
    width: 100px !important;
    height: 100px !important;
    /* Adjust the z-index as needed */
}

.no-margin-top {
    margin-top: 0;
}

.display:hover {
    cursor: pointer;
}

.custom-inline {
    display: flex;
    align-items: center;
}

.subcat:hover {
    cursor: pointer;
}

.product-item {
    flex-shrink: 0;
    width: 220px;
    height: 380px;
    /* margin-right: 10px; */
}

.figure-grid {
    position: relative;
    text-align: center;
    height: 100%;
    /* Ensure that the figure-grid takes the full height */
}

.image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Add this CSS to your stylesheet or in a <style> tag in your HTML file */
.col-2 {
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 5px;
}

.filter-section {
    margin-bottom: 15px;
}

.filter-section label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.filter-section input {
    margin-right: 18px;
}

.filter-section h6 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.custom-checkbox {
    display: flex !important;
    position: relative;
    cursor: pointer;
    margin-right: 8px;
    /* Add margin to the right of the checkbox */
}

.custom-checkbox input {
    opacity: 0;
    position: absolute;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #888;
    border-radius: 20%;
    background-color: #fff;
    transition: background-color 0.3s;
}

.custom-checkbox input:checked+.checkmark::after {
    content: '\2713';
    /* Check mark symbol */
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-checkbox input:checked+.checkmark {
    background-color: #4285f4;
}

.list-view .product-item {
    display: flex;
    flex-direction: column;
}


@media (max-width: 767px) {


    .col-2,
    .col-9 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .subs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: auto;
    }

    .subcat {
        flex-basis: calc(50% - 10px);
        /* Adjust the percentage and spacing as needed */
        max-width: calc(50% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin: 5px;
        /* Adjust the margin as needed for spacing */
        box-sizing: border-box;
        /* Ensure long words break onto the next line */
        white-space: normal;
        /* Allow wrapping of long text */
        min-width: 150px;

    }

    .row-clean {
        margin: 0 -5px;
    }

    .product-item {
        flex-basis: calc(45% - 10px);
        /* Display three products per row, adjust as needed */
        max-width: calc(45% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin-left: 20px;
        /* margin-right: 20px; */
    }

    .product-item-list {
        flex-basis: calc(100% - 10px);
        /* Display three products per row, adjust as needed */
        max-width: calc(100% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin-left: 20px;
        /* margin-right: 20px; */
    }

    .figure-grid,
    .figure-list {
        box-sizing: border-box;
        margin-right: 10px;
        /* Adjust the margin as needed for spacing between items */
        margin-bottom: 15px;
        /* Adjust the margin as needed for spacing between rows */
    }

    .figure-list {
        border-bottom: none;
        /* Remove border bottom for list view */
    }

    .product-image {
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .text {
        padding-left: 10px;
        padding-right: 10px;
        /* Adjust padding as needed */
    }

    .title {
        line-height: 1em !important;
    }

    /* Clear the margin-right for the last item in each row */
    .product-item:nth-child(3n) {
        margin-right: 0;
    }
}
</style>