<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <!-- Left navbar links -->
        <ul class="navbar-nav">
            <a class="nav-link" data-widget="pushmenu" role="button" @click="toggleSidebar"
                :class="{ 'mobile-sidebar-open': isSidebarOpen && isMobileScreen }">
                <i class="fas fa-bars"></i>
            </a>
            <li class="nav-item d-none d-sm-inline-block">
                <a href="index3.html" class="nav-link">Home</a>
            </li>
            <li class="nav-item d-none d-sm-inline-block">
                <a href="#" class="nav-link">Contact</a>
            </li>
        </ul>

        <!-- Right navbar links -->
        <ul class="navbar-nav ml-auto">
            <!-- Navbar Search -->
            <li class="nav-item">
                <a class="nav-link" data-widget="navbar-search" href="#" role="button">
                    <i class="fas fa-search"></i>
                </a>
                <div class="navbar-search-block">
                    <form class="form-inline">
                        <div class="input-group input-group-sm">
                            <input class="form-control form-control-navbar" type="search" placeholder="Search"
                                aria-label="Search">
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit">
                                    <i class="fas fa-search"></i>
                                </button>
                                <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </li>

            <!-- Message Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-comments"></i>
                    <span class="badge badge-danger navbar-badge">3</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <a href="#" class="dropdown-item">
                        <!-- Message Start -->
                        <div class="media">
                            <img src="admin_assets/dist/img/user1-128x128.jpg" alt="User Avatar"
                                class="img-size-50 mr-3 img-circle">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    Brad Diesel
                                    <span class="float-right text-sm text-danger"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">Call me whenever you can...</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>
                        <!-- Message End -->
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                        <!-- Message Start -->
                        <div class="media">
                            <img src="admin_assets/dist/img/user8-128x128.jpg" alt="User Avatar"
                                class="img-size-50 img-circle mr-3">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    John Pierce
                                    <span class="float-right text-sm text-muted"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">I got your message bro</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>
                        <!-- Message End -->
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                        <!-- Message Start -->
                        <div class="media">
                            <img src="admin_assets/dist/img/user3-128x128.jpg" alt="User Avatar"
                                class="img-size-50 img-circle mr-3">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    Nora Silvester
                                    <span class="float-right text-sm text-warning"><i class="fas fa-star"></i></span>
                                </h3>
                                <p class="text-sm">The subject goes here</p>
                                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> 4 Hours Ago</p>
                            </div>
                        </div>
                        <!-- Message End -->
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
                </div>
            </li>
            <!-- Notifications Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <i class="far fa-bell"></i>
                    <span class="badge badge-warning navbar-badge">15</span>
                </a>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <span class="dropdown-item dropdown-header">15 Notifications</span>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                        <i class="fas fa-envelope mr-2"></i> 4 new messages
                        <span class="float-right text-muted text-sm">3 mins</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                        <i class="fas fa-users mr-2"></i> 8 friend requests
                        <span class="float-right text-muted text-sm">12 hours</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item">
                        <i class="fas fa-file mr-2"></i> 3 new reports
                        <span class="float-right text-muted text-sm">2 days</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-widget="fullscreen" href="#" role="button">
                    <i class="fas fa-expand-arrows-alt"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                    <i class="fas fa-th-large"></i>
                </a>
            </li>
        </ul>
    </nav>
    <!-- Main Sidebar Container -->
    <aside style="margin-top:150px !important" class="main-sidebar"
        :class="{ 'sidebar-hidden': !isSidebarOpen, 'sidebar-mobile': !isSidebarOpen && isMobileScreen }">

        <!-- Brand Logo -->
        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar Menu -->
            <nav class="mt-2" style="background-color: black; font-size: 12pt;">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <!-- Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library -->

                    <li class="nav-item">
                        <router-link to="/super-admin-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>New Orders <span class="badge badge-danger ml-1">{{ orders }}</span></p>
                            <!-- <p>New Orders <span class="badge badge-danger ml-1"></span></p> -->
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/old-super-admin-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Not Reading Orders <span class="badge badge-danger ml-1">{{ notVerified }}</span></p>
                            <!-- <p>Not Reading Orders <span class="badge badge-danger ml-1"></span></p> -->
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/super-admin-pending-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <!-- <p>Pending Orders <span class="badge badge-danger ml-1">{{ PendingOrders }}</span> </p> -->
                            <p>Pending Orders <span class="badge badge-danger ml-1"></span> </p>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/super-admin-closed-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Closed Orders <span class="badge badge-danger ml-1"></span></p>
                            <!-- <p>Closed Orders <span class="badge badge-danger ml-1">{{ ClosedOrders }}</span></p> -->
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/super-admin-shipped-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <!-- <p>Shipped Orders <span class="badge badge-danger ml-1">{{ ShippedOrders }}</span></p> -->
                            <p>Shipped Orders <span class="badge badge-danger ml-1"></span></p>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/super-admin-delivered-orders" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <!-- <p>Delivered Orders <span class="badge badge-danger ml-1">{{ DeliveredOrders }}</span></p> -->
                            <p>Delivered Orders <span class="badge badge-danger ml-1"></span></p>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/barcode-product" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Invoice Barcode</p>
                        </router-link>
                    </li>

                    <li class="nav-item">
                        <router-link to="/prepare-order" class="nav-link">
                            <i class="far fa-circle nav-icon"></i>
                            <p>Orders to be prepared</p>
                        </router-link>
                    </li>


                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Dashboard</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard v1</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <!-- Small boxes (Stat box) -->
                <div class="row">
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div class="small-box bg-info">
                            <div class="inner">
                                <h3>150</h3>

                                <p>New Orders</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-bag"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div class="small-box bg-success">
                            <div class="inner">
                                <h3>53<sup style="font-size: 20px">%</sup></h3>

                                <p>Bounce Rate</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-stats-bars"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div class="small-box bg-warning">
                            <div class="inner">
                                <h3>44</h3>

                                <p>User Registrations</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-person-add"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                    <div class="col-lg-3 col-6">
                        <!-- small box -->
                        <div class="small-box bg-danger">
                            <div class="inner">
                                <h3>65</h3>

                                <p>Unique Visitors</p>
                            </div>
                            <div class="icon">
                                <i class="ion ion-pie-graph"></i>
                            </div>
                            <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                    <!-- ./col -->
                </div>
                <!-- /.row -->
                <!-- Main row -->

            </div>

        </section>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            orders: [],
            notVerified: [],
            PendingOrders: [],
            ClosedOrders: [],
            ShippedOrders: [],
            DeliveredOrders: [],
            isSidebarOpen: true,
            isMobileScreen: false,
            showDropdown: false,
        };
    },
    mounted() {
        this.checkMobileScreen(); // Check the screen size on component mount
        window.addEventListener('resize', this.checkMobileScreen); // Add a listener to update on window resize
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkMobileScreen); // Remove the listener on component unmount
    },
    methods: {


        async fetchOrderCount(page) {
            const { data } = await axiosInstance.get(page);
            return data;
        },

        async getOrderCounts() {
            try {
                this.notVerified = (await this.fetchOrderCount(`api/not-verified-orders-count`)).countOrderNotVerified;
                this.orders = (await this.fetchOrderCount(`api/sub-admin-orders-count`)).countOrder;
                // this.PendingOrders = (await this.fetchOrderCount(`api/sub-admin-pending-orders-count`)).pendingOrderCount;
                // this.ClosedOrders = (await this.fetchOrderCount(`api/sub-admin-closed-orders-count`)).closedCountOrder;
                // this.ShippedOrders = (await this.fetchOrderCount(`api/sub-admin-shipped-order-count`)).countShippedOrder;
                // this.DeliveredOrders = (await this.fetchOrderCount(`api/sub-admin-delivered-order-count`)).countDeliveredOrder;
            } catch (error) {
                console.error("Error fetching order counts:", error);
            }
        },

        checkForUpdates() {
            setInterval(() => {
                this.getOrderCounts();
            }, 10000);
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;
        },
        checkMobileScreen() {
            this.isMobileScreen = window.innerWidth < 768; // Set the isMobileScreen flag based on the window width
        },
    },

    created() {
        this.getOrderCounts();
        this.checkForUpdates();
        this.checkMobileScreen();
        window.addEventListener('resize', this.checkMobileScreen);
    },

};
</script>
    
    
<style scoped>
.nav-item {
    position: relative;
}

.nav-item .nav-link {
    cursor: pointer;
}

.nav-item ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    display: none;
    min-width: 200px;
    padding: 10px;
    margin: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 0;
    list-style: none;
}

.nav-item ul li {
    padding: 5px 0;
}

.nav-item ul li a {
    display: block;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
}

.nav-item.active ul {
    display: block;
}

.sidebar-hidden {
    display: none;
}

.sidebar-mobile {
    display: block !important;
    position: absolute;
    left: 50%;
    top: 50%;
}

.fa-bars {
    @media (max-width: 767px) {
        padding-left: 150px;
        position: fixed !important;
        /* Adjust the padding value as per your requirement */
        /* or use margin instead */
        /* margin-right: 10px; */
    }
}
</style>