<template>
    <p style="margin-top:0;margin-bottom:0;color:#484848;text-align:right;">
        <svg ref="barcode"></svg>
    </p>

    <table width="100%" class="data-table" cellpadding="0" cellspacing="0" style="page-break-inside: avoid;">

        <tr>
            <td style="background-color:#dddddd">

                <!-- ========= Table content ========= -->


                <table cellpadding="0" cellspacing="0" width="600" class="table-mobile" align="center"
                    style="margin-bottom: 70px !important">
                    <tr>
                        <td height="25"></td>
                    </tr>

                    <!-- ========= Header ========= -->

                    <tr>
                        <td>

                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center">
                                <tr>
                                    <td class="header-item">
                                        <img :src="'https://api.gocami.com/logo_image/' + logos.logo" alt="" />
                                    </td>
                                    <td class="header-item">
                                        <p
                                            style="font-family:sans-serif;font-size:20px;font-weight:bold;text-transform:uppercase;margin-top:0;margin-bottom:0;color:#484848;text-align:right;">
                                            Invoice
                                        </p>

                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Divider ========= -->

                    <tr>
                        <td>
                            <table cellpadding="0" cellspacing="0" width="100%" align="center"
                                style="page-break-inside: avoid;">
                                <tr>
                                    <td height="20"></td>
                                </tr>
                                <tr>
                                    <td style="border-bottom:1px solid #f8f8f8;" height="1"></td>
                                </tr>
                            </table>

                        </td>
                    </tr>

                    <tr>
                        <td
                            style="background:#ffffff;padding:35px 0;border-top:1px solid #eeeeee;border-bottom:1px solid #eeeeee; page-break-inside: avoid !important;">
                            <table cellpadding="0" cellspacing="0" class="table-mobile-small" align="center"
                                style="page-break-inside: avoid !important;">
                                <tr>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Shipping info</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Name:</strong> {{ invoices.first_name }} {{
                                                            invoices.middle_name }} {{ invoices.last_name }}
                                                    </p>

                                                    <p v-if="invoices.affiliate_first_name && invoices.affiliate_last_name"
                                                        style="font-family: sans-serif; font-size: 14px; font-weight: normal; text-transform: none; margin: 0; color: #3a3d45; text-align: left;">
                                                        <strong>Affiliate Name:</strong> {{
                                                            invoices.affiliate_first_name
                                                        }} {{ invoices.affiliate_last_name }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Phone:</strong> (+961) {{ invoices.phone }}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Location:</strong> {{ invoices.governorates_name }} {{
                                                            invoices.city_name }} {{ invoices.area_name }}
                                                    </p>
                                                </td>
                                            </tr>

                                            <tr class="break-before">
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Address:</strong> {{ invoices.address }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td width="50%" valign="top">
                                        <table cellpadding="0" cellspacing="0" width="100%" align="center">
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:22px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order details</strong>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order no: INV00</strong>{{ invoices.id }}
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="padding-top:5px;padding-bottom:5px;border-bottom:1px solid #f5f5f5;">
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Order Date :</strong> {{ invoices.pending_date }}
                                                    </p>
                                                    <p
                                                        style="font-family:sans-serif;font-size:14px;font-weight:normal;text-transform:none;margin:0;color:#3a3d45;text-align:left;">
                                                        <strong>Printing date :</strong> {{ currentDate }}
                                                    </p>
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                    <!-- ========= Booking details ========= -->

                    <div v-for="(carts, bundleId) in bundles" :key="bundleId" class="bundle-container">
                        <div class="bundle-header">
                            Bundle Name: <b>{{ carts[0].bundle.bundle_name }}</b>
                            <br>
                            Bundle Sku: <b>{{ carts[0].bundle.bundle_sku }}</b>
                            <br>
                            Bundle Price: <b>{{ carts[0].bundle.bundle_price.toFixed(2) }}</b>
                        </div>

                        <div>
                            <table class="product-table">
                                <tr>
                                    <th>Product Sku</th>
                                    <th>Product Title</th>
                                    <th>Quantity</th>
                                </tr>
                                <tr v-for="cart in carts" :key="cart.id">
                                    <td>{{ cart.productdetail.sku }}</td>
                                    <td>{{ cart.productdetail.title }}</td>
                                    <td>{{ cart.quantity }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>


                    <td width="300" valign="middle" class="product-title" align="center">
                        <input v-model="barcode" class="my-4 form-control w-50" @input="onBarcodeInput"
                            placeholder="Scan Barcode" />
                        <div v-if="productFound">✅ Product Found</div>
                    </td>

                    <tr>
                        <td style="background-color:#ffffff;">

                            <table cellpadding="0" cellspacing="0" width="100%" align="center">

                                <tbody>

                                    <!----------- product table header ----------->

                                    <tr class="product-header">
                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Title
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Sku
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Configuration
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Quantity
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Barcode
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Complete
                                            </p>
                                        </td>

                                        <td valign="middle" style="background-color:#f7f7f7;">
                                            <p
                                                style="font-family:sans-serif;font-size:10px;font-weight:bold;text-transform:uppercase;margin:0;color:#3a3d45;text-align:left;">
                                                Complete
                                            </p>
                                        </td>
                                    </tr>

                                    <!--product-->

                                    <tr v-for="carts in cart" :key="carts.id" style="page-break-inside: avoid;">
                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            {{ carts.title }}
                                        </td>

                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            {{ carts.sku }}
                                        </td>

                                        <td width="150" valign="middle" class="product-title"
                                            style="width:150px;font-size: 12px;">
                                            <span
                                                v-if="carts.attribute_type_id !== null || carts.color_id !== null || carts.size_id !== null">
                                                {{ carts.attribute_type_name }} | {{ carts.color_name }} | {{
                                                    carts.size_name }}
                                            </span>
                                            <span v-else>&nbsp;</span>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                {{ carts.quantity }}
                                            </p>
                                        </td>

                                        <td width="300" valign="middle" class="product-title">
                                            <p
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                {{ carts.barcode }}
                                            </p>
                                        </td>
                                        <td v-if="carts.is_complete_scan === 0">
                                            <p class="text-center"
                                                v-if="scanCounts[carts.product_detail_id] === undefined || scanCounts[carts.product_detail_id] === 0"
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                <span class="bg-danger p-3 text-center rounded-lg">x</span>
                                            </p>
                                        </td>



                                        <td class="text-center" v-else>
                                            <p class="text-center"
                                                style="font-family:sans-serif;font-size:14px;margin:0;color:#3a3d45;text-align:left;">
                                                <span>✅</span>
                                            </p>

                                        </td>

                                        <td v-if="carts.is_complete_scan === 0"> <span>Product ID: {{
                                            carts.product_detail_id }}, Scans: {{
        scanCounts[carts.product_detail_id] || 0 }}</span></td>

                                        <td>

                                        </td>


                                    </tr>


                                </tbody>


                                <div style="display:flex; padding-top: 50px; padding-left:25px;min-width:500px">
                                    Note:
                                    <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">{{ invoices.note }}</span>
                                    </p>
                                </div>


                                <div v-if="invoices.pick_up === 1" style="display:flex;padding-left:25px;min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">By Delivery</span>
                                    </p>
                                </div>
                                <div v-else-if="invoices.pick_up === 0"
                                    style="display:flex;padding-left:25px; min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">From Office</span>
                                    </p>

                                </div>

                                <div v-else style="display:flex;padding-left:25px; min-width:300px">
                                    Pick
                                    Up: <p
                                        style="font-family:sans-serif;font-size:24px;margin:0;color:#3a3d45;text-align:left;">
                                        <span style="padding-left: 15px">ملحق</span>
                                    </p>

                                </div>

                                <div style="padding-left: 25px; margin-top: 10px;">Total Quantity: <b>{{
                                    invoices.total_quantity }}</b></div>

                                <tr align="right" class="pb-5">
                                    <td align="right" colspan="4">
                                        <p v-if="invoices.pick_up == 1"
                                            style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:right;">
                                            $ {{ (totalPrice + invoices.price).toFixed(3) }}
                                        </p>
                                        <p v-else
                                            style="font-family:sans-serif;font-size:28px;font-weight:bold;text-transform:none;margin-top:0;margin-bottom:0;padding:0;color:#3a3d45;text-align:right;">
                                            $ {{ (totalPrice).toFixed(3) }}
                                        </p>
                                    </td>

                                </tr>

                                <tr>
                                    <!-- Your custom checkbox input -->
                                    <label class="custom-checkbox-container ms-5 d-flex">
                                        <input type="checkbox" v-model="isComplete">
                                        <div class="custom-checkbox">
                                            <div class="checkmark">✓</div>
                                        </div>

                                        <span class="ms-3">Check to complete</span>

                                        <button @click="updateCompleteStatus" class="btn btn-primary ms-4">Update
                                            Status</button>


                                    </label>
                                    <input ref="numberOfCopiesInput" type="number" class="form-control w-50 ms-5 my-4"
                                        :value="defaultNumberOfCopies" />
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
            </td>
        </tr>
    </table>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
import JsBarcode from 'jsbarcode';

export default {
    data() {
        return {
            isComplete: false, // Initial state of the checkbox
            cart: [],
            logos: [],
            invoices: [],
            invo: [],
            bundles: [],
            currentDate: '', // Initialize with an empty string
            barcode: '',
            productFound: false,
            scanCounter: 0,
            scanCounts: {},
            updateTriggered: {},
            notificationAudio: 'https://www.gocami.com/sounds/buzz1.wav',
            defaultNumberOfCopies: 2, // Set the default number of copies

        };

    },
    mounted() {
        this.getCurrentDate();
        this.generateBarcode();

        // Use setInterval to update the date and time every second
        setInterval(this.getCurrentDate, 1000);
    },

    watch: {
        'invoices.invoice_barcode': 'generateBarcode',
    },

    methods: {

        generateBarcode() {
            const barcodeData = this.invoices.invoice_barcode;
            const svg = this.$refs.barcode;
            svg.innerHTML = '';

            JsBarcode(svg, barcodeData, {
                format: 'CODE39',
                width: 1,
                height: 50,
            });
        },

        updateCompleteStatus() {
            const formData = {
                complete_checkbox: this.isComplete,
                numberOfCopies: this.$refs.numberOfCopiesInput.value,
            };

            // If the input is empty, use the default value
            const numberOfCopies = formData.numberOfCopies ? formData.numberOfCopies : this.defaultNumberOfCopies;

            axiosInstance.post(`api/invoice/${this.$route.params.id}/complete-check`, formData)
                .then(response => {
                    console.log(response.data.message);

                    // Wait for the API call to complete, then trigger printing logic
                    this.$nextTick(() => {
                        this.printPage(numberOfCopies);
                    });
                    this.$router.push({ path: '/barcode-product' });

                })
                .catch(error => {
                    console.error(error.response.data.error);
                });
        },

        printPage(numberOfCopies) {
            // Implement your printing logic here
            // You can use browser print functionality or a dedicated printing library
            // For example, using window.print():
            for (let i = 0; i < numberOfCopies; i++) {
                window.print(); // Print the page as many times as specified in the input
            }
        },

        playNotification() {
            // Add logic to play the notification sound
            const audio = new Audio(this.notificationAudio);
            audio.play();
        },

        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');

            this.currentDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
        },

        orderLoad() {
            var page = `api/barcode-export/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.cart = data.cart;
                this.logos = data.logo;
                this.invoices = data.invoice;
                this.invo = data.inv;
                this.bundles = data.bundle;
            });
        },
        onBarcodeInput() {
            // Clear any existing timer
            clearTimeout(this.barcodeTimer);

            // Set a new timer to delay the API request by 1000 milliseconds (1 second)
            this.barcodeTimer = setTimeout(() => {
                axiosInstance.post('api/scan-barcode', { barcode: this.barcode })
                    .then(response => {
                        const data = response.data;
                        console.log('Data from API:', data);

                        if (data.product) {
                            const productId = data.product.product_detail_id;

                            if (!this.scanCounts[productId]) {
                                this.scanCounts[productId] = 0;
                            }

                            this.scanCounts[productId]++;

                            const maxQuantity = this.getQuantityByProductDetailId(productId);

                            if (this.scanCounts[productId] <= maxQuantity) {
                                if (this.scanCounts[productId] === maxQuantity) {
                                    const invoiceId = parseInt(this.$route.params.id);
                                    console.log('Invoice ID from URL:', invoiceId);

                                    this.updateIsCompleteScanPromise(invoiceId, productId)
                                        .then(() => {
                                            this.updateTriggered[productId] = true;
                                        })
                                        .catch(error => {
                                            console.error(error);
                                        });
                                }
                                this.barcode = '';
                            } else {
                                // Quantity exceeded, play notification
                                this.barcode = '';
                                this.playNotification();

                            }
                        } else {
                            // Barcode does not exist, play notification
                            this.barcode = '';
                            this.playNotification();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }, 10); // Adjust the delay time as needed
        },


        updateIsCompleteScanPromise(invoiceId, productDetailId) {
            return new Promise((resolve, reject) => {
                console.log('Updating is_complete_scan...');
                // Call Laravel API to update is_complete_scan using POST
                axiosInstance.post(`api/update-is-complete-scan/${invoiceId}/${productDetailId}`)
                    .then(response => {
                        console.log('is_complete_scan updated successfully:', response.data);

                        // Clear the input field
                        this.barcode = '';

                        // Refresh the order data (optional)
                        this.orderLoad();

                        resolve(); // Resolve the promise
                    })
                    .catch(error => {
                        console.error('Error updating is_complete_scan:', error);
                        reject(error); // Reject the promise with the error
                    });
            });
        },


        getQuantityByProductDetailId(productDetailId) {
            // Helper function to get the quantity for a given product detail ID
            const product = this.cart.find(cartItem => cartItem.product_detail_id === productDetailId);
            return product ? product.quantity : 0;
        },
    },
    created() {
        this.orderLoad();
    },

    computed: {
        completedScans() {
            return this.cart.map(carts => ({
                product_detail_id: carts.product_detail_id,
                completed: this.scanCounts[carts.product_detail_id] === carts.quantity,
            }));
        },

        totalPrice() {
            let total = 0;

            this.invo.forEach((inv) => {
                if (inv.price_after_discount !== null) {
                    total += inv.price_after_discount;
                } else {
                    total += inv.sale_price * inv.quantity - (inv.sale_price * inv.quantity * inv.cart_discount / 100);
                }
            });

            return total;
        },

    }
}
</script>

<style scoped>
/* Style for the checkbox container */
/* Style for the checkbox container */
/* Style for the checkbox container */
/* Style for the checkbox container */
/* Style for the checkbox container */
.barcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.barcode-text {
    margin: 0;
    /* Remove margin */
    padding: 0;
    /* Remove padding */
}

.custom-checkbox-container {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

/* Style for the custom checkbox */
.custom-checkbox-container input {
    position: absolute;
    opacity: 0;
    /* Make the actual checkbox transparent */
    cursor: pointer;
}

.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    /* Size of the custom checkbox */
    height: 20px;
    background-color: #eee;
    /* Background color */
    border-radius: 4px;
    /* Rounded corners */
    border: 2px solid #ccc;
    /* Border color */
    transition: background-color 0.3s;
    /* Smooth transition on hover */
}

/* Style for the checkmark (hidden by default) */
.checkmark {
    position: absolute;
    left: 6px;
    /* Adjust the position of the checkmark */
    top: 2px;
    font-size: 14px;
    /* Adjust the size of the checkmark */
    color: #333;
    /* Checkmark color */
    visibility: hidden;
}

/* Style for the checkbox when checked */
.custom-checkbox-container input:checked+.custom-checkbox {
    background-color: #4CAF50;
    /* Background color when checked */
    border: 2px solid #4CAF50;
    /* Border color when checked */
}

/* Show the checkmark when the checkbox is checked */
.custom-checkbox-container input:checked+.custom-checkbox+.checkmark {
    visibility: visible;
}

/* Hover effect */
.custom-checkbox-container:hover .custom-checkbox {
    background-color: #ddd;
    /* Background color on hover */
}




@media print {}

@media print {
    .wtsp {
        display: none !important;
    }

    .page {
        page-break-after: avoid;
    }

    .data-table {
        display: none !important;
    }

}

.wtsp {
    display: none !important;
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/****** EMAIL CLIENT BUG FIXES - BEST NOT TO CHANGE THESE ********/

.ExternalClass {
    width: 100%;
}

/* Forces Outlook.com to display emails at full width */
.ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
}

/* Forces Outlook.com to display normal line spacing, here is more on that*/

template {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevents Webkit and Windows Mobile platforms from changing default font sizes. */

template {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

/* Resets all body margins and padding to 0 for good measure */

table td {
    border-collapse: collapse;
    border-spacing: 0px;
    border: 0px none;
    vertical-align: middle;
    width: auto;
}

/*This resolves the Outlook 07, 10, and Gmail td padding issue. Heres more info */
/****** END BUG FIXES ********/
/****** RESETTING DEFAULTS, IT IS BEST TO OVERWRITE THESE STYLES INLINE ********/

.table-mobile {
    width: 600px;
    margin: 0 auto;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: white;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.table-mobile-small {
    width: 560px !important;
}

body,
p {
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: #222222;
    font-size: 12px;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

a {
    color: #2e73bb;
    text-decoration: none;
    display: inline-block;
}

img {
    border: 0 none;
    display: block;
}

@media(min-width:480px) {
    .product-header td {
        padding: 25px;
    }

    .product-image,
    .product-title,
    .product-price {
        border-bottom: 1px solid #eeeeee;
    }

    .product-title {
        padding-left: 15px;
    }

    .product-price {
        padding-right: 25px;
    }
}

@media (max-width:479px) {
    body {
        padding-left: 10px;
        padding-right: 10px;
    }

    table,
    table>tbody,
    table>tr,
    table>tbody>tr,
    table>tr>td,
    table>tbody>tr>td {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        /*height: auto !important;*/
    }

    .table-mobile-small {
        width: 95% !important;
        max-width: 100% !important;
        display: block !important;
        overflow: hidden !important;
        box-sizing: border-box;
        height: auto !important;
    }

    .full-image {
        width: 100% !important;
    }

    .footer-content p {
        text-align: left !important;
    }

    .product-title,
    .product-price {
        width: 50% !important;
        float: left;
        border-bottom: 1px solid #eeeeee;
    }

    .product-image,
    .product-title,
    .product-price {
        padding: 10px;
    }

    .product-image img {
        width: 100% !important;
    }

    .product-price p {
        text-align: right !important;
    }

    .product-header {
        display: none !important;
    }

    .header-item {
        display: table-cell !important;
        float: none !important;
        width: 50% !important;
    }

    .table-mobile {
        box-shadow: none !important;
        margin: 0;
        border-radius: 0;
    }
}

.bundle-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
}

.bundle-header {
    font-weight: bold;
}

.product-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.product-table th,
.product-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.product-table th {
    background-color: #f2f2f2;
}
</style>