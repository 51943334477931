<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Size Panel</h3>

          <router-link to="/create-size" class="btn btn-primary">Size Color</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Size Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sizes in size" v-bind:key="sizes.id">
                <th scope="row">{{ sizes.id }}</th>
                <td>{{ sizes.size_name }}</td>
                <td>
                  <router-link :to="{ name: 'EditSize', params: { id: sizes.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(sizes)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
  name: "SizeView",

  data() {
    return {
      size: [],
    };
  },

  created() {
    this.sizeLoad();
  },

  methods: {
    sizeLoad() {
      var page = `api/size`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.size = data;
      });
    },

    remove(sizes) {
      var url = `api/size-delete/${sizes.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.size.indexOf(sizes);
        this.size.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>