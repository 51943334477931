<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>


                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Barcode Number</th>
                                <th>Details</th>
                                <th>Description</th>
                                <th>Video url</th>
                                <th>Weight</th>
                                <th>Product Tips</th>
                                <th>Expiry Date</th>
                                <th>User Position</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>

                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.barcode">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <textarea v-model="product.details" rows="3"></textarea>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <textarea v-model="product.description" rows="3"></textarea>
                                    </div>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.video_url">
                                    </div>
                                </td>


                                <td><input type="number" v-model="product.weight"></td>
                                <td><input type="text" v-model="product.product_tips"></td>
                                <td><input type="date" v-model="product.expiry_date"></td>
                                <td><input type="integer" v-model="product.product_position"></td>
                                <td><input type="integer" v-model="product.user_position"></td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>

                                    <router-link :to="{ name: 'MultiImage', params: { id: product.id } }"
                                        class="btn btn-warning me-2">Multi Image</router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
<!-- 
                    <div class="pagination">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li class="page-item" :class="{ disabled: !allProductDetails.prev_page_url }">
                                    <button class="page-link" @click="changePage(allProductDetails.current_page - 1)"
                                        :disabled="!allProductDetails.prev_page_url">Previous</button>
                                </li>
                                <li class="page-item" v-for="page in allProductDetails.last_page" :key="page"
                                    :class="{ active: page === allProductDetails.current_page }">
                                    <button class="page-link" @click="changePage(page)">{{ page }}</button>
                                </li>
                                <li class="page-item" :class="{ disabled: !allProductDetails.next_page_url }">
                                    <button class="page-link" @click="changePage(allProductDetails.current_page + 1)"
                                        :disabled="!allProductDetails.next_page_url">Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div> -->

                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            currentPage: 1,
            selectedBrand: null,
            brands: []
        };
    },

    created() {
        this.userLoad();
    },

    methods: {

        userLoad(brandId) {
            var nextPage = `api/data-entry/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.products;
                this.brands = data.brands;
            });
        },

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            } else {
                // Handle case where no brand is selected (show all products)
                this.userLoad();
            }
        },

        changePage(page) {
            this.currentPage = page;
            this.userLoad();
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-data/${product.id}`, {
                    details: product.details, weight: product.weight, description: product.description,
                    product_tips: product.product_tips, barcode: product.barcode, user_position: product.user_position, expiry_date: product.expiry_date, video_url: product.video_url, product_position: product.product_position
                })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}
</style>
  