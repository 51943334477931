<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <div v-if="showZoomedImage" id="zoomedImageContainer" style="width: 100px; height: 100px;">
                        <div class="zoomedImageContent" style="width: 100%; height: 100%;">
                            <span class="close" @click="closeZoomedImage">&times;</span>
                            <img :src="zoomedImageSrc" alt="Zoomed Image" style="max-width: 100%; max-height: 100%;">
                        </div>
                    </div>

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>Sku</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Price</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Total Commission</th>
                                <th>Affiliate Commission</th>
                                <th>Sub Affiliate Commission</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td>{{ product.sku }}</td>
                                <td>{{ product.title }}</td>
                                <td><img @click="zoomImage(product.image)"
                                        :src="'https://api.gocami.com/product_detail/' + product.image" width="100" />
                                </td>
                                <td v-if="product.sale_price">${{ product.sale_price.toFixed(2) }}</td>
                                <td><p v-html="product.description"></p></td>
                                <td>{{ product.quantity }}</td>
                                <td v-if="product.total_commission">{{ product.total_commission }}</td>
                                <td v-if="product.affiliate_commission">{{ product.affiliate_commission }}</td>
                                <td v-if="product.sub_affiliate_commission">{{ product.sub_affiliate_commission }}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
            currentPage: 1,
            selectedBrand: null,
            brands: [],
            showZoomedImage: false,
            zoomedImageSrc: ''
        };
    },

    created() {
        this.userLoad();
    },

    methods: {

        zoomImage(imageSrc) {
            this.zoomedImageSrc = 'https://api.gocami.com/product_detail/' + imageSrc;
            this.showZoomedImage = true;
        },

        closeZoomedImage() {
            this.showZoomedImage = false;
        },


        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            }
        },

        userLoad(brandId) {
            var nextPage = `api/get-old-product-acc/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.products.map(product => ({
                    ...product,
                    checked: false // Adding a checked property to each product
                }));
                this.brands = data.brands;
            });
        },


    },
}
</script>


<style scoped>
.link-to:hover {
    color: red;
}

.bg-danger {
    background-color: red;
}

.bg-warning {
    background-color: orange;
}

.bg-success {
    background-color: green;
}

/* New styles for zoomed image container */
#zoomedImageContainer {
    display: block;
    /* Add this line to make the container visible */
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.9); */
    overflow: auto;
}

.zoomedImageContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999999 !important;
}

.zoomedImageContent img {
    z-index: 99999 !important;
    max-width: 100%;
    max-height: 100%;
    border: 2px solid #fff;
    /* Optional: Add a border around the image */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* Optional: Add a box shadow */
}

.close {
    color: red !important;
    position: absolute;
    top: 10px;
    right: 110px;
    font-size: 30px !important;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.close:hover {
    transform: scale(1.2);
    /* Optional: Increase the size of the close icon on hover */
}
</style>