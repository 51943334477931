<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="userbranddiscount.brand_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Brand --</option>
                            <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                                {{ brand.brand_name }}
                            </option>
                        </select>

                        <select v-model="userbranddiscount.user_discount_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="simplediscount in simplediscounts" :key="simplediscount.id" :value="simplediscount.id">
                                {{ simplediscount.user_discount_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateUserBrandDiscount",

    data() {
        return {
            userbranddiscount: {
                brand_id: 0,
                user_discount_id: 0,
            },
            brands: [], // Update variable name to employees
            simplediscounts: []
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('brand_id', this.userbranddiscount.brand_id);
            formData.append('user_discount_id', this.userbranddiscount.user_discount_id);
            axiosInstance.post(`api/user-brand-discount-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/user-brand-discount");
            });
        },
        fetchAttributes() {
            axiosInstance
                .get(`api/user-brand-discount-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.brands = response.data.brand;
                    this.simplediscounts = response.data.simplediscount;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};
</script>
