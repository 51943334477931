<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">

                        <select v-model="affiliatediscount.discount_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Discount --</option>
                            <option v-for="discount in discounts" :key="discount.id" :value="discount.id">
                                {{ discount.discount_name }}
                            </option>
                        </select>

                        <select v-model="affiliatediscount.user_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose User --</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">
                                {{ user.first_name }} {{ user.last_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateAffiliateDiscount",

    data() {
        return {
            affiliatediscount: {
                user_id: 0,
                discount_id: 0,
            },
            users: [],
            discounts: [],
        };
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            let formData = new FormData();
            formData.append('user_id', this.affiliatediscount.user_id);
            formData.append('discount_id', this.affiliatediscount.discount_id);
            axiosInstance.post(`api/affiliate-discount-store`, formData, {
            }).then(() => {
                // Redirect to the desired page
                router.push("/affiliate-discount");
            });
        },

        fetchAttributes() {
            axiosInstance
                .get(`api/affiliate-discount-create`) // Update endpoint to fetch employees
                .then(response => {
                    this.users = response.data.user;
                    this.discounts = response.data.discount;
                });
        },
    },
    mounted() {
        this.fetchAttributes();
    },
};
</script>
