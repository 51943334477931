<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="filter.id" />

                        <input type="text" class="form-control" v-model="filtertype.product_filter" /><br /><br />

                        <select class="form-control mb-3" v-model="filtertype.filter_id">
                            <option v-for="filters in filter" :key="filters.id" :value="filters.id">
                                {{ filters.filter_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditFilterType",

    data() {
        return {
            filtertype: {
                filter_id: "",
                product_filter: "",
                id: this.$route.params.id,
            },
            filter: {}, // new data property to store the old employee data
            oldFiltertype: {},
        };
    },
    methods: {

        editData() {
            let formData = new FormData();
            if (this.filtertype.filter_id) {
                formData.append('filter_id', this.filtertype.filter_id);
            }
            if (this.filtertype.product_filter) {
                formData.append('product_filter', this.filtertype.product_filter);
            }
            axiosInstance.post(`api/filtertype-update/${this.filtertype.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.filtertype = {
                    filter_id: "",
                    product_filter: "",
                    id: "",
                };
                router.push("/filtertype");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/filtertype-edit/` + this.filtertype.id)
                .then((response) => {
                    this.filtertype = response.data.filtertype;
                    this.filter = response.data.filter;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>