<template>
    <div>
        Privacy policy
        This Privacy Policy describes how Gocami.com collects, uses, and discloses your Personal Information when you make a
        purchase from the Site.

        Collecting Personal Information
        When you make an order on the Site, we collect certain information about your device, your interaction with the
        Site, and information necessary to process your purchases. We may also collect additional information if you contact
        us for customer support. In this Privacy Policy, we refer to any information that can uniquely identify an
        individual (including the information below) as “Personal Information”. See the list below for more information
        about what Personal Information we collect and why.

        Device information

        Examples of Personal Information collected: what sites or products you view, search terms, and how you interact with
        the Site.
        Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our
        Site.
        Source of collection: Collected when you make an order, or pixels
        Order information
        Examples of Personal Information collected: name, billing address, shipping address, payment information, email
        address, and phone number.
        Purpose of collection: to provide products or services to you to fulfill our contract, to process your payment
        information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you,
        screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us,
        provide you with information or advertising relating to our products or services.
        Source of collection: collected from you.

    Minors
    The Site is not intended for individuals under the age of 13. We do not intentionally collect Personal Information
    from children. If you are the parent or guardian and believe your child has provided us with Personal Information,
    please contact us at the address below to request deletion.

    Behavioural Advertising

    We use Google Analytics to help us understand how our customers use the Site. You can read more about how Google
    uses your Personal Information here: https://policies.google.com/privacy?hl=en.You can also opt-out of Google
    Analytics here: https://tools.google.com/dlpage/gaoptout.

</div></template>