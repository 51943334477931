<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Attribute Type Panel</h3>

          <router-link to="/create-attributetype" class="btn btn-primary">Create Attribute Type</router-link>

          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>Attribute Name</th>
                <th>Attribute Type Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="attributetypes in attributetype" v-bind:key="attributetypes.id">
                <th scope="row">{{ attributetypes.id }}</th>
                <td>{{ attributetypes.attribute.attribute }}</td>
                <td>{{ attributetypes.attribute_type_name }}</td>
                <td>
                  <router-link :to="{ name: 'EditAttributeType', params: { id: attributetypes.id } }"
                    class="btn btn-warning me-2"><i class="fa fa-pencil"></i></router-link>

                  <button @click="remove(attributetypes)" class="btn btn-danger"><i class="fa fa-trash"></i></button>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
  name: "AttributeTypeView",

  data() {
    return {
      attributetype: [],
    };
  },

  created() {
    this.attributetypeLoad();
  },

  methods: {
    attributetypeLoad() {
      var page = `api/attributetype`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.attributetype = data;
      });
    },

    remove(attributetypes) {
      var url = `api/attributetype-delete/${attributetypes.id}`;
      axiosInstance.delete(url).then(() => {
        const index = this.attributetype.indexOf(attributetypes);
        this.attributetype.splice(index, 1);
      });
    },
  },
};

</script>
    
<style scoped></style>