<template>
    <div>

        <div class="row text-center justify-content-center mt-0 pt-0 mb-4">
            <!-- <span class="brand-name">{{ brands.brand_name }}</span> -->
            <img v-if="isPC" :src="'https://api.gocami.com/brand_image/' + getbrand.brand_image" alt="" class="brand_image" />
            <img v-else :src="'https://api.gocami.com/mobile_brand_image/' + getbrand.mobile_brand_image" alt=""  />
        </div>

        <div class="container">
            <div class="row product-item-container">
                <div v-for="product in products" :key="product.id" class="col-md-3 col-lg-3 product-item">
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.id } }">
                        <span class="product-images">
                            <img :src="'https://api.gocami.com/product_main/' + product.image" height="180"
                                alt="Product Image">
                        </span>
                        <div v-if="product.discount > 0">
                            <h2>{{ product.product_name }}</h2>
                            <p>%{{ product.discount }}</p>
                            <p><strong>${{ (product.price - (product.price * product.discount / 100)).toFixed(2) }}</strong>
                                <del class="text-danger">$ {{ product.price.toFixed(2) }}</del>
                            </p>
                        </div>
                        <div v-else>
                            <h2>{{ product.product_name }}</h2>
                            <p>$ {{ product.price.toFixed(2) }}</p>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>
    </div>

    <FooterLayout />
</template>
<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';

export default {
    components: { FooterLayout },

    data() {
        return {
            brands: [],
            products: [],
            getbrand: [],
            isPC: false,
        };
    },

    methods: {
        checkIfPC() {
            this.isPC = window.innerWidth >= 768;
        },

        dataLoad() {
            var page = `api/brand-view/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.brand = data.brand;
                this.products = data.products;
            });
        },

        brandImg() {
            var page = `api/get-brand-images/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                this.getbrand = data.brand;
            });
        },
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);

        window.removeEventListener("resize", this.checkIfPC);
    },

    mounted() {
        this.checkIfPC();
        window.addEventListener("resize", this.checkIfPC);
    },

    created() {
        this.dataLoad();
        this.brandImg();
    }
}
</script>
  
<style scoped>
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.6;
        /* Adjust opacity for a subtle effect */
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@media screen and (min-width: 769px) {
    .product-item {
        position: relative;
        width: 300px !important;
        height: 350px !important;
        /* margin: 20px; */
        overflow: hidden;
        border-radius: 8px;
        transition: transform 0.3s ease;
    }


}

.product-item:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.product-info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.product-item:hover .product-info {
    opacity: 1;
    transform: translateY(0);
}

.product-info h2 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
}

.product-info p {
    font-size: 16px;
    margin: 0;
}

.brand-name {
    font-family: 'Poppins', sans-serif !important;
    /* Change 'Poppins' to any preferred font */
    /* Use any preferred font family */
    font-size: 24px !important;
    /* Adjust the font size as needed */
    font-weight: bold !important;
    /* Adjust the font weight as needed */
    color: #333 !important;
    /* Adjust the color as needed */
    text-transform: uppercase;
    /* Optionally capitalize the text */
    letter-spacing: 5px !important;
    margin: 25px 0;
    /* Optionally adjust letter spacing */
    /* Add any additional styling as desired */
}

@media screen and (max-width: 768px) {
    .brand-image {
        height: 200px !important;
    }

    .product-item-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* Adjust as needed */
    }

    .product-item {
        flex-basis: calc(50% - 10px);
        /* Display three products per row, adjust as needed */
        max-width: calc(50% - 10px);
        /* Adjust the maximum width and spacing as needed */
        margin-left: 10px !important;
        /* Display two items per row */
        box-sizing: border-box;
        /* Include padding and border in the total width and height */
        margin-bottom: 20px;
        height: 350px;
        overflow: hidden;
        border-radius: 8px;
        transition: transform 0.3s ease;
        /* Adjust as needed */
    }

    .brand_image{
        height: 150px;
    }

}
</style>
  