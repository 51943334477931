<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Refunded Products</h3>

                    <div class="card">
                        <div class="card-body">
                            <h3 class="mb-5">Orders Panel</h3>

                            <table class="responsive table-responsive table w-100">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Product Sku</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(order, index) in allOrder" :key="index">
                                        <th scope="row">{{ index + 1 }}</th>
                                        <th> <img :src="'https://api.gocami.com/product_detail/' + order.image" width="50"
                                                height="50" /></th>
                                        <td>{{ order.title }}</td>
                                        <td>{{ order.sku }}</td>
                                        <td class="bg-danger text-center">{{ order.refund_quantity }}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../axiosInstance';

export default {

    data() {
        return {
            allOrder: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var endpoint = `api/super-admin-refund-product`;
            axiosInstance.get(endpoint).then(({ data }) => {
                console.log(data);
                this.allOrder = data;
            });
        },

    },
};
</script>
  