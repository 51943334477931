<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <table class="table table-responsive">
            <tr>
              <td>Names</td>
              <td>Action</td>
            </tr>

            <tr v-for="aff in affs" :key="aff.id">
              <td>{{ aff.first_name }} {{ aff.last_name }}</td>
              <td><router-link :to="{ name: 'AdminExportResultsWithoutTarget', params:{id:aff.id} }" class="btn btn-success">View</router-link></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axiosInstance from "../../../axiosInstance.js"

export default {
  data() {
    return {
      affs: []
    }
  },

  created() {
    this.orderLoad();
  },

  methods: {
    orderLoad() {
      var page = `api/export-admin-names`;
      axiosInstance.get(page).then(({ data }) => {
        this.affs = data.aff;
      });
    },
  }
}

</script>