<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <table class="table table-responsive">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Main Price</th>
                                <th>Old Price</th>
                                <th>New Price</th>
                                <th>Discount</th>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Brand</th>
                                <th>Product Image</th>
                                <th>Image</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails.data" :key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.product_name }}</td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.price">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.old_price">
                                    </div>
                                </td>
                                <td v-if="product.new_price">
                                    {{product.new_price.toFixed(2)}}
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.discount">
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <input type="text" v-model="product.description">
                                    </div>
                                </td>

                                <td>
                                    <div class="form-group">
                                        <select v-model="product.category_id">
                                            <option v-for="category in category" :key="category.id" :value="category.id">
                                                {{ category.category_name }}</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-group">
                                        <label for=""></label>
                                        <select v-model="product.brand_id">
                                            <option v-for="brand in brand" :key="brand.id" :value="brand.id">{{
                                                brand.brand_name }}</option>
                                        </select>
                                    </div>
                                </td>
                                <td v-if="product.image == null">
                                    <div class="form-group">
                                        <label for=""></label>
                                        <input type="file" accept="image/*" @change="onFileChange($event, product.id)"
                                            class="form-control mb-3" />
                                    </div>
                                </td>

                                <td v-else><img :src="'https://api.gocami.com/product_main/' + product.image" width="50"
                                        height="50" /></td>

                                <td v-if="product.image != null"> <button @click="remove(product)" class="btn btn-danger"><i
                                            class="fa fa-trash"></i></button>
                                </td>

                                <td v-else>:D</td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">
                                        Update
                                    </button>
                                </td>

                                <td>
                                    <button @click="updateImage(product)" class="btn btn-primary">
                                        Update Image
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="pagination">
                        <nav aria-label="Page navigation">
                            <ul class="pagination">
                                <li class="page-item"
                                    :class="{ disabled: !allProductDetails || !allProductDetails.prev_page_url }">
                                    <button class="page-link" @click="changePage(allProductDetails.current_page - 1)"
                                        :disabled="!allProductDetails || !allProductDetails.prev_page_url">Previous</button>
                                </li>
                                <template v-if="allProductDetails && allProductDetails.last_page">
                                    <li v-for="page in allProductDetails.last_page" :key="page"
                                        :class="{ active: allProductDetails.current_page === page }">
                                        <button class="page-link" @click="changePage(page)">{{ page }}</button>
                                    </li>
                                </template>
                                <li class="page-item"
                                    :class="{ disabled: !allProductDetails || !allProductDetails.next_page_url }">
                                    <button class="page-link" @click="changePage(allProductDetails.current_page + 1)"
                                        :disabled="!allProductDetails || !allProductDetails.next_page_url">Next</button>
                                </li>
                            </ul>
                        </nav>
                    </div>



                </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: {},
            currentPage: 1,
            category: [],
            brand: [],
            selectedImage: {}, // Use an object to store selected images for each row

        };
    },

    created() {
        this.userLoad();
    },

    methods: {

        userLoad() {
            var nextPage = `api/main-product-entry?page=${this.currentPage}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                console.log(data); // Log the entire response
                console.log(data.allProductDetails); // Log specifically the allProductDetails
                this.allProductDetails = data.allProductDetails;
                this.category = data.category;
                this.brand = data.brand;
            });
        },

        changePage(page) {
            this.currentPage = page;
            this.userLoad();
        },

        onFileChange(event) {
            this.selectedImage = event.target.files[0]; // Capture the selected image
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/update-product-data/${product.id}`, {
                    category_id: product.category_id, brand_id: product.brand_id,
                     discount: product.discount, description: product.description,
                      price: product.price, old_price: product.old_price
                })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                    this.userLoad();

                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },

        updateImage(product) {
            console.log('Updating image for product:', product);

            // Create a FormData object to send the image file
            const formData = new FormData();
            formData.append('image', this.selectedImage); // Use 'image' as the key

            axiosInstance
                .post(`api/update-product-image/${product.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Image updated successfully:', response.data);
                    this.userLoad();
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating image:', error);
                });
        },

        remove(product) {
            var url = `api/destroy-product-image/${product.id}`;
            axiosInstance.delete(url).then(() => {
                const index = this.allProductDetails.data.indexOf(product);
                if (index !== -1) {
                    this.allProductDetails.data.splice(index, 1);
                }
                this.userLoad();
            });
        },
    },
}
</script>
  
<style scoped>
* {
    font-size: 12pt !important;
}

.pagination {
    display: flex;
    justify-content: center;
}

.pagination ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
    /* Adjust the margin as needed */
}
</style>
  