<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Product Detail</h3>
                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product Title</th>
                                <th>Product Sku</th>
                                <th>Expiry Dates</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, productIndex) in allProductDetails" :key="product.id">
                                <th scope="row">{{ product.id }}</th>
                                <td>{{ product.title }}</td>
                                <td>{{ product.sku }}</td>
                                <td class="flex" style="display: flex">
                                    <div v-for="(expiry, expiryIndex) in product.expiry_dates" :key="expiryIndex">
                                        <input type="date" v-model="product.expiry_dates[expiryIndex]" class="form-control">
                                        <button @click="removeExpiryDate(productIndex, expiryIndex)" class="btn bg-danger ml-2"><i class="fa fa-trash"></i></button>
                                    </div>
                                    <button @click="addExpiryDate(productIndex)" class="btn bg-success ml-2"><i class="fa fa-plus"></i></button>
                                </td>
                                <td>
                                    <button @click="updateDescription(product)" class="btn btn-primary">Update</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        userLoad() {
            var page = `api/data-entry`;
            axiosInstance.get(page).then(({ data }) => {
                this.allProductDetails = data.map(product => ({
                    ...product,
                    expiry_dates: [product.expiry_date], // Initialize with the existing expiry date
                }));
            });
        },

        addExpiryDate(productIndex) {
            this.allProductDetails[productIndex].expiry_dates.push(""); // Add a new empty expiry date
        },

        removeExpiryDate(productIndex, expiryIndex) {
            this.allProductDetails[productIndex].expiry_dates.splice(expiryIndex, 1); // Remove the expiry date at the specified index
        },

        updateDescription(product) {
            console.log('Updating description for product:', product);

            axiosInstance
                .post(`api/expiry-product-date/${product.id}`, { expiry_dates: product.expiry_dates })
                .then(response => {
                    // You can handle the response as needed
                    console.log('Details updated:', response.data);
                })
                .catch(error => {
                    // Handle error
                    console.error('Error updating details:', error);
                });
        },
    },
};
</script>