<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="bundle.id" />
                        <input type="text" class="form-control mb-3" v-model="bundle.bundle_name" />
                        <input type="text" class="form-control mb-3" v-model="bundle.bundle_sku" />
                        <input type="text" class="form-control mb-3" v-model="bundle.bundle_price" />
                        <input type="text" class="form-control mb-3" v-model="bundle.bundle_discount" />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="bundle.progress" :value="bundle.progress.percentage" max="100">
                            {{ bundle.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditBundle",

    data() {
        return {
            bundle: {
                bundle_name: "",
                bundle_sku: "",
                bundle_image: "",
                bundle_price: "",
                bundle_discount: "",
                id: this.$route.params.id,
            },
            oldBundle: {}, // new data property to store the old employee data
        };
    },
    methods: {
        onFileChange(event) {
            this.bundle.bundle_image = event.target.files[0];
        },
        editData() {
            let formData = new FormData();
            if (this.bundle.bundle_name) {
                formData.append('bundle_name', this.bundle.bundle_name);
            }
            if (this.bundle.bundle_sku) {
                formData.append('bundle_sku', this.bundle.bundle_sku);
            }
            if (this.bundle.bundle_image) {
                formData.append('bundle_image', this.bundle.bundle_image);
            }
            if (this.bundle.bundle_price) {
                formData.append('bundle_price', this.bundle.bundle_price);
            }
            if (this.bundle.bundle_discount) {
                formData.append('bundle_discount', this.bundle.bundle_price);
            }
            axiosInstance.post(`api/bundle-update/${this.bundle.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.bundle = {
                    bundle_name: "",
                    bundle_sku: "",
                    bundle_image: "",
                    bundle_price: "",
                    bundle_discount: "",
                    id: "",
                };
                router.push("/bundle");
            }).catch((error) => {
                console.log(error);
            });
        },
        fetchData() {
            axiosInstance
                .get(`api/bundle-edit/` + this.bundle.id)
                .then((response) => {
                    this.oldBunde = response.data;
                    this.bundle.bundle_name = this.oldBunde.bundle_name;
                    this.bundle.bundle_sku = this.oldBunde.bundle_sku;
                    this.bundle.bundle_price = this.oldBunde.bundle_price;
                    this.bundle.bundle_image = this.oldBunde.bundle_image;
                    this.bundle.bundle_discount = this.oldBunde.bundle_discount;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>