<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData">
                        <input type="hidden" v-model="discount.id" />
                        <input type="text" class="form-control" v-model="discount.discount_name" /><br/> <br/>
                        <input type="text" class="form-control" v-model="discount.commission" />

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import router from "@/router";
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "EditDiscount",

    data() {
        return {
            discount: {
                discount_name: "",
                commission: "",
                id: this.$route.params.id,
            },
            oldDiscount: {}, // new data property to store the old employee data
        };
    },
    methods: {
        editData() {
            axiosInstance
                .put(
                    `api/discount-update/${this.discount.id}`, // Use the BASE_URL constant for the base URL
                    this.discount
                )
                .then(() => {
                    this.discount = {
                        discount_name: "",
                        commission: "",
                        id: "",
                    };
                    router.push("/discount");
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        fetchData() {
            axiosInstance
                .get(`api/discount-edit/` + this.discount.id)
                .then((response) => {
                    this.oldDiscount = response.data; // store the old employee data in oldEmployee property
                    this.discount.discount_name = this.oldDiscount.discount_name; // set the old employee_name value to the input
                    this.discount.commission = this.oldDiscount.commission; // set the old employee_name value to the input
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.fetchData(); // call the fetchData method when the component is mounted to fetch the old employee data
    },
};
</script>