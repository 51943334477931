
<template>
    <!-- ========================  404 ======================== -->

    <section class="not-found">
        <div class="container">
            <h1 class="title" data-title="Page not found!">404</h1>
            <div class="h4 subtitle">Sorry! Page not found.</div>
            <p>The requested URL was not found on this server. That’s all we know.</p>
            <p>Click <router-link to="/">here</router-link> to get to the front page? </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>