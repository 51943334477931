<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <input type="text" class="form-control" placeholder="Create Color" v-model="color.color_name" /><br /><br />

                        <button type="submit" class="btn btn-success">Create</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateColor",

    data() {
        return {
            color: {
                color_name: "",
            },
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            axiosInstance
                .post(`api/color-store`, this.color)
                .then(() => {
                    // Redirect to the desired page
                    router.push("/color");
                });
        },
    },
};
</script>