<template>
  <!-- ========================  Main header ======================== -->

  <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
    {{ message }}
  </div>

  <div v-if="showMaxQuantityError" class="toast" :class="{ 'error': isError }">
    Max Quantity has been excceed
  </div>

  <!-- <section class="main-header text-center justify-content-center m-auto" style="margin: 0;">
    <header>
      <div class="container">
        <h2 class="h2 title">Checkout</h2>
        <ol class="breadcrumb breadcrumb-inverted text-center justify-content-center m-auto">
          <li>
            <router-link to="/"><span class="icon icon-home"></span></router-link>
          </li>
          <li><router-link class="active" to="/">Cart items</router-link></li>
        </ol>
      </div>
    </header>
  </section> -->

  <!-- ========================  Checkout ======================== -->

  <section class="checkout">
    <div class="container">
      <!-- ========================  Cart wrapper ======================== -->

      <div>
        <div v-for="bdl in bdlArray" :key="bdl.id" style="display:flex">
          <div class="cart-block cart-block-item" style="margin-top: 1%; display: flex; align-items: start;">
            <div style="display: flex; flex-direction: column; align-items: center; justify-items: center;">
              <div class="image">
                {{ bdl.bundle_sku }} - $ {{ bdl.bundle_price.toFixed(2) }}
                <img :src="'https://api.gocami.com/bundle_image/' + bdl.bundle_image" alt=""
                  style="min-width: 75px; height: 75px;" />
              </div>
              <button class="btn btn-danger" @click="removeBdl(bdl)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div v-for="cartBdl in cartBdl" :key="cartBdl.id" style="display: block !important;">
          <p>{{ cartBdl.productdetail.title }} - {{ cartBdl.quantity }} - ${{ cartBdl.sale_price }}</p>
        </div>

      </div>


      <div class="cart-block cart-block-footer clearfix" v-if="bdlArray > 0">
        <div>
          <strong>Total</strong>
        </div>
        <div>
          <span>$ {{ bdlTotal.toFixed(2) }}</span>
        </div>
      </div>

      <div class="cart-wrapper">
        <!--cart header -->

        <div v-if="this.user?.id">

          <div class="cart-block cart-block-header clearfix">
            <div>
              <span>Product</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
            <div>
              <span>Quantity</span>
            </div>
            <div class="text-right">
              <span>Price</span>
            </div>

          </div>

          <div class="clearfix">

            <!-- 
                <div v-for="bdl in cart" :key="bdl.id">
                  <div style="display: flex; flex-direction: row; align-items: center; justify-items: center;">
                    <div class="image">
                      {{ bdl.bundle.bundle_sku }} ---- %{{ bdl.bundle.bundle_discount }} - {{
                        bdl.bundle.bundle_price.toFixed(2) }}
                      <img :src="'https://api.gocami.com/product_detail/' + bdl.productdetail.image" alt=""
                        style="min-width: 75px; height: 75px;" />
                    </div>
                    <div v-if="cart.length"></div>
                    <button @click="removeBundleItem(cart, bdl)" class="btn btn-danger">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              -->

            <!--cart items-->


            <div v-if="isLoading" class="spinner">Loading...</div>

            <div class="clearfix" v-else>
              <div class="cart-block cart-block-item clearfix"
                style="margin-top: 1%; display: flex; align-items: center;" v-for="cart in carts" :key="cart.id">
                <div class="image">
                  <img :src="'https://api.gocami.com/product_detail/' + cart.productdetail.image" alt="" />
                </div>

                <div class="title" style="flex-grow: 1;">
                  <div class="h4">{{ cart.productdetail.title }}</div>
                  <div>{{ cart.productdetail.sku }}</div>
                </div>


                <span>
                  <div class="input-group quantity">

                    <template v-if="cart.is_spinner === 1">
                      <div v-if="cart.quantity > cart.productdetail.productconfiguration.quantity" class="out-of-stock">
                        Out of stock
                      </div>
                      <div v-else>
                        {{ cart.quantity }}
                      </div>
                    </template>

                    <template v-else>
                      <div v-if="cart.quantity > cart.productdetail.productconfiguration.quantity" class="out-of-stock">
                        Out of stock
                      </div>
                      <div v-else>
                        <button class="btn btn-sm btn-primary" :disabled="cart.quantity <= 1"
                          @click="decrementQuantity(cart)">
                          <i class="fa fa-minus"></i>
                        </button>

                        <div>
                          <input v-model="cart.quantity" class="form-control form-quantity" readonly />
                        </div>

                        <button class="btn btn-sm btn-primary" :disabled="isDisabled(cart)"
                          @click="incrementQuantity(cart)">
                          <i class="fa fa-plus"></i>
                        </button>

                        <div v-if="insufficientPoints" class="alert alert-danger">
                          You don't have enough points to increment the quantity.
                        </div>
                      </div>
                    </template>

                  </div>
                </span>

                <div class="price" style="margin-left: auto;" v-if="cart.productdetail.discount > 0">

                  <span class="final h3" style="margin-right: 10px;" v-if="cart.price_after_discount != null">
                    ${{ cart.price_after_discount.toFixed(2) }}
                  </span>

                  <span class="final h3" style="margin-right: 10px;" v-else>
                    ${{ (cart.sale_price * cart.quantity - (cart.sale_price * cart.quantity *
    cart.productdetail.discount
    /
    100)).toFixed(2) }}
                  </span>

                  <span class="discount" v-if="cart.price_after_discount != null" style="margin-right: 10px;">
                    ${{ (cart.sale_price * cart.quantity).toFixed(2) }}
                  </span>

                  <span class="discount" v-else-if="cart.price_after_discount === null" style="margin-right: 10px;">
                    ${{ (cart.sale_price * cart.quantity).toFixed(2) }}
                  </span>

                </div>

                <div class="price" style="margin-left: auto;" v-else>
                  <span class="final h3" style="margin-right: 10px;">
                    ${{ cart.price_after_discount ? (cart.price_after_discount).toFixed(2) : (cart.sale_price
    *
    cart.quantity - (cart.sale_price * cart.quantity * cart.productdetail.discount /
      100)).toFixed(2) }}
                  </span>
                  <span class="discount" v-if="cart.price_after_discount != null" style="margin-right: 10px;">
                    ${{ (cart.sale_price * cart.quantity).toFixed(2) }}
                  </span>
                </div>

                <!-- <div v-if="cart.offers && cart.offers.length > 0" class="offer-details">
                  <div v-for="offer in cart.offers" :key="offer.id">
                    <template v-if="cart.quantity >= offer.main_quantity">
                      <p>{{ offer.offer_product.title }}</p>
                      <p>{{ offer.offer_quantity }}</p>
                    </template>
                  </div>
                </div> -->

                <!--delete-this-item-->
                <button @click="remove(cart)" class="btn btn-danger" style="background-color: #f9534f
  ;">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>


            <!-- <div v-if="freeProducts && freeProducts.length > 0">
            <h2>Free Products</h2>
            <div class="row row-clean">
              <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 mx-3" v-for="relatedProduct in freeProducts"
                :key="relatedProduct.id">
                <article>
                  <div class="figure-grid">
                    <router-link :to="{ name: 'ProductDetail', params: { id: relatedProduct.productdetail.product_id } }">
                      <div class="image">
                        <img :src="'https://api.gocami.com/product_detail/' + relatedProduct.productdetail.image" alt=""
                          width="200" class="mb-2 mt-2" />
                      </div>
                    </router-link>
                    <div class="text">
                      <h2 class="title h5" style="color:black">{{ relatedProduct.productdetail.title }}</h2>
                      <span class="d-flex justify-content-between pt-2">
                        <sub style="color:black">$ 0.00</sub>
                        <i class="fa fa-shopping-cart fs-4"></i>
                      </span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>

          <div v-else>
          
          </div> -->

            <!--cart prices -->

            <div class="clearfix" style="margin-top: 2%">
              <div class="cart-block cart-block-footer clearfix">
                <div>
                  <strong>sub Total</strong>
                </div>
                <div>
                  <span>$ {{ cartTotal.toFixed(2) }}</span>
                </div>
              </div>

              <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                <div>
                  <strong>Delivery</strong>
                </div>
                <div>
                  <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
                </div>
              </div>

              <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
                <div>
                  <strong>Total</strong>
                </div>
                <div>
                  <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span>
                </div>
              </div>
            </div>

            <!--cart final price -->

            <div class="clearfix">

              <div class="cart-block cart-block-footer cart-block-footer-price clearfix">
                <div>
                  <form @submit.prevent="save">
                    <div class="alert" :class="[isCouponError ? 'alert-danger' : 'alert-success']" role="alert"
                      v-if="couponMessage">
                      <span v-if="isCouponError"><i class="fa fa-close"></i></span>
                      <span v-else>&#10004;</span>
                      {{ couponMessage }}
                    </div>
                    <span class="checkbox">
                      <input type="checkbox" id="couponCodeID" />
                      <label for="couponCodeID">Promo code</label>
                      <input type="text" v-model="cart.coupon_code" class="form-control form-coupon"
                        placeholder="Enter your coupon code" />
                    </span>

                    <button type="submit" class="btn btn-main">Apply</button>

                  </form>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div v-else>

          <div class="cart-block cart-block-header clearfix">
            <div>
              <span>Product</span>
            </div>
            <div>
              <span>&nbsp;</span>
            </div>
            <div>
              <span>Quantity</span>
            </div>
            <div class="text-right pe-5">
              <span>Price</span>
            </div>

            <div>
              <span>Remove</span>
            </div>
          </div>

          <div class="cart-block cart-block-item clearfix" style="margin-top: 1%; display: flex; align-items: center;"
            v-for="(item, index) in cartItems" :key="index">

            <div class="image">
              <img :src="'https://api.gocami.com/product_detail/' + item.image" width="100" />
            </div>

            <div class="title" style="flex-grow: 1;">
              <div class="h4">{{ item.title }}</div>
              <div>{{ item.sku }}</div>
            </div>

            <div class="price d-flex fs-5 mx-4">

              <button class="btn text-white" @click="decrementLocalQuantity(item.product_detail_id)">-</button>

              {{ item.quantity }}

              <button class="btn text-white" @click="incrementLocalQuantity(item.product_detail_id)">+</button>

            </div>

            <div class="price" style="margin-left: auto;" v-if="item.discount > 0">

              <span class="final h3" style="margin-right: 10px;">
                ${{ (item.sale_price * item.quantity - (item.sale_price * item.quantity * item.discount /
    100)).toFixed(2) }}
                <del>$ {{ item.sale_price.toFixed(2) }}</del>
              </span>

            </div>

            <div class="price" style="margin-left: auto;" v-else>

              <span class="final h3" style="margin-right: 10px;">
                ${{ (item.sale_price * item.quantity).toFixed(2) }}
              </span>

            </div>

            <button @click="removeLocalCartItem(item.product_detail_id)" class="btn btn-danger" style="background-color: #f9534f
  ;"><i class="fa fa-trash"></i></button>

          </div>

          <div class="clearfix" style="margin-top: 2%">
            <div class="cart-block cart-block-footer clearfix">
              <div>
                <strong>sub Total</strong>
              </div>
              <div>
                <span>$ {{ localCartTotal.toFixed(2) }}</span>
              </div>
            </div>

            <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
              <div>
                <strong>Delivery</strong>
              </div>
              <div>
                <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
              </div>
            </div>

            <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
              <div>
                <strong>Total</strong>
              </div>
              <div>
                <span>$ {{ (localCartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span>
              </div>
            </div>

            <!-- <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
              <div>
                <strong>Delivery</strong>
              </div>
              <div>
                <span>$ {{ selectedGovernorateDetails.price.toFixed(2) }}</span>
              </div>
            </div> -->
            <!-- 
            <div class="cart-block cart-block-footer clearfix" v-if="selectedGovernorateDetails">
              <div>
                <strong>Total</strong>
              </div>
              <div>
                <span>$ {{ (cartTotal + selectedGovernorateDetails.price).toFixed(2) }}</span>
              </div>
            </div> -->

          </div>

        </div>

      </div>

      <!-- <div class="text-center justify-content-center">
        <button class="btn text-white me-5">continue shopping</button>
        <button class="btn text-white">continue to checkout</button>
      </div> -->

      <form @submit.prevent="saveOrder">
        <div class="login-block login-block-signup">
          <hr />

          <h3>Your Information</h3>



          <div class="row" v-if="this.user?.id">
            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.first_name" class="form-control" placeholder="First name: *"
                  required />
                <span class="error-message">{{ validationMessages.first_name }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.middle_name" class="form-control" placeholder="Middle name: *"
                  required />
                <span class="error-message">{{ validationMessages.middle_name }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.last_name" class="form-control" placeholder="Last name: *"
                  required />
                <span class="error-message">{{ validationMessages.last_name }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <select class="form-select" v-model="checkout.selectedGovernorate" required>
                  <option value='' selected disabled hidden>-- Choose your governorates --</option>
                  <option v-for="governorate in governorates" :key="governorate.id" :value="governorate.id">
                    {{ governorate.governorates_name }}
                  </option>
                </select>
                <span class="error-message">{{ validationMessages.selectedGovernorate }}</span>

              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <select class="form-select" v-model="checkout.selectedCity" required>
                  <option value="" selected hidden>-- Choose your city --</option>
                  <option v-for="city in filteredFilterGovernorate(checkout.selectedGovernorate)" :key="city.id"
                    :value="city.id">
                    {{ city.city_name }}
                  </option>
                </select>
                <span class="error-message">{{ validationMessages.selectedCity }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <select class="form-select" v-model="checkout.area_id" required>
                  <option value="" selected hidden>-- Choose your area --</option>
                  <option v-for="area in filteredFilterCity(checkout.selectedCity)" :key="area.id" :value="area.id">
                    {{ area.area_name }}
                  </option>
                </select>
                <span class="error-message">{{ validationMessages.area_id }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.address" class="form-control" placeholder="Address: *" required />
                <span class="error-message">{{ validationMessages.address }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.phone" class="form-control" placeholder="Phone: *" required />
                <span class="error-message">{{ validationMessages.phone }}</span>

              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <input type="text" v-model="checkout.note" class="form-control" placeholder="Note: *" />
              </div>
            </div>

          </div>

        </div>

        <!-- <div class="col-md-12">
            <div class="white-block">
              <div class="h4">Choose your gift card!</div>
              <hr />
              <div class="gift-card">
                <label class="gift-card-label">
                  <span class="gift-icon">🎁</span>
                  Check the box if you want to send a gift card!
                  <input type="checkbox" class="gift-card-checkbox" v-model="isChecked" />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="row" v-if="isChecked">
                <section class="blog blog-block">
                  <div class="row row-clean">

                    <section class="products">
                      <div class="products-container equal-height-card">
                        <div class="products-inner">

                          <div class="col-md-3" v-for="giftcard in giftcards" :key="giftcard.id">
                            <div class="gift-card-content">
                              <article style="overflow: hidden">
                                <div class="figure-grid">
                                  <div class="image">
                                    <img :src="'http://localhost:8000/card_photo/' + giftcard.card_photo" alt=""
                                      class="product-image" />
                                  </div>
                                  <div class="text">
                                    <h2 class="title h5" style="color: black">{{ giftcard.card_name }}</h2>
                                    <label class="radio-label">
                                      <input type="radio" :value="giftcard.id" v-model="selectedGiftCardId"
                                        class="radio-input" />
                                      <div class="radio-custom"></div>
                                      Select this gift card
                                    </label>
                                  </div>
                                </div>
                              </article>
                            </div>
                          </div>

                        </div>
                      </div>
                    </section>
                  </div>
                </section>
                <hr />
              </div>
            </div>
          </div> -->

        <div class="white-block">
          <div class="h4">Choose delivery</div>

          <hr />

          <div class="row ms-3">
            <span class="checkbox">
              <input type="radio" id="deliveryId2" name="deliveryOption" v-model="checkout.deliveryOption"
                value="delivery" />
              <label for="deliveryId2">Delivery - <strong>{{ selectedGovernorateDetails ? '$ ' +
    selectedGovernorateDetails.price.toFixed(2) : '' }}</strong></label>
            </span>

            <div class="row d-flex justify-content-between"></div>
            <span class="checkbox">
              <input type="radio" id="deliveryId3" name="deliveryOption" v-model="checkout.deliveryOption"
                value="pickup" />
              <label for="deliveryId3">Pick up in the store - <strong>0</strong></label>
            </span>

            <div class="checkout-button-container" v-if="this.user?.id">
              <button type="submit" class="btn btn-main" :disabled="isCheckoutSuccessful">
                <span class="icon icon-cart"></span> Checkout
              </button>
            </div>

            <div class="checkout-button-container" v-else>
              <router-link :to="{ path: '/login', query: { from: $route.fullPath } }" class="btn btn-main">
                <span class="icon icon-cart"></span> Checkout
              </router-link>
            </div>

          </div>

          <div v-if="showOutOfStockError" class="error-message">
            One or more products are out of stock.
          </div>

          <div v-if="showMaxQuantityError" class="error-message">
            Max Quantity exceed
          </div>
          <hr />
        </div>

      </form>
      <!--/signup-->

      <hr />
      <!-- ========================  Cart navigation ======================== -->
    </div>
    <!--/container-->

    <div class="clearfix mx-auto">
      <div class="row">
        <!-- <div class="col-xs-6 text-center">
          <a href="checkout-2.html" class="btn btn-main"><span class="icon icon-cart"></span> Checkout</a>
        </div> -->
      </div>
    </div>
  </section>
  <FooterLayout />
  <!-- Cart End -->
</template>

<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';
import { useUserStore } from "../../../../store";


export default {
  components: { FooterLayout },
  name: "addtocart-view",
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },
  data() {
    return {
      cartItems: [],
      userProductOffers: [],
      isCheckoutSuccessful: false,
      insufficientPoints: false,
      isChecked: false,
      selectedGiftCardId: null, // Holds the selected gift card ID
      checks: [],
      validationMessages: {
        first_name: '',
        middle_name: '',
        last_name: '',
        selectedGovernorate: '',
        selectedCity: '',
        area_id: '',
        address: '',
        phone: '',
      },
      addToCart: false,
      message: '',
      isError: false,
      showSuccessMessage: false,
      cart: {
        coupon_code: "",
      },
      checkout: {
        area_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        address: "",
        phone: "",
        deliveryOption: 'delivery',
        pick_up: 1,
        selectedGovernorate: '',
        selectedCity: ''
      },
      showOutOfStockError: false, // Add this line to define the property
      showMaxQuantityError: false,
      bdlArray: [],
      cartBdl: [],
      priceAfterDiscount: null,
      carts: [],
      governorates: [],
      outOfStockProducts: [], // Initialize an empty array
      areas: [],
      cities: [],
      giftcards: [],
      isLoading: false, // Add this line
      isCouponError: false,
      couponMessage: "",
      freeProducts: [],
      userInf:'',
    };
  },

  created() {
    this.checkBdl();
    this.cartLoad();
    this.checkQty();
    this.giftCard();
    this.freeProduct();
  },

  mounted() {
    this.retrieveCartItems();

    // console.log(this.carts); 
    this.carts.forEach(cart => {
      console.log(cart.offerProducts); 
    });
  },

  computed: {

    selectedGovernorateDetails() {
      if (this.checkout.area_id === 0) {
        return null; // No governorate selected
      }

      if (this.checkout.deliveryOption === 'pickup') {
        return { price: 0 }; // Set price to 0 for pick up in store
      }

      return this.areas.find((area) => area.id === this.checkout.area_id);
    },

    cartTotal() {
      return this.carts.reduce((total, item) => {
        let price;
        if (item.price_after_discount !== null) {
          price = item.price_after_discount;
        } else {
          price = item.sale_price * item.quantity - (item.sale_price * item.quantity * item.productdetail.discount / 100);
        }
        return total + price;
      }, 0);
    },

    localCartTotal() {
      return this.cartItems.reduce((total, item) => {
        let price;

        price = item.sale_price * item.quantity - (item.sale_price * item.quantity * item.discount / 100);

        return total + price;
      }, 0);
    },



    bdlTotal() {
      return this.cartBdl.reduce((total, item) => {
        let price = 0; // Initialize the price with a default value
        if (item.bundle_id !== null && item.sale_price) { // Check if sale_price is truthy
          price = item.sale_price * item.quantity;
        }
        return total + price;
      }, 0);
    }
  },

  watch: {
    carts: {
      handler() {
        this.$nextTick(() => {
          this.updateCartTotal();
        });
      },
      deep: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  methods: {

    incrementLocalQuantity(productId) {
      // Call Laravel API to check available quantity in the database
      axiosInstance.get(`api/check-product-quantity/${productId}`)
        .then(response => {
          if (response.data.success && response.data.quantity > 0) {
            // If the quantity in the database is available, proceed to update local storage
            const cartData = JSON.parse(localStorage.getItem('guestCart'));
            if (cartData) {
              let updatedCartData = cartData.map(item => {
                if (item.product_detail_id === productId) {
                  // Check if the current quantity in local storage is less than the quantity available in the database
                  if (item.quantity < response.data.quantity) {
                    // Increment the quantity for the corresponding product
                    item.quantity++;
                  } else {
                    console.log('Quantity in local storage is already equal to or greater than the quantity in the database. Cannot increment further.');
                  }
                }
                return item;
              });

              // If no item was found matching the productId, add a new item with quantity 1
              if (!updatedCartData.find(item => item.product_detail_id === productId)) {
                updatedCartData.push({ product_detail_id: productId, quantity: 1 });
              }

              // Save the updated cart data back to local storage
              localStorage.setItem('guestCart', JSON.stringify(updatedCartData));
              console.log('Quantity incremented in local storage successfully');
              this.retrieveCartItems();
            }
          } else {
            console.error('Failed to increment quantity or insufficient quantity in the database');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    decrementLocalQuantity(productId) {
      const cartData = JSON.parse(localStorage.getItem('guestCart'));
      if (cartData) {
        let updatedCartData = cartData.map(item => {
          if (item.product_detail_id === productId) {
            if (item.quantity > 1) {
              // Decrement the quantity for the corresponding product
              item.quantity--;
            } else {
              console.log('Quantity cannot be less than 1.');
            }
          }
          return item;
        });

        // Save the updated cart data back to local storage
        localStorage.setItem('guestCart', JSON.stringify(updatedCartData));
        console.log('Quantity decremented in local storage successfully');
        this.retrieveCartItems();
      }
    },

    removeLocalCartItem(productId) {
      const cartData = JSON.parse(localStorage.getItem('guestCart'));
      if (cartData) {
        // Filter out the item with the specified productId
        const updatedCartData = cartData.filter(item => item.product_detail_id !== productId);

        // Save the updated cart data back to local storage
        localStorage.setItem('guestCart', JSON.stringify(updatedCartData));
        console.log('Item removed from cart successfully');
        this.retrieveCartItems();
      }
    },

    retrieveCartItems() {
      // Retrieve data from local storage
      const cartData = localStorage.getItem('guestCart');
      if (cartData) {
        // Parse the retrieved data
        this.cartItems = JSON.parse(cartData);
      }
    },


    filteredFilterGovernorate(governorateId) {
      return this.cities.filter(city => city.governorates_id === governorateId)
    },

    filteredFilterCity(cityId) {
      return this.areas.filter(areas => areas.city_id === cityId);
    },

    saveOrder() {
      this.saveOrderData();
    },

    saveOrderData() {
      if (this.checkout.deliveryOption === 'pickup') {
        this.checkout.pick_up = 0; // Pick up from the store
      } else {
        this.checkout.pick_up = 1; // Not pick up from the store
      }

      Object.keys(this.validationMessages).forEach(field => {
        this.validationMessages[field] = '';
      });

      let isValid = true;

      // Check if each required field is filled, if not, update the corresponding validation message
      if (!this.checkout.first_name) {
        this.validationMessages.first_name = '* First name is required.';
        isValid = false;
      }

      if (!this.checkout.middle_name) {
        this.validationMessages.middle_name = '* Middle name is required.';
        isValid = false;
      }

      if (!this.checkout.last_name) {
        this.validationMessages.last_name = '* Last name is required.';
        isValid = false;
      }

      if (!this.checkout.selectedGovernorate) {
        this.validationMessages.selectedGovernorate = '* Governorate selection is required.';
        isValid = false;
      }

      if (!this.checkout.selectedCity) {
        this.validationMessages.selectedCity = '* City selection is required.';
        isValid = false;
      }

      if (!this.checkout.area_id) {
        this.validationMessages.area_id = '* Area selection is required.';
        isValid = false;
      }

      if (!this.checkout.address) {
        this.validationMessages.address = '* Address is required.';
        isValid = false;
      }

      if (!this.checkout.phone) {
        this.validationMessages.phone = '* Phone is required.';
        isValid = false;
      }

      // If any required field is not filled, stop further processing and show the validation messages
      if (!isValid) {
        return;
      }

      // Prepare the order data to be sent to the server
      const orderData = {
        area_id: this.checkout.area_id,
        first_name: this.checkout.first_name,
        middle_name: this.checkout.middle_name,
        last_name: this.checkout.last_name,
        address: this.checkout.address,
        note: this.checkout.note,
        phone: this.checkout.phone,
        pick_up: this.checkout.pick_up,
        coupon_code: this.cart.coupon_code,
        gift_card_id: this.selectedGiftCardId, // Add selected gift card ID
        // other order data properties...
      };
      // Make the API request to save the order data
      axiosInstance.post(`api/checkout?user_id=${this.user?.id}`, orderData)
        .then((response) => {
          if (response.status === 200) {
            this.isCheckoutSuccessful = true;
            // Checkout success
            this.message = "Checkout done successfully";
            this.showSuccessMessage = true;
            this.isError = false;
            this.displayMessageAndReload();
          } else {
            // Checkout failure
            this.message = "Failed to checkout";
            this.isError = true;
            this.hideMessageAfterDelay(5000);
          }
          this.hideCouponMessageAfterDelay(5000);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.message = "Failed to checkout: " + error.response.data.error;

              // Check if the error indicates exceeding max quantity
              if (error.response.data.error.includes("Quantity exceeds maximum allowed for product")) {
                this.showMaxQuantityError = true;
                this.isError = true;
              }

              // Check if the error indicates insufficient stock
              if (error.response.data.error.includes("Insufficient stock")) {
                this.showOutOfStockError = true;
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            this.message = "No response received from the server.";
          } else {
            // Something happened in setting up the request that triggered an Error
            this.message = "An error occurred while making the request.";
          }
          this.isError = true;
          console.error("Failed to checkout", error);
          this.hideMessageAfterDelay(5000);
        });
    },

    displayMessageAndReload() {
      // Add the code to display the message on the webpage (e.g., toast message)
      // For example:
      // const toastElement = document.getElementById('toast');
      // toastElement.innerHTML = this.message;
      // toastElement.style.display = 'block';

      // Reload the page after a delay (e.g., 3 seconds)
      setTimeout(() => {
        window.location.reload();
      }, 3000); // Adjust the delay time as needed (here, it's set to 3 seconds)
    },

    save() {
      this.saveData();
    },

    cartLoad() {
      var page = `api/addToCart?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.carts = data.cart;
        this.governorates = data.governorate;
        this.cities = data.city;
        this.areas = data.area;
        this.userInf = data.user;
        this.isLoading = false;
      });
    },

    freeProduct() {
      var page = `api/get-free-product?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        this.freeProducts = data;
      });
    },

    giftCard() {
      var page = `api/gift-card`;
      axiosInstance.get(page).then(({ data }) => {
        this.giftcards = data;
      });
    },


    checkBdl() {
      var page = `api/cart-bundle?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({ data }) => {
        //console.log(data)
        this.cartBdl = data.cart;
        this.bdlArray = data.bdl;
        /*  (data.cart).forEach(element => {
         //  console.log(element)
          if (!this.bdlArray[element.bundle_id]) {
             this.bdlArray[element.bundle_id] = []; // Initialize bdlArray[element.bundle_id] as an empty array
           }
           // Add the 'id' property to the element object
           element.id = this.bdlArray[element.bundle_id].length + 1;
 
           this.bdlArray[element.bundle_id].push(element);
         })*/
      });
    },


    isDisabled($cart) {
      if ($cart.productdetail.productsales && $cart.productdetail.productsales.status === 0) {
        if ($cart.productdetail.productsales.max_quantity) {
          return $cart.quantity >= Math.min($cart.productdetail.productsales.max_quantity, $cart.productdetail.productsales.quantity);
        }
      }
      return $cart.quantity >= $cart.productdetail.productconfiguration.quantity;
    },

    async checkQty(cartId) {
      var page = `api/check-qty?user_id=${this.user?.id}&cart_id=${cartId}`;
      const response = await axiosInstance.get(page);
      return response.data;
    },

    async incrementQuantity(cart) {
      try {
        const userId = this.user?.id;

        if (cart.buy_by_points === 1) {
          // Points-based increment
          const cartItemsResponse = await axiosInstance.get(`api/get-cart-items/${userId}`);
          const cartItems = cartItemsResponse.data;

          const totalRequiredPoints = cartItems.reduce((total, item) => {
            if (item.buy_by_points === 1) {
              return total + item.product_point * item.quantity;
            }
            return total;
          }, 0);

          const userTotalPointsResponse = await axiosInstance.get(`api/get-user-total-points/${userId}`);
          const userTotalPoints = userTotalPointsResponse.data.total_points;

          if (userTotalPoints >= totalRequiredPoints + cart.product_point) {
            cart.quantity++;

            await axiosInstance.post(`api/cart-update/${cart.id}`, {
              quantity: cart.quantity,
              user_id: userId,
              product_detail_id: cart.productdetail.id,
            });

            this.insufficientPoints = false;
            return;
          } else {
            this.insufficientPoints = true;
            console.error("Insufficient points for this quantity.");
            return;
          }
        } else {
          // Non-points-based increment
          let maxQuantity;

          if (cart.productdetail.item_id) {
            // If item_id exists, make an API call to fetch the maximum quantity
            const availabilityCheckUrl = `https://api.upsilon.systems/api/api.php?k=Wes1tR3&endpoint=item&item_id=${cart.productdetail.item_id}&c=2`;
            const response = await axiosInstance.get(availabilityCheckUrl);
            const responseData = response.data;

            if (responseData && responseData.status === 'success' && responseData.data) {
              const avqty = parseInt(responseData.data[0].avqty);
              if (avqty > 0) {
                maxQuantity = avqty;
              } else {
                console.error("Requested item is not available.");
                return;
              }
            } else {
              console.error("Failed to retrieve availability information from the API.");
              return;
            }
          } else {
            // If item_id does not exist, use the maximum quantity from product configurations
            maxQuantity = cart.productdetail.productconfiguration.quantity;
          }

          if (cart.quantity < maxQuantity) {
            cart.quantity++;

            await axiosInstance.post(`api/cart-update/${cart.id}`, {
              quantity: cart.quantity,
              user_id: userId,
              product_detail_id: cart.productdetail.id,
            });

            this.insufficientPoints = false;
            return;
          } else {
            console.error("Quantity reached the maximum allowed quantity.");
            return;
          }
        }
      } catch (error) {
        console.error("Error while checking quantity:", error);
      }
    },


    decrementQuantity(carts) {
      if (carts.quantity > 1) {
        carts.quantity--;

        // send an AJAX request to update the cart quantity in the database
        axiosInstance.post(`api/cart-update/${carts.id}`, {
          quantity: carts.quantity,
          user_id: this.user?.id,
          product_detail_id: carts.productdetail.id,
        });
      }
    },

    updateCartTotal() {
      if (
        this.cart &&
        this.cart.productdetail &&
        this.cart.productdetail.discount !== undefined &&
        this.cart.quantity !== undefined
      ) {
        if (this.cart.productdetail.discount > 0 && this.priceAfterDiscount !== null) {
          // Calculate total with discount
          this.cartTotal = (this.priceAfterDiscount * this.cart.quantity).toFixed(2);
        } else {
          // Calculate total without discount
          this.cartTotal = (this.cart.sale_price * this.cart.quantity - (this.cart.sale_price * this.cart.quantity * this.cart.productdetail.discount / 100)).toFixed(2);
        }
      }
    },

    saveData() {
      let formData = new FormData();
      formData.append("coupon_code", this.cart.coupon_code);
      axiosInstance
        .post(
          `api/coupon?user_id=${this.user?.id}`,
          formData
        )
        .then((response) => {
          if (response.status === 200) {
            this.showSuccessMessage = true;
            this.message = "Coupon has been added successfully";
            this.isError = false;
            this.hideMessageAfterDelay(5000);
            this.cartLoad();
            this.priceAfterDiscount = response.data.price_after_discount; // Update the price after discount
            this.updateCartTotal(); // Update the cart total
          } else {
            this.showSuccessMessage = true;
            this.message = "Failed to add coupon";
            this.isError = true;
            this.hideMessageAfterDelay(5000);
          }
          this.hideCouponMessageAfterDelay(5000);
        })
        .catch((error) => {
          this.showSuccessMessage = true;
          this.message = "Failed to add coupon";
          this.isError = true;
          console.error("Failed to add coupon", error);
          this.hideMessageAfterDelay(5000);
          console.error("Failed to add to cart", error);
        });
    },

    remove(carts) {
      var url = `api/cart-delete/${carts.id}`;
      axiosInstance
        .delete(url)
        .then((response) => {
          if (Array.isArray(this.carts) && carts) {
            const index = this.carts.indexOf(carts);
            if (index !== -1) {
              this.carts.splice(index, 1);
            }
          }
          if (response.status === 200) {
            this.freeProduct();
            this.message = "Product has been removed from cart successfully"; // Update success message
            this.isError = false; // Reset isError to false
          } else {
            this.message = "Failed to remove product"; // Update error message
            this.isError = true; // Set isError to true
          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to remove product"; // Update error message
          this.isError = true; // Set isError to true
          console.error("Failed to remove product", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    removeBdl(bdl) {
      var url = `api/cart-delete-bundle/${bdl.id}?user_id=${this.user?.id}`; // Correct URL formatting
      axiosInstance
        .delete(url)
        .then((response) => {
          if (Array.isArray(this.bdl) && bdl) {
            const index = this.bdl.indexOf(bdl);
            if (index !== -1) {
              this.bdl.splice(index, 1);
            }
          }
          if (response.status === 200) {
            this.message = "Bundle has been removed from cart successfully"; // Update success message
            this.isError = false; // Reset isError to false
          } else {
            this.message = "Failed to remove bundle"; // Update error message
            this.isError = true; // Set isError to true
          }
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        })
        .catch((error) => {
          this.message = "Failed to remove bundle"; // Update error message
          this.isError = true; // Set isError to true
          console.error("Failed to remove bundle", error);
          this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
        });
    },

    hideCouponMessageAfterDelay(delay) {
      setTimeout(() => {
        this.Couponmessage = ""; // Clear the message
        this.isCouponError = false; // Reset isError to false
      }, delay);
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },

  },
};
</script>

<style scoped>
.white-block {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.h4 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.checkbox {
  display: block;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* .checkout-button-container {
  text-align: center;
} */

.btn {
  background-color: #154068;
  border: hidden;
}

.input-group.quantity {
  display: flex;
  align-items: center;
}

.radio-label {
  display: flex;
  align-items: center;
}

.radio-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-custom {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.3s ease-in-out;
}

.radio-input:checked~.radio-custom {
  border-color: #007bff;
}

.radio-input:checked~.radio-custom::before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #007bff;
  border-radius: 50%;
  display: block;
}

.gift-card {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
}

.gift-card-label {
  position: relative;
  cursor: pointer;
  padding-left: 40px;
  /* Add space for icon and checkbox */
}

.gift-icon {
  font-size: 14pt !important;
  margin-right: 5px;
}

.gift-card-checkbox {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 50%;
  /* Make the checkbox circular */
}

.gift-card-label:hover .checkmark {
  background-color: #e0e0e0;
}

.gift-card-checkbox:checked+.checkmark:after {
  content: "\2713";
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 3px;
  left: 5px;
}

.gift-card-checkbox:checked+.checkmark {
  background-color: #4caf50;
  border: none;
}

/* Ribbon-like styling for checkbox */
.checkmark:before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #f1f1f1 transparent transparent;
}


.alert-danger {
  background-color: #dc143c;
  color: white;
}

.toast {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #28a745;
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.error-message {
  color: red;
  font-size: 12px;
}

.equal-height-card {
  display: flex !important;
  flex-direction: column !important;
  min-height: 10vh !important;
}

.toast.error {
  position: fixed;
  max-width: 50%;
  top: 20px;
  right: -100%;
  /* Start offscreen on the right */
  background-color: #dc3545;
  /* Background color for error */
  color: #fff;
  padding: 12px 16px;
  border-radius: 4px;
  transition: right 0.5s ease-in-out;
  z-index: 999999;
}

.toast.show {
  right: 20px;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
