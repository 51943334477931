<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div v-if="cartLoaded">
            <div>
              <p>Invoice Note: {{ note }}</p>
            </div>

            <div v-for="carts in cart" :key="carts.id">
              <p>{{ carts.sku }}</p>
              <p>{{ carts.quantity }}</p>
              <p v-if="carts.is_simple_discount === 1">{{ carts.sale_price - (carts.sale_price * carts.user_simple_discount / 100) }}</p>
              <p v-else>{{ carts.sale_price }}</p>
            </div>
            <div class="flex">
              <button class="btn btn-primary mr-3" @click="downloadAsTxt">Download as TXT</button>
              <button class="btn btn-success" @click="markInvoiceAsExported(cart[0].invoiceId)">Mark as Exported</button>
            </div>
          </div>
          <div v-else>
            <p>Loading...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axiosInstance from '../../../axiosInstance';
import { downloadAsTxt } from '../../../exportForManagement'; // Correctly import the downloadAsTxt method from the appropriate file

export default {
  data() {
    return {
      note: '', // Initialize note property
      cart: [],
      cartLoaded: false,
    };
  },

  methods: {

    markAsExported(orderId) {
      const url = `api/update-invoice-exported/${orderId}`;
      axiosInstance.put(url, { is_exported: 1 })
        .then(() => {
          // Update the local data to reflect the change
          if (this.invoices[0] && this.invoices[0].id === orderId) {
            this.invoices[0].is_exported = 1;
          }
        })
        .catch(error => {
          console.error('Error marking invoice as printed:', error);
        });
    },

    markInvoiceAsExported(invoiceId) {
      this.markAsExported(invoiceId);
    },

    orderLoad() {
      var page = `api/order-management-note/${this.$route.params.id}`;
      axiosInstance.get(page).then(({ data }) => {
        console.log(data);
        this.note = data.carts[0].note; // Assign the note from the first cart item
        this.cart = data.carts;
        this.cartLoaded = true;
      }).catch((error) => {
        console.error('Error loading data:', error);
        this.cartLoaded = true;
      });
    },

    downloadAsTxt() {
      // Use the method directly imported from the exportUtils.js file
      downloadAsTxt(this.cart);
    },
  },

  created() {
    this.orderLoad();
  },
};
</script>
  