<template>
  <div class="temp justify-content-center text-center mx-auto">

    <div v-if="loading" class="loading-indicator">
      <img src="https://api.gocami.com/photos/cami.gif" width="200" height="200" alt="Loading..."/>
    </div>

    <div v-else>
      <section class="header-content">
        <img v-if="isPC" :src="'https://api.gocami.com/photos/' + category.banner_category"
             style="width: auto !important; height: auto !important;" alt="">
        <img v-else :src="'https://api.gocami.com/photos/' + category.banner_category"
             style="width: 100% !important; height: 150px !important;" alt="">
        <div class="row mt-2 mx-2 subs">
          <div class="d-flex text-center justify-content-center">
            <router-link v-for="subcategory in subcategories" :key="subcategory.id"
                         :to="{ name: 'SubCategory', params: { id: subcategory.sub_category_name } }">
              <div class="border px-3 py-3 mx-2 my-5 subcat">
                {{ subcategory.sub_category_name }}
              </div>
            </router-link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 text-center">
            <h1 class="fs-1">{{ category.category_name }}</h1>
          </div>
        </div>
      </section>

      <div class="mb-3 mx-5 text-end d-flex justify-content-end">
        <div class="d-flex align-items-center">
          <label for="sortBy fw-bolder text-gray" class="me-3">Sort By:</label>
          <select v-model="selectedSortBy" id="sortBy" @change="sortProducts" class="me-3 rounded display"
                  style="width: 120px; padding-left: 15px;">
            <option v-for="option in sortByOptions" :key="option.value" :value="option.value">
              {{ option.label }}
            </option>
          </select>
          <span v-if="displayMode === 'grid'">Grid<i class="pi pi-list border border-dark p-2 ms-1 display"
                                                     @click="toggleDisplayMode"></i></span>
          <span v-else>List <i class="pi pi-th-large border border-dark p-2 ms-1 display"
                               @click="toggleDisplayMode"></i></span>
        </div>
      </div>

      <div class="row">
        <div class="col-2 mx-5 mt-5">
          <!-- Brand Filter -->
          <div class="filter-section">
            <h6 class="text-start">Brands</h6>
            <label class="custom-checkbox" v-for="brand in brands" :key="brand.id">
              <input type="checkbox" v-model="selectedBrands" :value="brand.id">
              <span class="checkmark"></span> <span class="ms-4">{{ brand.brand_name }}</span>
            </label>
          </div>

          <div class="filter-section">
            <h6 class="text-start">Prices</h6>
            <div v-for="priceRange in priceRanges" :key="priceRange.value">
                            <span class="custom-checkbox" @click="togglePriceFilter(priceRange.value)">
                                <input type="checkbox" :checked="selectedPrices.includes(priceRange.value)"/>
                                <span class="checkmark"></span>
                                <span class="ms-4">{{ priceRange.label }}</span>
                            </span>
            </div>
          </div>

          <!-- Discount Filter -->
          <div class="filter-section">
            <h6 class="text-start">Discounts</h6>
            <div v-for="discountValue in discountValues" :key="discountValue.value">
                            <span class="custom-checkbox" @click="toggleDiscountFilter(discountValue.value)">
                                <input type="checkbox" :checked="selectedDiscounts.includes(discountValue.value)"/>
                                <span class="checkmark"></span>
                                <span class="ms-4">{{ discountValue.label }}</span>
                            </span>
            </div>
          </div>

        </div>

        <div class="col-9" style="margin: 0 !important; padding: 0 !important;">
          <div class="row row-clean mt-3 mb-4">
            <div v-for="product in filteredProducts" :key="product.id"
                 :class="{ 'product-item': displayMode === 'grid', 'product-item-list': displayMode === 'list' }">
              <article style="overflow: hidden">
                <div v-if="displayMode === 'grid'" class="figure-grid">
                  <div class="image" v-if="product.in_stock == 0" style="position: relative;">
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.id } }"
                                 class="mfp-open" @click="handleClick"
                    >
                      <img :src="'https://api.gocami.com/product_main/' + product.image" alt=""
                           class="product-image"/>
                    </router-link>
                    <div class="out-of-stock-overlay">Out of Stock</div>
                  </div>

                  <div class="image" v-else style="position: relative;">
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.id } }"
                                 class="mfp-open" @click="handleClick"
                    >
                      <img :src="'https://api.gocami.com/product_main/' + product.image" alt=""
                           class="product-image"/>
                    </router-link>
                  </div>

                  <div class="text">
                    <h2 class="title h5" style="color: black">{{ product.product_name }}</h2>
                    <span v-if="product.discount > 0">
                                            <p class="title pt-0 mt-0 pb-0 mb-0 text-dark fw-bolder"> <del>${{
                                                Number(product.price).toFixed(2)
                                              }}</del> ${{
                                                Math.floor(product.price -
                                                    (product.price * product.discount)
                                                    /
                                                    100)
                                              }}.<span style="vertical-align: super; font-size:90% !important">
                                                    {{
                                                  (
                                                      product.price -
                                                      (product.price *
                                                          product.discount) /
                                                      100
                                                  ).toFixed(2).slice(-2)
                                                }}
                                                </span>
                                            </p>
                                            <span v-if="product.old_price && product.old_price > product.price">
                                                <p class="title pt-0 mt-0 pb-0 mb-0 text-danger fw-bolder "> <del>${{
                                                    Number(product.old_price).toFixed(2)
                                                  }} ${{
                                                    Math.floor(product.old_price -
                                                        (product.old_price * product.discount)
                                                        /
                                                        100)
                                                  }}.<span
                                                      style="vertical-align: super; font-size:90% !important">
                                                        {{
                                                      (
                                                          product.old_price -
                                                          (product.old_price *
                                                              product.discount) /
                                                          100
                                                      ).toFixed(2).slice(-2)
                                                    }}
                                                    </span></del>
                                                </p>
                                            </span>
                                        </span>
                    <span v-else>
                                            <p class="title pt-0 mt-0 pb-0 mb-0"
                                               v-if="product.old_price && product.old_price > product.price">
                                                ${{ product.price.toFixed(2) }}
                                                <del class="text-danger">${{ product.old_price.toFixed(2) }}</del>
                                            </p>
                                            <p class="title pt-0 mt-0 pb-0 mb-0" v-else>
                                                ${{ product.price.toFixed(2) }}
                                            </p>
                                        </span>

                  </div>
                  <!-- <span>Price: {{ product.price.toFixed(2) }}, Discount: %{{ product.discount }}</span> -->
                </div>
                <div v-else class="figure-list border-bottom text-start">
                  <div>
                    <router-link :to="{ name: 'ProductDetail', params: { id: product.id } }"
                                 class="mfp-open" @click="handleClick">
                      <img :src="'https://api.gocami.com/product_main/' + product.image" alt=""
                           class="product-image" style="width:100px"/>
                    </router-link>
                  </div>
                  <div class="text">
                    <h2 class="title h5" style="color: black">{{ product.product_name }}</h2>
                    <h2 class="title h5" style="color: black">{{ product.description }}</h2>
                    <h2 class="title h5" style="color: black">${{
                        Number(product.price).toFixed(2)
                      }}
                    </h2>
                  </div>
                </div>
              </article>
            </div>
          </div>

        </div>
      </div>

    </div>

    <FooterLayout/>

  </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';
import FooterLayout from "./FooterLayout.vue";
import {nextTick} from 'vue'; // To ensure DOM update before scrolling

export default {
  components: {FooterLayout},

  data() {
    return {
      scrollPosition: 0, // Local reactive state for scroll position
      category: {},
      isPC: false,
      subcategories: {},
      brands: [],
      products: [],
      currentPage: 1,
      totalPages: 0,
      loading: true,
      selectedBrands: [],
      filteredProducts: [], // Initialize with all products
      selectedPrices: [],
      selectedDiscounts: [],
      priceRanges: [
        {label: '$0 - $50', value: '0-50'},
        {label: '$51 - $100', value: '51-100'},
        {label: '$101 - $150', value: '101-150'},
        // Add more price ranges as needed
      ],
      discountValues: [
        {label: '10% Off', value: '0-10'},
        {label: '15% Off', value: '11-15'},
        {label: '20% Off', value: '16-20'},
        // Add more discount values as needed
      ],
      sortByOptions: [
        {label: 'Default', value: 'default'}, // Add a default option
        {label: 'High to Low Price', value: 'highToLow'},
        {label: 'Low to High Price', value: 'lowToHigh'},
        {label: 'A to Z', value: 'aToZ'},
        {label: 'Z to A', value: 'zToA'},
        {label: 'Newest First', value: 'newestFirst'},
      ],
      defaultSortBy: 'default', // Set your default sorting option here
      selectedSortBy: 'default', // Set the initial
      displayMode: 'grid', // Default display mode
    };
  },
  created() {
    this.fetchSale();
    this.selectedSortBy = this.defaultSortBy;
  },
  beforeUnmount() {

    window.removeEventListener("resize", this.checkIfPC);
    localStorage.setItem('scrollPosition', window.scrollY);

  },


  methods: {

    handleClick() {
      // Store the current scroll position before navigating
      this.scrollPosition = window.scrollY; // Get the current scroll position
      localStorage.setItem('scrollPosition', this.scrollPosition); // Store in localStorage
    },

    fetchData() {
      this.fetchSale();
      this.fetchProduct();
    },

    checkIfPC() {
      this.isPC = window.innerWidth >= 768;
    },

    sortProducts() {
      switch (this.selectedSortBy) {
        case 'highToLow':
          this.filteredProducts.sort((a, b) => b.price - a.price);
          break;
        case 'lowToHigh':
          this.filteredProducts.sort((a, b) => a.price - b.price);
          break;
        case 'aToZ':
          this.filteredProducts.sort((a, b) => a.product_name.localeCompare(b.product_name));
          break;
        case 'zToA':
          this.filteredProducts.sort((a, b) => b.product_name.localeCompare(a.product_name));
          break;
        case 'newestFirst':
          this.filteredProducts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          break;
        default:
          break;
      }
    },

    toggleDisplayMode() {
      this.displayMode = this.displayMode === 'grid' ? 'list' : 'grid';
    },

    getPriceRange(price) {
      if (price >= 1 && price <= 50) return '0-50';
      else if (price > 50 && price <= 100) return '51-100';
      else if (price > 100 && price <= 150) return '101-150';
      else return '151+';
    },

    getDiscountValue(discount) {
      if (discount >= 0 && discount <= 10) return '0-10';
      else if (discount <= 15) return '11-15';
      else if (discount <= 20) return '16-20';
      // Add more conditions as needed
    },

    fetchSale() {
      const page = `api/get-category/${this.$route.params.id}`;
      this.loading = true;
      axiosInstance.get(page).then(({data}) => {
        this.category = data.category;
        this.subcategories = data.subcategories;
        this.brands = data.brands;

        this.loading = false;
      }).catch(error => {
        console.error('Error fetching category:', error);
      });
    },
    fetchProduct() {
      const page = `api/get-category/${this.$route.params.id}`;
      axiosInstance
          .get(page)
          .then(({ data }) => {
            this.products = data.product; // Assign fetched data
            this.filterProducts(); // Additional updates if needed

            // Restore scroll position after data fetch
            nextTick(() => {
              setTimeout(() => {
                const storedScrollPosition = localStorage.getItem('scrollPosition');
                if (storedScrollPosition) {
                  const position = parseInt(storedScrollPosition, 10);
                  window.scrollTo(0, position); // Restore stored scroll position
                }

                // Set scroll position to 0 after restoring
                localStorage.removeItem('scrollPosition');
              }, 50); // Adding a brief delay for DOM stability
            });
          })
          .catch((error) => {
            console.error('Error fetching products:', error);
          });
    },
    // setPage(page) {
    //     this.currentPage = page;
    //     this.fetchProduct();
    // },
    // nextPage() {
    //     if (this.currentPage < this.totalPages) {
    //         this.currentPage++;
    //         this.fetchProduct();
    //     }
    // },
    // prevPage() {
    //     if (this.currentPage > 1) {
    //         this.currentPage--;
    //         this.fetchProduct();
    //     }
    // },

    togglePriceFilter(price) {
      if (this.selectedPrices.includes(price)) {
        this.selectedPrices = this.selectedPrices.filter(p => p !== price);
      } else {
        this.selectedPrices.push(price);
      }
      this.filterProducts();
    },

    toggleDiscountFilter(discount) {
      if (this.selectedDiscounts.includes(discount)) {
        this.selectedDiscounts = this.selectedDiscounts.filter(d => d !== discount);
      } else {
        this.selectedDiscounts.push(discount);
      }
      this.filterProducts();
    },
    filterProducts() {

      // Filter products based on selected brands
      let filteredByBrands = this.selectedBrands.length > 0 ?
          this.products.filter(product => this.selectedBrands.includes(product.brand_id)) :
          this.products;

      // Filter products based on selected price ranges
      let filteredByPrices = this.selectedPrices.length > 0 ?
          filteredByBrands.filter(product => this.selectedPrices.includes(this.getPriceRange(product.price))) :
          filteredByBrands;

      // Filter products based on selected discount values
      this.filteredProducts = this.selectedDiscounts.length > 0 ?
          filteredByPrices.filter(product => this.selectedDiscounts.includes(this.getDiscountValue(product.discount))) :
          filteredByPrices;

      // Convert Proxies to regular objects
      this.filteredProducts = JSON.parse(JSON.stringify(this.filteredProducts));

      // Log the final filtered products
    },

  },
  watch: {
    selectedBrands: 'filterProducts', // Watch for changes in selected brands
    '$route.params.id': 'fetchData', // Watch for changes in the route parameter

  },
  mounted() {

    // Fetch the products when the component is mounted
    this.fetchProduct();

    this.checkIfPC();
    window.addEventListener("resize", this.checkIfPC);
    this.fetchProduct();
  },
};
</script>

<style scoped>
.out-of-stock-overlay {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: rgba(255, 0, 0, 0.7) !important;
  /* Red color with some transparency */
  color: white !important;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-align: center !important;
  z-index: 1 !important;
  /* Ensure the overlay is above the image */
}


@media (min-width: 1366px) {
  .temp {
    width: 96%;
    max-width: 1500px !important;
    overflow-x: hidden !important;
  }
}

@media (max-width: 767px) {
  .temp {
    overflow-x: hidden !important;
  }
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 150px;
  height: 150px;
}

.display:hover {
  cursor: pointer;
}

.custom-inline {
  display: flex;
  align-items: center;
}

.subcat:hover {
  cursor: pointer;
}

.product-item {
  flex-shrink: 0;
  width: 220px;
  height: 320px;
  /* margin-right: 10px; */
}

.figure-grid {
  position: relative;
  text-align: center;
  height: 100%;
  /* Ensure that the figure-grid takes the full height */
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Add this CSS to your stylesheet or in a <style> tag in your HTML file */
.col-2 {
  background-color: #f2f2f2;
  padding: 15px;
  border-radius: 5px;
}

.filter-section {
  margin-bottom: 15px;
}

.filter-section label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.filter-section input {
  margin-right: 18px;
}

.filter-section h6 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

.custom-checkbox {
  display: flex !important;
  position: relative;
  cursor: pointer;
  margin-right: 8px;
  /* Add margin to the right of the checkbox */
}

.custom-checkbox input {
  opacity: 0;
  position: absolute;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border: 1px solid #888;
  border-radius: 20%;
  background-color: #fff;
  transition: background-color 0.3s;
}

.custom-checkbox input:checked + .checkmark::after {
  content: '\2713';
  /* Check mark symbol */
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-checkbox input:checked + .checkmark {
  background-color: #4285f4;
}

.list-view .product-item {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {

  .col-2,
  .col-9 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .subs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
  }

  .subcat {
    flex-basis: calc(50% - 10px);
    /* Adjust the percentage and spacing as needed */
    max-width: calc(50% - 10px);
    /* Adjust the maximum width and spacing as needed */
    margin: 5px;
    /* Adjust the margin as needed for spacing */
    box-sizing: border-box;
    /* Ensure long words break onto the next line */
    white-space: normal;
    /* Allow wrapping of long text */
    min-width: 150px;

  }

  .row-clean {
    margin: 0 -5px;
  }

  .product-item {
    flex-basis: calc(45% - 10px);
    /* Display three products per row, adjust as needed */
    max-width: calc(45% - 10px);
    /* Adjust the maximum width and spacing as needed */
    margin-left: 20px;
    /* margin-right: 20px; */
  }

  .product-item-list {
    flex-basis: calc(100% - 10px);
    /* Display three products per row, adjust as needed */
    max-width: calc(100% - 10px);
    /* Adjust the maximum width and spacing as needed */
    margin-left: 20px;
    /* margin-right: 20px; */
  }

  .figure-grid,
  .figure-list {
    box-sizing: border-box;
    margin-right: 10px;
    /* Adjust the margin as needed for spacing between items */
    margin-bottom: 15px;
    /* Adjust the margin as needed for spacing between rows */
  }

  .figure-list {
    border-bottom: none;
    /* Remove border bottom for list view */
  }

  .product-image {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .text {
    padding: 10px;
    /* Adjust padding as needed */
  }

  /* Clear the margin-right for the last item in each row */
  .product-item:nth-child(3n) {
    margin-right: 0;
  }
}
</style>