<template>
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">
          <div>
            <label for="from">From:</label>
            <input type="date" v-model="filterFrom" @change="applyDateFilter"/>
          </div>
          <div>
            <label for="to">To:</label>
            <input type="date" v-model="filterTo" @change="applyDateFilter"/>
          </div>


          <table class="table w-100" ref="table">
            <thead>
            <tr>
              <th>Affiliate ID</th>
              <th>Total Sale Price(target exist)</th>
              <th>Total After Discount(target exist)</th>
              <th>Total Sale Price(no target)</th>
              <th>Total After Discount(no target)</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(affiliate, index) in carts" :key="index">
              <td>
                <router-link :to="{ name: 'SuperAdminSubAffiliatePercent', params: { id: affiliate.id } }" >
                {{ affiliate.affiliate_first_name }} {{ affiliate.affiliate_last_name }}
              </router-link>
              </td>
              <td>$ {{ affiliate.total_before_discount }}</td>
              <td>$ {{ affiliate.total_after_discount }}</td>
              <td>$ {{ affiliate.no_target_total_before_discount }}</td>
              <td>$ {{ affiliate.no_target_total_after_discount }}</td>
            </tr>
            </tbody>
          </table>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '../../../axiosInstance.js';
import {useUserStore} from "../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },

  data() {
    return {
      carts: [],
      filterFrom: null,
      filterTo: null,
    }
  },

  methods: {

    mounted() {
      this.userLoad();
    },

    async userLoad() {
      const page = `api/super-admin-affiliate-chart`;
      const params = {
        from_date: this.filterFrom,
        to_date: this.filterTo,
      };

      try {
        const {data} = await axiosInstance.get(page, {params});

        // Assuming the array response directly represents carts data
        if (Array.isArray(data)) {
          // Update the array to trigger reactivity
          this.carts = [...data];
          console.log('Updated carts:', this.carts);
        } else {
          console.error('API response is not in the expected format.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    applyDateFilter() {
      this.userLoad();
    },

  },
};
</script>
