<template>
    <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
        {{ message }}
    </div>

    <div class="container">
        <h2>Contact Us</h2>

        <form class="contact-form" @submit.prevent="save">
            <div class="input-container">
                <label for="name">Name</label>
                <input type="text" id="name" v-model="contact.name" required>
            </div>
            <div class="input-container">
                <label for="email">Email</label>
                <input type="email" id="email" v-model="contact.email" required>
            </div>
            <div class="input-container">
                <label for="subject">Subject</label>
                <input type="text" id="subject" v-model="contact.subject" required>
            </div>
            <div class="input-container">
                <label for="message">Message</label>
                <textarea id="message" v-model="contact.text" required></textarea>
            </div>
            <button type="submit">Submit</button>
        </form>
    </div>
    <FooterLayout />
</template>
  
<script>
import FooterLayout from "./FooterLayout.vue";
import axiosInstance from '../../../../axiosInstance';
import { useUserStore } from "../../../../store";

export default {
    components: { FooterLayout },
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            contact: {
                name: "",
                email: "",
                subject: "",
                text: "",
            },
            showSuccessMessage: false,
            message: "", // Define the "message" property with an initial value
            isError: false,
        }
    },

    methods: {

        reportSend() {
            let formData = new FormData();
            formData.append("user_id", this.user?.id);
            formData.append("name", this.contact.name);
            formData.append("email", this.contact.email);
            formData.append("subject", this.contact.subject);
            formData.append("text", this.contact.text);

            axiosInstance.post(`api/send-report`, formData)  // Change the API endpoint to your actual endpoint
                .then(response => {
                    if (response.status === 200) {
                        this.showSuccessMessage = true;
                        this.message = 'Report has been send successfully'; // Update success message
                        this.isError = false; // Reset isError to false
                    } else {
                        this.message = 'Failed to send report'; // Update error message
                        this.isError = true; // Set isError to true
                        this.showSuccessMessage = true;
                    }
                    this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                })
                .catch(error => {
                    this.message = 'Failed to send report'; // Update error message
                    this.isError = true; // Set isError to true
                    this.showSuccessMessage = true;
                    console.error('Failed to send report', error);
                    this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
                });
        },

        save() {
            this.reportSend();
        },

        hideMessageAfterDelay(delay) {
            setTimeout(() => {
                this.message = ""; // Clear the message
                this.isError = false; // Reset isError to false
            }, delay);
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

template {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 35px;
    margin-top: 25px;
}

h2 {
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-container {
    margin: 10px 0;
    width: 100%;
    text-align: left;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
}

input:focus,
textarea:focus {
    border-color: #007bff;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.toast {
    position: fixed;
    max-width: 50%;
    top: 20px;
    right: -100%;
    /* Start offscreen on the right */
    background-color: #28a745;
    color: #fff;
    padding: 12px 16px;
    border-radius: 4px;
    transition: right 0.5s ease-in-out;
    z-index: 999999;
}

.toast {
    position: fixed;
    max-width: 50%;
    top: 20px;
    right: -100%;
    /* Start offscreen on the right */
    background-color: #28a745;
    color: #fff;
    padding: 12px 16px;
    border-radius: 4px;
    transition: right 0.5s ease-in-out;
    z-index: 999999;
}


.toast.error {
    position: fixed;
    max-width: 50%;
    top: 20px;
    right: -100%;
    /* Start offscreen on the right */
    background-color: #dc3545;
    /* Background color for error */
    color: #fff;
    padding: 12px 16px;
    border-radius: 4px;
    transition: right 0.5s ease-in-out;
    z-index: 999999;
}

.toast.show {
    right: 20px;
    /* Slide in to the desired position */
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>