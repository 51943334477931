<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Main Product</h3>

                    <div class="row">
                        <div class="col-4"><input type="text" class="form-control" v-model="product.product_name"
                                placeholder="Product Name" /></div>
                        <div class="col-4">
                            <select class="form-select" v-model="product.category_id">
                                <option value="" selected hidden disabled>-- Choose Category --</option>
                                <option v-for="category in category" :key="category.id" :value="category.id">{{
                            category.category_name }}</option>
                            </select>
                        </div>
                        <div class="col-4">
                            <select class="form-select" v-model="product.brand_id">
                                <option value="" selected hidden disabled>-- Choose Brand --</option>
                                <option v-for="brand in brand" :key="brand.id" :value="brand.id">{{ brand.brand_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button @click="updateDescription(product)" class="btn btn-primary my-4">
                        Update
                    </button>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>Brand</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Price</th>
                                <th>Image</th>
                                <th>Position</th>
                                <th>Supplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in productDetail" :key="product.id">
                                <td>{{ product.id }}</td>
                                <td>{{ product.product_name }}</td>
                                <td>{{ product.brand_name }}</td>
                                <td>{{ product.category_name }}</td>
                                <td>
                                    <p v-html="product.description"></p>
                                </td>
                                <td>${{ product.price }}</td>

                                <td><img :src="'https://api.gocami.com/product_main/' + product.image" width="75" />
                                </td>
                                <td>{{ product.position }}</td>
                                <td>{{ product.supplier_name }}</td>

                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../axiosInstance';

export default {
    data() {
        return {
            productDetail: [],
            product: {
                brand_id: '',
                category_id: '',
                product_name: ''
            },
            brand: [],
            products: [],
            category: [],
        };
    },

    created() {
        this.fetchAllData();
        this.fetchCredential();
    },

    methods: {
        fetchAllData() {
            return axiosInstance
                .get(`api/get-disabled-product`)
                .then(response => {
                    this.productDetail = response.data.productDetail;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },

        updateDescription(product) {
            console.log('Creating product:', product);

            axiosInstance
                .post(`api/store-final-data-name`, {
                    brand_id: product.brand_id,
                    product_name: product.product_name,
                    category_id: product.category_id,
                })
                .then(response => {
                    console.log('Product created:', response.data);
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('Error creating product:', error);
                });
        },


        fetchCredential() {
            return axiosInstance
                .get(`api/get-credential-data`)
                .then(response => {
                    this.brand = response.data.brand;
                    this.products = response.data.product;
                    this.category = response.data.category;
                })
                .catch(error => {
                    console.error('Error fetching main data:', error);
                });
        },
    },

}
</script>

<style scoped>
* {
    font-size: 12pt !important;
}
</style>
