<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Product Detail</h3>

                    <select v-model="selectedBrand" @change="loadProductsByBrand" class="form-select">
                        <option v-for="brand in brands" :key="brand.id" :value="brand.id">
                            {{ brand.brand_name }}
                        </option>
                    </select>
<br/>
                    <button @click="updateCheckedQuantities" class="btn btn-success">Update Checked Quantities</button>

                    <table class="table w-100 responsive">
                        <thead>
                            <tr>
                                <th width="15%">Image</th>
                                <th width="15%">Sku</th>
                                <th width="15%">Title</th>
                                <th>Quantity</th>
                                <th>New Quantity</th>
                                <th width="15%">Select</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="product in allProductDetails" :key="product.id">
                                <td><img :src="'https://api.gocami.com/product_detail/' + product.image" width="75" />
                                </td>
                                <th>{{ product.sku }}</th>
                                <th>{{ product.title }}</th>
                                <th>{{ product.quantity }}</th>
                                <td><input type="number" class="form-control w-75" v-model="product.newQuantity" /></td>
                                <td><input type="checkbox" class="form-check-input" v-model="product.checked" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    data() {
        return {
            allProductDetails: [],
            currentPage: 1,
            selectedBrand: null,
            brands: [],
        };
    },

    created() {
        this.userLoad();
    },

    methods: {

        loadProductsByBrand() {
            if (this.selectedBrand) {
                this.userLoad(this.selectedBrand);
            } else {
                this.userLoad();
            }
        },

        userLoad(brandId) {
            var nextPage = `api/get-quantity-data/${brandId}`;
            axiosInstance.get(nextPage).then(({ data }) => {
                this.allProductDetails = data.products.map(product => ({ ...product, checked: false }));
                this.brands = data.brands;
            });
        },

        updateCheckedQuantities() {
            // Filter only the checked products
            const checkedProducts = this.allProductDetails.filter(product => product.checked);

            // Iterate over checked products and update their quantities
            checkedProducts.forEach(product => {
                const oldQuantity = product.quantity;
                const newQuantity = parseInt(product.newQuantity);
                const productDetailId = product.id;

                // Update product quantity and insert into product_quantity_checks
                axiosInstance.put('api/update-quantity-and-check', {
                    productDetailId,
                    oldQuantity,
                    newQuantity
                }).then(response => {
                    console.log(response);
                    product.quantity = newQuantity;
                    product.newQuantity = ''; // Clear input field after update
                }).catch(error => {
                    console.log(error);
                });
            });
        }
    }
}
</script>
