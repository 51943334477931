<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <h3 class="mb-5">Affiliate Panel</h3>

                    <table class="table w-100">
                        <thead>
                            
                            <tr>
                                <th>#</th>
                                <th>User Name</th>
                                <th>Note</th>
                                <th>Phone</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-for="user in userInvoices" :key="user.id">
                                <th scope="row">{{ user.id }}</th>
                                <th scope="row">{{ user.first_name }} {{ user.middle_name }} {{ user.last_name }}</th>
                                <th scope="row">{{ user.note }}</th>
                                <th scope="row">{{ user.phone }}</th>
                                <td>
                                    <router-link :to="{ name: 'UserAffiliate', params: { id: user.id } }"
                                        class="btn btn-warning me-2">
                                        <i class="fa fa-eye"></i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
          
<script>
import axiosInstance from '../../../../../axiosInstance';

export default {
    name: "UserAffiliate",

    data() {
        return {
            userInvoices: [],
        };
    },

    created() {
        this.orderLoad();
    },

    methods: {
        orderLoad() {
            var page = `api/user-invoice/${this.$route.params.id}`;
            axiosInstance.get(page).then(({ data }) => {
                console.log(data);
                this.userInvoices = data.userInvoice;
            });
        },

    },
};

</script>
          
