<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save" enctype="multipart/form-data">
                        <input type="text" class="form-control" placeholder="Create Bag Name"
                            v-model="bag.bag_name" /><br /><br />
                            <input type="text" class="form-control" placeholder="Create Bag price"
                            v-model="bag.bag_price" /><br /><br />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <button type="submit" class="btn btn-primary">Create Bag</button>
                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "CreateBag",

    data() {
        return {
            bag: {
                bag_name: "",
                bag_price: "",
                bag_image: ""
            },
        };
    },

    methods: {
        onFileChange(event) {
            this.bag.bag_image = event.target.files[0];
        },

        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('bag_name', this.bag.bag_name);
            formData.append('bag_price', this.bag.bag_price);
            formData.append('bag_image', this.bag.bag_image);
            axiosInstance.post(`api/bag-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/bag-view");
            });
        },
    },
};
</script>