<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <input type="text" class="form-control" placeholder="Filter Type Name"
                            v-model="filtertype.product_filter" /><br /><br />

                        <select v-model="filtertype.filter_id" class="form-control mb-3">
                            <option value="0" selected disabled hidden>-- Choose Filter --</option>
                            <option v-for="filter in filters" :key="filter.id" :value="filter.id">
                                {{ filter.filter_name }}
                            </option>
                        </select>

                        <button type="submit" class="btn btn-success">Create</button>

                    </form>

                </div>
            </div>
        </div>

    </div>
</template>
    

<script>
import router from "@/router";
import axiosInstance from '../../../../../axiosInstance';


export default {
    name: "CreateFilterType",

    data() {
        return {
            filtertype: {
                filter_id: 0,
                product_filter: "",
            },
            filters: [],
        };
    },

    methods: {
        save() {
            this.saveData();
        },
        saveData() {
            let formData = new FormData();
            formData.append('filter_id', this.filtertype.filter_id);
            formData.append('product_filter', this.filtertype.product_filter);
            axiosInstance.post(`api/filtertype-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/filtertype");
            });
        },
        fetchFilters() {
            axiosInstance
                .get(`api/filtertype-create`) 
                .then(response => {
                    this.filters = response.data;
                });
        },
    },
    mounted() {
        this.fetchFilters();
    },
};
</script>
