<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="editData" enctype="multipart/form-data">
                        <input type="hidden" v-model="logo.id" />

                        <input type="file" accept="image/*" @change="onFileChange" class="form-control mb-3" />

                        <progress v-if="logo.progress" :value="logo.progress.percentage" max="100">
                            {{ logo.progress.percentage }}%
                        </progress>

                        <button type="submit" class="btn btn-success">Save</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import axiosInstance from '../../../../../axiosInstance';
import router from "@/router";

export default {
    name: "EditLogo",

    data() {
        return {
            logo: {
                logo: "",
                id: this.$route.params.id,
            },
            oldLogo: {}, // new data property to store the old employee data
        };
    },
    methods: {
        onFileChange(event) {
            this.logo.logo = event.target.files[0];
        },
        editData() {
            let formData = new FormData();
            if (this.logo.logo) {
                formData.append('logo', this.logo.logo);
            }
            axiosInstance.post(`api/logo-update/${this.logo.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.logo = {
                    logo: "",
                    id: "",
                };
                router.push("/logo-view");
            }).catch((error) => {
                console.log(error);
            });
        },
    }
};
</script>