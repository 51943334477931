<template>
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <title>Admin Dashboard</title>

  </head>

  <body>
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <AdminNavbar />
      <!-- partial -->
      <div class="container-fluid page-body-wrapper">
        <!-- partial:partials/_sidebar.html -->
        <AdminSidebar />
        <!-- partial -->
        <router-view />
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>

  </body>
</template>
  
<style></style>
  
<script>

import AdminNavbar from "../Admin/shared/AdminNavbar.vue"
import AdminSidebar from "../Admin/shared/AdminSidebar.vue"

export default {
  components: { AdminNavbar, AdminSidebar }
}

</script>

<style scoped>
@import url("../../../public/admin_assets/plugins/fontawesome-free/css/all.min.css");
@import url("../../../public/admin_assets/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css");
@import url("../../../public/admin_assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css");
@import url("../../../public/admin_assets/plugins/jqvmap/jqvmap.min.css");
@import url("../../../public/admin_assets/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");

@import url("../../../public/admin_assets/plugins/daterangepicker/daterangepicker.css");
@import url("../../../public/admin_assets/plugins/summernote/summernote-bs4.min.css");
@import url("../../../public/admin_assets/dist/css/adminlte.min.css");
</style>
  