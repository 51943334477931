<script>
import axiosInstance from '../../../../../axiosInstance';
import {useUserStore} from "../../../../../store";

export default {
  setup() {
    const store = useUserStore();
    const user = store.user;
    return {
      user,
    };
  },
  data() {
    return {
      carts: [],
      rating: [], // Change rating to an array
      showSuccessMessage: false,
      message: '',
      isError: false,

    };
  },

  created() {
    this.loadCatalogues();
  },

  beforeRouteEnter(to, from, next) {
    if (to.query.addToCart) {
      next(vm => {
        vm.showSuccessMessage = true;
        setTimeout(() => {
          vm.showSuccessMessage = false;
        }, 5000);
      });
    } else {
      next();
    }
  },

  methods: {

    loadCatalogues() {
      var page = `api/get-affiliate-products?user_id=${this.user?.id}`;
      axiosInstance.get(page).then(({data}) => {
        this.carts = data.cart;
        // Initialize rating array with default values for each product
        this.rating = this.carts.map(() => ({
          feedback: '',
          rating: '',
          image: [],
        }));
      });
    },

    rate(index) {
      this.saveRate(index); // Pass index of the product being rated
    },

    onFileChange(index, e) {
      // Convert FileList to array
      this.rating[index].image = Array.from(e.target.files);
    },

    removeImage(index, imageIndex) {
      if (Array.isArray(this.rating[index].image)) {
        this.rating[index].image.splice(imageIndex, 1);
      } else {
        console.error('image is not an array');
      }
    },

    getImageUrl(file) {
      // Check if createObjectURL is available
      if (window.URL && window.URL.createObjectURL) {
        return URL.createObjectURL(file);
      } else {
        // Handle the case where createObjectURL is not available (e.g., use a placeholder)
        console.error('createObjectURL is not supported in this environment.');
        return ''; // Provide a default or placeholder URL
      }
    },

    saveRate(index) {
      if (this.rating[index]) {
        let formData = new FormData();
        for (let i = 0; i < this.rating[index].image.length; i++) {
          formData.append('image[]', this.rating[index].image[i]);
        }
        formData.append("rating", this.rating[index].rating);
        formData.append("feedback", this.rating[index].feedback);
        formData.append("product_detail_id", this.carts[index].product_detail_id);
        formData.append("user_id", this.user?.id);

        axiosInstance
            .post(`api/rate-store`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then((response) => {
              if (response.status === 200) {
                this.showSuccessMessage = true;
                this.message = "Rate has been added successfully"; // Update success message
                this.isError = false; // Reset isError to false (no error)
              } else {
                if (response.data && response.data.error) {
                  this.message = response.data.error;
                } else {
                  this.message = "Failed to add to rate"; // Update error message
                }
                this.isError = true; // Set isError to true (error)
                this.showSuccessMessage = true; // Show the toast message
              }
              this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
            })
            .catch((error) => {
              this.message = "Failed to add to rate"; // Update error message
              this.isError = true; // Set isError to true (error)
              this.showSuccessMessage = true; // Show the toast message
              console.error("Failed to add to rate", error);
              this.hideMessageAfterDelay(5000); // Hide message after 5 seconds (5000 milliseconds)
            });
      }
    },

    hideMessageAfterDelay(delay) {
      setTimeout(() => {
        this.showSuccessMessage = false; // Hide the toast message after the specified delay
      }, delay);
    },

  },
};
</script>

<template>
  <div class="toast" :class="{ 'show': showSuccessMessage, 'error': isError }">
    {{ message }}
  </div>

  <div class="main-panel">
    <div class="content-wrapper">
      <div class="card">
        <div class="card-body">

          <h3 class="mb-5">Products Rate</h3>

          <table class="table w-100">
            <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Sku</th>
              <th>Title</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(cart, index) in carts" :key="cart.id">
              <th scope="row">{{ index + 1 }}</th>
              <td><img :src="'https://api.gocami.com/product_detail/' + cart.image" width="100"/></td>
              <td>{{ cart.sku }}</td>
              <td>{{ cart.title }}</td>
              <td>
                <form @submit.prevent="saveRate(index)">
                  <div class="star-rating">
                    <input type="radio" name="rating" v-model="rating[index].rating" :value="5" :id="'star5_' + index">
                    <label :for="'star5_' + index"></label>

                    <input type="radio" name="rating" v-model="rating[index].rating" :value="4" :id="'star4_' + index">
                    <label :for="'star4_' + index"></label>

                    <input type="radio" name="rating" v-model="rating[index].rating" :value="3" :id="'star3_' + index">
                    <label :for="'star3_' + index"></label>

                    <input type="radio" name="rating" v-model="rating[index].rating" :value="2" :id="'star2_' + index">
                    <label :for="'star2_' + index"></label>

                    <input type="radio" name="rating" v-model="rating[index].rating" :value="1" :id="'star1_' + index">
                    <label :for="'star1_' + index"></label>
                  </div>

                  <div class="feedback-container">
                    <label class="feedback-label">Feedback:</label>
                    <textarea class="feedback-input form-control" v-model="rating[index].feedback" rows="4"></textarea>
                  </div>

                  <input type="file" class="form-control mt-2" multiple @change="onFileChange(index, $event)"/>

                  <div v-if="rating[index].image && rating[index].image.length > 0">
                    <h4>Selected Images:</h4>
                    <div class="row d-flex">
                      <div class="col-3" v-for="(file, imageIndex) in rating[index].image" :key="imageIndex">
                        <img :src="getImageUrl(file)" alt="Selected Image" class="selected-image"/>
                        <button @click="removeImage(index, imageIndex)" style="border-radius: 50px !important">
                          <i class="fa fa-trash text-danger fs-3"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <button class="btn btn-primary mt-4">Submit</button>

                </form>

              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>
.star-rating {
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
  /* Display stars from right to left */
  font-size: 0;
  /* Prevent inline-block spacing */
  align-items: center;
}

.star-rating input {
  display: none;
}

.star-rating label {
  font-size: 24px !important;
  /* Adjust as needed */
  color: #ccc;
  cursor: pointer;
  margin: 0 2px;
}

.star-rating label:before {
  content: '\2605';
  /* Unicode character for star outline */
  display: block;
}

.star-rating input:checked ~ label,
.star-rating input:checked ~ label ~ label {
  color: #2e73bb;
  /* Color for filled star */
}


.toast {
  position: fixed !important;
  max-width: 50% !important;
  top: 20px !important;
  right: -100% !important;
  /* Start offscreen on the right */
  background-color: #28a745 !important;
  color: #fff !important;
  padding: 12px 16px !important;
  border-radius: 4px !important;
  transition: right 0.5s ease-in-out !important;
  z-index: 999999 !important;
}

.form-group {
  margin-bottom: 1.5rem;
}

.toast.error {
  position: fixed !important;
  max-width: 50% !important;
  top: 20px !important;
  right: -100% !important;
  /* Start offscreen on the right */
  background-color: #dc3545 !important;
  /* Background color for error */
  color: #fff !important;
  padding: 12px 16px !important;
  border-radius: 4px !important;
  transition: right 0.5s ease-in-out !important;
  z-index: 999999 !important;
}

.toast.show {
  right: 20px !important;
  /* Slide in to the desired position */
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

</style>