<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">

                    <form @submit.prevent="save">
                        <div class="flex flex-wrap max-w-xl">
                            <div class="p-2 text-2xl text-gray-800 font-semibold">
                                <h3>Register an account</h3>
                            </div>
                            <div class="p-2">
                                <label class="" for="name">First Name</label>
                                <input class="form-control" placeholder="First Name" type="text" v-model="form.first_name"
                                    required>
                            </div>
                            <div class="p-2">
                                <label class="" for="middle_name">Middle Name</label>
                                <input class="form-control" placeholder="Middle Name" type="text" v-model="form.middle_name"
                                    required>
                            </div>
                            <div class="p-2">
                                <label class="" for="last_name">Last Name</label>
                                <input class="form-control" placeholder="Last Name" type="text" v-model="form.last_name"
                                    required>
                            </div>

                            <div class="p-2">
                                <label for="email">E-mail</label>
                                <input class="form-control" placeholder="Email" type="email" v-model="form.email" required>
                            </div>
                            <div class="p-2">
                                <label for="password">Password</label>
                                <input class="form-control" placeholder="Password" type="password" v-model="form.password"
                                    name="password" required>
                            </div>

                            <div class="p-2">
                                <label class="" for="address">Address</label>
                                <input class="form-control" placeholder="Address" type="text" v-model="form.address"
                                    required>
                            </div>

                            <div class="p-2">
                                <label class="" for="dob">Date Of Birth</label>
                                <input class="form-control" placeholder="date of birth" type="date" v-model="form.date_of_birth"
                                    required>
                            </div>

                            <div class="p-2">
                                <label class="" for="phone_number">First Number</label>
                                <input class="form-control" placeholder="First Number" type="text"
                                    v-model="form.phone_first" required>
                            </div>

                            <div class="p-2">
                                <label class="" for="second_number">Second Number</label>
                                <input class="form-control" placeholder="Second Number" type="text"
                                    v-model="form.phone_second" required>
                            </div>

                            <div class="p-2">
                                <label for="role">Roles</label>
                                <select v-model="form.roles" class="form-control" required>
                                    <option value="0">User</option>
                                    <option value="5">Sub Affiliate</option>
                                </select>
                            </div>

                            <div class="p-2 w-full mt-4">
                                <button type="submit" class="btn btn-primary">Register</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";

import router from "@/router";

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            form: {
                first_name: '',
                middle_name: '',
                last_name: '',
                address: '',
                phone_first: '',
                phone_second: '',
                date_of_birth: '',
                email: '',
                password: '',
                roles: 0
            },
            errors: []
        }
    },

    methods: {
        save() {
            this.saveData();
        },

        saveData() {
            if (!this.form.first_name || !this.form.middle_name || !this.form.last_name || !this.form.address ||
                !this.form.email || !this.form.password || !this.form.phone_first || !this.form.phone_second || !this.form.date_of_birth) {
                alert('Please fill out all required fields.');
                return;
            }

            let formData = new FormData();
            formData.append('first_name', this.form.first_name);
            formData.append('middle_name', this.form.middle_name);
            formData.append('last_name', this.form.last_name);
            formData.append('address', this.form.address);
            formData.append('phone_first', this.form.phone_first);
            formData.append('phone_second', this.form.phone_second);
            formData.append('email', this.form.email);
            formData.append('password', this.form.password);
            formData.append('date_of_birth', this.form.date_of_birth);
            formData.append('roles', this.form.roles);
            formData.append('user_id', this.user?.id);
            axiosInstance.post(`api/affiliate-user-store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                // Redirect to the desired page
                router.push("/user-affiliate");
            });
        },
        //     saveForm() {
        //         axios.post(`${BASE_URL}affiliate-user-store`, this.form).then(() => {

        //             router.push("/affiliate-user");
        //         }).catch((error) => {
        //             this.errors = error.response.data.errors;
        //         })
        //     }
    }
}

</script>
<style scoped>
* {
    font-size: 12pt !important;
}
</style>