<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <h3 class="mb-5">Tips</h3>

                    <router-link to="/data-entry-create-tips" class="btn btn-primary">Create Tips</router-link>

                    <table class="table w-100">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Brand</th>
                                <th>Tips</th>
                                <th>Status</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="tip in tips" v-bind:key="tip.id">
                                <th scope="row">{{ tip.id }}</th>
                                <td>{{ tip.brand_name }}</td>
                                <td>
                                    <p v-html="tip.tips_text"></p>
                                </td>
                                <td v-if="tip.status === 0"><span class="badge badge-success">Active</span></td>
                                <td v-else><span class="badge badge-danger">Not Active</span></td>
                                <td><router-link :to="{ name: 'DataEntryEditTips', params: { id: tip.id } }"
                                        class="btn btn-warning me-2"><i class="fa fa-pencil-square-o" style="font-size:14px !important"></i></router-link></td>
                                <td>
                                    <button @click="remove(tip)" class="btn btn-danger"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axiosInstance from '../../../../../axiosInstance';


export default {
    name: "DataEntryTipsView",

    data() {
        return {
            tips: [],
        };
    },

    created() {
        this.tipsLoad();
    },

    methods: {
        tipsLoad() {
            var page = `api/data-entry-tips`;
            axiosInstance.get(page).then(({ data }) => {
                this.tips = data;
            });
        },

        remove(tip) {
            var url = `api/data-entry-delete-tips/${tip.id}`;
            axiosInstance.delete(url).then(() => {
                const index = this.tips.indexOf(tip);
                if (index !== -1) {
                    this.tips.splice(index, 1);
                }
            });
        },

    },
};

</script>