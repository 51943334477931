<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="card">
                <div class="card-body">
                    <button @click="exportToExcel">Export to Excel</button>


                    <div>
                        <label for="from">From:</label>
                        <input type="date" v-model="filterFrom" @change="applyDateFilter" />
                    </div>
                    <div>
                        <label for="to">To:</label>
                        <input type="date" v-model="filterTo" @change="applyDateFilter" />
                    </div>

                    <table class="table w-100" ref="table">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>Total Sale Price</th>
                                <th>Total After Discount</th> <!-- Display total after discount -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(cart, index) in carts" :key="index">
                                <td>{{ cart.first_name }} {{ cart.last_name }}</td>
                                <td>$ {{ cart.total_sale_price.toFixed(2) }}</td>
                                <td>$ {{ cart.total_after_discount.toFixed(2) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import axiosInstance from '../../../../../axiosInstance';
import { useUserStore } from "../../../../../store";
import * as XLSX from 'xlsx'; // Import the entire XLSX module

export default {
    setup() {
        const store = useUserStore();
        const user = store.user;
        return {
            user,
        };
    },

    data() {
        return {
            carts: [],
        };
    },

    created() {
        this.userLoad();
    },

    methods: {
        exportToExcel() {
            const worksheet = XLSX.utils.table_to_sheet(this.$refs.table);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'excel_export.xlsx');
        },

        userLoad(startDate, endDate) {
            const page = `api/affiliate-result-point/${this.$route.params.id}`;
            const params = {
                from: startDate,
                to: endDate,
            };

            axiosInstance.get(page, { params })
                .then(({ data }) => {
                    console.log('API Response:', data);

                    // Assuming the array response directly represents carts data
                    if (Array.isArray(data)) {
                        const userCarts = {};
                        data.forEach(cart => {
                            const userId = cart.first_name + ' ' + cart.last_name;
                            if (!userCarts[userId]) {
                                userCarts[userId] = {
                                    first_name: cart.first_name,
                                    last_name: cart.last_name,
                                    total_sale_price: 0,
                                    total_after_discount: 0,
                                };
                            }

                            userCarts[userId].total_sale_price += cart.total_sale_price;
                            userCarts[userId].total_after_discount += cart.total_after_discount;
                        });

                        this.carts = Object.values(userCarts);
                        console.log('Updated carts:', this.carts);
                    } else {
                        console.error('API response is not in the expected format.');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        },


        applyDateFilter() {
            if (this.filterFrom && this.filterTo) {
                const startDate = new Date(this.filterFrom);
                const endDate = new Date(this.filterTo);

                // Format the dates as 'YYYY-MM-DD' before sending
                const formattedStartDate = startDate.toISOString().split("T")[0];
                const formattedEndDate = endDate.toISOString().split("T")[0];

                // Make the API request with formatted dates
                this.userLoad(formattedStartDate, formattedEndDate);
            }
        },
    },
};
</script>
  